import { Fragment, useCallback, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Drawer,
    Toolbar,
    Box,
    List,
    ListItemIcon,
    ListItemButton,
    Collapse,
    Typography,
    Link,
    DrawerProps,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import i18n from '../../../i18n';

// redux
import { useAppSelector, useWindowSize, useAppDispatch, useActiveModules } from '../../hooks';

// common components, interfaces, constants and helpers
import {
    BREAKPOINTS,
    HECTRONIC_WEBSITE_URL,
    LIVE_DATA_MODULE,
    MODAL_TYPES,
    SIDEBAR_WIDTH_MIN,
    URLS,
    servicesModulesMapping,
} from '../../../constants';
import {
    checkModuleRights,
    clearLSOnComponentLeave,
    closeModal,
    flattenArray,
    hasMarketplaceDependency,
    navigateBack,
    openLeavePageModal,
    sortByField,
} from '../../../helpers';

// subcomponents
import { MenuEntries } from './SideBarItems';
import { ComponentWidthProps, MenuType, ModulePermissions } from '../interfaces';

import { updateSelectedModule } from './userRightsDuck';
import { displayModal } from '../modal/modalDuck';

interface SideBarProps extends ComponentWidthProps {
    variant: DrawerProps['variant'];
    mobileOpen: boolean;
    handleMouseLeave: () => void;
    handleMouseEnter: () => void;
    handleDrawerOpen?: () => void;
}

const searchMenu = (path: string) => {
    for (const menu of MenuEntries) {
        if (menu.subMenu) {
            const subMenu = menu.subMenu.find((item) => item.path.includes(path));
            if (subMenu) return subMenu;
        }
        if (menu.path === path) {
            return menu;
        }
    }
    return null;
};

const flatMenuEntries = flattenArray(MenuEntries, 'subMenu');
const menuNames = flatMenuEntries.map((menuItem) => menuItem.title);

const specialPaths = ['/prices'];
export const getSideBarMenuByPath = (path: string) => {
    const specialPath = specialPaths.filter((item) => path.indexOf(item) > -1)[0];
    if (specialPath) {
        return flatMenuEntries.find((menuItem) => menuItem.path === specialPath);
    }

    const pathName = path.split('/').length > 2 ? `/${path.split('/')[1]}` : path;
    return flatMenuEntries.find((menuItem) => menuItem.path === pathName);
};

const getSubPages = (subMenus: MenuType[], accessibleMenuEntries: MenuType[]) => {
    const subPages: MenuType[] = [];
    subMenus.forEach((subMenu) => {
        if (accessibleMenuEntries.includes(subMenu)) {
            subPages.push(subMenu);
        }
    });
    return subPages;
};

const createMenuItems = (accessibleMenuEntries: MenuType[]) => {
    return accessibleMenuEntries.reduce((previousItem: MenuType[], currentItem: MenuType) => {
        if (currentItem.subMenu) {
            const subMenuPages: MenuType[] = getSubPages(
                currentItem.subMenu,
                accessibleMenuEntries
            );
            previousItem.push({ ...currentItem, subMenu: subMenuPages });
        } else {
            if (currentItem.key.indexOf('-') > -1 && previousItem.length > 0) {
                const parentIndex = currentItem.key.split('-')[0];
                const parentItem = previousItem.find((item) => item.key === parentIndex);
                if (!parentItem?.subMenu?.includes(currentItem)) {
                    previousItem.push(currentItem);
                }
            } else previousItem.push(currentItem);
        }
        return previousItem;
    }, []);
};

/**
 * Renders the sidebar component.
 *
 * @param {SideBarProps} props - The component props.
 * @param {string} props.variant - The variant of the drawer.
 * @param {boolean} props.mobileOpen - Whether the drawer is open on mobile.
 * @param {number} props.width - The width of the drawer.
 * @param {Function} props.handleMouseEnter - The function to handle mouse enter.
 * @param {Function} props.handleMouseLeave - The function to handle mouse leave.
 * @param {Function} props.handleDrawerOpen - The function to handle drawer open.
 * @return {JSX.Element} The rendered sidebar component.
 */
const SideBar = ({
    variant,
    mobileOpen,
    width,
    handleMouseEnter,
    handleMouseLeave,
    handleDrawerOpen,
}: SideBarProps): JSX.Element => {
    const location = useLocation();
    const [menuEntries, setMenuEntries] = useState<MenuType[]>([]);
    const { selectedModule, userRights } = useAppSelector((state) => state.userRights);
    const { payedComponents, payedModules } = useAppSelector((state) => state.payedServices);
    const { loggedUser } = useAppSelector((state) => state.auth);
    const { activeModules } = useAppSelector((state) => state.marketplace);
    const [selectedIndex, setSelectedIndex] = useState('1');
    const [openedKey, setOpenedKey] = useState<{ [index: string]: boolean }>({ 1: true });

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // update active modules for mandator
    useActiveModules();

    const { t } = useTranslation();
    const isTabletSize: boolean = width === SIDEBAR_WIDTH_MIN;

    const windowDimensions = useWindowSize();
    const isMobileSize = windowDimensions.width <= BREAKPOINTS.xs;

    const checkViewRight = useCallback((item: MenuType, moduleRights: ModulePermissions[]) => {
        return (
            moduleRights.findIndex(
                (right) => right.sub_component === item.title && right.view === true
            ) > -1
        );
    }, []);

    const getMenuItems = useCallback(() => {
        return flatMenuEntries.reduce((previousItem: MenuType[], currentValue: MenuType) => {
            //check if menu item is payed
            if (currentValue.payed) {
                const isPayed = payedComponents.get(currentValue.title ?? '');
                if (isPayed) {
                    previousItem.push(currentValue);
                }
            }
            //check if menu item has marketplace dependency
            else if (hasMarketplaceDependency(currentValue.title)) {
                const isActive = activeModules.includes(servicesModulesMapping[currentValue.title]);
                isActive && previousItem.push(currentValue);
            } else {
                previousItem.push(currentValue);
            }
            return previousItem;
        }, []);
    }, [activeModules, payedComponents]);

    const preferredPageAtLogin = () => {
        const preferredPage = flatMenuEntries.filter((menu) => loggedUser?.page === menu.key);
        const userRight = userRights.find((item) => item.sub_component === preferredPage[0]?.title);
        const hasRight = checkPageRights(payedComponents, userRight, activeModules);
        if (userRight && hasRight) {
            localStorage.setItem('selectedModule', userRight?.module ?? '');
            dispatch(updateSelectedModule({ name: userRight?.module }));
            navigate(preferredPage[0].path);
            return true;
        }
    };

    const sortPagesFromModule = (modulePages: ModulePermissions[] | string[]) => {
        const newModulePages = modulePages.map((item) => {
            const itemToCheck = typeof item === 'string' ? item : item.sub_component;
            const menuPage = flatMenuEntries.find((page) => page.title === itemToCheck);
            return {
                ...menuPage,
                key: menuPage?.key.includes('-')
                    ? Number(menuPage.key.replace('-', ''))
                    : Number(menuPage?.key),
            };
        });

        return sortByField(newModulePages, 'key');
    };

    const findAccessibleModuleAndPage = () => {
        const availableModulesAndPages = userRights.filter(
            (item) =>
                item.view === true &&
                payedModules.get(item.module ?? '') !== false &&
                payedComponents.get(item.sub_component ?? '') !== false &&
                menuNames.includes(item.sub_component ?? '') &&
                (hasMarketplaceDependency(item.sub_component ?? '')
                    ? activeModules.includes(servicesModulesMapping[item.sub_component ?? ''])
                    : true)
        );

        const pagesFromModule = availableModulesAndPages.filter(
            (pages) => pages.module === availableModulesAndPages[0].module
        );

        const sortedPages = sortPagesFromModule(pagesFromModule);

        if (sortedPages.length > 0) {
            localStorage.setItem('selectedModule', availableModulesAndPages[0].module ?? '');
            dispatch(updateSelectedModule({ name: availableModulesAndPages[0].module }));
            navigate(sortedPages[0]?.path ?? '');
        }
    };

    const findAccessiblePageFromModule = (moduleName: string) => {
        const availableRightsForModule = userRights.filter(
            (item) =>
                item.module === moduleName &&
                item.view === true &&
                menuNames.includes(item.sub_component ?? '')
        );
        const modulePages = availableRightsForModule.map((right) => right.sub_component);

        const pages = modulePages.filter(
            (item) =>
                payedComponents.get(item ?? '') !== false &&
                (hasMarketplaceDependency(item ?? '')
                    ? activeModules.includes(servicesModulesMapping[item ?? ''])
                    : true)
        );

        const sortedMenuPages = sortPagesFromModule(pages as string[]);

        if (sortedMenuPages.length > 0) {
            navigate(sortedMenuPages[0]?.path ?? '');
        }
    };

    const checkDashboardRights = () => {
        const rightForDashboard = userRights.filter(
            (item) => item.component === 'services.dashboard.title'
        );
        if (rightForDashboard[0]?.view === true) {
            localStorage.setItem('selectedModule', LIVE_DATA_MODULE);
            dispatch(updateSelectedModule({ name: LIVE_DATA_MODULE }));
        }
    };

    const checkPageRights = (
        payedComponent: Map<string, boolean | undefined>,
        rightForPage: ModulePermissions | undefined,
        enabledModules: string[]
    ) => {
        const marketplacePage = hasMarketplaceDependency(rightForPage?.sub_component ?? '');
        const isPageActivated = marketplacePage
            ? enabledModules.includes(servicesModulesMapping[rightForPage?.sub_component ?? ''])
            : true;
        if (
            rightForPage?.view === false ||
            payedComponent.get(rightForPage?.sub_component ?? '') === false ||
            isPageActivated === false
        ) {
            return false;
        }
        return true;
    };

    const setMenuKey = (menuKey: string) => {
        setSelectedIndex(menuKey);
        const isSubmenuKey = menuKey.includes('-');
        const parentKey = menuKey.split('-')[0];
        if (!openedKey[parentKey] && isSubmenuKey)
            setOpenedKey({
                [parentKey]: true,
            });
    };

    const checkPagePermission = (menuItem: MenuType | undefined) => {
        const currentRight = userRights.find((right) => right.sub_component === menuItem?.title);
        const currentModule = { name: currentRight?.module ?? localStorage.selectedModule };

        const moduleRight = checkModuleRights(
            currentModule,
            userRights,
            payedModules,
            undefined,
            activeModules
        );

        if (!moduleRight) {
            findAccessibleModuleAndPage();
            return;
        }
        const pageRight = checkPageRights(payedComponents, currentRight, activeModules);
        if (!pageRight) {
            findAccessiblePageFromModule(currentModule.name);
        }
        if (currentRight) {
            dispatch(updateSelectedModule(currentModule));
            localStorage.setItem('selectedModule', currentModule.name);
        }
    };

    useEffect(() => {
        if (userRights.length > 0) {
            if (!localStorage.selectedModule) {
                // check where the user should navigate at login
                if (loggedUser?.page) {
                    // navigate to this page and module
                    const hasRightsPreferred = preferredPageAtLogin();
                    if (hasRightsPreferred) {
                        return;
                    }
                } else {
                    checkDashboardRights();
                }
            }

            const menuItem = getSideBarMenuByPath(location.pathname);
            // check page permission
            checkPagePermission(menuItem);
            const menuKey = menuItem?.key ?? '1';
            setMenuKey(menuKey);
        }
    }, [location.pathname, userRights]);

    // create menu
    useEffect(() => {
        //check if Menu items are payed or have marketplace dependencies
        const menuItemsList: MenuType[] = getMenuItems();
        //get from Menu entries only the entities available for the selected module
        const moduleRights = userRights.filter((el) => {
            return el.module === localStorage.selectedModule;
        });

        const accessibleMenuEntries = menuItemsList.filter((menu) => {
            return checkViewRight(menu, moduleRights);
        });

        // recreate the menu
        const newMenuEntries = createMenuItems(accessibleMenuEntries);
        setMenuEntries(newMenuEntries);
    }, [selectedModule, userRights, getMenuItems, checkViewRight]);

    const navigateCallBack = (path: string, sideBarIndex: string) => {
        navigate(path);
        setSelectedIndex(sideBarIndex);
        setOpenedKey({
            [sideBarIndex.split('-')[0]]: true,
        });
    };

    const handleListItemClick = (index: string, path: string) => {
        handleDrawerOpen?.();
        if (localStorage.addOrEdit === 'true') {
            openLeavePageModal(() => navigateCallBack(path, index));
        } else {
            clearLSOnComponentLeave();
            const parentIndex = index.split('-')[0];
            index === parentIndex
                ? setOpenedKey({ [parentIndex]: !openedKey[parentIndex] })
                : setOpenedKey({ [parentIndex]: true });

            setSelectedIndex(index);
            if (path !== '') {
                navigate(path);
            }
        }
    };

    /**
     * Generate menu entries
     * @param menu - menu item
     */

    function generateMenu(menu: MenuType) {
        switch (menu.type) {
            case 'menu': {
                const icon =
                    selectedIndex === menu.key ? (
                        <ListItemIcon className="selectedSidebarItem">{menu.icon}</ListItemIcon>
                    ) : (
                        <ListItemIcon>{menu.icon}</ListItemIcon>
                    );
                return (
                    <ListItemButton
                        key={menu.key}
                        onClick={() => handleListItemClick(menu.key, menu.path)}
                        selected={selectedIndex === menu.key}>
                        {menu.icon && icon}

                        {!isTabletSize && (
                            <Typography variant="h4">
                                {t(menu.customTitle ?? menu.title)}
                            </Typography>
                        )}
                    </ListItemButton>
                );
            }

            case 'subMenu': {
                const expandItem = () => {
                    if (menu.subMenu && menu.subMenu.length > 0) {
                        return openedKey[menu.key] ? (
                            <ExpandLess sx={{ width: '24px' }} />
                        ) : (
                            <ExpandMore sx={{ width: '24px' }} />
                        );
                    }
                };
                return (
                    <Fragment key={menu.key}>
                        <ListItemButton
                            onClick={() => handleListItemClick(menu.key, menu.path)}
                            selected={selectedIndex === menu.key}>
                            {menu.icon && selectedIndex === menu.key ? (
                                <ListItemIcon sx={{ color: '#fff' }}>{menu.icon}</ListItemIcon>
                            ) : (
                                <ListItemIcon>{menu.icon}</ListItemIcon>
                            )}

                            {!isTabletSize && (
                                <Typography variant="h4" sx={{ flex: 1 }}>
                                    {t(menu.title)}
                                </Typography>
                            )}

                            {!isTabletSize && expandItem()}
                        </ListItemButton>
                        <Collapse
                            in={openedKey[menu.key]}
                            timeout="auto"
                            unmountOnExit
                            sx={{ pl: { xs: 3.7, sm: 1.5, md: 1.5, lg: 3.7 } }}>
                            {!isTabletSize && (
                                <List disablePadding>
                                    {menu.subMenu &&
                                        menu.subMenu.map((subMenu: MenuType) => {
                                            return generateMenu(subMenu);
                                        })}
                                </List>
                            )}
                        </Collapse>
                    </Fragment>
                );
            }
        }
    }

    const setMenuPage = () => {
        const currentPath = window.location.pathname;
        const menuItem = searchMenu(currentPath);
        if (menuItem) {
            setOpenedKey({ [menuItem.key.split('-')[0]]: true });
            const menuSelectedModule = userRights.filter(
                (el) => el.sub_component === menuItem.title
            );
            localStorage.setItem('selectedModule', menuSelectedModule[0]?.module ?? '');
            dispatch(updateSelectedModule({ name: menuSelectedModule[0]?.module }));
        }
        clearLSOnComponentLeave();
    };

    window.onpopstate = () => {
        if (localStorage.addOrEdit) {
            window.history.go(1);
            dispatch(
                displayModal({
                    showModal: true,
                    title: i18n.t('general.messages.notSaved'),
                    type: MODAL_TYPES.ConfirmationMessage,
                    message: 'general.messages.leavePage',
                    onLeave: () => {
                        closeModal();
                        navigateBack();
                        setMenuPage();
                    },
                })
            );
        } else {
            setMenuPage();
        }
    };

    return (
        <Drawer
            variant={variant}
            sx={{
                width: `${width}px`,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: `${width}px`,
                    boxSizing: 'border-box',
                    border: 'none',
                    paddingRight: '10px',
                    overflow: 'hidden',
                },
            }}
            onMouseEnter={() => {
                flushSync(handleMouseEnter);
            }}
            onMouseLeave={() => {
                flushSync(handleMouseLeave);
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
                hideBackdrop: !isMobileSize ? true : false,
            }}
            transitionDuration={300}
            open={mobileOpen}
            role="sidebar">
            <Toolbar />
            <Box sx={{ overflow: 'auto', flex: 1 }}>
                <List>
                    {menuEntries.map((item: MenuType) => {
                        return generateMenu(item);
                    })}
                </List>
            </Box>
            {mobileOpen && width !== SIDEBAR_WIDTH_MIN && (
                <>
                    <Box
                        sx={{
                            overflow: 'auto',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            display: 'flex',
                        }}>
                        <Link
                            href={HECTRONIC_WEBSITE_URL}
                            color="secondary"
                            underline="none"
                            target="blank"
                            role="about-link">
                            <Typography variant="caption">{t('general.labels.about')}</Typography>
                        </Link>
                        <Link
                            href={`${HECTRONIC_WEBSITE_URL}/de/service-kontakt/ansprechpartner`}
                            color="secondary"
                            underline="none"
                            target="blank"
                            role="help-link">
                            <Typography variant="caption">{t('general.labels.help')}</Typography>
                        </Link>
                        <Link
                            href={`${URLS.EulaFile}/EULA-DE.pdf`}
                            color="secondary"
                            underline="none"
                            target="_blank">
                            <Typography variant="caption">EULA</Typography>
                        </Link>
                    </Box>

                    <Box
                        sx={{
                            overflow: 'auto',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        <Typography variant="caption" sx={{ textAlign: 'center' }}>
                            @{moment().format('YYYY')} By Hectronic GmbH
                        </Typography>
                    </Box>
                </>
            )}
        </Drawer>
    );
};

export default SideBar;
