import { COLUMN_VISIBLE, CUSTOM_COLUMN_MIN_WIDTH, MIN_COLUMN_WIDTH } from '../../../constants';
import { IGridColumn } from '../../common/interfaces';

export const getProviderSiteDeviceColumns = (): IGridColumn[] => [
    {
        field: 'name',
        title: 'hostProvider.provider',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'enabledSite',
        title: 'digitalReceipts.siteAssignmentCount',
        minWidth: MIN_COLUMN_WIDTH,
        filter: 'numeric',
        show: COLUMN_VISIBLE,
        sortable: false,
    },
    {
        field: 'enabledDevice',
        title: 'devices.deviceCount',
        filter: 'numeric',
        minWidth: CUSTOM_COLUMN_MIN_WIDTH,
        show: COLUMN_VISIBLE,
        sortable: false,
    },
];
