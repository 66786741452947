import { useEffect, useCallback, createRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Typography,
} from '@mui/material';
import { Email, Visibility, VisibilityOff } from '@mui/icons-material';

// redux
import { useAppDispatch, useAppSelector, useTranslateFormErrors } from '../hooks';
import { login, loadUser } from './authDuck';

// common components, interfaces, constants and helpers
import { AuthHeader, ErrorComponent } from '.';
import { getStringValidations, getCognitoPasswordValidations, sanitizeFields } from '../../helpers';
import './auth.scss';

interface LoginFormValues {
    email: string;
    password: string;
    showPassword: boolean;
}

/**
 * Renders the login component.
 *
 * @return {JSX.Element} The rendered login component.
 */
const Login = (): JSX.Element => {
    const { t } = useTranslation();
    const { isAuthenticated, error, newPasswordRequired } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const initialValues: LoginFormValues = { email: '', password: '', showPassword: false };
    const formRef = createRef<FormikProps<LoginFormValues>>();
    useTranslateFormErrors(formRef);

    const validationSchema = useCallback(
        () =>
            Yup.object().shape({
                email: getStringValidations(5, 70, t('general.labels.email'), true).email(
                    t('general.validations.email')
                ),
                password: getCognitoPasswordValidations(8, 20, t('password.customLabel'), true),
            }),
        [t]
    );
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(loadUser());
        }
    }, [isAuthenticated, dispatch]);
    /**
     * If login is successful then dashboard page is displayed
     * @param fields
     */
    const handleLogin = async (fields: LoginFormValues) => {
        fields = sanitizeFields(fields);
        await dispatch(login({ username: fields.email, password: fields.password }));
    };
    if (newPasswordRequired) return <Navigate to="/reset-password" />;
    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <>
            <AuthHeader />
            <Grid
                container
                alignItems="center"
                justifyContent="space-evenly"
                className="login-page">
                <Grid item xs={8} sm={7} md={5} lg={4} xl={3}>
                    <Box className="login-content" sx={{ p: 5 }}>
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            onSubmit={(fields) => {
                                handleLogin(fields);
                            }}
                            validationSchema={validationSchema}>
                            {({ values, touched, errors, handleChange, handleBlur, setValues }) => (
                                <Form className="login-form">
                                    <Typography variant="h1" className="white-text" sx={{ mb: 2 }}>
                                        {t('authentication.login.title')}
                                    </Typography>
                                    {error && <ErrorComponent error={error} />}
                                    <FormControl variant="standard" sx={{ my: 1 }}>
                                        <InputLabel htmlFor="email" className="form-input">
                                            {t('general.labels.email')}
                                        </InputLabel>
                                        <Input
                                            id="email"
                                            name="email"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-input"
                                            defaultValue={values.email || ''}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Email className="form-input" />
                                                </InputAdornment>
                                            }
                                            inputProps={{ role: 'email' }}
                                        />
                                        <FormLabel className="form-error" role="error-email">
                                            {errors.email && touched.email ? errors.email : ''}
                                        </FormLabel>
                                    </FormControl>
                                    <FormControl variant="standard" sx={{ my: 1 }}>
                                        <InputLabel htmlFor="password" className="form-input">
                                            {t('password.customLabel', { val: '' })}
                                        </InputLabel>
                                        <Input
                                            type={values.showPassword ? 'text' : 'password'}
                                            name="password"
                                            id="password"
                                            className="form-input"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputProps={{ role: 'password' }}
                                            defaultValue={values.password || ''}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="form-input"
                                                        sx={{ padding: 0 }}
                                                        onClick={() =>
                                                            setValues({
                                                                ...values,
                                                                showPassword: !values.showPassword,
                                                            })
                                                        }>
                                                        {values.showPassword ? (
                                                            <Visibility className="form-input" />
                                                        ) : (
                                                            <VisibilityOff className="form-input" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormLabel className="form-error" role="error-password">
                                            {errors.password && touched.password
                                                ? errors.password
                                                : ''}
                                        </FormLabel>
                                    </FormControl>
                                    <Button
                                        role="submit-button"
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        className="form-submit-button">
                                        {t('authentication.login.button')}
                                    </Button>
                                    <Button
                                        href="/forgot-password"
                                        className="mb-3 mx-auto forgot-pass-text white-text">
                                        {t('authentication.forgotPassword.title')}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
export default Login;
