import { ComponentType } from 'react';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';
import { IGridColumn } from '../../common/interfaces';
import { GridCellProps } from '@progress/kendo-react-grid';

export const getHistoryColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => [
    {
        field: 'change_time',
        title: 'general.history.dateOfModification',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        filter: 'date',
        sortable: false,
    },
    {
        field: 'before_value',
        title: 'general.history.previous',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        sortable: false,
        filter: 'text',
        cell: customCells.get('beforeChanges'),
    },
    {
        field: 'after_value',
        title: 'general.history.current',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        sortable: false,
        filter: 'text',
        cell: customCells.get('afterChanges'),
    },
    {
        field: 'user',
        title: 'general.history.updatedBy',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        filter: 'text',
        sortable: false,
    },
];
