import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// create custom language detection
const lngDetector = new LanguageDetector();
lngDetector.addDetector({
    name: 'customLanguageDetector',
    lookup() {
        const browserLangCode = window.navigator.language;
        return browserLangCode.substring(0, 2);
    },
});
i18n.use(Backend)
    .use(lngDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        initImmediate: false,
        detection: {
            order: ['localStorage', 'customLanguageDetector'],
            caches: ['localStorage'],
        },
        backend: {
            loadPath: '/locale/{{lng}}.json',
        },
        interpolation: {
            defaultVariables: {
                entity: '',
                specialCharacter: '',
                entitiesSeparator: '',
                entity2: '',
                unitMeasure: '',
                action: '',
                val: '',
                parent: '',
                index: 1,
            },
        },
    });
i18n.services.formatter?.add('lowercase', (value) => value.toLowerCase());
i18n.services.formatter?.add('uppercase', (value) => value.toUpperCase());
i18n.services.formatter?.add(
    'capitalize',
    (value) => `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`
);
export default i18n;
