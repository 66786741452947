import { DataResult } from '@progress/kendo-data-query';
import { CustomDataState } from '../../common/interfaces';

export interface HistoryStateTypes {
    histories: DataResult;
}

export enum HistoryResources {
    Site = 'site',
    Point = 'point',
    Device = 'device',
    Price = 'price',
    Product = 'product',
    FieldTranslation = 'mandator_field_translation',
    EmailTemplate = 'email_template',
}
export interface HistoryGetReqProps {
    resource_name: `${HistoryResources}`;
    resource_id: string;
    url: string;
    data_state: CustomDataState;
}
export interface History {
    user: string;
    change_time: string;
    before_value: object;
    after_value: object;
}
