import { Circle } from '@mui/icons-material';
import { GridCellProps } from '@progress/kendo-react-grid';

interface StatusCellProps extends GridCellProps {
    component?: JSX.Element;
}

/**
 * Renders a custom cell for displaying a status in a Kendo React Grid.
 *
 * @param {StatusCellProps} props - The props object containing the field, dataItem, component, className, and style.
 * @param {string} props.field - The field name to which the cell is bound.
 * @param {Record<string, any>} props.dataItem - The data item of the row.
 * @param {JSX.Element} [props.component] - An optional component to render alongside the status icon.
 * @param {string} [props.className] - Additional class name for the cell.
 * @param {React.CSSProperties} [props.style] - Additional inline styles for the cell.
 * @return {JSX.Element} The JSX element representing the custom cell.
 */
const StatusCell = ({
    field,
    dataItem,
    component,
    className,
    style,
}: StatusCellProps): JSX.Element => {
    const iconColor = dataItem[field || ''] ? 'success' : 'error';

    return (
        <td className={`${className} text-center`} style={style}>
            <span>
                <Circle color={iconColor} />
            </span>
            {component}
        </td>
    );
};
export default StatusCell;
