import { lookup } from 'country-data';
import moment from 'moment-timezone';

import {
    LANGUAGES,
    NumberSeparators,
    DAY_FORMAT,
    MONTH_FORMAT,
    YEAR_FORMAT,
    HOUR_FORMAT,
    MINUTES_FORMAT,
    DEFAULT_CURRENCY,
    DEFAULT_DATE,
} from '../constants';
import { NumberSeparatorsTypes } from '../app/common/interfaces';

export const convertEnabledToBoolean = (enabled: string) => {
    switch (enabled) {
        case 'active':
            return true;
        case 'inactive':
            return false;
        default:
            return undefined;
    }
};

export const convertCountryNameToCode = (countryName: string) => {
    const country = lookup.countries({ name: capitalizeString(countryName) })[0];
    return country ? country.alpha2 : 'GB';
};

export const capitalizeString = (str: string) => {
    return str ? `${str.substring(0, 1).toUpperCase()}${str.slice(1)}` : '';
};

export const addCountryCodeToLanguage = (lng: string) => {
    const countryCode = LANGUAGES.find((language) => language.code === lng)?.countryCode;
    return `${lng}-${countryCode}` ?? 'en-GB';
};

const getDateFormatFromDateTimeFormat = (dateTimeFormat: string) => {
    let dateFormat = '';
    const splittedDateTimeFormat = dateTimeFormat.split(' ');
    if (splittedDateTimeFormat.length > 3) {
        dateFormat =
            `${splittedDateTimeFormat[0]} ${splittedDateTimeFormat[1]} ${splittedDateTimeFormat[2]}`.replace(
                ',',
                ''
            );
    } else {
        dateFormat = splittedDateTimeFormat[0].replace(',', '');
    }
    return dateFormat;
};

export const convertHourToLocale = (lang: string, utcHour: string) => {
    const countryCode = LANGUAGES.find((language) => language.code === lang)?.countryCode ?? 'GB';
    const timezone = moment.tz.zonesForCountry(countryCode)[0];
    const momentDateAndTime = moment.utc(`${DEFAULT_DATE} ${utcHour}`).tz(timezone);
    return Number(momentDateAndTime?.format().slice(11, 13));
};

export const getFormats = (lng: string) => {
    const todayDate = Intl.DateTimeFormat(lng, {
        dateStyle: 'short',
        timeStyle: 'short',
    }).formatToParts(new Date());

    const dateFormat = todayDate.map((el) => {
        switch (el.type) {
            case 'year':
                return YEAR_FORMAT;
            case 'day':
                return DAY_FORMAT;
            case 'month':
                return MONTH_FORMAT;
            case 'hour':
                return HOUR_FORMAT;
            case 'minute':
                return MINUTES_FORMAT;
            case 'dayPeriod':
                return '';
            default:
                return el.value;
        }
    });

    const numberFormat = Intl.NumberFormat(lng).formatToParts(18988999999.2);
    const decimalSeparator =
        (numberFormat.find((item) => item.type === 'decimal')?.value as NumberSeparatorsTypes) ??
        '.';
    const thousandsSeparator =
        (numberFormat.find((item) => item.type === 'group')?.value as NumberSeparatorsTypes) ?? ',';

    const date = getDateFormatFromDateTimeFormat(dateFormat.join(''));

    return {
        dateFormat: date,
        dateTimeFormat: dateFormat.join(''),
        decimalSeparator: decimalSeparator,
        thousandsSeparator: thousandsSeparator,
        momentDateTimeFormat: dateFormat
            .join('')
            .replace(DAY_FORMAT, convertStringToUpperCase(DAY_FORMAT))
            .replace(YEAR_FORMAT, convertStringToUpperCase(YEAR_FORMAT)),
        momentDateFormat: convertStringToUpperCase(date),
    };
};

export const convertNumberToString = (
    data: number | string | null | undefined,
    language: string,
    options?: Intl.NumberFormatOptions
) => {
    if (typeof data === 'string') {
        return parseFloat(data).toLocaleString(language, options);
    } else {
        return data !== null && data !== undefined ? data?.toLocaleString(language, options) : '';
    }
};

export const convertStringToNumber = (
    number: string,
    decimalSeparator: string,
    thousandsSeparator: string
) => {
    let value = number;
    if (decimalSeparator === NumberSeparators.Comma) {
        if (thousandsSeparator === NumberSeparators.Point) {
            value = value.replaceAll(NumberSeparators.Point, '');
        }
        value = value.replace(NumberSeparators.Comma, NumberSeparators.Point);
    } else if (
        decimalSeparator === NumberSeparators.Point &&
        thousandsSeparator === NumberSeparators.Comma
    ) {
        value = value.replaceAll(NumberSeparators.Comma, '');
    }
    return parseFloat(value.replace(/\s/g, ''));
};

export const convertStringToUpperCase = (str: string) => {
    return str.toUpperCase();
};

export const convertNumberToCurrencyString = (
    data: number,
    language: string,
    currency = DEFAULT_CURRENCY,
    unitMeasure?: string
): string => {
    if (!unitMeasure) {
        return `${convertNumberToString(data, language)} ${currency}`;
    }
    return `${convertNumberToString(data, language)} ${currency}/${unitMeasure}`;
};

export const convertCoordinates = (lat: number, lng: number, language: string) => {
    return `${convertNumberToString(lat, language)}, ${convertNumberToString(lng, language)}`;
};

export const htmlEncode = (htmlString: string) => {
    return htmlString
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
};

export const htmlDecode = (str: string) => {
    if (str && typeof str === 'string') {
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
    }

    return str;
};
