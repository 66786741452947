import { ChartColors } from '.';
export const PAYMENT_METHODS_TITLE = 'charts.paymentMethods';
export const SITES_RANKING_TITLE = 'charts.sitesRanking';
export const DEFAULT_CHART_TYPE = 'column';

export const MOCKED_CHARTS_DATA = [
    {
        title: PAYMENT_METHODS_TITLE,
        data: [
            {
                value: [12179.4],
            },
            {
                value: [8866],
            },
            {
                value: [7865],
            },
            {
                value: [4356],
            },
            {
                value: [1942],
            },
            {
                value: [1792],
            },
        ],
        formatting: 'n',
    },
    {
        title: SITES_RANKING_TITLE,
        data: [
            {
                value: [0.34],
            },
            {
                value: [0.22],
            },
            {
                value: [0.12],
            },
            {
                value: [0.1],
            },
            {
                value: [0.14],
            },
            {
                value: [0.08],
            },
        ],
        formatting: 'p',
    },
];

export const MOCKED_CHART_COLORS = [
    ChartColors.Green,
    ChartColors.Blue,
    ChartColors.Orange,
    ChartColors.Red,
    ChartColors.Yellow,
    ChartColors.Gray,
];
