import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { URLS } from '../../../constants';
import { Interceptor } from '../../common';

export interface Service {
    id?: string;
    text: string;
    items?: Service[];
    hovered?: boolean;
}

interface ServiceStateTypes {
    services: Service[];
}

const initialState: ServiceStateTypes = {
    services: [],
};

// create a thunk for get services
export const getServices = createAsyncThunk('services/list', async (_, { rejectWithValue }) => {
    try {
        // axios request
        const response = await Interceptor().get(URLS.Services);
        return response.data;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error.response);
    }
});

// reduces
const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getServices.fulfilled, (state, { payload }) => {
            state.services = [...payload.data];
        });
    },
});

// Action creators are generated for each case reducer function
export default servicesSlice.reducer;
