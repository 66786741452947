import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

//redux
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getDeviceById } from '../../../pages/devices/deviceDuck';

// common components, interfaces, constants and helpers
import { t } from 'i18next';
import { FormComponentHeader } from '../../../common';
import ExpandFleetnetDevicesComponent from './ExpandFleetnetDevicesComponent';

const FleetnetDeviceDetails = () => {
    const dispatch = useAppDispatch();
    const paramData: { id?: string; action?: string } = useParams();
    const { device } = useAppSelector((state) => state.devices);

    // get site data for edit page
    const getDevice = useCallback(
        async (deviceID: string) => {
            dispatch(getDeviceById(deviceID));
        },
        [dispatch]
    );

    useEffect(() => {
        if (paramData.id) {
            getDevice(paramData.id);
        }
    }, [dispatch, getDevice, paramData.id]);

    const configureLabel = useMemo(() => t('services.manageFleetnets.configure'), [t]);

    return (
        <>
            <FormComponentHeader title={`${configureLabel} ${device?.name ? device.name : ''}`} />

            {device && device.id === paramData?.id && (
                <ExpandFleetnetDevicesComponent
                    dataItem={{
                        id: device.id,
                        name: device.name,
                        code: device.code,
                        device_model_name: device?.device_model_name,
                    }}
                />
            )}
        </>
    );
};

export default FleetnetDeviceDetails;
