import { GridCellProps, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

//redux
import { useAppDispatch, useAppSelector } from '../../../hooks';

// common components, interfaces, constants and helpers
import { Typography } from '@mui/material';
import { EDIT_ACTION_BUTTONS } from '../../../../constants/kendoConstants';
import { getInitialGridState, updateGridColumns } from '../../../../helpers';
import {
    ActionButtonsCell,
    ComponentHeader,
    GridActionsAndFilters,
    GridComponent,
    TextCell,
} from '../../../common';
import { CustomDataState, IGridColumn } from '../../../common/interfaces';
import { getDevices } from '../../../pages/devices/deviceDuck';
import { ExtendedGetReqProps } from '../../../pages/interfaces';
import { getFleetnetDevicesColumns } from '../../gridColumn/fleetnetDevice';
import ExpandFleetnetDevicesComponent from './ExpandFleetnetDevicesComponent';

interface FleetnetDeviceCustomCellProps extends GridCellProps {
    buttonsVisibility: Map<string, boolean>;
}

const FleetnetDevicesList = () => {
    const fleetDeviceButtonsVisibility = useMemo(() => new Map(EDIT_ACTION_BUTTONS), []);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const { devices } = useAppSelector((state) => state.devices);

    const [devicesServerDataState, setDevicesServerDataState] = useState<CustomDataState>(
        getInitialGridState('name')
    );
    const [columns, setColumns] = useState<IGridColumn[]>([]);

    // get all devices for selected mandator
    const getDevicesData = useCallback(
        (data_state: CustomDataState, mandator_id: string) => {
            const requestObj: ExtendedGetReqProps = {
                data_state,
                mandator_id,
            };
            dispatch(getDevices(requestObj));
        },
        [dispatch]
    );

    useEffect(() => {
        if (selectedMandator?.id) {
            getDevicesData(devicesServerDataState, selectedMandator?.id);
        }
    }, [devicesServerDataState, getDevicesData, selectedMandator?.id]);

    const CustomCellForName = (props: FleetnetDeviceCustomCellProps) => {
        return (
            <TextCell
                {...props}
                component={
                    <ActionButtonsCell
                        {...props}
                        path={`/fleetnet-devices/${props?.dataItem?.id}`}
                        index="id"
                        buttonsVisibility={props.buttonsVisibility}
                        component="services.manageFleets.fleets"
                    />
                }
            />
        );
    };

    const NameCell = useCallback(
        (props: GridCellProps) => {
            return (
                <CustomCellForName {...props} buttonsVisibility={fleetDeviceButtonsVisibility} />
            );
        },
        [fleetDeviceButtonsVisibility]
    );

    const CustomColumns = useMemo(
        () => new Map<string, ComponentType<GridCellProps>>([['name', NameCell]]),
        [NameCell]
    );

    useEffect(() => {
        const initialColumns = getFleetnetDevicesColumns(CustomColumns);
        if (columns.length === 0) {
            setColumns(initialColumns);
        } else {
            updateGridColumns(columns, initialColumns, setColumns);
        }
    }, [CustomColumns]);

    const onDevicesDataStateChange = useCallback(
        (event: GridDataStateChangeEvent) => {
            const { filter, group, ...state } = event.dataState;
            setDevicesServerDataState({ ...devicesServerDataState, ...state });
        },
        [devicesServerDataState]
    );

    //I18n
    const emptyListMsg = useMemo(() => t(`fleetnetDevice.noDeviceFound`), []);

    //If empty then display this component
    const NoFleetnetDevicesComponent = useMemo(
        () => (
            <Typography
                variant="subtitle1"
                align="center"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                {emptyListMsg}
            </Typography>
        ),
        [emptyListMsg]
    );

    const formattedDevices = {
        data: devices.data.map((device) => ({ ...device })),
        total: devices.total,
    };

    return (
        <>
            <ComponentHeader title={t('fleetnetDevice.fleetnetDeviceHeader')} />
            <GridActionsAndFilters
                viewRight={false}
                entityName="services.manageSites.devices"
                columns={columns}
                componentServerDataState={devicesServerDataState}
                setComponentServerDataState={setDevicesServerDataState}
            />
            <GridComponent
                result={formattedDevices}
                columns={columns}
                initialColumns={getFleetnetDevicesColumns(CustomColumns)}
                setColumns={setColumns}
                serverDataState={devicesServerDataState}
                detailComponent={ExpandFleetnetDevicesComponent}
                onServerDataStateChange={onDevicesDataStateChange}
                noDataMessage={NoFleetnetDevicesComponent}
            />
        </>
    );
};

export default FleetnetDevicesList;
