import { MouseEvent, useMemo, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, IconButton, Typography, SxProps } from '@mui/material';
import {
    Person,
    ArrowDropDown,
    ArrowDropUp,
    Assessment,
    Settings,
    AdminPanelSettings,
    BuildCircleOutlined,
    Payments,
} from '@mui/icons-material';
import '../layout.scss';
import { EllipsisText } from '../..';
// redux
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getUserRights, updateSelectedModule, ModuleType } from '../userRightsDuck';
import { MenuEntries } from '../SideBarItems';
import { ComponentWidthProps } from '../../interfaces';
import { clearLSOnComponentLeave, checkModuleRights } from '../../../../helpers';
import { BREAKPOINTS, LIVE_DATA_MODULE } from '../../../../constants';

const getModuleIcon = (iconName?: string, sx?: SxProps) => {
    switch (iconName) {
        case LIVE_DATA_MODULE:
            return <Assessment sx={sx} />;
        case 'services.configuration':
            return <Settings sx={sx} />;
        case 'services.administration':
            return <AdminPanelSettings sx={sx} />;
        case 'services.controlTower':
            return <BuildCircleOutlined sx={sx} />;
        case 'services.payments':
            return <Payments sx={sx} />;
        default:
            return <Person sx={sx} />;
    }
};

/**
 * Renders a license menu component.
 *
 * @param {ComponentWidthProps} props - The props object containing the following properties:
 * @param {number} props.width - The width of the component.
 * @param {number} props.maxWidth - The maximum width of the component.
 * @return {JSX.Element} The rendered license menu component.
 */
const LicenseMenu = ({ width, maxWidth }: ComponentWidthProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { modules, selectedModule, userRights } = useAppSelector((state) => state.userRights);
    const { payedModules, payedComponents } = useAppSelector((state) => state.payedServices);
    const { activeModules } = useAppSelector((state) => state.marketplace);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (localStorage.selectedMandator) {
            dispatch(getUserRights(localStorage.selectedMandator));
        }
    }, [dispatch, localStorage.selectedMandator]);

    useEffect(() => {
        if (localStorage.selectedModule) {
            const newSelectedModule = modules.filter(
                (mod) => mod.name === localStorage.selectedModule
            )[0];
            if (newSelectedModule) {
                dispatch(updateSelectedModule(newSelectedModule));
            }
        }
    }, [modules, dispatch]);

    const sortedModules = useMemo(() => {
        return [...modules].sort((firstModule: ModuleType, secondModule: ModuleType) =>
            t(firstModule.name).localeCompare(t(secondModule.name))
        );
    }, [modules, t]);

    const menuOpened = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleItemClick = useCallback(
        (item: ModuleType) => {
            const selectedMenuEntries = MenuEntries.filter(
                (menu) =>
                    userRights.findIndex(
                        (right) =>
                            right.sub_component === menu.title &&
                            right.module === item.name &&
                            right.view === true
                    ) > -1
            );
            if (selectedMenuEntries.length > 0) {
                dispatch(updateSelectedModule(item));
                localStorage.setItem('selectedModule', item.name);
                setAnchorEl(null);
                clearLSOnComponentLeave();
                navigate(selectedMenuEntries[0].path);
            } else {
                navigate('/*');
            }
        },
        [dispatch, navigate, userRights]
    );

    const generateMenu = useCallback(
        (item: ModuleType) => {
            const selectedModuleRights = checkModuleRights(
                item,
                userRights,
                payedModules,
                payedComponents,
                activeModules
            );

            return (
                selectedModuleRights && (
                    <MenuItem
                        key={item.name}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            whiteSpace: 'break-spaces',
                        }}
                        selected={item.name === selectedModule?.name}
                        onClick={() => handleItemClick(item)}>
                        {getModuleIcon(item.name, {
                            width: '30px',
                            height: '30px',
                            alignSelf: 'center',
                        })}
                        <EllipsisText variant="h5" maxWidth={120}>
                            {t(item.name)}
                        </EllipsisText>
                    </MenuItem>
                )
            );
        },
        [
            userRights,
            payedModules,
            payedComponents,
            selectedModule?.name,
            t,
            handleItemClick,
            activeModules,
        ]
    );

    return (
        <>
            <IconButton
                sx={{ color: '#fff' }}
                aria-controls={menuOpened ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpened ? 'true' : undefined}
                onClick={handleClick}>
                {getModuleIcon(selectedModule?.name, { mr: { sm: 1 } })}
                {width > BREAKPOINTS.md && (
                    <Typography variant="subtitle1" noWrap sx={{ maxWidth: maxWidth }}>
                        {t(selectedModule?.name || '')}
                    </Typography>
                )}
                {menuOpened ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={menuOpened}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    className: 'license-menu',
                }}>
                {sortedModules.map((item: ModuleType) => {
                    return generateMenu(item);
                })}
            </Menu>
        </>
    );
};
export default LicenseMenu;
