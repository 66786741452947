import { Circle } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import React, { SyntheticEvent, useEffect, useState } from 'react';

interface SwitchButtonCellProps {
    dataItem: any;
    field?: string;
    onChange?: (data: SwitchChangeData) => void;
    dataIndex?: number;
}

interface SwitchChangeData {
    dataIndex?: number;
    dataItem?: any;
    field?: string;
    syntheticEvent?: SyntheticEvent;
    value?: boolean;
}

const SwitchButtonCell: React.FC<SwitchButtonCellProps> = ({
    dataItem,
    field,
    onChange,
    dataIndex,
}) => {
    const fieldName = field || '';
    const dataValue = dataItem[fieldName];
    const [switchVal, setSwitchVal] = useState(dataValue === 'active');

    // Update switchVal immediately based on dataValue
    useEffect(() => {
        if ((dataValue === 'active' && !switchVal) || (dataValue === 'inactive' && switchVal)) {
            setSwitchVal(dataValue === 'active');
        }
    }, [dataValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const newValue = isChecked ? 'active' : 'inactive';

        setSwitchVal(isChecked);

        if (onChange) {
            onChange({
                dataIndex,
                dataItem: { ...dataItem, [fieldName]: newValue },
                field,
                syntheticEvent: e,
                value: isChecked,
            });
        }
    };

    return (
        <td className="text-center" style={{ textAlign: 'inherit' }}>
            {dataItem.inEdit ? (
                <Switch onChange={handleChange} checked={switchVal} />
            ) : (
                <span>
                    <Circle color={dataValue === 'active' ? 'success' : 'error'} />
                </span>
            )}
        </td>
    );
};

export default SwitchButtonCell;
