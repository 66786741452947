import { PropsWithChildren } from 'react';
import { ThemeProvider } from '@mui/material/styles';

import { getCurrentTheme } from './customThemes';
import { useAppSelector } from '../app/hooks';

const CustomThemeProvider = ({ children }: PropsWithChildren) => {
    const { theme, mode } = useAppSelector((state) => state.theme);

    const currentTheme = getCurrentTheme(theme, mode);

    return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
};
export default CustomThemeProvider;
