import { useRef, useState } from 'react';
import { DownloadFileProps, DownloadedFileInfo } from '../common/interfaces';

// hook used for downloading single file from url
export const useDownloadFile = ({
    apiDefinition,
    preDownloading,
    postDownloading,
    onError,
    fileName,
}: DownloadFileProps): DownloadedFileInfo => {
    const ref = useRef<HTMLAnchorElement | null>(null);
    const [url, setFileUrl] = useState<string>();

    const download = async () => {
        try {
            preDownloading?.(); // disable button or something else to pre process

            const { data } = await apiDefinition();

            const newUrl = URL.createObjectURL(new Blob([data]));
            setFileUrl(newUrl);
            const link = document.createElement('a');
            link.href = newUrl;
            link.setAttribute('download', fileName || 'Report');

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);

            postDownloading?.();

            URL.revokeObjectURL(newUrl);
        } catch (error) {
            onError?.();
        }
    };

    return { download, ref, url };
};
