import { GridCellProps } from '@progress/kendo-react-grid';
import { useAppSelector } from '../../../hooks';

/**
 * Renders a custom cell for displaying JSON text data in a Kendo React Grid.
 *
 * @param {GridCellProps} props - The props object containing the field and dataItem.
 * @param {string} props.field - The field name to which the cell is bound.
 * @param {Record<string, any>} props.dataItem - The data item of the row.
 * @return {JSX.Element} The JSX element representing the custom cell.
 */
const JsonTextCell = ({ field, dataItem }: GridCellProps): JSX.Element => {
    const { language } = useAppSelector((state) => state.commonData);

    const data = dataItem[field ?? ''].length > 3 ? JSON.parse(dataItem[field ?? '']) : '';

    if (data.currentTime) {
        data.currentTime = new Date(data.currentTime).toLocaleString(language);
    }

    return (
        <td>
            <pre>{data ? JSON.stringify(data, null, 2) : '-'}</pre>
        </td>
    );
};

export default JsonTextCell;
