import { ChangeEvent, FocusEventHandler, memo } from 'react';

//third party
import { Typography } from '@mui/material';
import { FormikErrors, FormikTouched, FormikValues, getIn } from 'formik';

//styles
import './numericalTimeInput.scss';

//helpers
import { processHoursValue, processMinutesValue } from '../../../helpers';

interface NumericalTimeInputProps {
    value: string; //hh:mm or hh:mm:ss format
    field: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    disabled?: boolean;
    errors?: FormikErrors<FormikValues>;
    touched?: FormikTouched<FormikValues>;
}

/**
 * Renders a numerical time input field with hours and minutes.
 *
 * @param {NumericalTimeInputProps} props - The props for the component:
 * @param {string} props.value - The value of the time input in hh:mm or hh:mm:ss format.
 * @param {string} props.field - The field name for the input.
 * @param {FocusEventHandler<HTMLInputElement>} props.onBlur - The event handler for onBlur.
 * @param {boolean} props.disabled - Boolean indicating if the input is disabled.
 * @param {Array} props.errors - Formik errors related to the input.
 * @param {Array} props.touched - Formik touched state related to the input.
 * @param {(event: ChangeEvent<HTMLInputElement>) => void} props.onChange - The event handler for onChange.
 */
const NumericalTimeInput = ({
    value,
    field,
    onBlur,
    disabled,
    errors,
    touched,
    onChange,
}: NumericalTimeInputProps) => {
    const numberRegex = /^\d*$/;
    const applyError = !!(getIn(touched, field) && getIn(errors, field));
    const errorText = applyError ? getIn(errors, field) : '';

    const handleHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value;
        if (!numberRegex.test(eventValue)) {
            return;
        }

        const processedHour = processHoursValue(eventValue);
        if (processedHour === '24') {
            event.target.value = '24:00:00';
        } else {
            event.target.value = `${processedHour}${value.slice(2)}`;
        }

        onChange(event);
    };

    const handleMinutesChange = (event: ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value;
        if (value.startsWith('24') || !numberRegex.test(eventValue)) {
            return;
        }

        event.target.value = `${value.slice(0, 2)}:${processMinutesValue(eventValue)}:00`;
        onChange(event);
    };

    return (
        <div className="formInputContainer" aria-label="timeInput">
            <div className="inputsContainer">
                <input
                    name={field}
                    value={value.slice(0, 2)}
                    disabled={disabled}
                    placeholder="hh"
                    type="text"
                    aria-label="hours-input"
                    className="numericalTimeInput hours"
                    onChange={handleHoursChange}
                    onFocus={(event) => event.target.select()}
                    onBlur={onBlur}
                />
                <span style={{ height: '20px' }}>:</span>
                <input
                    name={field}
                    value={value.slice(3, 5)}
                    disabled={disabled}
                    placeholder="mm"
                    type="text"
                    aria-label="minutes-input"
                    className="numericalTimeInput minutes"
                    onChange={handleMinutesChange}
                    onFocus={(event) => event.target.select()}
                    onBlur={onBlur}
                />
            </div>
            {errors && (
                <Typography
                    color="#d63232"
                    variant="caption"
                    className="errorHeight"
                    role={`error-${field}`}>
                    {errorText}
                </Typography>
            )}
        </div>
    );
};

export default memo(NumericalTimeInput);
