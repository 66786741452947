import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import { Tabs, Tab, Paper, Grid, Switch, Typography, Box } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    activateModule,
    deactivateModule,
    getModuleFields,
    updateModuleFields,
} from '../marketplaceDuck';

import { FormComponentFooter, FormComponentHeader } from '../../../common';
import {
    preventSubmit,
    handleChangeTabs,
    navigateAfterFulfilledAction,
    setExtendedModuleInfo,
    navigateToParent,
    getServiceStatus,
    validateForm,
} from '../../../../helpers';
import { GRID_CLASS_NAME, Operations } from '../../../../constants';
import { Module, ModuleField, ModuleFormValues } from '../../interfaces';
import GeneralDetailsTab from './GeneralDetailsTab';
import ModuleConfigurationTab from './ModuleConfigurationTab';

const ModuleDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const pathParams = useParams();
    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const { modules } = useAppSelector((state) => state.marketplace);

    const [moduleStatus, setModuleStatus] = useState<boolean>(false);
    const [module, setModule] = useState<Module>();
    const [moduleFields, setModuleFields] = useState<ModuleField[] | []>([]);
    const [tabState, setTabState] = useState(1);

    const moduleUrl = modules.find((item) => {
        return item.name === pathParams.name;
    })?.url;

    const moduleFieldsUrl = useMemo(() => `${moduleUrl}/config/mandators/fields`, [moduleUrl]);

    useEffect(() => {
        if (moduleUrl && selectedMandator?.id) {
            setExtendedModuleInfo(
                moduleUrl,
                selectedMandator.id,
                dispatch,
                setModule,
                setModuleStatus
            );
        }
    }, [dispatch, moduleUrl, selectedMandator]);

    const fetchModuleFields = useCallback(async () => {
        const currentModule = modules.filter((item) => item.name === pathParams.name)[0];
        if (selectedMandator?.id && currentModule?.status === true) {
            const fieldsResponse = await dispatch(
                getModuleFields({
                    moduleUrl: moduleFieldsUrl,
                    mandatorId: selectedMandator.id,
                })
            );
            if (getModuleFields.fulfilled.match(fieldsResponse)) {
                setModuleFields(fieldsResponse.payload.data);
            }
        }
    }, [dispatch, moduleFieldsUrl, modules, pathParams.name, selectedMandator]);

    useEffect(() => {
        fetchModuleFields();
    }, [fetchModuleFields]);

    const initialValues = useMemo(() => {
        let formInitialValues: ModuleFormValues = {
            active: false,
        };
        const dynamicModuleFields: { [key: string]: string } = {};
        moduleFields.forEach((field) => {
            dynamicModuleFields[field.field_name] = field.field_value;
        });

        if (module && moduleStatus) {
            formInitialValues = {
                active: moduleStatus,
                ...(Object.keys(dynamicModuleFields).length > 0 && dynamicModuleFields),
            };
        }
        return formInitialValues;
    }, [module, moduleStatus, moduleFields]);

    const updateModuleActivationErrors = (isSuccessfulOperation: boolean) => {
        const moduleName = module?.name ?? '';
        const localStorageActivationErrors = localStorage.moduleActivationErrors
            ? new Map<string, string[]>(JSON.parse(localStorage.moduleActivationErrors))
            : new Map<string, string[]>();
        let currentModules = localStorageActivationErrors.get(localStorage.selectedMandator) ?? [];

        if (isSuccessfulOperation) {
            currentModules = currentModules.filter((mod) => mod !== moduleName);
        } else {
            if (!currentModules.includes(moduleName)) {
                currentModules = [...currentModules, moduleName];
            }
        }
        localStorageActivationErrors.set(localStorage.selectedMandator, currentModules);
        localStorage.setItem(
            'moduleActivationErrors',
            JSON.stringify(Array.from(localStorageActivationErrors))
        );
    };

    const saveModuleFields = async (formFields: ModuleFormValues, mandatorId: string) => {
        // if we have other fields on module
        // save them
        if (Object.keys(formFields).length > 1) {
            const { active, ...fieldValues } = formFields;
            await dispatch(
                updateModuleFields({
                    moduleUrl: moduleFieldsUrl,
                    mandatorId,
                    moduleFields: fieldValues,
                })
            );
        }
    };
    const handleSubmit = async (fields: ModuleFormValues) => {
        if (selectedMandator?.id) {
            await saveModuleFields(fields, selectedMandator.id);
            const moduleStatusUrl = moduleUrl + '/config/mandators/activations/';
            if (fields.active) {
                const activateResponse = await dispatch(
                    activateModule({
                        moduleUrl: moduleStatusUrl,
                        mandatorId: selectedMandator.id,
                    })
                );
                if (activateModule.fulfilled.match(activateResponse)) {
                    updateModuleActivationErrors(true);
                    navigateAfterFulfilledAction(navigate, Operations.Updated);
                } else {
                    updateModuleActivationErrors(false);
                    // navigate to parent
                    navigateToParent(navigate);
                }
            } else {
                const deactivateResponse = await dispatch(
                    deactivateModule({
                        moduleUrl: moduleStatusUrl,
                        mandatorId: selectedMandator.id,
                    })
                );
                if (deactivateModule.fulfilled.match(deactivateResponse)) {
                    updateModuleActivationErrors(true);
                    navigateAfterFulfilledAction(navigate, Operations.Updated);
                }
            }

            getServiceStatus(selectedMandator.id, moduleStatusUrl, dispatch);
        }
    };
    return (
        <>
            {module ? (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={(fields) => {
                        handleSubmit(fields);
                    }}
                    validate={validateForm}>
                    {(props) => (
                        <>
                            <FormComponentHeader
                                title={module.name}
                                customButton={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <Typography variant="h3">
                                            {t('general.labels.active')}
                                        </Typography>
                                        <Switch
                                            color="success"
                                            checked={props.values.active}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            name="active"
                                        />
                                    </Box>
                                }
                            />
                            <Paper
                                elevation={0}
                                sx={{ mt: 2, backgroundColor: '#fff', width: '100%' }}>
                                <Form onKeyDown={preventSubmit}>
                                    <Tabs
                                        value={tabState}
                                        onChange={(_, value: number) => {
                                            handleChangeTabs(setTabState, value);
                                        }}
                                        scrollButtons="auto">
                                        <Tab
                                            value={1}
                                            label={t('general.labels.generalTab')}
                                            wrapped
                                        />
                                        {moduleFields.length > 0 && (
                                            <Tab
                                                value={2}
                                                label={t('general.labels.configurationTab')}
                                                wrapped
                                            />
                                        )}
                                    </Tabs>

                                    <Grid
                                        container
                                        columnSpacing={{ xs: 2, sm: 2, md: 4, lg: 8 }}
                                        spacing={2}
                                        className={GRID_CLASS_NAME}>
                                        {tabState === 1 && <GeneralDetailsTab module={module} />}
                                        {tabState === 2 && (
                                            <ModuleConfigurationTab
                                                moduleFields={moduleFields}
                                                formProps={props}
                                                selectedMandatorId={selectedMandator?.id ?? ''}
                                                componentName={module.name}
                                                isDisabled={false}
                                            />
                                        )}
                                    </Grid>
                                    <FormComponentFooter isDisabled={false} />
                                </Form>
                            </Paper>
                        </>
                    )}
                </Formik>
            ) : null}
        </>
    );
};

export default ModuleDetails;
