import { memo } from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import {
    PeopleAlt,
    TrendingUp,
    TrendingDown,
    ConfirmationNumber,
    MonetizationOn,
    TrendingFlat,
} from '@mui/icons-material';
import { BlockObjectProps } from '../interfaces';
import { useAppSelector } from '../../hooks';
import { convertNumberToString } from '../../../helpers';
import { staticColors } from '../../../themes/themes';

/**
 * Component used to render custom block with avatar and value
 * It can display optionally a trending icon and the value
 *
 * @param {BlockObjectProps} props - The component props containing:
 * @param {BlockObjectProps} props.data - The data object containing growth and value information.
 * @param {number} props.width - The width of the custom block.
 * @param {number} props.height - The height of the custom block.
 * @param {string} props.alignItems - The alignment of items within the block.
 * @param {string} props.role - The role of the custom block.
 * @param {string} props.avatar - The type of avatar to display.
 * @param {boolean} props.showTrending - Flag to determine if trending information should be shown.
 * @return {JSX.Element} The custom block component with avatar, value, and optional trending information.
 */
const CustomBlock = ({
    data,
    width,
    height,
    alignItems,
    role,
    avatar,
    showTrending,
}: BlockObjectProps): JSX.Element => {
    const { ISOLanguageCode } = useAppSelector((state) => state.commonData);
    const { growth, value } = data || {};
    const localizedGrowth = convertNumberToString(Number(growth), ISOLanguageCode);
    const localizedValue = convertNumberToString(Number(value), ISOLanguageCode);

    const iconBackgroundDimensions = '64px';
    const iconDimensions = '32px';

    const generateCustomAvatar = (color: string, icon: JSX.Element) => {
        return (
            <Avatar
                sx={{
                    bgcolor: color,
                    width: iconBackgroundDimensions,
                    height: iconBackgroundDimensions,
                }}
                className="custom-avatar"
                variant="rounded"
                role="avatar">
                {icon}
            </Avatar>
        );
    };

    const generateChartAvatar = () => {
        switch (avatar) {
            case 'tickets':
                return generateCustomAvatar(staticColors.green, <ConfirmationNumber />);
            case 'total':
                return generateCustomAvatar(
                    staticColors.darkSelectedBackground,
                    <MonetizationOn />
                );
            default:
                return (
                    <Avatar
                        sx={{ bgcolor: '#439BDF', width: '64px', height: '64px' }}
                        variant="rounded"
                        role="avatar">
                        <PeopleAlt sx={{ width: iconDimensions, height: iconDimensions }} />
                    </Avatar>
                );
        }
    };

    const generateTrending = () => {
        if (Number(growth) > 0) {
            return (
                <Typography variant="body1" sx={{ color: staticColors.green }}>
                    +{localizedGrowth}
                    <TrendingUp sx={{ color: staticColors.green }} />
                </Typography>
            );
        } else if (Number(growth) === 0) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <Typography variant="body1" sx={{ color: staticColors.lightGray }}>
                        {localizedGrowth}
                    </Typography>
                    <TrendingFlat sx={{ color: staticColors.lightGray }} />
                </div>
            );
        } else {
            return (
                <Typography variant="body1" sx={{ color: staticColors.error }}>
                    {localizedGrowth}
                    <TrendingDown sx={{ color: staticColors.error }} />
                </Typography>
            );
        }
    };
    return (
        <Grid
            container
            spacing={2}
            width={width}
            height={height}
            role={role}
            alignItems={alignItems || ''}>
            <Grid item>{generateChartAvatar()}</Grid>
            <Grid item>
                <Typography variant="h1">{localizedValue}</Typography>
                {showTrending ? generateTrending() : null}
            </Grid>
        </Grid>
    );
};

export default memo(CustomBlock);
