import { FormControl, Typography } from '@mui/material';
import { FormControlLabelComponent, FormInput } from '../../common';
import { ExtendedFieldValue, GeneralTabProps } from '../interfaces';
import { InputType, InputValue } from '../../common/interfaces';
import { ProviderFieldTypes, REQUIRED_FIELD_CLASS_NAME } from '../../../constants';

interface ProviderTemplateFieldProps extends GeneralTabProps<any> {
    field: ExtendedFieldValue;
    fieldIndex: number;
    providerIndex?: number;
}

export const ProviderTemplateField = ({
    formProps,
    isDisabled,
    field,
    fieldIndex,
    providerIndex,
}: ProviderTemplateFieldProps) => {
    const { errors, touched, handleChange, handleBlur } = formProps;

    const fieldName =
        providerIndex !== undefined
            ? `provider_templates[${providerIndex}].fields[${fieldIndex}].value`
            : `fields_values[${fieldIndex}].value`;

    if (field.type === ProviderFieldTypes.Boolean) {
        return (
            <FormControl className="formControl switchInput" variant="outlined">
                <Typography variant="h3" className={REQUIRED_FIELD_CLASS_NAME}>
                    {field.name}
                </Typography>
                <FormControlLabelComponent
                    active={JSON.parse(field.value as string)}
                    onChange={(event) => {
                        event.target.value = (
                            event.target.value.length > 0 ? true : false
                        ).toString();
                        handleChange(event);
                    }}
                    size={'medium'}
                    color={'success'}
                    name={fieldName}
                    control={'switch'}
                    disabled={isDisabled}
                />
            </FormControl>
        );
    }
    const fieldValue = (field.type === 'number' ? field.value : field.value ?? '') as InputValue;
    return (
        <FormInput
            label={field.name}
            fieldName={fieldName}
            type={field.type as InputType}
            value={fieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            disabled={isDisabled}
            requiredField={true}
        />
    );
};
