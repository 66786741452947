import { PaletteMode } from '@mui/material';
import { getOppositeColor } from '../helpers';
import { CUSTOM_THEME_NAME } from '../constants';
import { ColorsInterface, StaticColorsInterface, ThemeInterface } from './interfaces';
import { paletteModes, staticColors } from './themes';

const themeVariables = new Map<string, string>([
    ['--app-primary-color', 'primary'],
    ['--button-active-color', 'primaryButtonActive'],
    ['--button-hover-color', 'primaryButtonHover'],
    ['--button-disabled-color', 'primaryButtonDisabled'],
    ['--text-color', 'secondaryInvertedColor'],
    ['--text-hover-color', 'primaryInvertedColor'],
    ['--icon-color', 'primaryInvertedColor'],
    ['--background-header', 'backgroundHeader'],
    ['--secondary-color', 'secondary'],
]);

//dark variables
const darkThemeVariables = new Map<string, string>([
    ['--primary-background-color', 'darkBackground'],
    ['--secondary-background-color', 'lightBackground'],
    ['--bg-color-hover', 'lightBackground'],
    ['--bg-color-selected', 'darkSelectedBackground'],
]);

const defaultDarkThemeVariables = new Map<string, string>([
    ['--primary-background-color', 'white'],
    ['--secondary-background-color', 'white'],
    ['--text-dark-theme', 'darkSelectedBackground'],
    ['--bg-color-hover', 'darkWhite'],
    ['--bg-color-selected', 'lightGray'],
]);

export const generateAppTheme = (theme: ThemeInterface, mode: PaletteMode) => {
    //change colors dynamically
    const root = document.documentElement;
    const { colours, name } = theme;
    themeVariables.forEach((value, key) => {
        let colorValue = colours[value as keyof ColorsInterface];
        //for custom theme
        if (name === CUSTOM_THEME_NAME) {
            const primaryInvertedColor = getOppositeColor(colours.primary);
            const secondaryInvertedColor = getOppositeColor(colours.backgroundHeader);
            if (value === 'primaryInvertedColor') colorValue = primaryInvertedColor;
            if (value === 'secondaryInvertedColor') colorValue = secondaryInvertedColor;
        }
        root.style.setProperty(key, colorValue ?? null);
    });
    //for dark theme
    if (mode === paletteModes.dark) {
        darkThemeVariables.forEach((value, key) => {
            const staticValue = staticColors[value as keyof StaticColorsInterface];
            root.style.setProperty(key, staticValue);
        });
    } else {
        defaultDarkThemeVariables.forEach((value, key) => {
            const staticValue = staticColors[value as keyof StaticColorsInterface];
            root.style.setProperty(key, staticValue);
        });
    }
};
