import { Dispatch, SetStateAction } from 'react';
import {
    ChartDataProps,
    ChartPoint,
    GPSCoordinates,
    GraphResponse,
    Graphs,
} from '../app/common/interfaces';
import {
    Mandator,
    SitePointsData,
    CustomizationObject,
    SelectDataType,
    ExtendedProduct,
} from '../app/pages/interfaces';
import {
    BONNDORF_COORDINATES,
    ChartColors,
    DEFAULT_CHART_DATA,
    DEFAULT_CHART_TYPE,
    DashboardMarkerStatuses,
    NOMINATIM_GEOCODE_URL,
    PAYMENT_METHODS_TITLE,
    PointStatuses,
    SITES_RANKING_FILTERS,
    SITES_RANKING_TITLE,
} from '../constants';
import i18n from '../i18n';
import { geocode, sortArrayDescending, translateTerm } from '.';

export const getGraphCustomization = (
    graphTitle: string,
    graphsCustomization: CustomizationObject[]
) => {
    return (
        graphsCustomization.filter(
            (item: CustomizationObject) => item.configuration.system_name === graphTitle
        )[0] ?? {}
    );
};

export const changeGraphCustomizationData = (data: CustomizationObject[]) => {
    return data.map((item) => {
        return { ...item, configuration: JSON.parse(item.configuration as unknown as string)[0] };
    });
};

const generateCustomCard = (title: string, avatar: string, statisticsData: GraphResponse) => {
    const transactionsChartsData =
        title === 'charts.totalAmount'
            ? (statisticsData?.transactions_total_amount as ChartDataProps)
            : (statisticsData?.transactions_count as ChartDataProps);
    return {
        kind: 'custom',
        title,
        avatar,
        data: transactionsChartsData ?? DEFAULT_CHART_DATA,
    };
};

export const generateSitesRankingChart = (
    transactionStatistics: GraphResponse,
    graphsCustomization: CustomizationObject[],
    sites_ranking_filter: string
) => {
    const sitesRankingAmountFilter = sites_ranking_filter === SITES_RANKING_FILTERS[0].value;
    const rankingData = (transactionStatistics?.[sites_ranking_filter] as ChartDataProps[]) ?? [];

    const sitesRankingCustomization = getGraphCustomization(
        SITES_RANKING_TITLE,
        graphsCustomization
    );

    const trxTotalAmount: number =
        ((transactionStatistics?.transactions_total_amount as ChartDataProps)?.value as number) ??
        1;
    const trxTotalNumber: number =
        ((transactionStatistics?.transactions_count as ChartDataProps)?.value as number) ?? 1;

    const sitesRankingData = rankingData.map((item) => {
        const itemValue = sitesRankingAmountFilter
            ? [Number(((item?.amount ?? 0) / trxTotalAmount).toFixed(4))]
            : [Number(((item?.transactions ?? 0) / trxTotalNumber).toFixed(4))];
        return {
            name: item?.site_name,
            value: itemValue,
        };
    });

    const sitesRankingGraph = {
        kind: sitesRankingCustomization?.configuration?.type ?? DEFAULT_CHART_TYPE,
        title: SITES_RANKING_TITLE,
        formatting: 'p',
        data: sortArrayDescending(sitesRankingData, 'value'),
        hasActions: true,
        seriesColor: sitesRankingCustomization?.configuration?.series_colors ?? undefined,
    };
    return sitesRankingGraph;
};

export const generateTotalQuantityChart = (
    transactionStatistics: GraphResponse,
    total_quantity_unit_measure: string
) => {
    const totalQuantityData =
        ((transactionStatistics?.article_quantities_ranking as ChartDataProps[]).find(
            (quantityData) => quantityData.unit_measure === total_quantity_unit_measure
        )?.articles_quantities as ChartDataProps[]) ?? [];

    const totalQuantityChart = {
        kind: DEFAULT_CHART_TYPE,
        title: 'charts.totalQuantity',
        formatting: 'n',
        data: totalQuantityData.map((item) => {
            return {
                name: item.article_name,
                value: [item.quantity ?? 0],
            };
        }),
    };
    return totalQuantityChart;
};

export const generatePaymentMethodsGraph = (
    transactionStatistics: GraphResponse,
    graphsCustomization: CustomizationObject[],
    payment_method_key: string
) => {
    const paymentMethodName = payment_method_key.split('_ranking')[0];

    const paymentData = (transactionStatistics?.[payment_method_key] as ChartDataProps[]) ?? [];

    const paymentMethodsCustomization = getGraphCustomization(
        PAYMENT_METHODS_TITLE,
        graphsCustomization
    );

    const paymentMethodsChart = {
        kind: paymentMethodsCustomization?.configuration?.type ?? DEFAULT_CHART_TYPE,
        title: PAYMENT_METHODS_TITLE,
        formatting: 'n', // number
        data: paymentData
            .filter((item) => item[paymentMethodName])
            .map((item) => {
                const translationKey = `charts.cardTypes.${item[paymentMethodName]?.toLowerCase()}`;
                return {
                    name: translateTerm(translationKey, item[paymentMethodName] as string),
                    value: [parseFloat((item.amount ?? 0).toFixed(2))],
                };
            }),
        hasActions: true,
        seriesColor: paymentMethodsCustomization?.configuration?.series_colors ?? undefined,
        isPopoverVisible: true,
    };
    return paymentMethodsChart;
};

export const calculateGraphs = (
    transactionStatistics: GraphResponse,
    sitesPointData: SitePointsData,
    coordinates: GPSCoordinates
) => {
    const pointsStatus: ChartDataProps[] = pointStatusChartData(sitesPointData);
    const calculatedGraphs: Graphs = {};

    calculatedGraphs.totalAmount = generateCustomCard(
        'charts.totalAmount',
        'total',
        transactionStatistics
    );
    calculatedGraphs.transactions = generateCustomCard(
        'charts.transactions',
        'tickets',
        transactionStatistics
    );

    calculatedGraphs.sitesLocations = {
        kind: 'map',
        title: 'charts.sitesLocations',
        data: sitesPointData.statuses,
        mapCoordinates: coordinates,
    };
    calculatedGraphs.pointsStatus = {
        kind: 'column',
        title: 'charts.pointsStatus',
        formatting: 'n',
        data: pointsStatus,
        menuData: sitesPointData,
    };
    return calculatedGraphs;
};

// order devices and points by status
export const calculateSitePointsStatus = (sites: ChartDataProps[]): SitePointsData => {
    let statuses: ChartDataProps[] = [];
    let totalAvailablePoints: ChartPoint[] = [];
    let totalInUsePoints: ChartPoint[] = [];
    let totalOutOfOrderPoints: ChartPoint[] = [];
    let totalOccupiedPoints: ChartPoint[] = [];
    let totalUnknownPoints: ChartPoint[] = [];
    let totalReservedPoints: ChartPoint[] = [];

    sites.forEach((site) => {
        let siteStatus = DashboardMarkerStatuses.Available;
        const activeDevices = site.devices?.filter((device) => device.device_state === true) ?? [];
        const inactiveDevices =
            site.devices?.filter((device) => device.device_state === false) ?? [];

        const outOfOrderPoints =
            site.points?.filter((point) => point.status === PointStatuses.OutOfOrder) ?? [];
        const inUsePoints =
            site.points?.filter((point) => point.status === PointStatuses.InUse) ?? [];
        const reservedPoints =
            site.points?.filter((point) => point.status === PointStatuses.Reserved) ?? [];
        const occupiedPoints =
            site.points?.filter((point) => point.status === PointStatuses.Occupied) ?? [];
        const availablePoints =
            site.points?.filter((point) => point.status === PointStatuses.Available) ?? [];
        const unknownPoints =
            site.points?.filter((point) => point.status === PointStatuses.Unknown) ?? [];

        totalOutOfOrderPoints = outOfOrderPoints.concat(totalOutOfOrderPoints);
        totalInUsePoints = inUsePoints.concat(totalInUsePoints);
        totalReservedPoints = reservedPoints.concat(totalReservedPoints);
        totalOccupiedPoints = occupiedPoints.concat(totalOccupiedPoints);
        totalAvailablePoints = availablePoints.concat(totalAvailablePoints);
        totalUnknownPoints = unknownPoints.concat(totalUnknownPoints);

        if (outOfOrderPoints.length > 0) {
            siteStatus = DashboardMarkerStatuses.OutOfOrder;
        } else if (inUsePoints.length > 0 || reservedPoints.length > 0) {
            siteStatus = DashboardMarkerStatuses.InUse;
        } else if (occupiedPoints.length > 0) {
            siteStatus = DashboardMarkerStatuses.Occupied;
        } else if (unknownPoints.length > 0) {
            siteStatus = DashboardMarkerStatuses.Unknown;
        }

        statuses = [
            ...statuses,
            {
                ...site,
                devices: [...inactiveDevices, ...activeDevices],
                points: [
                    ...outOfOrderPoints,
                    ...inUsePoints,
                    ...reservedPoints,
                    ...occupiedPoints,
                    ...availablePoints,
                    ...unknownPoints,
                ],
                status: siteStatus,
            },
        ];
    });
    return {
        statuses,
        totalAvailablePoints,
        totalInUsePoints,
        totalOccupiedPoints,
        totalOutOfOrderPoints,
        totalReservedPoints,
        totalUnknownPoints,
    };
};

// create point status chart data
export const pointStatusChartData = (sitesPointData: SitePointsData) => {
    return [
        {
            name: 'points.statuses.available',
            value: [sitesPointData.totalAvailablePoints.length ?? 0],
            color: ChartColors.Green,
        },
        {
            name: 'points.statuses.outOfOrder',
            value: [sitesPointData.totalOutOfOrderPoints.length ?? 0],
            color: ChartColors.Red,
        },
        {
            name: 'points.statuses.occupied',
            value: [sitesPointData.totalOccupiedPoints.length ?? 0],
            color: ChartColors.Blue,
        },
        {
            name: 'points.statuses.inUse',
            value: [sitesPointData.totalInUsePoints.length ?? 0],
            color: ChartColors.Yellow,
        },
        {
            name: 'points.statuses.unknown',
            value: [sitesPointData.totalUnknownPoints.length ?? 0],
            color: ChartColors.Gray,
        },
    ];
};

export const getMandatorCoordinates = async (
    currentMandator: Mandator,
    setMandatorCoordinates: Dispatch<SetStateAction<GPSCoordinates>>
) => {
    let currentMandatorCoordinates: GPSCoordinates = {
        latitude: BONNDORF_COORDINATES.lat,
        longitude: BONNDORF_COORDINATES.lng,
    };

    const addressQuery = `${currentMandator.street || ''} + ${currentMandator.city} + ${
        currentMandator.country
    }`;
    const address = await geocode(NOMINATIM_GEOCODE_URL(addressQuery));
    if (address && address[0]) {
        currentMandatorCoordinates = {
            latitude: address[0].lat,
            longitude: address[0].lon,
        };
    }

    setMandatorCoordinates(currentMandatorCoordinates);
};

export const getTranslatedSelectData = (data: SelectDataType[]) => {
    return data.map((item) => ({
        ...item,
        name: i18n.t(item.name),
    }));
};

export const generateSelectFilterProperties = (
    selectData: SelectDataType[],
    handleChangeSelectValue: any,
    selectDefaultValue: string,
    selectName: string,
    isDataTranslated?: boolean
) => {
    const translatedData = getTranslatedSelectData(selectData);
    return {
        isSelectFilterAvailable: true,
        selectFilterData: isDataTranslated ? translatedData : selectData,
        handleChangeSelectFilterData: handleChangeSelectValue,
        selectFilterDefaultValue: selectDefaultValue,
        selectName: selectName,
    };
};

export const getProductsUnitMeasures = (mandatorProducts: ExtendedProduct[]) => {
    const productsUnitMeasures: string[] = [];
    mandatorProducts.forEach((product) => {
        if (!productsUnitMeasures.includes(product?.unit_measure ?? ''))
            productsUnitMeasures.push(product?.unit_measure ?? '');
    });
    return productsUnitMeasures.sort();
};

export const getTotalQuantityUnitMeasures = (mandatorProducts: ExtendedProduct[]) => {
    const newUnitMeasures = getProductsUnitMeasures(mandatorProducts);
    const unitMeasuresData = newUnitMeasures.map((unitMeasure) => {
        return { name: unitMeasure, value: unitMeasure.toUpperCase() };
    });
    return unitMeasuresData;
};
