import { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { MapContainer, useMap } from 'react-leaflet';
import L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';

import { DashboardMapTiles } from '../../common/index';
import { GPSCoordinates } from '../../common/interfaces';
import { BlockArrayProps } from '../interfaces';
import { BONNDORF_COORDINATES, POINT_ICON_STATUSES, POINT_STATUSES } from '../../../constants';

// Add the GestureHandling handler to the Leaflet map
L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

const RecenterAutomatically = ({ latitude, longitude }: GPSCoordinates) => {
    const map = useMap();
    useMemo(() => {
        if (latitude && longitude) map.setView([latitude, longitude]);
    }, [map, latitude, longitude]);
    return null;
};

const defaultMapOptions = {
    zoom: 6,
    minZoom: 2,
    gestureHandling: true,
};

/**
 * A component that renders a custom Leaflet map with a set of markers and a legend.
 * @param {BlockArrayProps} props - The properties of the MapBlock component.
 * @param {Array} props.data - The data to be displayed on the map.
 * @param {number} props.width - The width of the map.
 * @param {number} props.height - The height of the map.
 * @param {string} props.role - The role of the map.
 * @param {GPSCoordinates} props.mapCoordinates - The coordinates of the map.
 * @return {JSX.Element} The rendered MapBlock component.
 */
const MapBlock = ({ data, width, height, role, mapCoordinates }: BlockArrayProps): JSX.Element => {
    const centerCoordinates = new L.LatLng(
        mapCoordinates?.latitude || BONNDORF_COORDINATES.lat,
        mapCoordinates?.longitude || BONNDORF_COORDINATES.lng
    );
    const { t } = useTranslation();

    const legendMaxWidth = `${(width as number) - 31}px`;

    const mapOptions = {
        ...defaultMapOptions,
        gestureHandlingOptions: {
            text: {
                touch: t('general.geolocation.zoomAtTouch'),
                scroll: t('general.geolocation.zoomAtScroll'),
                scrollMac: t('general.geolocation.zoomAtScrollMac'),
            },
            duration: 3000,
        },
        center: centerCoordinates,
    };

    return (
        <Box width={width} height={height} role={role}>
            <Box>
                <MapContainer {...mapOptions}>
                    <DashboardMapTiles data={data} width={width} height={height} />
                    <RecenterAutomatically
                        latitude={centerCoordinates.lat}
                        longitude={centerCoordinates.lng}
                    />
                </MapContainer>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                    width: '97%',
                    gap: 1,
                    position: 'absolute',
                    bottom: '5px',
                    maxWidth: legendMaxWidth,
                    zIndex: '999999',
                }}>
                {Array.from(POINT_STATUSES.keys()).map((key) => (
                    <Box className="customLegendItem" key={key}>
                        <img
                            src={POINT_ICON_STATUSES.get(key)}
                            alt="status"
                            className="pointStatusImage"
                        />
                        <Typography variant="body1" display={'inline'}>
                            {t(POINT_STATUSES.get(key) ?? '')}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default memo(MapBlock);
