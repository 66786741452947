import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { SelectionRange } from '@progress/kendo-react-dateinputs';
import moment, { unitOfTime } from 'moment';
import { TODAY_DATE } from '../constants';

const getUTCDate = (date: Date) => {
    return new Date(
        Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
        )
    )
        .toISOString()
        .replace('Z', '')
        .replace('T', ' ');
};

export const createDateFilters = (
    dateFilters: SelectionRange,
    filters: (CompositeFilterDescriptor | FilterDescriptor)[],
    fieldName?: string
) => {
    const selectedFieldName = fieldName ?? 'created_at';
    if (dateFilters.start) {
        filters.push({
            field: selectedFieldName,
            operator: 'gt',
            value: getUTCDate(moment(dateFilters.start?.setHours(0, 0, 0)).toDate()),
        });
        if (!dateFilters.end || dateFilters.end.getTime() === dateFilters.start.getTime()) {
            filters.push({
                field: selectedFieldName,
                operator: 'lt',
                value: getUTCDate(moment(dateFilters.start).add(1, 'day').toDate()),
            });
        } else {
            filters.push({
                field: selectedFieldName,
                operator: 'lt',
                value: getUTCDate(
                    moment(dateFilters.end.setHours(0, 0, 0))
                        .add(1, 'day')
                        .toDate()
                ),
            });
        }
    }
    return filters;
};

export const createDateFiltersParams = (dateFilters: SelectionRange) => {
    let start_date = '';
    let end_date = '';
    if (dateFilters.start) {
        start_date = getUTCDate(moment(dateFilters.start?.setHours(0, 0, 0)).toDate());
        if (!dateFilters.end || dateFilters.end.getTime() === dateFilters.start.getTime()) {
            end_date = getUTCDate(moment(dateFilters.start).add(1, 'day').toDate());
        } else {
            end_date = getUTCDate(
                moment(dateFilters.end.setHours(0, 0, 0))
                    .add(1, 'day')
                    .toDate()
            );
        }
    }
    return {
        start_date,
        end_date,
    };
};

export const generateMinEndTime = (startTime: Date) => {
    return new Date(startTime.setMinutes(startTime.getMinutes() + 1));
};

//return the translated short form of a day, depending on language (Mon, Tue, Wed)
export const getTranslatedShortDay = (date: Date, language: string) => {
    return new Intl.DateTimeFormat(language, { weekday: 'short' }).format(date);
};

export const convertMinutesToTimeString = (nrOfMinutes: number) => {
    const hours = Math.floor(nrOfMinutes / 60);
    const minutes = nrOfMinutes % 60;
    const hoursString = hours < 10 ? `0${hours}` : hours.toString();
    const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
    return `${hoursString}:${minutesString}`;
};

export const convertDateToFormat = (data: string | Date, format: string) => {
    return moment(data).format(format);
};

export const convertToDateObject = (data: string, format: string) => {
    return moment(new Date(data), format).toDate();
};

export const formatTodayDate = (format: string) => {
    return moment(new Date(), format).toDate();
};

export const addAmountToTodayDate = (format: string, amount: number, unit: unitOfTime.Base) => {
    return moment(new Date(), format).add(amount, unit).toDate();
};

export const formatDateToISOString = (data: Date | null) => {
    return moment(data).milliseconds(0).toISOString();
};

export const addCurrentTimeToDate = (date: Date | null) => {
    if (date) {
        const currentDate = date.setHours(
            TODAY_DATE.getHours(),
            TODAY_DATE.getMinutes(),
            TODAY_DATE.getSeconds()
        );
        return new Date(currentDate);
    }
    return date;
};

export const formatTimeString = (time: string, language: string) => {
    return moment(time, 'hh:mm:ss').toDate().toLocaleTimeString(language);
};

export const compareDates = (first: string | Date, second: string | Date) => {
    //returns true if 'first' > 'second' and false if 'second' > 'first' or one of the dates is invalid
    return new Date(first).getTime() - new Date(second).getTime();
};

export const compareTimes = (a: Date, b: Date) => {
    if (a.getHours() < b.getHours()) return -1;
    if (a.getHours() > b.getHours()) return 1;
    if (a.getHours() === b.getHours()) {
        if (a.getMinutes() < b.getMinutes()) return -1;
        if (a.getMinutes() > b.getMinutes()) return 1;
    }
    return 0;
};

//numerical time input processors
const processTwoUpdate = (incomingDigit: number) => {
    if (incomingDigit <= 4) {
        return `2${incomingDigit}`;
    }
    return `0${incomingDigit}`;
};

export const processHoursValue = (hours: string) => {
    if (hours.length === 1) return `0${hours}`;

    const firstDigit = parseInt(hours.slice(0, 1));
    const secondDigit = parseInt(hours.slice(1, 2));
    const incomingDigit = parseInt(hours.slice(2, 3));

    if (isNaN(incomingDigit)) {
        return '00';
    }

    if (firstDigit !== 0) {
        return `0${incomingDigit}`;
    } else {
        switch (secondDigit) {
            case 1:
                return `1${incomingDigit}`;
            case 2:
                return processTwoUpdate(incomingDigit);
            default:
                return `0${incomingDigit}`;
        }
    }
};

export const processMinutesValue = (minutes: string) => {
    if (minutes.length === 1) return `0${minutes}`;

    const firstDigit = parseInt(minutes.slice(0, 1));
    const secondDigit = parseInt(minutes.slice(1, 2));
    const incomingDigit = parseInt(minutes.slice(2, 3));

    if (isNaN(incomingDigit)) {
        return '00';
    }

    if (firstDigit !== 0 || secondDigit > 5) {
        return `0${incomingDigit}`;
    } else {
        return `${secondDigit}${incomingDigit}`;
    }
};
