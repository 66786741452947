import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/grid/interfaces';
import { COLUMN_VISIBLE, CUSTOM_COLUMN_MIN_WIDTH, MIN_COLUMN_WIDTH } from '../../../constants';

export const getDevicesColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => [
    {
        field: 'name',
        title: 'general.labels.name',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'code',
        title: 'general.labels.code',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'site_name',
        title: 'sites.entityName',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'protocol_name',
        title: 'devices.protocol',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'device_model_name',
        title: 'devices.model',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'installation_date',
        title: 'devices.installationDate',
        filter: 'date',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'version',
        title: 'devices.version',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'gps.latitude',
        title: 'general.geolocation.latitude',
        filter: 'numeric',
        minWidth: MIN_COLUMN_WIDTH,
        show: !COLUMN_VISIBLE,
        sortable: false,
    },
    {
        field: 'gps.longitude',
        title: 'general.geolocation.longitude',
        filter: 'numeric',
        minWidth: MIN_COLUMN_WIDTH,
        show: !COLUMN_VISIBLE,
        sortable: false,
    },
    {
        field: 'device_state',
        title: 'general.labels.status',
        filter: 'boolean',
        minWidth: CUSTOM_COLUMN_MIN_WIDTH,
        show: COLUMN_VISIBLE,
        cell: customCells.get('deviceStatus'),
    },
];
