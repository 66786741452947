import { ErrorOutline } from '@mui/icons-material';
import { Alert, Typography } from '@mui/material';
import { staticColors } from '../../themes/themes';
interface ErrorProps {
    error?: string | Record<string, string>;
    iconColor?: string;
    marginBottom?: string;
    alertBackgroundColor?: string;
    textAlertColor?: string;
    forcedHeight?: string;
}

/**
 * Renders an error component with the given props.
 *
 * @param {ErrorProps} props - The props for the error component.
 * @param {string | Record<string, string>} props.error - The error message or object of error messages.
 * @param {string} [props.iconColor] - The color of the error icon. Defaults to white.
 * @param {string} [props.marginBottom] - The bottom margin of the error component.
 * @param {string} [props.alertBackgroundColor='#CF5757'] - The background color of the error alert. Defaults to #CF5757.
 * @param {string} [props.textAlertColor=staticColors.white] - The color of the error text. Defaults to staticColors.white.
 * @param {string} [props.forcedHeight] - The forced height of the error component.
 * @return {JSX.Element} The rendered error component.
 */
const ErrorComponent = ({
    error,
    iconColor,
    marginBottom,
    alertBackgroundColor = '#CF5757',
    textAlertColor = staticColors.white,
    forcedHeight,
}: ErrorProps): JSX.Element => {
    const multipleErrors = typeof error === 'object';
    const calculatedHeight = multipleErrors ? '10vh' : 'auto';

    const renderContent = () => {
        return multipleErrors ? (
            Object.entries(error ?? {}).map(([key, value]) => (
                <Typography component="span" key={key}>{` ${value}; `}</Typography>
            ))
        ) : (
            <Typography component="span">
                <>{error}</>
            </Typography>
        );
    };

    return (
        <Alert
            icon={<ErrorOutline sx={{ color: iconColor ?? '#fff' }} />}
            sx={{
                alignItems: 'center',
                backgroundColor: `${alertBackgroundColor} !important`,
                color: `${textAlertColor} !important`,
                fontFamily: 'Open Sans-regular',
                marginBottom: marginBottom,
                minHeight: forcedHeight ?? calculatedHeight,
            }}>
            {renderContent()}
        </Alert>
    );
};
export default ErrorComponent;
