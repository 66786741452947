import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// common components, interfaces, constants and helpers
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { Interceptor } from '../../../common';
import { fleetnetBuildQueryParams } from '../../helpers/fleetnetQueryBuilders';
import { HostProviderStateTypes, HostProvidersReqProps } from '../../interfaces/providers';

const initialState: HostProviderStateTypes = {
    hostProviders: INITIAL_DATA_STATE,
    hostProvider: null,
};

/**
 *  create a thunk for getting hostProvider
 */
export const getHostProvider = createAsyncThunk(
    'hostProviders/list',
    async ({ dataState }: HostProvidersReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = dataState ? fleetnetBuildQueryParams(dataState) : null;
            // const response = await Interceptor().get(`${URLS.HostProvider}`, {
            const response = await Interceptor().get(`${URLS.HostProvider}`, {
                params: { ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 *  create thunk for updating an hostProvider
 */
export const updateHostProvider = createAsyncThunk(
    'hostProviders/update',
    async ({ hostProvider, hostProviderId }: any, { rejectWithValue }) => {
        try {
            return await Interceptor().patch(`${URLS.HostProvider}/${hostProviderId}`, {
                ...hostProvider,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 *  create a thunk for adding a host provider
 */
export const addHostProvider = createAsyncThunk(
    'hostProvider/add',
    async (hostProvider: any, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.HostProvider}`, {
                ...hostProvider,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 * create thunk for getting the hostProvider
 */
export const getHostProviderById = createAsyncThunk(
    'hostProviders/hostProvider',
    async (hostProviderId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.HostProvider}/${hostProviderId}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

export const deleteHostProvider = createAsyncThunk(
    'hostProviders/delete',
    async (hostProviderId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.HostProvider}/${hostProviderId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 *  create a thunk for getting provider list
 */
export const getProviderList = createAsyncThunk('host-provider/list', async () => {
    try {
        const response = await Interceptor().get(`${URLS.HostProviderList}`);
        return response.data;
    } catch (err) {
        const error = err as AxiosError;
        return error?.response;
    }
});

const hostProviderSlice = createSlice({
    name: 'hostProvider',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.hostProviders.data = [...action.payload];
        },
        resetHostProvider: (state) => {
            state.hostProvider = initialState.hostProvider;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getHostProvider.fulfilled, (state, { payload }) => {
            state.hostProviders.data = [...payload.data];
            state.hostProviders.total = payload.meta.totalItems;
        });
        builder.addCase(getHostProviderById.fulfilled, (state, { payload }) => {
            state.hostProvider = payload;
        });
    },
});

// Action creators are generated for each case reducer function
export const { updateItems, resetHostProvider } = hostProviderSlice.actions;
export default hostProviderSlice.reducer;
