import { GridCellProps } from '@progress/kendo-react-grid';
import { ComponentType } from 'react';
import { MIN_COLUMN_WIDTH, COLUMN_VISIBLE, CUSTOM_COLUMN_MIN_WIDTH } from '../../../constants';
import { IGridColumn } from '../../common/grid/interfaces';

export const getSiteColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>,
    additionalColumn = false
): IGridColumn[] => [
    {
        field: 'name',
        title: 'general.labels.name',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'code',
        title: 'general.labels.code',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'street',
        title: 'general.geolocation.street',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'city',
        title: 'general.geolocation.city',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'country',
        title: 'general.geolocation.country',
        filter: 'text',
        cell: customCells.get('country'),
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'currency',
        title: 'sites.currency',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: !additionalColumn,
    },
    additionalColumn
        ? {
              field: 'site_state',
              title: 'general.labels.status',
              filter: 'text',
              minWidth: CUSTOM_COLUMN_MIN_WIDTH,
              show: !COLUMN_VISIBLE,
          }
        : {},
    {
        field: 'capacity',
        title: 'sites.capacity',
        filter: 'numeric',
        minWidth: MIN_COLUMN_WIDTH,
        show: !COLUMN_VISIBLE,
        sortable: false,
    },
    !additionalColumn
        ? {
              field: 'site_state',
              title: 'general.labels.status',
              filter: 'text',
              cell: customCells.get('site_state'),
              minWidth: CUSTOM_COLUMN_MIN_WIDTH,
              show: COLUMN_VISIBLE,
          }
        : {},
];
