import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// common components, interfaces, constants and helpers
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { Interceptor } from '../../../common';
import { CardIssuerStateTypes, CardIssuersReqProps } from '../../../pages/interfaces';
import { fleetnetBuildQueryParams } from '../../helpers/fleetnetQueryBuilders';
import { CardIssuer, CardIssuerUpdateReqProps } from '../../interfaces/cardIssuer';

const initialState: CardIssuerStateTypes = {
    cardIssuers: INITIAL_DATA_STATE,
    cardIssuer: null,
};

/**
 *  create a thunk for getting cardIssuer
 */
export const getCardIssuer = createAsyncThunk(
    'cardIssuers/list',
    async ({ dataState }: CardIssuersReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = dataState ? fleetnetBuildQueryParams(dataState) : null;
            const response = await Interceptor().get(`${URLS.CardIssuers}`, {
                params: { ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

/**
 *  create a thunk for adding a card issuer
 */
export const addcardIssuer = createAsyncThunk(
    'cardIssuers/add',
    async (cardIssuer: CardIssuer, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.CardIssuers}`, { ...cardIssuer });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

/**
 * create thunk for getting the cardIssuer
 */
export const getCardIssuerById = createAsyncThunk(
    'cardIssuers/cardIssuer',
    async (cardIssuerId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.CardIssuers}/${cardIssuerId}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

/**
 *  create thunk for updating an cardIssuer
 */
export const updateCardIssuer = createAsyncThunk(
    'cardIssuers/update',
    async ({ cardIssuer, cardIssuerId }: CardIssuerUpdateReqProps, { rejectWithValue }) => {
        try {
            return await Interceptor().patch(`${URLS.CardIssuers}/${cardIssuerId}`, {
                ...cardIssuer,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const deleteCardIssuer = createAsyncThunk(
    'cardIssuers/delete',
    async (cardIssuerId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.CardIssuers}/${cardIssuerId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

const cardIssuerSlice = createSlice({
    name: 'cardIssuer',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.cardIssuers.data = [...action.payload];
        },
        resetCardIssuer: (state) => {
            state.cardIssuer = initialState.cardIssuer;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCardIssuer.fulfilled, (state, { payload }) => {
            state.cardIssuers.data = [...payload.data];
            state.cardIssuers.total = payload.meta.totalItems;
        });
        builder.addCase(getCardIssuerById.fulfilled, (state, { payload }) => {
            state.cardIssuer = payload;
        });
    },
});

// Action creators are generated for each case reducer function
export const { updateItems, resetCardIssuer } = cardIssuerSlice.actions;
export default cardIssuerSlice.reducer;
