import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { URLS, INITIAL_DATA_STATE } from '../../../constants';
import { buildQueryParams } from '../../../helpers';
import { Interceptor } from '../../common';
import {
    Manufacturer,
    ManufacturerByProtocolReqProps,
    ManufacturerModelsReqProps,
    ManufacturerStateTypes,
    ManufacturerUpdateProps,
} from '../interfaces';

const initialState: ManufacturerStateTypes = {
    manufacturers: INITIAL_DATA_STATE,
    manufacturer: null,
    models: [],
};

// create a thunk for getting manufacturers by protocol
export const getManufacturersByProtocolId = createAsyncThunk(
    'manufacturers/list',
    async ({ protocol_id, data_state }: ManufacturerByProtocolReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const res = await Interceptor().get(`${URLS.Manufacturers}`, {
                params: { protocol_id: protocol_id, ...queryParams },
            });
            return res.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// get a manufacturer's details
export const getManufacturerById = createAsyncThunk(
    'manufacturers/manufacturer',
    async (manufacturer_id: string, { rejectWithValue }) => {
        try {
            const res = await Interceptor().get(`${URLS.Manufacturers}/${manufacturer_id}`);
            return res.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// get a manufacturer's models
export const getManufacturerModels = createAsyncThunk(
    'manufacturers/models',
    async ({ manufacturer_id, data_state }: ManufacturerModelsReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const res = await Interceptor().get(`${URLS.Manufacturers}/${manufacturer_id}/models`, {
                params: queryParams,
            });
            return res.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// add a manufacturer
export const addManufacturer = createAsyncThunk(
    'manufacturers/add',
    async (manufacturer: Manufacturer, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Manufacturers}`, { ...manufacturer });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// update a manufacturer
export const updateManufacturer = createAsyncThunk(
    'manufacturers/update',
    async ({ manufacturer_id, manufacturer }: ManufacturerUpdateProps, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.Manufacturers}/${manufacturer_id}`, {
                ...manufacturer,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for deleting a manufacturer
export const deleteManufacturer = createAsyncThunk(
    'manufacturers/delete',
    async (manufacturer_id: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Manufacturers}/${manufacturer_id}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// reducers
const manufacturersSlice = createSlice({
    name: 'manufacturers',
    initialState,
    reducers: {
        resetManufacturer: (state) => {
            state.manufacturer = initialState.manufacturer;
        },
        resetManufacturers: (state) => {
            state.manufacturers = initialState.manufacturers;
        },
        resetModels: (state) => {
            state.models = initialState.models;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getManufacturersByProtocolId.fulfilled, (state, { payload }) => {
            state.manufacturers.data = [...payload.data];
            state.manufacturers.total = payload.meta.total;
        });
        builder.addCase(getManufacturerById.fulfilled, (state, { payload }) => {
            state.manufacturer = payload.data[0];
        });
        builder.addCase(getManufacturerModels.fulfilled, (state, { payload }) => {
            state.models = payload.data;
        });
    },
});

export const { resetManufacturer, resetManufacturers, resetModels } = manufacturersSlice.actions;
export default manufacturersSlice.reducer;
