import { Dispatch, KeyboardEvent, SetStateAction } from 'react';
import { t } from 'i18next';
import { FormikErrors } from 'formik';
import { toast } from 'react-toastify';
import { NavigateFunction, Params } from 'react-router-dom';

import { Actions, SUCCESSFUL_SELECTED_ACTION, ToasterType } from '../constants';
import { openLeavePageModal } from './handleModalVisibility';
import { clearLSOnComponentLeave, navigateToParent } from '.';

export const backAndCancelAction = (navigate: NavigateFunction) => {
    if (localStorage.addOrEdit === 'true') {
        openLeavePageModal(() => navigateToParent(navigate));
    } else {
        navigateToParent(navigate);
        clearLSOnComponentLeave();
    }
};
export const validateForm = <T>(values: T) => {
    if (values) {
        localStorage.setItem('addOrEdit', 'true');
    }
};

// prevent form submit when enter is pressed
export const preventSubmit = (keyEvent: KeyboardEvent<HTMLElement>) => {
    const textareas = document.getElementsByClassName('textarea');
    const isTextArea = testTextarea(textareas, keyEvent.target);

    if (keyEvent.key === 'Enter' && !isTextArea) {
        keyEvent.preventDefault();
    }
};

const testTextarea = (textareas: HTMLCollection, item: EventTarget) => {
    const arr = Array.from(textareas);
    let isTextArea = false;
    if (arr.length > 0) {
        arr.forEach(function (element) {
            if (element.ariaHidden !== 'true' && element === item) {
                isTextArea = true;
            }
        });
    }
    return isTextArea;
};

export const generateFormTitle = (
    pathParameters: Readonly<Params<string>> | any,
    itemName: string,
    entityName?: string
) => {
    if (pathParameters.id && pathParameters.action !== Actions.Copy) {
        const translatedAction = t(`general.labels.${pathParameters.action}`);
        return `${translatedAction ?? ''} ${itemName}`;
    } else {
        return t(`general.labels.add`, { entity: t(entityName ?? '', { count: 1 }) });
    }
};

export const checkEditButtonVisibility = (pathParamsAction: string, editRight: boolean) => {
    return pathParamsAction === Actions.View && editRight;
};

export const checkDeleteButtonVisibility = (pathParamsActions: string, deleteRight: boolean) => {
    return (
        (pathParamsActions === Actions.View || pathParamsActions === Actions.Edit) && deleteRight
    );
};

export const isDisabledTab = (
    dirty: boolean,
    isValid: boolean,
    selectedTab: number,
    previousTab: number,
    idItem?: string
) => {
    if (!idItem) {
        return (!isValid || !dirty) && previousTab === selectedTab;
    }
    return !isValid && previousTab === selectedTab;
};

export const showToaster = (
    type: string,
    message: string,
    action?: string,
    skipTranslation?: boolean
) => {
    let translatedMessage = skipTranslation ? message : t(action ? `${message}${action}` : message);

    if (translatedMessage.includes(SUCCESSFUL_SELECTED_ACTION)) {
        translatedMessage = t(message, { action: t(action?.toLowerCase() ?? '') });
    }
    switch (type) {
        case 'success':
            toast.success(translatedMessage, { theme: 'colored' });
            break;
        case 'warning':
            toast.warn(translatedMessage, { theme: 'colored' });
            break;
        case 'error':
            toast.error(translatedMessage, { theme: 'colored' });
            break;
        default:
            toast.info(translatedMessage, { theme: 'colored' });
    }
};

export const handleChangeTabs = (setState: Dispatch<SetStateAction<number>>, value: number) => {
    setState(value);
};

export const showValidationErrorOnDifferentTab = <T>(
    formikErrors: FormikErrors<T>,
    tabsFields: Map<number, string[]>,
    currentTab: number
) => {
    const errorKeys = Object.keys(formikErrors);
    if (errorKeys.length > 0) {
        const currentTabFields = tabsFields.get(currentTab);
        const foundError = errorKeys.some((key) => currentTabFields?.includes(key));
        if (!foundError) {
            showToaster(ToasterType.Error, t('general.validations.invalidEntries'));
        }
    }
};

export const checkRequiredField = (schemaValidation: any, fieldName: string) => {
    let isRequired = false;
    if (fieldName.includes('.')) {
        const splitedName = fieldName.split('.');
        isRequired =
            schemaValidation[splitedName[0]]?.fields[splitedName[1]]?.exclusiveTests?.required;
    } else {
        isRequired = schemaValidation[fieldName]?.exclusiveTests?.required;
    }
    return isRequired;
};
