import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/grid/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

export const getCardsColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => [
    {
        field: 'number',
        title: 'cards.cardNumber',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'payment_card_id',
        title: 'cards.cardID',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'status',
        title: 'general.labels.status',
        filter: 'boolean',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        cell: customCells.get('cardStatus'),
    },
];
