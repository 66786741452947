import { SortDescriptor } from '@progress/kendo-data-query';
import { CustomDataState } from '../../common/interfaces';

export const fleetnetBuildQueryParams = (state: CustomDataState) => {
    let query = buildPaginationQuery(state);

    if (state.sort && state.sort.length > 0) {
        const sort = buildSortQuery(state.sort[0]);
        query = { ...query, ...sort };
    }
    if (state.filter) {
        const allFilters = state.filter.filters;
        allFilters.forEach((filterObj) => {
            const filterQuery = buildFilterQuery(filterObj);
            query = { ...query, ...filterQuery };
        });
    }
    return query;
};

const buildPaginationQuery = (state: CustomDataState) => {
    let pageNo = 1;
    const takeNo = state.take || 1;
    const skipNo = state.skip || 0;
    if (skipNo > 0) {
        pageNo = skipNo / takeNo + 1;
    }

    return { limit: state.take, page: pageNo };
};

const buildSortQuery = (state: SortDescriptor) => {
    return { sortBy: state.field + ':' + state.dir?.toUpperCase() };
};

const buildFilterQuery = (filterObj: any) => {
    if (filterObj.operator == 'contains') {
        return { search: filterObj.value };
    }
    return { [filterObj.field]: filterObj.operator + filterObj.value };
};
