export enum PointStatuses {
    Available = 'available',
    InUse = 'in_use',
    OutOfOrder = 'out_of_order',
    Occupied = 'occupied',
    Unknown = 'unknown',
    Reserved = 'reserved',
}
export enum TransactionEvents {
    Release = 'release',
    Stop = 'abort',
}
export enum ArticleStates {
    Reserved = 'reserved',
    Released = 'released',
    Ongoing = 'ongoing',
    Done = 'done',
    Error = 'error',
    Declined = 'declined',
}

export enum PaymentStates {
    Reserved = 'reserved',
    Collected = 'collected',
    Declined = 'declined',
}
export enum RemoteActions {
    Restart = 'restart',
    Start = 'start',
    Stop = 'stop',
}
export enum RemoteActionStates {
    InProgress = 'in_progress',
    Pending = 'pending',
    Error = 'error',
    Completed = 'completed',
}
export const COMPLETED_ACTION_STATES: string[] = [
    RemoteActionStates.Completed,
    RemoteActionStates.Error,
];
