import { Theme, createTheme, responsiveFontSizes, PaletteMode } from '@mui/material';
import { staticColors, paletteModes } from './themes';
import { ColorsInterface, ThemeInterface } from './interfaces';
import { generateAppTheme } from './generateTheme';

declare module '@mui/material/styles/createTypography' {
    interface TypographyOptions {
        tooltip: React.CSSProperties;
    }
    interface Typography {
        tooltip: React.CSSProperties;
    }
}

export const getCurrentTheme = (theme: ThemeInterface, mode: PaletteMode): Theme => {
    if (mode === paletteModes.dark) {
        theme = {
            ...theme,
            colours: {
                ...theme.colours,
                secondary: staticColors.white,
                outlineButtonText: staticColors.white,
                popoverBackground: staticColors.lightBackground,
                grayColor: staticColors.lightBackground,
            },
        };
    }
    generateAppTheme(theme, mode);
    return createMuiCustomTheme(theme.colours, mode);
};

const createMuiCustomTheme = (colors: ColorsInterface, mode: PaletteMode): Theme => {
    const isDarkTheme = mode === paletteModes.dark;
    const customMuiTheme = createTheme({
        palette: {
            mode,
            primary: {
                main: colors.primary,
                light: colors.primary,
            },
            secondary: {
                main: colors.secondary,
            },
            success: {
                main: staticColors.success,
            },
            error: {
                main: staticColors.error,
            },
        },
        typography: {
            fontFamily: 'Open Sans-regular',
            fontSize: 16,
            htmlFontSize: 16,
            h1: {
                fontSize: 28,
                htmlFontSize: 28,
                fontWeight: 700,
                color: colors.secondary,
                fontFamily: 'Open Sans-bold',
            },
            h2: {
                fontSize: 18,
                htmlFontSize: 18,
                fontWeight: 700,
                color: colors.secondary,
            },
            h3: {
                fontSize: 16,
                htmlFontSize: 16,
                fontWeight: 700,
                color: colors.secondary,
            },
            h4: {
                fontSize: 16,
                htmlFontSize: 16,
                fontWeight: 400,
                color: colors.secondary,
            },
            h5: {
                fontSize: 14,
                htmlFontSize: 14,
                fontWeight: 400,
            },
            h6: {
                fontSize: 12,
                htmlFontSize: 12,
                fontWeight: 700,
            },
            subtitle1: {
                fontSize: 16,
                htmlFontSize: 16,
                fontWeight: 400,
            },
            subtitle2: {
                fontSize: 14,
                htmlFontSize: 14,
                fontWeight: 700,
                colors: colors.secondary,
            },
            body1: {
                fontSize: 14,
                htmlFontSize: 14,
                fontWeight: 400,
                colors: colors.secondary,
            },
            body2: {
                fontSize: 12,
                htmlFontSize: 12,
                fontWeight: 400,
                color: colors.secondary,
            },
            button: {
                fontSize: 14,
                htmlFontSize: 14,
                fontWeight: 700,
                textAlign: 'center',
                textTransform: 'capitalize',
            },
            caption: {
                fontSize: 12,
                htmlFontSize: 12,
                fontWeight: 400,
            },
            overline: {
                fontSize: 10,
                htmlFontSize: 10,
                fontWeight: 400,
                letterSpacing: '1px',
                letterTransform: 'uppercase',
            },
            tooltip: {
                fontSize: 10,
                fontWeight: 400,
                letterSpacing: '1px',
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        color: staticColors.white,
                        '&:hover': {
                            backgroundColor: colors.primaryButtonHover,
                        },
                        '&:active': {
                            backgroundColor: colors.primaryButtonActive,
                        },
                        '&:disabled': {
                            backgroundColor: colors.primaryButtonDisabled,
                            color: staticColors.white,
                        },
                    },
                    outlined: {
                        borderColor: '#C4C4C4',
                        color: colors.outlineButtonText,
                        '&:hover': {
                            borderColor: '#C4C4C433',
                            backgroundColor: isDarkTheme
                                ? staticColors.darkBackground
                                : staticColors.lightGray,
                            boxShadow:
                                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                        },
                        '&:active': {
                            backgroundColor: staticColors.gray,
                        },
                        '&:disabled': {
                            backgroundColor: staticColors.lightGray,
                        },
                    },
                },
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        '.MuiIconButton-root': {
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0)',
                            },
                        },
                    },
                },
            },
            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        width: '1em',
                        height: '1em',
                        color: staticColors.gray,
                        border: '1px solid',
                        borderColor: staticColors.darkGray,
                        borderRadius: '50%',
                        '&.Mui-active': {
                            color: staticColors.green,
                            '.MuiStepIcon-text': {
                                fill: staticColors.white,
                            },
                        },
                        '&.Mui-completed': {
                            color: staticColors.green,
                        },
                        '.MuiStepIcon-text': {
                            fill: colors.secondary,
                            fontFamily: 'Open Sans-bold',
                        },
                    },
                },
            },
            MuiStepLabel: {
                styleOverrides: {
                    root: {
                        '.MuiStepLabel-label.Mui-active': {
                            fontWeight: 900,
                        },
                    },
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    root: {
                        '.MuiDrawer-paper': {
                            backgroundColor: colors.popoverBackground,
                        },
                    },
                },
            },
            MuiStepper: {
                styleOverrides: {
                    root: {
                        minWidth: '40vw',
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        maxHeight: '28rem',
                    },
                },
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        color: colors.secondary,
                        '&.Mui-selected': {
                            backgroundColor: colors.primary,
                            borderRadius: '0px 5px 5px 0px',
                            color: staticColors.white,

                            '&>.MuiTypography-root': {
                                fontWeight: 700,
                                color: staticColors.white,
                            },

                            '&>.MuiListItemIcon-root': {
                                'svg path': {
                                    fill: staticColors.white,
                                },
                            },
                        },
                        '&>.MuiTypography-root': {
                            marginBottom: 0,
                            lineHeight: 1.375,
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: colors.primaryButtonHover,
                            borderRadius: '0px 5px 5px 0px',
                            color: staticColors.white,
                        },
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: '30px',
                        width: '18px',
                        height: 'auto',
                        color: colors.secondary,
                        'svg path': {
                            fill: colors.secondary,
                        },
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        width: '18px',
                        height: '18px',
                        ...(isDarkTheme && {
                            color: staticColors.white,
                        }),
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        top: 'calc(50% - 0.3em)',
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: staticColors.darkGray,
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.backgroundHeader,
                        textAlign: 'center',
                        color: staticColors.white,
                        fontSize: 16,
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        color: colors.secondary,
                        fieldset: {
                            borderColor: staticColors.darkGray,
                        },
                        '.MuiInputBase-inputAdornedStart': {
                            paddingTop: '6px',
                            paddingBottom: '6px',
                        },
                        '&.Mui-focused': {
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: staticColors.darkGray,
                                borderWidth: '1px',
                            },
                        },
                        '&:hover': {
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: staticColors.darkGray,
                                borderWidth: '1px',
                            },
                        },
                        '&.Mui-disabled': {
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: staticColors.darkGray,
                            },
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: staticColors.darkGray,
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        color: colors.secondary,
                        '&.Mui-selected': {
                            backgroundColor: colors.grayColor,
                        },
                        '&.Mui-selected:focus': {
                            backgroundColor: colors.grayColor,
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: colors.grayColor,
                        },
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        marginBottom: 4,
                        cursor: 'default',
                        '&.MuiTypography-button': {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        },
                    },
                    h1: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                    h5: {
                        color: colors.secondary,
                        textOverflow: 'ellipsis',
                    },
                    subtitle1: {
                        marginBottom: 0,
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: colors.secondary,
                        '&>.MuiTypography-root': {
                            cursor: 'pointer',
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        '&.MuiIconButton-colorPrimary': {
                            '&:hover': {
                                borderColor: staticColors.darkGray,
                                backgroundColor: colors.primaryButtonHover,
                                borderWidth: '1px',
                            },
                        },
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        '&>.MuiIconButton-root': {
                            color: colors.secondary,
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    container: {
                        color: colors.secondary,
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        color: colors.secondary,
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        fontSize: 18,
                        '&.Mui-selected': {
                            color: colors.secondary,
                        },
                        '&.Mui-disabled': {
                            pointerEvents: 'auto',
                        },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            opacity: '1',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        '&.MuiPaper-rounded': {
                            ...(isDarkTheme && {
                                backgroundColor: staticColors.darkBackground,
                            }),
                        },
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        maxWidth: '20rem',
                        backgroundColor: colors.popoverBackground,
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: colors.secondary,
                        '&:hover': {
                            wordBreak: 'break-all',
                            overflow: 'visible',
                            whiteSpace: 'normal',
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottomColor: staticColors.darkGray,
                        padding: '8px 0',
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        backgroundColor: staticColors.gray,
                    },
                },
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    content: {
                        display: 'flex',
                        justifyContent: 'center',
                        color: colors.secondary,
                    },
                    root: {
                        flexDirection: 'row-reverse',
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    inputRoot: {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                    paper: {
                        backgroundColor: colors.popoverBackground,
                    },
                    option: {
                        '&[aria-selected="true"]': {
                            backgroundColor: `${staticColors.gray} !important`,
                        },
                        '&[aria-selected="true"].Mui-focused': {
                            backgroundColor: `${staticColors.lightGray} !important`,
                        },
                        '&.Mui-focused': {
                            backgroundColor: {
                                backgroundColor: `${staticColors.lightGray} !important`,
                            },
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: staticColors.lightGray,
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        '&>.MuiTypography-root': {
                            marginBottom: '0px',
                        },
                    },
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.backgroundHeader,
                    },
                },
            },
        },
    });
    return responsiveFontSizes(customMuiTheme);
};
