import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { CreateUpdateEmailTemplateModel, EmailTemplateStateTypes } from '../../interfaces';
import { URLS } from '../../../../constants';
import { Interceptor } from '../../../common';

const initialState: EmailTemplateStateTypes = {
    emailTemplate: null,
};

export const addEmailTemplate = createAsyncThunk(
    'emailDelivery/template/add',
    async ({ mandatorId, template }: CreateUpdateEmailTemplateModel, { rejectWithValue }) => {
        try {
            return await Interceptor().put(
                `${URLS.DigitalReceiptTemplates}/${mandatorId}/emailtemplates`,
                {
                    ...template,
                }
            );
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getEmailTemplate = createAsyncThunk(
    'emailDelivery/template/get',
    async (mandatorId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(
                `${URLS.DigitalReceiptTemplates}/${mandatorId}/emailtemplates`
            );
            return response.data.data[0];
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

const emailDeliverySlice = createSlice({
    name: 'emailDelivery',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEmailTemplate.fulfilled, (state, { payload }) => {
            state.emailTemplate = payload;
        });
    },
});

export default emailDeliverySlice.reducer;
