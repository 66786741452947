import { validatePanMask } from '../../pages/CardProfile/PanValidator';

export const prepareTrackValidationPostdata = async (
    trackValidationProps: {
        trackThreePanMaskPattern: string;
        trackTwoPanMaskPattern: string;
        trackOnePanMaskPattern: string;
        scheme: string;
        name: string;
        trackNo: string;
        tracks: { trackNo: any }[];
        trackTwo: any;
        cardDescription: string;
        cardType: any;
    },
    cardProfileProps: any
) => {
    const trackNo =
        parseInt(trackValidationProps?.trackNo) ||
        parseInt(
            trackValidationProps?.tracks.some((track: { trackNo: number }) => track.trackNo === 2)
                ? 2
                : trackValidationProps?.tracks[0]?.trackNo || null
        );
    validatePanMask(
        trackValidationProps?.trackOnePanMaskPattern ||
            trackValidationProps?.trackTwoPanMaskPattern ||
            trackValidationProps?.trackThreePanMaskPattern,
        trackNo
    );
    const extractCardProfileProps = (trackFields: { id: string | number }[]) => {
        return trackFields?.map(({ id, ...rest }) => rest);
    };
    const cardProfilePropsForTrackOne = extractCardProfileProps(cardProfileProps?.trackOneFields);
    const cardProfilePropsForTrackTwo = extractCardProfileProps(cardProfileProps?.trackTwoFields);
    const cardProfilePropsForTrackThree = extractCardProfileProps(
        cardProfileProps?.trackThreeFields
    );

    const cardProfileFields = {
        cardProfilePropsForTrackOne: cardProfilePropsForTrackOne,
        cardProfilePropsForTrackTwo: cardProfilePropsForTrackTwo,
        cardProfilePropsForTrackThree: cardProfilePropsForTrackThree,
    };

    const getCardProfileFieldsFromTrackNo = (
        cpFields: {
            cardProfilePropsForTrackOne: object;
            cardProfilePropsForTrackTwo: object;
            cardProfilePropsForTrackThree: object;
        },
        trackNumber: number
    ) => {
        if (trackNumber === 1) {
            return cpFields?.cardProfilePropsForTrackOne;
        } else if (trackNumber === 2) {
            return cpFields?.cardProfilePropsForTrackTwo;
        } else if (trackNumber === 3) {
            return cpFields?.cardProfilePropsForTrackThree;
        }
    };

    const getPanMaskPatternForTrackNo = (trackValidation: any, trcNo: any) => {
        let panMaskPattern;

        // Set panMaskPattern based on the trackNo
        switch (trackNo) {
            case 1:
                panMaskPattern = trackValidation?.trackOnePanMaskPattern;
                break;
            case 2:
                panMaskPattern = trackValidation?.trackTwoPanMaskPattern;
                break;
            case 3:
                panMaskPattern = trackValidation?.trackThreePanMaskPattern;
                break;
            default:
                break;
        }
        return panMaskPattern;
    };
    const allTrackProfileFields = getCardProfileFieldsFromTrackNo(cardProfileFields, trackNo);
    const pmPattern = getPanMaskPatternForTrackNo(trackValidationProps, trackNo);
    return {
        data: trackValidationProps?.trackTwo,
        cardProfile: {
            name: trackValidationProps?.name,
            scheme: trackValidationProps?.scheme,
            cardDescription: trackValidationProps?.cardDescription,
            cardType: trackValidationProps?.cardType,
            tracks: [
                {
                    trackNo: trackNo,
                    panMaskPattern: pmPattern,
                    fields: allTrackProfileFields,
                },
            ],
        },
    };
};
