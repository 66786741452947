import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

export const getUserPermissionsColumns = (
    CustomCell: ComponentType<GridCellProps>
): IGridColumn[] => {
    return [
        {
            field: 'name',
            title: 'general.labels.name',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'created_at',
            title: 'general.labels.createdAt',
            filter: 'date',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'count_of_users',
            title: 'users.userCount',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
        {
            field: 'level',
            title: 'userPermissions.level',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: CustomCell,
        },
    ];
};
