import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { buildQueryParams } from '../../../helpers';
import { INITIAL_DATA_STATE, URLS } from '../../../constants';
import { Interceptor } from '../../common';
import {
    GetRequestProps,
    ProviderFieldsStateTypes,
    ProviderFieldFormValues,
    DataForDelete,
    UpdateProviderFieldModel,
} from '../interfaces';

const initialState: ProviderFieldsStateTypes = {
    providerFields: INITIAL_DATA_STATE,
    providerField: null,
};

// create a thunk for getting provider fields(paginated)
export const getProviderFields = createAsyncThunk(
    'providerFields/list',
    async ({ data_state }: GetRequestProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.ProviderFields}`, {
                params: { ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for adding a provider field
export const addProviderField = createAsyncThunk(
    'providerFields/add',
    async (providerField: ProviderFieldFormValues, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.ProviderFields}`, { ...providerField });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for deleting a provider field
export const deleteProviderField = createAsyncThunk(
    'providerFields/delete',
    async (providerFieldId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.ProviderFields}/${providerFieldId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting provider fields details by id
export const getProviderFieldById = createAsyncThunk(
    'providerFields/field',
    async (field_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.ProviderFields}/${field_id}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for updating a device
export const updateProviderField = createAsyncThunk(
    'providerFields/update',
    async ({ providerField, field_id }: UpdateProviderFieldModel, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.ProviderFields}/${field_id}`, {
                ...providerField,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// thunk for deleting multiple fields
export const deleteProviderFields = createAsyncThunk(
    'providerFields/deleteList',
    async (providerFieldList: DataForDelete, { rejectWithValue }) => {
        try {
            return await Interceptor().patch(`${URLS.ProviderFields}`, providerFieldList);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//reducers
const providerFieldsSlice = createSlice({
    name: 'providerFields',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.providerFields.data = [...action.payload];
        },
        resetProviderField: (state) => {
            state.providerField = initialState.providerField;
        },

        resetProviderFields: (state) => {
            state.providerFields = initialState.providerFields;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProviderFields.fulfilled, (state, { payload }) => {
            state.providerFields.data = [...payload.data];
            state.providerFields.total = payload.meta.total;
        });

        builder.addCase(getProviderFieldById.fulfilled, (state, { payload }) => {
            state.providerField = payload.data[0];
        });
    },
});

export const { updateItems, resetProviderField, resetProviderFields } = providerFieldsSlice.actions;
export default providerFieldsSlice.reducer;
