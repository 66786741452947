import { GridCellProps } from '@progress/kendo-react-grid';

/**
 * Renders a custom cell for displaying HTML text in a Kendo React Grid.
 *
 * @param {GridCellProps} props - The props object containing the dataItem, field, className, and style.
 * @param {Record<string, any>} props.dataItem - The data item of the row.
 * @param {string} props.field - The field name to which the cell is bound.
 * @param {string} [props.className] - Additional class name for the cell.
 * @param {React.CSSProperties} [props.style] - Additional inline styles for the cell.
 * @return {JSX.Element} The JSX element representing the custom cell.
 */
const HtmlTextCell = ({ dataItem, field, className, style }: GridCellProps): JSX.Element => {
    return (
        <td
            className={className}
            style={style}
            dangerouslySetInnerHTML={{ __html: dataItem[field as string] }}></td>
    );
};
export default HtmlTextCell;
