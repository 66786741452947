import { GridItemChangeEvent, GridRowClickEvent } from '@progress/kendo-react-grid';
import { DataResult } from '@progress/kendo-data-query';
import { NavigateFunction } from 'react-router-dom';
import { EDIT_FIELD } from '../constants/kendoConstants';
import { showToaster } from './formActions';
import { Operations, SUCCESSFUL_ACTION, ToasterType } from '../constants';

export const handleRowClick = <T extends Record<never, never>>(
    event: GridRowClickEvent,
    data: T[]
) => {
    return data.map((item) => ({
        ...item,
        [EDIT_FIELD]:
            item['id' as keyof typeof item] === event.dataItem.id &&
            !item[EDIT_FIELD as keyof typeof item],
    }));
};
export const handleEditButtonClick = <T extends Record<never, never>>(
    id: T[keyof T],
    data: T[],
    index = 'id'
) => {
    return data.map((item) => ({
        ...item,
        [EDIT_FIELD]: item[index as keyof typeof item] === id,
    }));
};
export const exitEdit = <T extends Record<never, never>>(data: T[]) => {
    return data.map((item) => ({
        ...item,
        [EDIT_FIELD]: undefined,
    }));
};
export const handleItemChange = <T extends Record<never, never>>(
    event: GridItemChangeEvent,
    data: T[],
    index: string
) => {
    const inEditID = event.dataItem[index];
    const field = event.field || '';
    return data.map((item) =>
        item[index as keyof typeof item] === inEditID ? { ...item, [field]: event.value } : item
    );
};

export const expandItem = <T>(dataItem: T, gridData: DataResult, fieldName = 'id') => {
    return {
        total: gridData.total,
        data: gridData.data.map((item) => {
            const newItem = JSON.parse(JSON.stringify(item));
            if (newItem[fieldName] === dataItem[fieldName as keyof typeof dataItem]) {
                newItem.expanded = dataItem['expanded' as keyof typeof dataItem];
            }
            return newItem;
        }),
    };
};

export const copyItemToClipboard = <T extends Record<string, string>>(
    dataItem: T,
    field: keyof T,
    customMessageOnCopy?: string
) => {
    navigator.clipboard.writeText(dataItem[field]);
    customMessageOnCopy
        ? showToaster(ToasterType.Success, customMessageOnCopy, undefined, true)
        : showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.CopiedToClipboard);
};

/**
 * Redirect the user to the item's view page, on row click.
 * @param dataItem The item checked on row click
 * @param location The item's page
 * @param specificItem specific item path element
 */
export const openItemOnRowClick = (
    dataItem: any,
    navigate: NavigateFunction,
    location: string,
    specificItem?: string
) => {
    const itemLocation = specificItem ? `/${specificItem}` : '';
    navigate(`/${location}/${dataItem.id}${itemLocation}/view`);
};
