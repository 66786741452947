import { useState, MouseEvent, useMemo, lazy, Suspense, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Divider,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Menu,
    MenuItem,
    SvgIconProps,
    Box,
} from '@mui/material';
import { Autorenew, Close, Edit, Fullscreen, InfoRounded, MoreVert } from '@mui/icons-material';

import { GridComponent, KendoNoDataMessage, SelectComponent } from '../../common';
import { WidgetProps } from '../../../app/pages/interfaces';
import PaymentMethodPopover from './PaymentMethodPopover';

const MapBlock = lazy(() => import('../../common/charts/MapBlock'));
const GraphicBlock = lazy(() => import('../../common/charts/GraphicBlock'));
const CustomBlock = lazy(() => import('../../common/charts/CustomBlock'));

/**
 * Component used to render a card with graphics as content
 * it is used on dashboard
 */
/**
 * Renders a card with graphics as content used on the dashboard.
 *
 * @param {WidgetProps} props - The properties for the component containing:
 * @param {Widget} props.item - The widget properties
 * @param {string} props.className - The CSS class name
 * @param {string} props.legendPosition - The position of the legend
 * @param {boolean} props.showTrending - Flag to show trending
 * @param {object} props.style - The style object
 * @param {object} props.customStyle - The custom style object
 * @param {number} props.graphHeight - The height of the graph
 * @param {ReactNode} props.children - The children nodes
 * @param {React.RefObject} props.innerRef - The inner reference
 * @param {number} props.min - The minimum value
 * @param {number} props.max - The maximum value
 * @param {string} props.axisTitle - The title of the axis
 * @param {Array} props.categoryAxis - The category axis
 * @param {boolean} props.isSelectFilterAvailable - Flag for select filter availability
 * @param {Array} props.selectFilterData - The select filter data
 * @param {Function} props.handleChangeSelectFilterData - The function to handle select filter data change
 * @param {string} props.selectFilterDefaultValue - The default value for select filter
 * @param {string} props.selectName - The select name
 * @param {...rest} rest - The rest of the props
 */
const CardItem = ({
    item,
    className,
    legendPosition,
    showTrending,
    style,
    customStyle,
    graphHeight,
    children,
    innerRef,
    min,
    max,
    axisTitle,
    categoryAxis,
    isSelectFilterAvailable,
    selectFilterData,
    handleChangeSelectFilterData,
    selectFilterDefaultValue,
    selectName,
    ...rest
}: WidgetProps) => {
    const { t } = useTranslation();
    const widgetActionButtons = useMemo(
        () =>
            new Map<string, SvgIconProps>([
                ['expand', <Fullscreen />],
                ['edit', <Edit />],
                ['refresh', <Autorenew />],
                ['close', <Close />],
            ]),
        []
    );

    const {
        kind,
        title,
        avatar,
        data,
        mapCoordinates,
        result,
        columns,
        initialColumns,
        setColumns,
        serverDataState,
        onServerDataStateChange,
        noDataMessage,
        formatting,
        menuData,
        hasActions,
        seriesColor,
        isPopoverVisible,
    } = item || {};

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [paymentMethodAnchorEl, setPaymentMethodAnchorInfo] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const openPopover = open ? 'true' : undefined;
    const popoverControls = open ? `long-menu-${title}` : undefined;

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const generateActionMenu = () => {
        const menu: JSX.Element[] = [];
        widgetActionButtons.forEach((value: SvgIconProps, key: string) => {
            key === 'edit' && //to be changed in the future
                menu.push(
                    <MenuItem
                        key={`${key}-${title}`}
                        onClick={(event: MouseEvent) => {
                            handleAction(event, key);
                        }}>
                        <IconButton disableRipple={true} role={`${key}-button`}>
                            <>{value}</>
                        </IconButton>
                        {t(`general.labels.${key}`)}
                    </MenuItem>
                );
        });

        return menu;
    };

    const onEditChart = () => {
        navigate('/dashboard/customize-chart');
        localStorage.setItem('graphTitle', item.title);
    };

    const handleAction = (_e: MouseEvent, key: string) => {
        switch (key) {
            case 'expand':
                break;
            case 'edit':
                onEditChart();
                break;
            case 'refresh':
                break;
            case 'close':
                break;
            default:
                return null;
        }
    };

    const width = style ? parseInt(style.width, 10) : 'auto';
    const height = style ? parseInt(style.height, 10) : 'auto';

    const memoGraphicBlock = useMemo(() => {
        const calculatedWidth = typeof width === 'number' ? width - 30 : width;
        const calculatedHeight = typeof height === 'number' ? height - 100 : height;
        return (
            <GraphicBlock
                role="chartItemContainer"
                type={kind}
                data={data}
                title={title}
                width={calculatedWidth}
                height={calculatedHeight}
                min={min}
                max={max}
                graphHeight={graphHeight}
                categoryAxis={categoryAxis ?? []}
                categoryAxisTitle={t(axisTitle ?? '')}
                legendVisibility={true}
                legendPosition={legendPosition ?? 'bottom'}
                formatting={formatting}
                menuData={menuData}
                seriesColor={seriesColor}
            />
        );
    }, [data, height, width, t]);

    const generateContent = () => {
        switch (kind) {
            case 'custom':
                return (
                    <CustomBlock
                        role="customItemContainer"
                        data={data}
                        width={width}
                        height={customStyle?.height ?? height}
                        title={title}
                        avatar={avatar}
                        showTrending={showTrending}
                        alignItems={customStyle?.alignItems || ''}
                    />
                );

            case 'map':
                return (
                    <MapBlock
                        role="mapItemContainer"
                        data={data}
                        width={width}
                        height={height}
                        mapCoordinates={mapCoordinates}
                    />
                );

            case 'grid': {
                const gridMaxHeight = (
                    typeof height === 'number' ? height - 140 : height
                ).toString();
                return (
                    <GridComponent
                        result={result}
                        columns={columns}
                        initialColumns={initialColumns}
                        setColumns={setColumns}
                        defaultMaxHeight={gridMaxHeight + 'px'}
                        defaultHeight={gridMaxHeight + 'px'}
                        serverDataState={serverDataState}
                        onServerDataStateChange={onServerDataStateChange}
                        noDataMessage={<KendoNoDataMessage message={noDataMessage} />}
                    />
                );
            }

            default: {
                return <>{memoGraphicBlock}</>;
            }
        }
    };

    const customGridItemTitleClass =
        className === 'transactions-grid-item'
            ? 'transactions-grid-item__title'
            : 'grid-item__title';

    const customGridItemClass =
        className === 'transactions-grid-item' ? 'transactions-card-content' : '';

    const renderActionButton = () => {
        return (
            hasActions && (
                <IconButton
                    aria-label="more"
                    id={`long-button-${title}`}
                    aria-controls={popoverControls}
                    aria-expanded={openPopover}
                    aria-haspopup="true"
                    onClick={handleClick}>
                    <MoreVert />
                </IconButton>
            )
        );
    };

    const handleClickInfoButton = (event: MouseEvent<HTMLButtonElement>) => {
        setPaymentMethodAnchorInfo(event.currentTarget);
    };
    const closePaymentPopover = () => {
        setPaymentMethodAnchorInfo(null);
    };

    return (
        <div className={`grid-item ${className}`} style={style} {...rest} ref={innerRef}>
            <Card>
                <CardHeader
                    action={renderActionButton()}
                    sx={{ paddingX: 1, paddingY: 2 }}
                    title={
                        <Box
                            className={customGridItemTitleClass}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between">
                            <Typography
                                variant="h2"
                                sx={{
                                    margin: 0,
                                    maxWidth: '20rem',
                                    wordBreak: 'keep-all',
                                    whiteSpace: 'pre-wrap',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                {t(title)}
                                {isPopoverVisible && (
                                    <>
                                        <IconButton
                                            role="payment-type-info-button"
                                            onClick={handleClickInfoButton}
                                            aria-label="info-button"
                                            aria-haspopup="true">
                                            {<InfoRounded />}
                                        </IconButton>
                                        {
                                            <PaymentMethodPopover
                                                anchorElement={paymentMethodAnchorEl}
                                                closePaymentPopover={closePaymentPopover}
                                            />
                                        }
                                    </>
                                )}
                            </Typography>
                            {isSelectFilterAvailable && (
                                <SelectComponent
                                    data={selectFilterData}
                                    defaultValue={selectFilterDefaultValue}
                                    onChange={handleChangeSelectFilterData}
                                    valueEntry="value"
                                    titleEntry="name"
                                    name={selectName}
                                    formChild
                                    sx={{ minWidth: '100px' }}
                                />
                            )}
                        </Box>
                    }
                />

                <Menu
                    id={`long-menu-${title}`}
                    aria-labelledby={`long-button-${title}`}
                    anchorEl={anchorEl}
                    open={open}
                    keepMounted
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    {generateActionMenu()}
                </Menu>
                <Divider sx={{ borderColor: '#E8E8E8' }} />
                <Suspense>
                    <CardContent
                        className={`${customGridItemClass} grid-item__graph`}
                        role="card-content">
                        {generateContent()}
                    </CardContent>
                </Suspense>
            </Card>
            {children}
        </div>
    );
};

export default memo(CardItem);
