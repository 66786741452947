import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { INITIAL_DATA_STATE, URLS } from '../../../constants';

import { Interceptor } from '../../common';
import { Card, CardsStateTypes, UpdateCardModel, ExtendedGetReqProps } from '../interfaces';
import { buildQueryParams } from '../../../helpers';

const initialState: CardsStateTypes = {
    cards: INITIAL_DATA_STATE,
    card: null,
};
// create a thunk for getting cards
export const getCards = createAsyncThunk(
    'cards/list',
    async ({ mandator_id, data_state }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Cards}`, {
                params: { mandator_id, ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting card details by id
export const getCardById = createAsyncThunk(
    'cards/card',
    async (card_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Cards}/${card_id}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for adding a card
export const addCard = createAsyncThunk('cards/add', async (card: Card, { rejectWithValue }) => {
    try {
        return await Interceptor().post(`${URLS.Cards}`, { ...card });
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error.response);
    }
});

// create a thunk for updating a card
export const updateCard = createAsyncThunk(
    'card/update',
    async ({ card, cardId }: UpdateCardModel, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.Cards}/${cardId}`, { ...card });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for deleting a card
export const deleteCard = createAsyncThunk(
    'cards/delete',
    async (cardId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Cards}/${cardId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//reducers
const cardsSlice = createSlice({
    name: 'cards',
    initialState,
    reducers: {
        resetCard: (state) => {
            state.card = initialState.card;
        },
        resetCards: (state) => {
            state.cards = initialState.cards;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCards.fulfilled, (state, { payload }) => {
            state.cards.data = [...payload.data];
            state.cards.total = payload.meta.total;
        });
        builder.addCase(getCardById.fulfilled, (state, { payload }) => {
            state.card = payload.data[0];
        });
    },
});

export const { resetCard, resetCards } = cardsSlice.actions;
export default cardsSlice.reducer;
