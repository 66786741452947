import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

//plugins
import { Box, Button, Typography } from '@mui/material';
import { t } from 'i18next';

const CopyCardProfile = ({ onClose }: any) => {
    //i18n
    const duplicateCardProfile = useMemo(() => t('cardProfile.duplicateCardProfile'), [t]);
    const cancleBtn = useMemo(() => t('general.labels.cancel'), [t]);
    const duplicateBtn = useMemo(() => t('cardProfile.duplicate'), [t]);

    const navigate = useNavigate();
    const cardProfilenumber = localStorage?.idItem;
    const cardProfileName = localStorage?.name;
    const handleCopy = useCallback(async () => {
        const path = `/card-profiles/${cardProfilenumber}/copy`;
        navigate(path);
        localStorage.setItem('currentPath', path);
        onClose();
        localStorage.setItem('idItem', cardProfilenumber);
    }, [onClose]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2, pt: 2 }}>
            <Typography variant="h3" align="center">
                {duplicateCardProfile} {cardProfileName} ?
            </Typography>

            <Box sx={{ display: 'flex', gap: 1, mt: 4 }}>
                <Button variant="outlined" sx={{ px: 6 }} onClick={onClose}>
                    {cancleBtn}
                </Button>
                <Button variant="contained" sx={{ px: 6 }} onClick={handleCopy}>
                    {duplicateBtn}
                </Button>
            </Box>
        </Box>
    );
};

export default CopyCardProfile;
