import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Interceptor } from '../../common';

import {
    ModuleReqProps,
    ModuleResult,
    ModulesStateTypes,
    UpdateModuleFieldsReq,
} from '../interfaces';

import { ITEMS } from './modulesData';

const modulesInitialData = ITEMS.map((item) => {
    return {
        name: item.name,
        url: item.url,
        status: false,
    };
});

const initialState: ModulesStateTypes = {
    modules: modulesInitialData,
    module: null,
    activeModules: [],
};

export const getModuleByUrl = createAsyncThunk(
    'marketplace/module',
    async (module_url: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${module_url}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getModuleStatus = createAsyncThunk(
    'marketplace/module/status',
    async ({ moduleUrl, mandatorId }: ModuleReqProps, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${moduleUrl}${mandatorId}`);
            return { moduleUrl: moduleUrl, status: response.data.active };
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const activateModule = createAsyncThunk(
    'marketplace/module/activation',
    async ({ moduleUrl, mandatorId }: ModuleReqProps, { rejectWithValue }) => {
        try {
            const response = await Interceptor().post(`${moduleUrl}`, { mandator_id: mandatorId });
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const deactivateModule = createAsyncThunk(
    'marketplace/module/deactivation',
    async ({ moduleUrl, mandatorId }: ModuleReqProps, { rejectWithValue }) => {
        try {
            const response = await Interceptor().delete(`${moduleUrl}${mandatorId}`);
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// get all module fields
export const getModuleFields = createAsyncThunk(
    'marketplace/module/fields',
    async ({ moduleUrl, mandatorId }: ModuleReqProps, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${moduleUrl}/${mandatorId}`);
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// updated the fields for a module
export const updateModuleFields = createAsyncThunk(
    'marketplace/module/fields/create',
    async ({ moduleUrl, mandatorId, moduleFields }: UpdateModuleFieldsReq, { rejectWithValue }) => {
        try {
            const response = await Interceptor().patch(`${moduleUrl}/${mandatorId}`, moduleFields);
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
const modulesSlice = createSlice({
    name: 'marketplace',
    initialState,
    reducers: {
        resetModule: (state) => {
            state.module = initialState.module;
        },
        updateActiveModules: (state, { payload }) => {
            state.activeModules = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getModuleByUrl.fulfilled, (state, { payload }) => {
            state.module = payload;
        });
        builder.addCase(getModuleStatus.fulfilled, (state, { payload }) => {
            const modulePath = payload.moduleUrl.split('/config/mandators/activations/')[0];
            const currentModule = state.modules.find(
                (item) => item.url === modulePath
            ) as ModuleResult;
            const newModules: ModuleResult[] = [];
            state.modules.forEach((module) => {
                if (module.url === currentModule.url) {
                    newModules.push({ ...module, status: payload.status });
                } else {
                    newModules.push(module);
                }
            });
            state.modules = newModules.slice();
        });
    },
});

export const { updateActiveModules } = modulesSlice.actions;
export default modulesSlice.reducer;
