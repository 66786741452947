import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Divider, Box, Button, Typography, Tooltip } from '@mui/material';
import { backAndCancelAction } from '../../../helpers';

interface FormComponentFooterProps {
    isDisabled?: boolean;
    handleFormValidation?: () => void;
    cancelAction?: () => void;
    customSaveLabel?: string;
    customBackButton?: () => void;
    isTooltipVisible?: boolean;
    closeButton?: boolean;
    customCancelLabel?: string;
}

/**
 * Renders the form footer action buttons.
 *
 * @param {Object} props - The properties for the component.
 * @param {boolean} props.isDisabled - Indicates if the form is disabled.
 * @param {Function} props.handleFormValidation - The function to handle form validation.
 * @param {Function} [props.cancelAction] - The function to handle cancel action.
 * @param {string} [props.customSaveLabel] - The custom label for the save button.
 * @param {Function} [props.customBackButton] - The function to handle custom back button.
 * @param {boolean} [props.isTooltipVisible] - Indicates if tooltip is visible.
 * @param {boolean} [props.closeButton] - Indicates if the close button is visible or cancel button is visible.
 * @param {string} [props.customCancelLabel] - The custom label for the cancel button.
 * @return {JSX.Element} The rendered footer component.
 */
const FormComponentFooter = memo(
    ({
        isDisabled,
        handleFormValidation,
        cancelAction,
        customSaveLabel,
        customBackButton,
        isTooltipVisible,
        closeButton,
        customCancelLabel,
    }: FormComponentFooterProps) => {
        const navigate = useNavigate();
        const { t } = useTranslation();

        const handleCancelAction = () => {
            cancelAction ? cancelAction() : backAndCancelAction(navigate);
        };

        const cancelLabel = useMemo(() => {
            return customCancelLabel ?? t('general.labels.cancel');
        }, [customCancelLabel, t]);

        return (
            <>
                {!isDisabled && (
                    <>
                        <Divider sx={{ mb: 1, mt: 1 }} />
                        <Box
                            className="formFooter"
                            sx={{
                                justifyContent: customBackButton ? 'space-between' : 'flex-end',
                            }}>
                            <Tooltip title={isTooltipVisible ? t('general.labels.cancel') : ''}>
                                <Button
                                    sx={{
                                        mr: closeButton ? 0 : 1,
                                        width: 'auto',
                                        minWidth: 120,
                                        height: 38,
                                        maxWidth: customBackButton ? '33%' : '50%',
                                    }}
                                    variant="outlined"
                                    onClick={handleCancelAction}>
                                    <Typography variant="button">
                                        {closeButton ? t('general.labels.close') : cancelLabel}
                                    </Typography>
                                </Button>
                            </Tooltip>
                            {closeButton !== true && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        maxWidth: customBackButton ? '67%' : '50%',
                                    }}>
                                    {customBackButton && (
                                        <Tooltip
                                            title={
                                                isTooltipVisible ? t('general.labels.back') : ''
                                            }>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    mr: 1,
                                                    width: 120,
                                                    height: 38,
                                                }}
                                                onClick={customBackButton}>
                                                <Typography variant="button">
                                                    {t('general.labels.back')}
                                                </Typography>
                                            </Button>
                                        </Tooltip>
                                    )}
                                    <Tooltip
                                        title={
                                            isTooltipVisible
                                                ? customSaveLabel ?? t('general.labels.save')
                                                : ''
                                        }>
                                        <Button
                                            color="primary"
                                            sx={{ minWidth: 120, height: 38 }}
                                            variant="contained"
                                            type="submit"
                                            onClick={handleFormValidation}>
                                            <Typography variant="button">
                                                {customSaveLabel ?? t('general.labels.save')}
                                            </Typography>
                                        </Button>
                                    </Tooltip>
                                </Box>
                            )}
                        </Box>
                    </>
                )}
            </>
        );
    }
);

export default FormComponentFooter;
