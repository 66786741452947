import enMessages from './en.json';
import frMessages from './fr.json';
import esMessages from './es.json';
import deMessages from './de.json';
import itMessages from './it.json';
import nlMessages from './nl.json';
import hrMessages from './hr.json';
import huMessages from './hu.json';
import plMessages from './pl.json';
import ptMessages from './pt.json';
import zhMessages from './zh.json';

const messages = new Map<string, object>([
    ['en', enMessages],
    ['fr', frMessages],
    ['de', deMessages],
    ['it', itMessages],
    ['nl', nlMessages],
    ['hr', hrMessages],
    ['hu', huMessages],
    ['pl', plMessages],
    ['pt', ptMessages],
    ['zh', zhMessages],
    ['es', esMessages],
]);
export default messages;
