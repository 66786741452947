/**
 *
 * @param html html string
 * @param htmlTag name of the tag that we are searching for
 * @returns collection of found html elements
 */
export const getHtmlElementByTagName = (html: string, htmlTag: string) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.getElementsByTagName(htmlTag);
};

/**
 * Used to convert an HTML string into text
 * @param html html string
 */
export const convertHTMLToPlainText = (html: string) => {
    //keep html brakes
    html = html.replace(/<\/p>/g, '\n');
    html = html.replace(/<\/div>/g, '\n');
    html = html.replace(/<\/h>/g, '\n');
    html = html.replace(/<br>/g, '\n');
    html = html.replace(/<br( )*\/>/g, '\n');

    //parse html into text
    const dom = new DOMParser().parseFromString('<!doctype html><body>' + html, 'text/html');
    return dom.body.textContent;
};
