import { Box, Button } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { RadioButtonGroup } from '..';
import { ControlRadioButton } from '../interfaces';

interface UpdateSelectedItemsProps {
    labelName?: string;
    formInitialValues: FormikValues;
    fieldName: string;
    extendedComponent?: boolean;
    statusControls: ControlRadioButton[];
    handleModalClose(): void;
    onSubmit?: (fields: FormikValues) => void;
}
type EntityValue = 'devices' | 'points';

/**
 * Renders a form as a body for a modal for updating selected items.
 *
 * @param {UpdateSelectedItemsProps} props - The properties for the component.
 * @param {string} props.labelName - The label name for the radio button group.
 * @param {string} props.fieldName - The name of the field in the form values.
 * @param {boolean} [props.extendedComponent] - Indicates if the component is extended.
 * @param {ControlRadioButton[]} props.statusControls - The controls for the radio button group.
 * @param {FormikValues} props.formInitialValues - The initial values for the form.
 * @param {() => void} props.handleModalClose - The function to handle closing the modal.
 * @param {((fields: FormikValues) => void) | undefined} [props.onSubmit] - The function to handle form submission.
 * @return {JSX.Element} The rendered form component.
 */
const UpdateSelectedItems = ({
    labelName,
    fieldName,
    extendedComponent,
    statusControls,
    formInitialValues,
    handleModalClose,
    onSubmit,
}: UpdateSelectedItemsProps): JSX.Element => {
    const { t } = useTranslation();
    const handleSubmit = (fields: FormikValues) => {
        handleModalClose();
        if (onSubmit) {
            onSubmit(fields);
        }
    };
    return (
        <Box>
            <Formik initialValues={formInitialValues} onSubmit={(fields) => handleSubmit(fields)}>
                {({ values, setValues }) => (
                    <Form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: extendedComponent ? 'center' : '',
                        }}>
                        <RadioButtonGroup
                            label={t(labelName ?? '')}
                            labelVariant="subtitle2"
                            color="secondary"
                            controls={statusControls}
                            value={values[fieldName]}
                            handleChange={(event) => {
                                const fieldValue = extendedComponent
                                    ? (event.target.value as EntityValue)
                                    : JSON.parse(event.target.value);
                                setValues({
                                    ...values,
                                    [fieldName]: fieldValue,
                                });
                            }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="outlined"
                                onClick={handleModalClose}
                                sx={{
                                    mr: 1,
                                }}>
                                {t('general.labels.cancel')}
                            </Button>
                            <Button type="submit" variant="contained">
                                {t('general.labels.apply')}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
export default UpdateSelectedItems;
