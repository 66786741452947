import { SetStateAction } from 'react';
import { TFunction } from 'react-i18next';
import { INITIAL_GRID_STATE, LARGER_INITIAL_GRID_STATE, SortDirection } from '../constants';
import { SelectedEntities } from '../app/pages/interfaces';

export const formatMessage = (t: TFunction, entity?: string, customMessage?: string) => {
    const message = entity
        ? t('general.messages.kendoNoDataPlaceholder', {
              entities: t(entity, { count: 2 }),
              entity: t(entity, { count: 1 }),
          })
        : t(customMessage || '');
    return message.split('\n').map((i, key) => {
        return <div key={key}>{i}</div>;
    });
};

export const getInitialGridState = (
    fieldName: string,
    sortDirection: SortDirection = SortDirection.Ascending,
    nonPageableGrid?: boolean,
    customGridState?: boolean
) => {
    const initialGridState = customGridState
        ? { ...LARGER_INITIAL_GRID_STATE }
        : { ...INITIAL_GRID_STATE };
    return {
        ...(nonPageableGrid ? {} : initialGridState),
        sort: [
            {
                field: fieldName,
                dir: sortDirection,
            },
        ],
    };
};

/**
 * Used to update the selection of the grid's items
 * @param newChangedData =>  new updated data when a selection is made
 * @param allSelectedItems => contains all IDs  of the selected entities or objects of type SelectedEntities
 * @param setNewSelectedItems => used to update the selected entities variable
 */
export const updateGridSelectedItems = (
    newChangedData: any[],
    allSelectedItems: (string | SelectedEntities)[],
    setNewSelectedItems: (value: SetStateAction<any>) => void,
    assignmentsFieldName?: string
) => {
    let itemsToAdd: (string | SelectedEntities)[] = [];

    if (!assignmentsFieldName) {
        itemsToAdd = newChangedData
            .filter((item) => item?.selected)
            .filter((selectedItem) => !allSelectedItems.includes(selectedItem.id))
            .map((newSelectedItems) => newSelectedItems?.id);
    } else {
        itemsToAdd = newChangedData
            .filter((item) => item?.selected)
            .filter(
                (selectedItem) =>
                    !allSelectedItems.find(
                        (element) => (element as SelectedEntities).id === selectedItem.id
                    )
            )
            .map((newSelectedItems) => {
                return {
                    id: newSelectedItems?.id,
                    number_of_assignations: newSelectedItems[assignmentsFieldName],
                };
            });
    }
    const itemsToRemove = newChangedData
        .filter((item) => item.selected === false)
        .filter((changedItems) =>
            assignmentsFieldName
                ? allSelectedItems.find(
                      (element) => (element as SelectedEntities).id === changedItems.id
                  )
                : allSelectedItems.includes(changedItems.id)
        )
        .map((item) => item.id);

    let newSelectedIDs = allSelectedItems;
    if (itemsToRemove) {
        newSelectedIDs = newSelectedIDs.filter(
            (item) =>
                !itemsToRemove.includes(assignmentsFieldName ? (item as SelectedEntities).id : item)
        );
    }
    setNewSelectedItems([...newSelectedIDs, ...itemsToAdd]);
};

/**
 * Used to check bulk actions button visibility
 */
export const checkBulkActionsButtonsVisibility = (data: (string | SelectedEntities)[]) => {
    return data.length > 0;
};

/**
 * Used to set the selection for grid data
 * @param selectedItems =>  all selected grid items
 * @param itemId => ID of each grid item
 */
export const checkGridDataSelection = (selectedItems: SelectedEntities[], itemId: string) => {
    return selectedItems.find((item) => item.id === itemId) ? true : false;
};
