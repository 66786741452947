import { Checkbox } from '@mui/material';
import { GridCellProps } from '@progress/kendo-react-grid';

const FleetnetCheckboxCell = ({ field, dataIndex, dataItem, onChange }: GridCellProps) => {
    const fieldName = field || '';
    const dataValue = dataItem[fieldName];

    const handleChange = (e: any) => {
        if (onChange) {
            onChange({
                dataIndex: dataIndex,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.checked,
            });
        }
    };

    return (
        <td>
            {dataItem.inEdit ? (
                <Checkbox
                    checked={dataValue}
                    value={dataValue}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="secondary"
                    name={fieldName}
                    role={fieldName}
                />
            ) : (
                <Checkbox
                    checked={dataValue}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="secondary"
                    disabled={dataValue}
                />
            )}
        </td>
    );
};
export default FleetnetCheckboxCell;
