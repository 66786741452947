import { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridDataStateChangeEvent, GridDetailRowProps } from '@progress/kendo-react-grid';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { getProductsForPoint, resetPointProducts } from '../points/pointsDuck';
import { getMandatorProducts, resetMandatorProducts } from './productAssignmentDuck';

import { ProductResponseItem } from '../interfaces';
import { CustomDataState, IGridColumn } from '../../common/interfaces';
import { GridComponent, KendoNoDataMessage } from '../../common';
import { getProductsColumns } from '../gridColumns';
import { convertNumberToString, getProductData } from '../../../helpers';

export const PointProducts = ({ dataItem }: GridDetailRowProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { ISOLanguageCode } = useAppSelector((state) => state.commonData);
    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const { mandatorProducts } = useAppSelector((state) => state.productsAssignment);
    const [productsServerDataState, setProductsServerDataState] = useState<CustomDataState>({});
    const [products, setProducts] = useState([]);
    const [columns, setColumns] = useState<IGridColumn[]>([]);

    const modifiedProductsData = useMemo(() => {
        return products?.map((item: ProductResponseItem) => {
            return {
                ...item,
                product: getProductData(item, mandatorProducts.data),
                unit_price: convertNumberToString(item.unit_price, ISOLanguageCode),
                tax_percentage: convertNumberToString(item.tax_percentage, ISOLanguageCode),
                max_power: convertNumberToString(item.max_power as number, ISOLanguageCode),
            };
        });
    }, [ISOLanguageCode, products, mandatorProducts.data]);

    const fetchPointProducts = useCallback(
        async (point_id: string, site_id: string, data_state: CustomDataState) => {
            const response = await dispatch(
                getProductsForPoint({
                    site_id,
                    point_id,
                    data_state,
                })
            );
            if (getProductsForPoint.fulfilled.match(response)) {
                setProducts(response.payload.data);
            }
        },
        [dispatch]
    );

    useEffect(() => {
        fetchPointProducts(dataItem.id, dataItem.site_id, productsServerDataState);
    }, [dataItem.id, dataItem.site_id]);

    useEffect(() => {
        selectedMandator?.id && dispatch(getMandatorProducts({ mandator_id: selectedMandator.id }));
    }, [selectedMandator, dispatch]);

    useEffect(() => {
        setColumns(getProductsColumns({ sortable: true, extendedProducts: true }));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetPointProducts());
            dispatch(resetMandatorProducts());
        };
    }, [dispatch]);

    const onPointProductsDataStateChange = (event: GridDataStateChangeEvent) => {
        const sortState = { sort: event.dataState.sort };
        setProductsServerDataState(sortState);
        fetchPointProducts(dataItem.id, dataItem.site_id, sortState);
    };
    const NoPointProductsComponent = useMemo(
        () => <KendoNoDataMessage message={t('products.noData')} />,
        [t]
    );

    if (products) {
        return (
            <GridComponent
                result={modifiedProductsData}
                columns={columns}
                initialColumns={getProductsColumns({ sortable: true, extendedProducts: true })}
                setColumns={setColumns}
                serverDataState={productsServerDataState}
                onServerDataStateChange={onPointProductsDataStateChange}
                noDataMessage={NoPointProductsComponent}
                isPageable={false}
                className="point-products-grid"
            />
        );
    }
    return null;
};
