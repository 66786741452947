export enum ReceiptFieldCategory {
    General = 'general',
    Articles = 'articles',
    Payment = 'payment',
    CustomerData = 'customer_data',
    Receipt = 'receipt',
    TerminalReceipt = 'terminal_receipt',
}

export const MAX_LENGTH_FOOTER = 1024;

export const TEMPLATE_TYPES = [
    { type: 'invoice', title: 'siteAssignments.invoice' },
    { type: 'note', title: 'siteAssignments.deliveryNote' },
];
export const INVOICE_TYPE = TEMPLATE_TYPES[0].type;
export const DELIVERY_NOTE_TYPE = TEMPLATE_TYPES[1].type;
