import { CustomDataState, IGridColumn } from '../app/common/interfaces';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

export const buildQueryParams = (state: CustomDataState) => {
    let query = buildPaginationQuery(state);

    if (state.sort && state.sort.length > 0) {
        const sort = buildSortQuery(state.sort[0]);
        query = { ...query, ...sort };
    }
    if (state.filter || state.simpleFilter) {
        const filter = buildFilterQuery(state);
        query = { ...query, ...filter };
    }
    return query;
};

const buildPaginationQuery = (state: CustomDataState) => {
    return { 'page[limit]': state.take, 'page[offset]': state.skip };
};

const buildSortQuery = (state: SortDescriptor) => {
    return { sort: state.dir === 'desc' ? `-${state.field}` : state.field };
};

const buildFilterQuery = (state: CustomDataState) => {
    return { filter: JSON.stringify(state.filter ?? state.simpleFilter) };
};
export const getSearchableFields = (columns: IGridColumn[]) => {
    return columns
        .map((column) =>
            column.filter && column.filter === 'text' && column.show ? column.field : undefined
        )
        .flat()
        .filter((field) => field);
};
export const getSearchFilters = (
    searchableFields: (string | undefined)[],
    value: string
): CompositeFilterDescriptor => {
    const searchFilter = searchableFields.map((field) => ({
        field,
        operator: 'contains',
        value,
    }));
    return { logic: 'or', filters: searchFilter };
};
