import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, Paper } from '@mui/material';
import { Close } from '@mui/icons-material';

interface FilterDrawerProps {
    filterInputs: Map<string, unknown>;
    applyFilters: () => void;
    resetFilters: () => void;
    closeDrawer: (newState: boolean) => void;
}

/**
 * Renders the FilterDrawer component with filter inputs, apply and reset filters functionality.
 *
 * @param {FilterDrawerProps} props - The props object containing the following properties:
 * - filterInputs - Map of filter inputs.
 * - applyFilters - Function to apply the filters.
 * - resetFilters - Function to reset the filters.
 * - closeDrawer - Function to close the drawer.
 * @return {JSX.Element} The rendered FilterDrawer component.
 */
const FilterDrawer = ({
    filterInputs,
    applyFilters,
    resetFilters,
    closeDrawer,
}: FilterDrawerProps): JSX.Element => {
    const { t } = useTranslation();

    const getInputs = () => {
        return Array.from(filterInputs.keys()).map((key) => filterInputs.get(key));
    };

    return (
        <Paper elevation={6} className="filterDrawer">
            <IconButton onClick={() => closeDrawer(false)} className="drawerCloseButton">
                <Close />
            </IconButton>
            <Box className="filterDrawerContent">
                <>{getInputs()}</>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'right', m: 1, gap: 3 }}>
                <Button variant="outlined" onClick={resetFilters}>
                    {t('general.labels.reset')}
                </Button>
                <Button variant="contained" onClick={applyFilters}>
                    {t('general.labels.apply')}
                </Button>
            </Box>
        </Paper>
    );
};

export default FilterDrawer;
