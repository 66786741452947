export const generateFilterQuery = (e: any) => {
    return {
        filter: {
            logic: e?.logic,
            filters: e?.filters?.flatMap((filterGroup: { filters: { value: string }[] }) => ({
                field: 'filter.hostProvider.name',
                operator: '$in:',
                value: [
                    filterGroup?.filters
                        ?.map((subFilter: { value: string }) => subFilter?.value)
                        ?.join(','),
                ],
            })),
        },
    };
};
