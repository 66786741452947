import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@mui/material';

import { useWindowSize } from './app/hooks';
import { BREAKPOINTS } from './constants';

import { ReactComponent as UnderConstructionIcon } from './assets/images/404.svg';
import UnderConstruction from './assets/images/404.png';

const NotFound = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    return (
        <Grid container direction="column" alignItems="center">
            {width > BREAKPOINTS.md ? (
                <UnderConstructionIcon style={{ maxWidth: '95%' }} />
            ) : (
                <img src={UnderConstruction} alt="404" style={{ maxWidth: '95%' }} />
            )}

            <Box sx={{ textAlign: 'center', mt: 4, pl: 1, pr: 1 }}>
                <Typography variant="h1">{t('general.messages.notFoundTitle')}</Typography>
                <br />
                <Typography variant="h4">{t('general.messages.notFoundBody')}</Typography>
            </Box>
        </Grid>
    );
};

export default NotFound;
