import { BASE_URL_DIGITAL_RECEIPT, BASE_URL_FLEETNET } from '../../../constants';

export const ITEMS = [
    // { Removed until production release
    //     name: 'FreeParking',
    //     url: BASE_URL_FREEPARKING,
    // },
    {
        name: 'DigitalReceipts',
        url: BASE_URL_DIGITAL_RECEIPT,
    },
    // { Removed until production release
    //     name: 'TWINT',
    //     url: BASE_URL_TWINT,
    // },
    {
        name: 'FleetNet',
        url: BASE_URL_FLEETNET,
    },
];
