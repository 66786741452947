import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/interfaces';

import {
    COLUMN_VISIBLE,
    MIN_COLUMN_WIDTH,
    LANGUAGE_CODES,
    FREQUENT_LANGUAGES,
} from '../../../constants';

export const getTranslationColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>,
    lastColumnName?: string
): IGridColumn[] => {
    const columns: IGridColumn[] = [
        {
            field: 'systemLanguage',
            title: 'digitalReceipts.systemLanguage',
            editable: false,
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
    ];

    LANGUAGE_CODES.forEach((lang) => {
        columns.push({
            field: lang,
            title: lang.toUpperCase(),
            minWidth: MIN_COLUMN_WIDTH,
            show: FREQUENT_LANGUAGES.includes(lang) ? COLUMN_VISIBLE : !COLUMN_VISIBLE,
            sortable: false,
            cell:
                lang === lastColumnName
                    ? customCells.get(lastColumnName)
                    : customCells.get('language'),
        });
    });

    return columns;
};
