// accepted unit measurements for products
export const UNIT_MEASUREMENTS = [
    { name: 'kg', code: 'kg' },
    { name: 'l', code: 'l' },
    { name: 'kWh', code: 'kWh' },
    { name: 'h', code: 'h' },
    { name: 'min', code: 'min' },
    { name: 'pc', code: 'pc' },
];

export const ProductsUnitMeasurements = new Map([
    ['KWH', 'kWh'],
    ['LTR', 'l'],
    ['L', 'l'],
    ['EA', 'pc'],
    ['PC', 'pc'],
    ['KGM', 'kg'],
    ['KG', 'kg'],
    ['KMT', 'km'],
    ['KM', 'km'],
    ['MTR', 'm'],
    ['M', 'm'],
    ['CMT', 'cm'],
    ['CM', 'cm'],
    ['MMT', 'mm'],
    ['MM', 'mm'],
    ['INH', 'in'],
    ['IN', 'in'],
    ['WHR', 'Wh'],
    ['WH', 'Wh'],
    ['A', 'a'],
    ['D', 'd'],
    ['HUR', 'h'],
    ['H', 'h'],
    ['MIN', 'min'],
    ['SEC', 's'],
    ['S', 's'],
    ['M2', 'm²'],
    ['MTK', 'm²'],
    ['M3', 'm³'],
    ['MTQ', 'm³'],
    ['CEL', '°C'],
    ['C', '°C'],
    ['FAH', '°F'],
    ['F', '°F'],
]);
// accepted categories for a product
export const PRODUCT_CATEGORIES = [
    { name: 'Refuelling', value: 'refuelling' },
    { name: 'Charging', value: 'charging' },
    { name: 'Parking', value: 'parking' },
    { name: 'Wash', value: 'wash' },
];
