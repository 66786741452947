import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { t } from 'i18next';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../hooks';
import { TrackResponseStatus } from '../../constants/fleetNetGeneral';
import { trackDataValidation } from '../../pages/CardProfile/cardProfileDuck';
import { prepareTrackValidationPostdata } from './prepareValidationPostData';

const tableStyle = {
    borderCollapse: 'collapse',
};

const cellStyle = {
    border: '1px #343A40',
    textAlign: 'left',
    paddingLeft: '8px',
    whiteSpace: 'normal',
    maxHeight: '100px',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
};

const headerCellStyle = {
    ...cellStyle,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Open Sans-regular',
};

const ValidateTrackModal = ({ additionalProps }: any) => {
    const dispatch = useAppDispatch();
    const [trackTwoValidationState, setTrackTwoValidationState] = useState<any>([]);
    const [responseStatus, setResponseStatus] = useState('');
    const [loading, setLoading] = useState(true);

    const validateTrackData = useCallback(async () => {
        const postData = await prepareTrackValidationPostdata(
            additionalProps?.validationProps,
            additionalProps?.cardProfileFields
        );
        const response = await dispatch(trackDataValidation(postData));
        if (response?.payload?.data?.status == TrackResponseStatus.Success) {
            const message = t('cardProfile.trackDataValidatedSuccessfully');
            toast.success(message, { theme: 'colored' });
        }
        setResponseStatus(response?.payload?.data?.status);
        const extractDataFromResponse = response?.payload?.data?.data?.results?.fields;
        setTrackTwoValidationState(extractDataFromResponse);
        setLoading(false);
    }, [additionalProps?.validationProps, additionalProps?.cardProfileFields]);

    useEffect(() => {
        const timer = setTimeout(() => {
            validateTrackData();
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    //Track data color if error in response  then scolor should be red else green
    const trackDataStyle: CSSProperties = {
        color: 'inherit',
    };

    if (responseStatus === TrackResponseStatus.Error) {
        trackDataStyle.color = 'red';
    } else if (responseStatus === TrackResponseStatus.Success) {
        trackDataStyle.color = 'green';
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                px: 2,
                pt: 2,
            }}>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <CircularProgress color="inherit" />
                </div>
            ) : (
                <TableContainer component={Paper} sx={tableStyle}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        ...headerCellStyle,
                                        maxWidth: '200px',
                                        whiteSpace: 'normal',
                                        textAlign: 'left',
                                    }}>
                                    {t('cardProfile.trackNo')} :
                                    {additionalProps?.trackNo
                                        ? additionalProps?.trackNo
                                        : additionalProps?.validationProps?.tracks[0]?.trackNo}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...cellStyle,
                                        ...headerCellStyle,
                                        maxWidth: '200px',
                                        whiteSpace: 'normal',
                                        textAlign: 'left',
                                        wordBreak: 'break-word',
                                        overflowWrap: 'break-word',
                                    }}>
                                    <Typography style={trackDataStyle}>
                                        {additionalProps?.validationProps?.trackTwo}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trackTwoValidationState?.map((res: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell sx={cellStyle} title={res?.key}>
                                        {res?.key}
                                    </TableCell>
                                    <TableCell sx={cellStyle} title={res?.value}>
                                        {res.Error === true ? 'Invalid value' : res?.value}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default ValidateTrackModal;
