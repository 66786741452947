import { createSlice } from '@reduxjs/toolkit';
import { PaletteMode } from '@mui/material';

import { ThemeInterface } from './interfaces';
import { DEFAULT_THEME } from '../constants';
import { paletteModes } from './themes';

interface ThemeProps {
    theme: ThemeInterface;
    mode: PaletteMode;
    logo: string | null;
}
const initialState: ThemeProps = {
    theme: DEFAULT_THEME,
    mode: paletteModes.light as PaletteMode,
    logo: null,
};

// reducers
const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        updateTheme: (state, { payload }) => {
            state.theme = payload;
        },
        updatePaletteMode: (state, { payload }) => {
            state.mode = payload
                ? (paletteModes.dark as PaletteMode)
                : (paletteModes.light as PaletteMode);
        },
        updateLogo: (state, { payload }) => {
            state.logo = payload;
        },
    },
});

export const { updateTheme, updatePaletteMode, updateLogo } = themeSlice.actions;
export default themeSlice.reducer;
