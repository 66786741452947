import { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    UpdateUserAttributesInput,
    confirmResetPassword,
    confirmSignIn,
    fetchAuthSession,
    getCurrentUser,
    resetPassword,
    signIn,
    signOut,
    updatePassword,
    updateUserAttributes,
} from 'aws-amplify/auth';
import { clearLSOnLogout } from '../../helpers';

import {
    StateTypes,
    UserCredentialsModel,
    ChangePasswordModel,
    ErrorMessage,
    UpdatePasswordModel,
} from './interfaces';
import { Interceptor } from '../common';
import { URLS } from '../../constants';

const initialState: StateTypes = {
    isAuthenticated: null,
    loading: false,
    error: null,
    newPasswordRequired: false,
    accessToken: null,
    loggedUser: null,
};

// create a thunk for login
export const login = createAsyncThunk(
    'auth/login',
    async (userCredentials: UserCredentialsModel, { rejectWithValue }) => {
        try {
            return await signIn(userCredentials);
        } catch (err) {
            const error = err as ErrorMessage;
            return rejectWithValue(error.message);
        }
    }
);

export const completeNewPassword = createAsyncThunk(
    'auth/completeNewPassword',
    async (newPassword: string, { dispatch, rejectWithValue }) => {
        try {
            await confirmSignIn({ challengeResponse: newPassword });
            await dispatch(loadUser());
        } catch (err) {
            const error = err as ErrorMessage;
            return rejectWithValue(error.message);
        }
    }
);

export const logout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
    try {
        return await signOut();
    } catch (err) {
        const error = err as ErrorMessage;
        return rejectWithValue(error.message);
    }
});

// create thunk for forgot password
export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (username: string, { rejectWithValue }) => {
        try {
            await resetPassword({ username });
            return username;
        } catch (err) {
            const error = err as ErrorMessage;
            return rejectWithValue(error.message);
        }
    }
);

// create thunk for change password
export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async (
        { username, newPassword, confirmationCode }: ChangePasswordModel,
        { rejectWithValue }
    ) => {
        try {
            const userEmail: string =
                username !== '' ? username : JSON.parse(localStorage.email || '');

            return await confirmResetPassword({
                username: userEmail,
                confirmationCode,
                newPassword,
            });
        } catch (err) {
            const error = err as ErrorMessage;
            return rejectWithValue(error.message);
        }
    }
);

// create thunk for getting current session
export const loadUser = createAsyncThunk('auth/loadUser', async (_, { rejectWithValue }) => {
    try {
        const { username } = await getCurrentUser();
        const { tokens } = await fetchAuthSession({ forceRefresh: true });
        return {
            accessToken: tokens?.accessToken.toString() ?? '',
            userId: username,
        };
    } catch (err) {
        const error = err as ErrorMessage;
        return rejectWithValue(error.message);
    }
});
// create thunk for changing the password by old password
export const changeOldPassword = createAsyncThunk(
    'auth/changeOldPassword',
    async (updatePasswordRequest: UpdatePasswordModel, { rejectWithValue }) => {
        try {
            return await updatePassword(updatePasswordRequest);
        } catch (err) {
            const error = err as ErrorMessage;
            return rejectWithValue(error.message);
        }
    }
);
// create thunk for updating current mandator
export const updateCurrentMandator = createAsyncThunk(
    'auth/updateCurrentMandator',
    async (current_mandator: string, { dispatch, rejectWithValue }) => {
        try {
            const userAttributes: UpdateUserAttributesInput = {
                userAttributes: { 'custom:current_mandator': current_mandator },
            };
            const updateResponse = await updateUserAttributes(userAttributes);
            if (updateResponse) await dispatch(loadUser());
        } catch (err) {
            const error = err as ErrorMessage;
            return rejectWithValue(error.message);
        }
    }
);

// create thunk for updating eula agreement
export const updateEulaAgreement = createAsyncThunk(
    'auth/updateEulaAgreement',
    async (userId: string, { dispatch, rejectWithValue }) => {
        try {
            const response = await Interceptor().patch(`${URLS.Users}/${userId}/eula`);

            if (response) await dispatch(loadUser());
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// handle the async calls in reducers
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateLoggedUser: (state, action) => {
            state.loggedUser = {
                ...action.payload,
                mandator_role_link_rough: action.payload.mandator_role_link,
            };
        },
        resetError: (state) => {
            state.error = initialState.error;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            const { nextStep } = action.payload;
            if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                state.loading = false;
                state.isAuthenticated = false;
                state.newPasswordRequired = true;
                state.error = '';
            } else {
                state.loading = false;
                state.isAuthenticated = true;
                state.newPasswordRequired = false;
                state.error = '';
            }
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.newPasswordRequired = false;
            state.error = action.payload as string;
        });
        builder.addCase(completeNewPassword.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(completeNewPassword.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.newPasswordRequired = false;
            state.isAuthenticated = true;
            state.error = '';
        });
        builder.addCase(completeNewPassword.rejected, (state, action) => {
            state.error = action.payload as string;
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.isAuthenticated = false;
            state.loggedUser = null;
            clearLSOnLogout();
        });
        builder.addCase(logout.rejected, (state, action) => {
            state.isAuthenticated = true;
            state.error = action.payload as string;
        });
        builder.addCase(forgotPassword.fulfilled, (state, action) => {
            const payload = action.payload;
            localStorage.setItem('email', payload);
        });
        builder.addCase(forgotPassword.rejected, (state, action) => {
            state.error = action.payload as string;
        });
        builder.addCase(changePassword.rejected, (state, action) => {
            state.error = action.payload as string;
        });
        builder.addCase(loadUser.fulfilled, (state, action) => {
            const payload = action.payload;
            if (payload !== undefined) {
                localStorage.setItem('userId', payload.userId);
                state.accessToken = payload.accessToken;
                state.isAuthenticated = true;
            }
            state.loading = false;
        });
        builder.addCase(loadUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadUser.rejected, (state) => {
            state.loading = false;
            state.isAuthenticated = false;
        });
        builder.addCase(changeOldPassword.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(changeOldPassword.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
        builder.addCase(updateEulaAgreement.fulfilled, (state, action) => {
            state.loading = false;
        });
    },
});

export const { updateLoggedUser, resetError } = authSlice.actions;
export default authSlice.reducer;
