import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellProps } from '@progress/kendo-react-grid';
import { FormikErrors, FormikValues } from 'formik';
import { SelectComponent } from '../..';
interface DropDownCellProps extends GridCellProps {
    data: Record<string, string>[];
    errors?: FormikErrors<FormikValues>[];
    required?: boolean;
    nameProp?: string;
    isValueTranslatable?: boolean;
}

/**
 * Renders a custom dropdown cell for a Kendo React Grid.
 *
 * @param {DropDownCellProps} props - The props for the DropDownCell component.
 * @param {object} props.dataItem - The data item.
 * @param {string} props.field - The name of the field.
 * @param {function} props.onChange - The function to call when the dropdown value changes.
 * @param {Record<string, string>[]} props.data - The data for the dropdown.
 * @param {FormikErrors<FormikValues>[]} [props.errors] - The errors for the form.
 * @param {number} props.dataIndex - The index of the data item.
 * @param {boolean} [props.required] - Whether the field is required.
 * @param {string} [props.nameProp] - The name of the property to display in the dropdown.
 * @param {function} [props.render] - The function to render the default rendering.
 * @param {boolean} [props.isValueTranslatable] - Whether the value is translatable.
 * @return The rendered dropdown cell.
 */
const DropDownCell = (props: DropDownCellProps) => {
    const {
        dataItem,
        field,
        onChange,
        data,
        errors,
        dataIndex,
        required,
        nameProp,
        render,
        isValueTranslatable,
    } = props;
    const { t } = useTranslation();
    const handleChange = (e: any) => {
        if (onChange) {
            onChange({
                dataIndex: dataIndex,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };
    //There are 2 different name and values. In that we are showing name in UI and Dropdown and sending value to backend
    //So we need to add nameProp to display valueEntityName dynamically
    const valueEntryName = nameProp ? 'name' : 'value';
    const fieldName = field || '';
    const dataValue = dataItem[fieldName] === null ? '' : dataItem[fieldName];

    const getTranslatedDataValue = useCallback(() => {
        let translatedDataValue = dataValue;
        if (isValueTranslatable) {
            const dataValueName = data.filter((item) => item.value === dataValue)[0].name;
            translatedDataValue = t(dataValueName);
        }
        return translatedDataValue;
    }, [data, dataValue, t, isValueTranslatable]);

    const customErrors =
        required && !dataItem[fieldName] ? { [fieldName]: 'required' } : errors?.[dataIndex];
    const defaultRendering = (
        <td className="custom-column">
            {dataItem.inEdit ? (
                <SelectComponent
                    data={data}
                    name={field}
                    defaultValue={dataValue}
                    valueEntry={valueEntryName}
                    titleEntry="name"
                    className="editableGridCell"
                    formChild
                    onChange={handleChange}
                    errors={customErrors}
                    hideErrors={true}
                />
            ) : (
                <span>{getTranslatedDataValue()}</span>
            )}
        </td>
    );
    return render ? render(defaultRendering, props) : defaultRendering;
};
export default DropDownCell;
