import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Popover, Typography } from '@mui/material';

import { PAYMENT_METHODS_TYPES } from '../../../constants';

interface PaymentMethodPopoverProps {
    anchorElement: HTMLElement | null;
    closePaymentPopover: () => void;
}
/**
 * A custom popover component that renders details about payment methods.
 * @param {Object} props - The props object containing the following properties:
 *   - anchorElement: The HTML element that the popover is anchored to. Can be null.
 *   - closePaymentPopover: A function to close the payment popover.
 * @return {JSX.Element} The JSX element representing the payment method popover.
 */

const PaymentMethodPopover = ({
    anchorElement,
    closePaymentPopover,
}: PaymentMethodPopoverProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Popover
            open={Boolean(anchorElement)}
            onClose={closePaymentPopover}
            anchorEl={anchorElement}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '200px',
                    overflowY: 'scroll',
                }}>
                <IconButton
                    onClick={closePaymentPopover}
                    sx={{ justifyContent: 'flex-end' }}
                    disableRipple={true}>
                    <Close />
                </IconButton>
                <Box sx={{ padding: 2 }}>
                    {PAYMENT_METHODS_TYPES.map((paymentMethod: { name: string; value: string }) => {
                        return (
                            <Box key={paymentMethod.value}>
                                <Typography variant="h6">
                                    {t(`charts.methodTypes.${paymentMethod.value}`) as string}:
                                </Typography>
                                <Typography variant="body2">
                                    {t(`charts.methodTypes.${paymentMethod.value}_info`) as string}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Popover>
    );
};
export default PaymentMethodPopover;
