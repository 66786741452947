import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Interceptor } from '../../common';
import { HistoryGetReqProps, HistoryStateTypes } from '../interfaces';
import { buildQueryParams } from '../../../helpers';
import { INITIAL_DATA_STATE } from '../../../constants';

const initialState: HistoryStateTypes = {
    histories: INITIAL_DATA_STATE,
};

// create a thunk for getting the resource history
export const getHistories = createAsyncThunk(
    'history/list',
    async (
        { url, resource_name, resource_id, data_state }: HistoryGetReqProps,
        { rejectWithValue }
    ) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(url, {
                params: { resource_name, resource_id, ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//reducers
const historiesSlice = createSlice({
    name: 'histories',
    initialState,
    reducers: {
        resetHistories: (state) => {
            state.histories = initialState.histories;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getHistories.fulfilled, (state, { payload }) => {
            state.histories.data = [...payload.data];
            state.histories.total = payload.meta.total;
        });
    },
});

export const { resetHistories } = historiesSlice.actions;
export default historiesSlice.reducer;
