import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { LIVE_DATA_MODULE, URLS } from '../../../constants';
import Interceptor from '../Interceptor';
import { ModulePermissions } from '../interfaces';

export interface ModuleType {
    name: string;
    hovered?: boolean;
    payed?: boolean;
}
interface UserRightsTypes {
    error: string | null;
    userRights: ModulePermissions[];
    modules: ModuleType[];
    selectedModule: ModuleType | null;
}

const initialState: UserRightsTypes = {
    error: null,
    userRights: [],
    modules: [],
    selectedModule: null,
};

// create a thunk for get user rights
export const getUserRights = createAsyncThunk(
    'userRights/list',
    async (mandator_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Rights}`, {
                params: { mandator_id },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// reducers
const userRightsSlice = createSlice({
    name: 'userRights',
    initialState,
    reducers: {
        updateSelectedModule: (state, { payload }) => {
            state.selectedModule = { ...payload };
        },
        resetUserRights: (state) => {
            state.userRights = initialState.userRights;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserRights.rejected, (state, action) => {
            state.error = action.payload as string;
        });
        builder.addCase(getUserRights.fulfilled, (state, { payload }) => {
            state.userRights = [...payload.data];
            state.modules = state.userRights
                .map((el) => {
                    return { name: el.module || '' };
                })
                .filter((item, index, arr) => arr.findIndex((t) => t.name === item.name) === index);
            state.selectedModule = !localStorage.selectedModule
                ? { name: LIVE_DATA_MODULE }
                : { name: localStorage.selectedModule };
        });
    },
});

// Action creators are generated for each case reducer function
export const { updateSelectedModule, resetUserRights } = userRightsSlice.actions;
export default userRightsSlice.reducer;
