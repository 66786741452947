export const TRACK_NO = [
    { name: 'Track 1', value: '1' },
    { name: 'Track 2', value: '2' },
    { name: 'Track 3', value: '3' },
];

export const AUTHORIZATION_BASED_ON = [
    { name: 'Quantity', value: 'Quantity' },
    { name: 'Amount', value: 'Amount' },
];

export const PIN_ENCRYPTION_METHOD = [{ name: 'ZKA', value: 'ZKA' }];

export const CARD_ISSUER_STATUS = [
    { name: 'Active', value: 'Active' },
    { name: 'In-Active', value: 'In-Active' },
];

export const trackDataValidations = [
    { key: 'name', message: 'Please Enter Name' },
    { key: 'cardType', message: 'Please Select Card Type' },
    { key: 'scheme', message: 'Please Select Scheme' },
    { key: 'tracks[0].trackNo', message: 'Please Select Track No' },
    { key: 'trackTwo', message: 'Please Enter Track Data' },
];

export const TRACK_PROP_FIELDS = ['trackOneFields', 'trackTwoFields', 'trackThreeFields'];

export enum PanMaskValidations {
    PanLengthNotMatched = 'panLengthNotMatched',
    PanMaskingFormatInvalid = 'panMaskingFormatInvalid',
    PanMaskPatternNotPresent = 'panMaskPatternNotPresent',
    Pan = 'PAN',
}
export enum TrackResponseStatus {
    Error = 'error',
    Success = 'success',
}
export enum ProductMappingCategories {
    Refuelling = 'refuelling',
    Wash = 'wash',
}
export enum FLEETNET_SITE_DEVICE {
    SiteComponent = 'SiteComponent',
    DeviceComponent = 'DeviceComponent',
}
