import { createRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    Typography,
} from '@mui/material';
import { Email } from '@mui/icons-material';

// redux
import { useAppDispatch, useAppSelector, useTranslateFormErrors } from '../hooks';
import { forgotPassword, resetError } from './authDuck';

// common components, interfaces, constants and helpers
import { AuthHeader, ErrorComponent } from '.';
import { getStringValidations, sanitizeFields } from '../../helpers';
import './auth.scss';
interface FormValues {
    email: string;
}

/**
 * Renders the ForgotPassword component, which allows users to request a password reset code.
 *
 * @return {JSX.Element} The rendered ForgotPassword component.
 */
const ForgotPassword = (): JSX.Element => {
    const { t } = useTranslation();
    const { error } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const initialValues: FormValues = { email: '' };
    const navigate = useNavigate();
    const formRef = createRef<FormikProps<FormValues>>();
    useTranslateFormErrors(formRef);

    const validationSchema = useCallback(
        () =>
            Yup.object().shape({
                email: getStringValidations(5, 70, t('general.labels.email'), true).email(
                    t('general.validations.email')
                ),
            }),
        [t]
    );

    const handleRequestPassword = async (fields: FormValues) => {
        fields = sanitizeFields(fields);
        const response = await dispatch(forgotPassword(fields.email));
        if (forgotPassword.fulfilled.match(response)) {
            //reset error if exists
            dispatch(resetError());
            navigate('/change-password');
        }
    };

    return (
        <>
            <AuthHeader />
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                className="forgot-password-page">
                <Grid item xs={8} sm={7} md={5} lg={4} xl={3}>
                    <Box className="forgot-password-content" sx={{ p: 5 }}>
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            onSubmit={(fields) => {
                                handleRequestPassword(fields);
                            }}
                            validationSchema={validationSchema}>
                            {({ touched, errors, handleChange, handleBlur }) => (
                                <Form className="forgot-password-form">
                                    <Typography variant="h1" className="white-text">
                                        {t('authentication.forgotPassword.title')}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ my: 1 }}
                                        fontFamily="Open Sans-regular"
                                        className="white-text">
                                        {t('authentication.forgotPassword.subtitle')}
                                    </Typography>

                                    {error && <ErrorComponent error={error} />}

                                    <FormControl variant="standard" sx={{ my: 2 }}>
                                        <InputLabel htmlFor="email" className="form-input">
                                            {t('general.labels.email')}
                                        </InputLabel>
                                        <Input
                                            id="email"
                                            name="email"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-input"
                                            inputProps={{ role: 'email' }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Email className="form-input" />
                                                </InputAdornment>
                                            }
                                        />
                                        <FormLabel className="form-error" role="error-email">
                                            {errors.email && touched.email ? errors.email : ''}
                                        </FormLabel>
                                    </FormControl>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        role="submit-button"
                                        sx={{ mt: 3, mb: 2 }}
                                        className="form-submit-button">
                                        {t('authentication.forgotPassword.codeRequest')}
                                    </Button>
                                    <Button
                                        href="/"
                                        className="mb-3 mx-auto forgot-pass-text white-text">
                                        {t('authentication.backToLogin')}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ForgotPassword;
