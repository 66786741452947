import { Cancel, Check, Close, DeleteForever, Edit } from '@mui/icons-material';
import { Box, Button, Dialog, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';

//plugins
import { t } from 'i18next';

// common components, interfaces, constants and helpers
import { FLEETNET_SITE_DEVICE } from '../constants/fleetNetGeneral';
import './fleetnetActionButtinCell.scss';

export const FleetnetActionButtonCell = (props: any) => {
    const { dataItem } = props;

    const componentName = props?.componentName;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem.id === undefined;
    const [visible, setVisible] = React.useState(false);

    const onDeleteData = () => {
        props.remove(props.dataItem);
        setVisible(!visible);
    };

    const toggleDialog = () => {
        setVisible(!visible);
    };

    //I18n
    const confirmDeleteMsg = useMemo(() => t('services.manageFleetnets.confirmDeleteProvider'), []);
    const yesLabel = useMemo(() => t('general.labels.yes'), []);
    const noLabel = useMemo(() => t('general.labels.no'), []);
    const DeleteLabel = useMemo(() => t('general.labels.delete'), []);
    const CancelLabel = useMemo(() => t('general.labels.cancel'), []);
    const EditLabel = useMemo(() => t('general.labels.edit'), []);
    const SaveLabel = useMemo(() => t('general.labels.save'), []);
    const UpdateLabel = useMemo(() => t('general.labels.update'), []);

    return (
        <td className="k-command-cell fleet-action-button">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                    onClick={() =>
                        inEdit
                            ? isNewItem
                                ? props.add(dataItem)
                                : props.update(dataItem)
                            : props.edit(dataItem)
                    }>
                    {inEdit ? (
                        // Displaying title save if new row is added else update
                        <Tooltip title={isNewItem ? SaveLabel : UpdateLabel}>
                            <Check />
                        </Tooltip>
                    ) : (
                        <Tooltip title={EditLabel}>
                            <Edit />
                        </Tooltip>
                    )}
                </IconButton>

                <IconButton
                    onClick={() =>
                        inEdit
                            ? isNewItem
                                ? props.discard(dataItem)
                                : props.cancel(dataItem)
                            : toggleDialog()
                    }>
                    {inEdit ? (
                        <Tooltip title={CancelLabel}>
                            <Cancel />
                        </Tooltip>
                    ) : (
                        <Tooltip title={DeleteLabel}>
                            <DeleteForever />
                        </Tooltip>
                    )}
                </IconButton>
            </div>
            {visible && (
                <Dialog open={visible} onClose={toggleDialog} maxWidth="xs" fullWidth={true}>
                    <DialogTitle>
                        {`${
                            componentName === FLEETNET_SITE_DEVICE.SiteComponent
                                ? t('fleetnetSite.deleteFleetnetSite')
                                : t('fleetnetDevice.deleteFleetnetDevice')
                        }`}
                        <IconButton
                            aria-label="close"
                            onClick={toggleDialog}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: '#fff',
                                padding: 0,
                            }}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <Typography variant="h3" align="center" sx={{ mt: 2, mx: 2 }}>
                        {`${confirmDeleteMsg} ${dataItem?.hostProvider?.name} ?`}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 4, mb: 4 }}>
                        <Button variant="outlined" onClick={toggleDialog} sx={{ mr: 1, px: 6 }}>
                            {noLabel}
                        </Button>
                        <Button variant="contained" sx={{ px: 6 }} onClick={onDeleteData}>
                            {yesLabel}
                        </Button>
                    </Box>
                </Dialog>
            )}
        </td>
    );
};
