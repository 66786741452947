import { MandatorProduct, ProductPrice, ProductResponseItem } from '../app/pages/interfaces';

export const getProductData = (
    pointProduct: ProductResponseItem,
    tenantProducts: MandatorProduct[]
) => {
    const changedProduct: MandatorProduct = tenantProducts.filter(
        (mandatorProduct) => pointProduct.id === mandatorProduct.product_id
    )[0];

    let code = pointProduct.code,
        name = pointProduct.name;

    if (changedProduct) {
        code = changedProduct.mandator_product_code || changedProduct.product_code;
        name = changedProduct.mandator_product_name || changedProduct.product_name;
    }

    return `${code} - ${name}`;
};

export const getProductName = (product: ProductPrice, mandatorProducts: MandatorProduct[]) => {
    let productName = product.product_name;
    const newProduct: MandatorProduct = mandatorProducts.filter(
        (mandatorProduct) => product.product_id === mandatorProduct.product_id
    )[0];
    if (newProduct) productName = newProduct.mandator_product_name || newProduct.product_name;
    return productName;
};
