import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/grid/interfaces';
import {
    MIN_COLUMN_WIDTH,
    COLUMN_VISIBLE,
    CUSTOM_COLUMN_MIN_WIDTH,
    EXPAND_COLUMN,
} from '../../../constants';

export const getPointsColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>,
    showSiteNameColumn?: boolean
): IGridColumn[] => [
    showSiteNameColumn ? EXPAND_COLUMN : {},
    {
        field: 'point_number',
        title: 'points.number',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        cell: customCells.get('pointNumber'),
        filter: 'text',
    },
    {
        field: 'name',
        title: 'general.labels.name',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        cell: customCells.get('name'),
        filter: 'text',
    },
    {
        field: 'category',
        title: 'general.labels.category',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        sortable: false,
    },
    {
        field: 'type',
        title: 'general.labels.type',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        cell: customCells.get('type'),
        filter: 'text',
    },
    {
        field: 'device_code',
        title: 'points.deviceCode',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        filter: 'text',
    },
    showSiteNameColumn
        ? {
              field: 'site_name',
              title: 'points.siteName',
              minWidth: MIN_COLUMN_WIDTH,
              show: COLUMN_VISIBLE,
              filter: 'text',
          }
        : {},
    {
        field: 'status',
        title: 'general.labels.status',
        minWidth: CUSTOM_COLUMN_MIN_WIDTH,
        show: COLUMN_VISIBLE,
        editable: false,
        filter: 'text',
        cell: customCells.get('pointStatus'),
    },
];
