import { useEffect, useMemo, useState } from 'react';
import {
    ListBox,
    processListBoxDragAndDrop,
    ListBoxDragEvent,
    ListBoxItemClickEvent,
} from '@progress/kendo-react-listbox';
import { Checkbox } from '@progress/kendo-react-inputs';

// common components, interfaces, constants and helpers
import { deepCloneArray } from '../../../helpers';

interface CheckListProps<T> {
    fieldName: string;
    draggable?: boolean;
    data: T[];
    className?: string;
    onChange?: (list: T[]) => void;
    disabled?: boolean;
}
/**
 * A custom component for ListBox Kendo React component
 * It allows selection and reordering of items in a list.
 * @param {CheckListProps<T>} props - The properties for the component:
 * @param {CheckListProps<T>} props.fieldName - The name of the field
 * @param {CheckListProps<T>} props.data - The data array
 * @param {CheckListProps<T>} props.draggable - Boolean to enable dragging
 * @param {CheckListProps<T>} props.className - Additional class name
 * @param {CheckListProps<T>} props.onChange - Function triggered on change
 * @param {CheckListProps<T>} props.disabled - Boolean to disable the component
 * @return {JSX.Element} The ListBox component with custom functionality
 */

const CheckList = <T extends Record<never, never>>({
    fieldName,
    data,
    draggable,
    className,
    onChange,
    disabled,
}: CheckListProps<T>): JSX.Element => {
    const [listItems, setListItems] = useState({
        items: data,
        draggedItem: {},
    });

    useEffect(() => {
        setListItems({ ...listItems, items: data });
    }, [data]);

    const handleDragStart = (e: ListBoxDragEvent) => {
        setListItems({
            ...listItems,
            draggedItem: e.dataItem,
        });
    };

    const handleDrop = (e: ListBoxDragEvent) => {
        const result = processListBoxDragAndDrop(
            listItems.items,
            undefined,
            listItems.draggedItem,
            e.dataItem,
            'id'
        );
        setListItems({
            ...listItems,
            items: result.listBoxOneData,
        });
        !disabled && onChange && onChange(result.listBoxOneData);
    };

    const handleCheckBoxClick = (e: ListBoxItemClickEvent) => {
        const updatedListItems = deepCloneArray(listItems.items);
        const itemIndex = updatedListItems.findIndex(
            (item: any) => item[fieldName] === e.dataItem[fieldName]
        );
        if (!updatedListItems[itemIndex].disabled)
            updatedListItems.splice(itemIndex, 1, {
                ...e.dataItem,
                selected: !e.dataItem.selected,
            });
        setListItems({ ...listItems, items: updatedListItems });
        !disabled && onChange && onChange(updatedListItems);
    };

    const CheckBoxItem = useMemo(
        () => (props: any) => {
            const { dataItem, ...others } = props;
            return (
                <li {...others}>
                    <Checkbox
                        value={dataItem.selected}
                        label={dataItem[fieldName]}
                        disabled={dataItem.disabled}
                    />
                </li>
            );
        },
        []
    );

    return (
        <ListBox
            onItemClick={handleCheckBoxClick}
            textField={fieldName}
            data={listItems.items}
            onDragStart={draggable ? handleDragStart : undefined}
            onDrop={draggable ? handleDrop : undefined}
            item={CheckBoxItem}
            draggable={draggable}
            className={className ?? ''}
        />
    );
};

export default CheckList;
