import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { URLS, INITIAL_DATA_STATE } from '../../../constants';
import { Interceptor } from '../../common';
import {
    Device,
    DeviceStateTypes,
    RestartDevice,
    SiteDevicesReqProps,
    UpdateDeviceModel,
    ExtendedGetReqProps,
    DeviceLogsReqProps,
    DeleteDevicesAndPoints,
} from '../interfaces';
import { buildQueryParams } from '../../../helpers';

const initialState: DeviceStateTypes = {
    devices: INITIAL_DATA_STATE,
    device: null,
    deviceModels: [],
    siteDevices: INITIAL_DATA_STATE,
    deviceLogs: INITIAL_DATA_STATE,
};

// create a thunk for get devices
export const getDevices = createAsyncThunk(
    'devices/list',
    async ({ data_state, mandator_id }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Devices}`, {
                params: { ...queryParams, mandator_id },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for adding a device
export const addDevice = createAsyncThunk(
    'devices/add',
    async (device: Device, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Devices}`, { ...device });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting device details by id
export const getDeviceById = createAsyncThunk(
    'devices/device',
    async (device_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Devices}/${device_id}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting the device models
export const getDeviceModels = createAsyncThunk(
    'device/models',
    async (manufacturerId: string, { rejectWithValue }) => {
        try {
            const res = await Interceptor().get(`${URLS.DeviceModels}`, {
                params: { manufacturer_id: manufacturerId },
            });
            return res.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create a thunk for getting the device logs
export const getDeviceLogs = createAsyncThunk(
    'device/logs',
    async ({ device_code, data_state }: DeviceLogsReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.DeviceLogs}${device_code}`, {
                params: { ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for updating a device
export const updateDevice = createAsyncThunk(
    'devices/update',
    async ({ device, device_id }: UpdateDeviceModel, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.Devices}/${device_id}`, { ...device });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for deleting a device
export const deleteDevice = createAsyncThunk(
    'devices/delete',
    async (deviceId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Devices}/${deviceId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// thunk for deleting multiple devices
export const deleteDevices = createAsyncThunk(
    'devices/deleteDevices',
    async ({ device_ids, delete_points }: DeleteDevicesAndPoints, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Devices}`, {
                data: { device_ids, delete_points },
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for get site devices
export const getSiteDevices = createAsyncThunk(
    'devices/siteDevices',
    async ({ data_state, site_id }: SiteDevicesReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Sites}/${site_id}/devices`, {
                params: { ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for restarting a device
export const restartDevice = createAsyncThunk(
    'devices/restart',
    async ({ device_code, event_type, device_id }: RestartDevice, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Devices}/${device_id}/controls`, {
                device_code,
                event_type,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//reducers
const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.devices.data = [...action.payload];
        },
        resetDevices: (state) => {
            state.devices = initialState.devices;
        },
        resetDevice: (state) => {
            state.device = initialState.device;
        },
        resetDeviceModels: (state) => {
            state.deviceModels = initialState.deviceModels;
        },
        resetSiteDevices: (state) => {
            state.siteDevices = initialState.siteDevices;
        },
        resetDeviceLogs: (state) => {
            state.deviceLogs = initialState.deviceLogs;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDevices.fulfilled, (state, { payload }) => {
            state.devices.data = [...payload.data];
            state.devices.total = payload.meta.total;
        });
        builder.addCase(getDeviceById.fulfilled, (state, { payload }) => {
            state.device = payload.data[0];
        });
        builder.addCase(getDeviceModels.fulfilled, (state, { payload }) => {
            state.deviceModels = [...payload.data];
        });
        builder.addCase(getSiteDevices.fulfilled, (state, { payload }) => {
            state.siteDevices.data = [...payload.data];
            state.siteDevices.total = payload.meta.total;
        });
        builder.addCase(getDeviceLogs.fulfilled, (state, { payload }) => {
            state.deviceLogs.data = [...payload.data];
            state.deviceLogs.total = payload.meta.total;
        });
    },
});
export const {
    updateItems,
    resetDevice,
    resetDeviceModels,
    resetSiteDevices,
    resetDevices,
    resetDeviceLogs,
} = devicesSlice.actions;
export default devicesSlice.reducer;
