import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// common components, interfaces, constants and helpers
import { URLS, INITIAL_DATA_STATE } from '../../../constants';
import { buildQueryParams } from '../../../helpers';
import { Interceptor } from '../../common';
import { PricesStateTypes, UpdateReqProps, ExtendedGetReqProps } from '../interfaces';

const initialState: PricesStateTypes = {
    prices: INITIAL_DATA_STATE,
    sitePrices: null,
};

// create a thunk for getting prices for all sites
export const getPrices = createAsyncThunk(
    'prices/list',
    async ({ mandator_id, data_state }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Prices}`, {
                params: { ...queryParams, mandator_id },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create a thunk for get prices for a site
export const getPricesBySiteId = createAsyncThunk(
    'prices/sitePrices',
    async (site_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Sites}/${site_id}/prices`);
            return response.data.data[0];
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
//create thunk for updating prices for a site
export const updatePrices = createAsyncThunk(
    'prices/update',
    async ({ site_id, prices }: UpdateReqProps, { rejectWithValue }) => {
        try {
            // axios request
            const response = await Interceptor().put(`${URLS.Sites}/${site_id}/prices`, {
                prices,
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
//reducers
const pricesSlice = createSlice({
    name: 'prices',
    initialState,
    reducers: {
        resetSitePrices: (state) => {
            state.sitePrices = initialState.sitePrices;
        },
        resetPrices: (state) => {
            state.prices = initialState.prices;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPrices.fulfilled, (state, { payload }) => {
            state.prices.data = [...payload.data];
            state.prices.total = payload.meta.total;
        });
        builder.addCase(getPricesBySiteId.fulfilled, (state, { payload }) => {
            state.sitePrices = payload;
        });
    },
});

export const { resetSitePrices, resetPrices } = pricesSlice.actions;
export default pricesSlice.reducer;
