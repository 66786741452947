import { DataResult } from '@progress/kendo-data-query';
import { ACTION_BUTTONS_VISIBILITY } from './kendoConstants';

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

// css theme breakpoints
export enum BREAKPOINTS {
    xxs = 0,
    xs = 390,
    sm = 576,
    md = 768,
    lg = 992,
    xl = 1200,
    xxl = 1400,
}

// app sidebar properties
export const SIDEBAR_WIDTH_MAX = 255;
export const SIDEBAR_WIDTH_MIN = 68;

// user gender selection values
export const GENDERS = [
    { gender: 'm', title: 'users.masculine' },
    { gender: 'f', title: 'users.feminine' },
    { gender: 'u', title: 'users.unknown' },
];

// application modal types
export const MODAL_TYPES = {
    ChangeOldPassword: 'CHANGE_OLD_PASSWORD', // modal that displays fields that are required for user password change
    ConfirmationMessage: 'CONFIRMATION_MESSAGE', // modal that always displays a custom message ended with a question and user input is required
    UpdateSelectedItems: 'UPDATE_SELECTED_ITEMS', // modal that displays a custom component inside - for bulk actions, add entity to site
    CardProfilesModalMessage: 'ADD_CARD_PROFILE',
    CopyCardProfile: 'COPY_CARD_PROFILE',
    ValidateTrackInfo: 'VALIDATE_TRACK_INFO',
    AssignTariff: 'ASSIGN_TARIFF', // modal for assigning a tariff to a site
    ExportOptions: 'EXPORT_OPTIONS',
    UnassignTemplates: 'UNASSIGN_TEMPLATES',
    UpdateSitesAssignments: 'UPDATE_SITES_ASSIGNMENTS',
    CheckboxConfirmationModal: 'CHECKBOX_CONFIRMATION_MODAL',
    UplaodImage: 'UPLOAD_IMAGE',
};

// highest user permission lever
export const MAX_PERMISSIONS_LEVEL = 4;

// general inputs limits (strings, numbers)
export const ZERO_LIMIT = 0;
export const POSITIVE_NUMBER_LIMIT = 1;
export const MAXIMUM_VALUE = 20;
export const MINIMUM_CHARACTERS = 3;
export const MAXIMUM_CHARACTERS = 30;

//number input step value
export const DECIMAL_STEP_VALUE = 0.01;

// application action buttons

export enum Actions {
    Add = 'add',
    Edit = 'edit',
    View = 'view',
    Delete = 'delete',
    Copy = 'copy',
    CopyItem = 'copyItem',
    Receipt = 'receipt',
    Print = 'print',
    Download = 'download',
    Link = 'link',
    New = 'new',
    Update = 'update',
}

export type ActionTypes = `${Actions}`;

// map default configuration
export const BONNDORF_COORDINATES = {
    lat: 47.82,
    lng: 8.34,
};

export const COORDINATES_LIMIT = {
    minLat: -90,
    maxLat: 90,
    minLng: -180,
    maxLng: 180,
};

// default data result format of requests
export const INITIAL_DATA_STATE: DataResult = {
    data: [],
    total: 0,
};

// number separators
export enum NumberSeparators {
    Point = '.',
    Comma = ',',
}

// constant used to know the app's payed modules
export const PAYED_MODULES = ['services.payments'];
export const PAYED_SERVICES_KEY = 'module';

//constant used to know the app's payed modules that have also marketplace menu items
export const MARKETPLACE_MENU_ITEMS = ['services.payments'];

// constant for live data module
export const LIVE_DATA_MODULE = 'services.liveData';

export const BUTTONS_VISIBILITY = new Map(ACTION_BUTTONS_VISIBILITY);

export const HTML_REGEX = /<[^>]+>/gi;

// class name for form's grid layout
export const GRID_CLASS_NAME = 'scrollableGrid overflow';

// class name for required field
export const REQUIRED_FIELD_CLASS_NAME = 'required-field';

export const TIME_INTERVAL = 60000;

// bulk actions
export enum BulkActionsOptions {
    Delete = 'delete',
}

export const DEFAULT_COORDINATES = {
    latitude: null,
    longitude: null,
};
