import { sortByField } from '.';
import { Field, Template, TemplateField } from '../app/pages/interfaces';

export const getTemplateData = (templates: Template[], type: string) => {
    const rawTemplateList = templates
        .filter((template) => template.type === type)
        .map((templateItem) => ({
            id: templateItem.id,
            name: templateItem.name,
        }));
    return sortByField(rawTemplateList, 'name');
};

export const getTranslatedTerm = (item: TemplateField | Field, language: string) => {
    const translatedTerm =
        item.custom_translation && JSON.parse(item.custom_translation ?? '')[language];
    return /\S/.test(translatedTerm) ? translatedTerm : undefined;
};
