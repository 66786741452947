import { ReactElement, ReactNode, cloneElement, useRef } from 'react';
import { GridCellProps, GridRowProps } from '@progress/kendo-react-grid';
import { EDIT_FIELD } from '../../../constants';

interface CellRenderProps {
    originalProps: GridCellProps;
    td: React.ReactElement<HTMLTableCellElement> | null;
    enterEdit?: (dataItem: unknown) => void;
}

interface RowRenderProps {
    originalProps?: GridRowProps;
    tr: ReactElement<HTMLTableRowElement>;
    updateRowData?: () => void;
}

export const CellRender = (props: CellRenderProps) => {
    const dataItem = props.originalProps.dataItem;

    const additionalProps = dataItem[EDIT_FIELD]
        ? undefined
        : {
              onClick: (event: MouseEvent) => {
                  const target = event.target as HTMLElement;
                  const isDeleteButton =
                      target.parentElement?.role === 'delete-button' ||
                      target.parentElement?.getAttribute('data-testid') === 'DeleteForeverIcon';

                  !isDeleteButton && props.enterEdit?.(dataItem);
              },
          };

    return (
        props.td &&
        cloneElement(
            props.td,
            { ...props.td.props, ...additionalProps },
            props.td.props.children as ReactNode
        )
    );
};

export const RowRender = (props: RowRenderProps) => {
    const blurTimeout = useRef<number | null>(null);
    const preventExitTimeout = useRef<number | null>(null);
    const preventExit = useRef<boolean | undefined>();

    const rowProps = {
        ...props.tr.props,
        onMouseDown: (event: MouseEvent) => {
            preventExit.current = true;

            window.clearTimeout(preventExitTimeout.current || 0);

            preventExitTimeout.current = window.setTimeout(() => {
                preventExit.current = undefined;
            });
        },
        onBlur: () => {
            window.clearTimeout(blurTimeout.current || 0);
            if (!preventExit.current) {
                blurTimeout.current = window.setTimeout(() => {
                    props.updateRowData?.();
                });
            }
        },
        onFocus: () => {
            window.clearTimeout(blurTimeout.current || 0);
        },
    };
    return cloneElement(props.tr, { ...rowProps }, props.tr.props.children as ReactNode);
};
