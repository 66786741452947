import { GridCellProps } from '@progress/kendo-react-grid';
import ReactCountryFlag from 'react-country-flag';
import { convertCountryNameToCode } from '../../../../helpers/convertors';

/**
 * Renders a custom cell for displaying a country flag in a Kendo React Grid.
 *
 * @param {GridCellProps} props - The props object containing the field and dataItem.
 * @param {string} props.field - The field name of the cell.
 * @param {Record<string, any>} props.dataItem - The data item of the row.
 * @return {JSX.Element} The JSX element representing the custom cell.
 */
const CountryFlagCell = ({ field, dataItem }: GridCellProps): JSX.Element => {
    const countryCode = convertCountryNameToCode(dataItem[field || '']);
    return (
        <td>
            <ReactCountryFlag
                countryCode={countryCode}
                svg
                style={{
                    width: '2em',
                    height: '2em',
                }}
                alt={countryCode}
            />
        </td>
    );
};
export default CountryFlagCell;
