import { t } from 'i18next';
import * as Yup from 'yup';
export interface PasswordValidationError {
    value: string;
    exists: boolean;
}
interface ValidatePasswordModel {
    passwordValidations: PasswordValidationError[];
    password: string;
    validationSchema: () => Yup.SchemaOf<object>;
    setPasswordValidations(res: PasswordValidationError[]): void;
}

export async function validatePassword({
    validationSchema,
    password,
    passwordValidations,
    setPasswordValidations,
}: ValidatePasswordModel) {
    try {
        const schema = validationSchema();
        await schema.validateAt('password', { password }, { abortEarly: false });
        const res = passwordValidations.map((err: PasswordValidationError) => {
            return {
                ...err,
                exists: false,
            };
        });
        setPasswordValidations(res);
    } catch (e: any) {
        if (passwordValidations.length === 0) {
            const res: PasswordValidationError[] = e.errors.map((err: string) => {
                return { value: err, exists: true };
            });
            setPasswordValidations(res);
        } else {
            const res: PasswordValidationError[] = passwordValidations.map(
                (err: PasswordValidationError) => {
                    const indexError = e.errors.findIndex((el: string) => el === err.value);
                    if (indexError === -1) {
                        return { value: err.value, exists: false };
                    }
                    return { value: err.value, exists: true };
                }
            );
            setPasswordValidations(res);
        }
    }
}

export async function validatePasswordOnChangedLanguage(
    validationSchema: () => Yup.SchemaOf<object>
) {
    const schema = validationSchema();
    let emptyList = [];
    try {
        await schema.validateAt('password', { password: '' }, { abortEarly: false });
    } catch (e: any) {
        emptyList = e.errors.map((err: string) => {
            return { value: err, exists: true };
        });
    }
    return emptyList;
}

export function getStringValidations(
    min: number,
    max: number,
    field: string,
    requiredField?: boolean
) {
    let stringValidation = Yup.string().trim();
    if (requiredField)
        stringValidation = stringValidation.required(
            t('general.validations.requiredField', { val: t(field) })
        );
    return stringValidation
        .min(min, t('general.validations.minLength', { entity: t(field), val: min }))
        .max(max, t('general.validations.maxLength', { entity: t(field), val: max }));
}

export function getCognitoPasswordValidations(
    min: number,
    max: number,
    field: string,
    requiredField?: boolean
) {
    return getStringValidations(8, 20, field, requiredField)
        .min(min, t('general.validations.minLength', { entity: t(field), val: min }))
        .max(max, t('general.validations.maxLength', { entity: t(field), val: max }))
        .matches(/(?=.*[A-Z])/, {
            message: t('general.validations.password', {
                format: t('general.labels.uppercase'),
                regex: t('password.letter'),
            }),
        })
        .matches(/(?=.*[a-z])/, {
            message: t('general.validations.password', {
                format: t('general.labels.lowercase'),
                regex: t('password.letter'),
            }),
        })
        .matches(/(?=.*\d)/, {
            message: t('general.validations.password', {
                format: '',
                regex: t('password.number'),
            }),
        })
        .matches(/(?=.*[\^$\-*.[\]{}()?"!@#%&/,><':;|_~`])/, {
            message: t('general.validations.password', {
                format: '',
                regex: t('password.specialCharacter'),
            }),
        });
}

export function getCoordinatesValidations(min: number, max: number, field: string) {
    return Yup.number()
        .nullable()
        .required(
            t('general.validations.requiredField', {
                val: t(field),
            })
        )
        .min(min, t('general.validations.minNumber', { val: min }))
        .max(
            max,
            t('general.validations.maxNumber', {
                entity: t(field),
                val: max,
            })
        );
}
