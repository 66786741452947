import {
    GridColumn as Column,
    Grid,
    GridCellProps,
    GridColumnMenuCheckboxFilter,
    GridColumnMenuProps,
    GridItemChangeEvent,
    GridPageChangeEvent,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

//redux
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getProviderList } from '../hostProvider/hostProviderDuck';
import {
    addFleetnetDeviceConfig,
    deleteFleetnetDeviceConfig,
    getFleetnetDeviceConfig,
    updateFleetnetDeviceConfig,
} from './fleetnetDeviceDuck';

//plugins
import { t } from 'i18next';

// common components, interfaces, constants and helpers

import { AddBox } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { DropDownCell } from '../../../common';

import { CUSTOM_INITIAL_GRID_STATE } from '../../../../constants/kendoConstants';
import { clearLSOnComponentLeave } from '../../../../helpers';
import { CustomDataState, DefaultFilters } from '../../../common/interfaces';
import { FLEETNET_SITE_DEVICE } from '../../constants/fleetNetGeneral';
import {
    FleetnetActionButtonCell,
    FleetnetCheckboxCell,
    FleetnetSearchComponent,
    FleetnetTooltip,
    SwitchButtonCell,
    generateFilterQuery,
} from '../../fleetnetCommon';
import { useFleetnetDeviceService } from './FleetnetDeviceService';

const editField = 'inEdit';
const ExpandFleetnetDevicesComponent = (dataProps: any) => {
    const { insertItem, getItems, updateItem } = useFleetnetDeviceService();
    const [data, setData] = useState<any[]>([]);
    const [providerForDevice, setProviderForDevice] = useState<any>([]);
    const dispatch = useAppDispatch();
    const [page, setPage] = useState<CustomDataState>(CUSTOM_INITIAL_GRID_STATE);
    const [fleetnetDeviceDataState, setFleetnetDeviceDataState] =
        useState<CustomDataState>(CUSTOM_INITIAL_GRID_STATE);
    const { fleetnetDevices } = useAppSelector((state) => state?.fleetnetDevices || {});
    const [searchInput, setSearchInput] = useState('');
    const [previousTerminalIdValue, setPreviousTerminalIdValue] = useState('');
    const [isEnabled, setIsEnabled] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [filterDropDownData, setFilterDropDownData] = useState([]);

    const fetchData = async (filterDeviceDataState: CustomDataState) => {
        const deviceConfigurationList = await getItems(dataProps, filterDeviceDataState);
        setData(deviceConfigurationList);
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const take = event.page.take;

        setPage({
            ...event.page,
            take,
        });
        setFleetnetDeviceDataState({
            ...event.page,
            take,
        });
    };
    useEffect(() => {
        fetchData(fleetnetDeviceDataState);
    }, [fleetnetDeviceDataState]);

    const getHostProviderListForDevice = useCallback(async () => {
        const providerResForDevice = await dispatch(getProviderList());
        setProviderForDevice(providerResForDevice?.payload?.data?.data);

        //Displaying list of provider in filter dropdown list
        const resData = providerResForDevice?.payload?.data?.data?.map(
            (deviceConfig: { hostProviderName: string; name: string }) => {
                return {
                    ...deviceConfig,
                    hostProviderName: deviceConfig?.name,
                };
            }
        );
        setFilterDropDownData(resData);
    }, [dispatch]);

    useEffect(() => {
        getHostProviderListForDevice();
    }, [dispatch]);

    const providerDropDownList = providerForDevice?.map((item: { name: string; id: string }) => ({
        name: item?.name,
        value: item?.name,
        id: item?.id,
    }));

    const ProviderSelectorForDevice = useCallback(
        (props: GridCellProps) => <DropDownCell {...props} data={providerDropDownList} />,
        [providerForDevice]
    );

    const removeFleetnetDevice = async (dataItem: { id: string }) => {
        const res = await dispatch(deleteFleetnetDeviceConfig(dataItem?.id));
        if (deleteFleetnetDeviceConfig.fulfilled.match(res)) {
            const message = t('general.messages.successfulAction', {
                action: t('general.labels.deleted'),
            });
            toast.success(message, { theme: 'colored' });
        }
        clearLSOnComponentLeave();
        fetchData(fleetnetDeviceDataState);
        setPreviousTerminalIdValue('');
    };

    const addFleetnetDevice = async (dataItem: any) => {
        const postData = await insertItem(dataItem, providerDropDownList, dataProps);
        if (!postData?.hostProvider?.id) {
            dataItem.inEdit = true;
            const message = t('hostProvider.pleaseSelectProvider');
            toast.error(message, {
                theme: 'colored',
            });
        } else if (postData?.enableTerminalId === true && postData?.terminalId === '') {
            dataItem.inEdit = true;
            const message = t('fleetnetDevice.enterDeviceId');
            toast.error(message, {
                theme: 'colored',
            });
        } else {
            const response = await dispatch(addFleetnetDeviceConfig(postData));
            if (addFleetnetDeviceConfig.fulfilled.match(response)) {
                clearLSOnComponentLeave();
                getHostProviderListForDevice();
                fetchData(fleetnetDeviceDataState);
                setPreviousTerminalIdValue('');
            } else {
                dataItem.inEdit = true;
            }
        }
    };

    const updateFleetnetDevice = async (dataItem: any) => {
        dataItem.inEdit = false;
        const fleetnetDeviceId = dataItem?.id;
        const patchData = await updateItem(dataItem, providerDropDownList);
        if (patchData?.enableTerminalId === true && patchData?.terminalId === '') {
            dataItem.inEdit = true;
            const message = t('fleetnetDevice.enterDeviceId');
            toast.error(message, {
                theme: 'colored',
            });
        } else {
            const response = await dispatch(
                updateFleetnetDeviceConfig({
                    deviceConfiguration: patchData,
                    deviceConfigurationId: fleetnetDeviceId,
                })
            );
            if (updateFleetnetDeviceConfig.fulfilled.match(response)) {
                const message = t('general.messages.successfulAction', {
                    action: t('general.labels.updated'),
                });
                toast.success(message, { theme: 'colored' });
                clearLSOnComponentLeave();
                getHostProviderListForDevice();
                fetchData(fleetnetDeviceDataState);
                setPreviousTerminalIdValue('');
            }
        }
    };

    // Local state operations
    const discardFleetnetDevice = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
        setPreviousTerminalIdValue('');
    };
    const cancelFleetnetDevice = async (dataItem: { id: string }) => {
        const originalItem = (await getItems()).find((p: { id: string }) => p?.id === dataItem.id);
        const newData = data.map((item) => (item.id === originalItem.id ? originalItem : item));
        setData(newData);
        setPreviousTerminalIdValue('');
    };
    const enterEditFleetnetDevice = (dataItem: { id: string }) => {
        const isAnyInEdit = data?.some((item) => item?.inEdit === true);

        if (isAnyInEdit) {
            const cannotEditDeviceMsg = t('services.manageFleetnets.cannotEditRow');
            toast.error(cannotEditDeviceMsg, {
                theme: 'colored',
            });
            return;
        }

        setData(
            data.map((item) =>
                item.id === dataItem.id
                    ? {
                          ...item,
                          inEdit: true,
                      }
                    : item
            )
        );
    };

    const itemChange = (event: GridItemChangeEvent) => {
        //If enableTerminalid is clicked then only enter this condition
        if (event?.field === 'enableTerminalId') {
            if (isEnabled) {
                setPreviousTerminalIdValue(event.dataItem.terminalId);
                event.dataItem.terminalId = '';
            } else {
                event.dataItem.terminalId = previousTerminalIdValue;
            }
        }
        const newDataForDevice = data.map((item) =>
            item.id === event.dataItem.id
                ? {
                      ...item,
                      [event.field || '']: event.value,
                  }
                : item
        );
        setData(newDataForDevice);
    };

    const addNewFleetnetDeviceConfig = () => {
        const isAnyRowInEditMode = data?.some((item) => item?.inEdit === true);

        if (isAnyRowInEditMode) {
            const msg = t('services.manageFleetnets.cannotAddNewRow');
            toast.error(msg, {
                theme: 'colored',
            });
            return;
        }
        setIsEnabled(false);

        const newDataItemForDevice = {
            inEdit: true,
            status: 'active',
        };
        setData([newDataItemForDevice, ...data]);
    };

    const CustomCheckBoxDeviceCell = (props: JSX.IntrinsicAttributes & GridCellProps) => {
        //Editable true false based on  enableTerminalId and inEdit true
        if (props?.dataItem?.inEdit) {
            if (props?.dataItem?.inEdit === true && props?.dataItem?.enableTerminalId) {
                setIsEnabled(true);
            } else {
                setIsEnabled(false);
            }
        }
        return (
            <>
                <FleetnetCheckboxCell {...props} />
            </>
        );
    };
    const FleetnetDeviceDropDownCell = useCallback(
        (props: any) => <SwitchButtonCell {...props} />,
        []
    );

    const fleetnetDeviceCellProps = {
        edit: enterEditFleetnetDevice,
        remove: removeFleetnetDevice,
        add: addFleetnetDevice,
        discard: discardFleetnetDevice,
        update: updateFleetnetDevice,
        cancel: cancelFleetnetDevice,
        editField: editField,
    };

    const preapredDeviceData = {
        data: data,
        total: fleetnetDevices?.total,
    };

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    }, []);

    //set default filter for filter by device-id and search
    const defaultFilter = (deviceId: string, searchValue: string): DefaultFilters => {
        return {
            filter: {
                logic: 'and',
                filters: [
                    { field: 'filter.deviceId', operator: '$eq:', value: deviceId },
                    { operator: 'contains', value: searchValue },
                ],
            },
        };
    };

    let dataState: CustomDataState = CUSTOM_INITIAL_GRID_STATE;
    const handleKeyPress = useCallback(
        async (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                const dfilters = defaultFilter(dataProps?.dataItem?.id, searchInput);

                dataState.filter = dfilters?.filter;
                const requestObj = {
                    dataState,
                };
                const response = await dispatch(getFleetnetDeviceConfig(requestObj));
                response?.payload?.data?.forEach(
                    (deviceConfig: {
                        hostProviderName: string;
                        hostProvider: { name: string };
                    }) => {
                        deviceConfig.hostProviderName = deviceConfig?.hostProvider?.name;
                    }
                );
                //clearing filter
                dataState.filter = undefined;
                setData(response?.payload?.data);
            }
        },
        [searchInput]
    );

    const clearInput = (event: React.MouseEvent<HTMLDivElement>) => {
        const inputElement = event.target as HTMLInputElement;
        inputElement.value = '';
        if (fleetnetDeviceDataState && setFleetnetDeviceDataState) {
            const { filter, ...newState } = fleetnetDeviceDataState;
            setFleetnetDeviceDataState({
                ...newState,
                skip: 0,
            });
        }
        handleSearch(event as unknown as ChangeEvent<HTMLInputElement>);
    };

    // Device id tooltip
    const deviceIdtooltipCustomCell = useCallback(() => {
        return <FleetnetTooltip tooltipMsg={deviceIdTooltipMsg} tooltipFieldName={deviceIdLabel} />;
    }, []);

    // Device id required tooltip
    const deviceIdRequiredtooltipCustomCell = useCallback(() => {
        return (
            <FleetnetTooltip
                tooltipMsg={deviceIdRequiredTooltipMsg}
                tooltipFieldName={deviceIdRequiredLabel}
            />
        );
    }, []);

    //Column Menu Filter
    const filterChanged = async (eventFilter: any) => {
        if (eventFilter) {
            //This will show slected providers in filter multip select drop down
            const filterDataState: CustomDataState = CUSTOM_INITIAL_GRID_STATE;
            filterDataState.filter = eventFilter;
            setSelectedFilters(filterDataState);

            //Transforming event to apply filter as per comma separated
            dataState = generateFilterQuery(eventFilter);
            const requestObj = {
                dataState,
            };
            requestObj?.dataState?.filter?.filters?.push({
                field: 'filter.deviceId',
                operator: '$eq:',
                value: dataProps?.dataItem?.id,
            });

            const response = await dispatch(getFleetnetDeviceConfig(requestObj));
            response?.payload?.data?.forEach(
                (deviceConfig: { hostProviderName: string; hostProvider: { name: string } }) => {
                    deviceConfig.hostProviderName = deviceConfig?.hostProvider?.name;
                }
            );
            setData(response?.payload?.data);
        }
    };

    const ColumnMenuCheckboxFilter = (props: GridColumnMenuProps) => {
        return (
            <div style={{ height: '300px', overflow: 'auto' }}>
                <GridColumnMenuCheckboxFilter
                    {...props}
                    data={filterDropDownData}
                    expanded={true}
                    onFilterChange={(e) => {
                        filterChanged(e);
                    }}
                />
            </div>
        );
    };

    //I18n
    const searchMsg = useMemo(
        () =>
            t('general.messages.searchAction', {
                entities: t('fleetnetDevice.fleetnetDeviceHeader'),
            }),
        [t]
    );
    const addLabel = useMemo(() => t('general.labels.add'), [t]);
    const deviceIdLabel = useMemo(() => t('fleetnetDevice.deviceID'), [t]);
    const deviceIdTooltipMsg = useMemo(() => t('fleetnetDevice.deviceIDTooltip'), [t]);
    const deviceIdRequiredLabel = useMemo(() => t('fleetnetDevice.enabledDeviceID'), [t]);
    const deviceIdRequiredTooltipMsg = useMemo(
        () => t('fleetnetDevice.deviceIDRequiredTooltip'),
        [t]
    );

    return (
        <>
            <Grid
                style={{
                    height: '420px',
                }}
                {...selectedFilters}
                className="fleet-tr"
                data={preapredDeviceData}
                onItemChange={itemChange}
                editField={editField}
                skip={page.skip}
                take={page.take}
                pageable={{
                    buttonCount: 5,
                    pageSizes: [20, 50, 100, 150],
                }}
                onPageChange={pageChange}>
                <GridToolbar className="fleetToolBar">
                    {!dataProps?.dataItem?.expanded && (
                        <div style={{ flex: 1 }}>
                            <FleetnetSearchComponent
                                searchMsg={searchMsg}
                                handleSearch={handleSearch}
                                handleKeyPress={handleKeyPress}
                                searchInput={searchInput}
                                clearInput={clearInput}
                            />
                        </div>
                    )}

                    <div style={{ marginLeft: '12px' }}>
                        <IconButton onClick={addNewFleetnetDeviceConfig}>
                            <Tooltip title={addLabel}>
                                <AddBox style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </IconButton>
                    </div>
                </GridToolbar>
                <Column
                    field="hostProviderName"
                    title={t('hostProvider.provider')}
                    cell={ProviderSelectorForDevice}
                    columnMenu={ColumnMenuCheckboxFilter}
                />
                <Column
                    field="enableTerminalId"
                    title={t('fleetnetDevice.enabledDeviceID')}
                    cell={CustomCheckBoxDeviceCell}
                    headerCell={deviceIdRequiredtooltipCustomCell}
                />
                <Column
                    field="terminalId"
                    title={t('fleetnetDevice.deviceID')}
                    editable={isEnabled}
                    headerCell={deviceIdtooltipCustomCell}
                />
                <Column
                    field="status"
                    title={t('general.labels.status')}
                    cell={FleetnetDeviceDropDownCell}
                />
                <Column
                    cell={(props) => (
                        <FleetnetActionButtonCell
                            componentName={FLEETNET_SITE_DEVICE.DeviceComponent}
                            {...props}
                            {...fleetnetDeviceCellProps}
                        />
                    )}
                />
            </Grid>
        </>
    );
};

export default ExpandFleetnetDevicesComponent;
