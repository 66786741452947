import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { URLS, INITIAL_DATA_STATE } from '../../../constants';
import { Interceptor } from '../../common';
import { ProtocolStateTypes } from '../interfaces';

const initialState: ProtocolStateTypes = {
    protocols: INITIAL_DATA_STATE,
};

// create a thunk for getting protocol's list
export const getProtocols = createAsyncThunk('protocols/list', async (_, { rejectWithValue }) => {
    try {
        const res = await Interceptor().get(`${URLS.Protocols}`);
        return res.data;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error.response);
    }
});

// reducers
const protocolsSlice = createSlice({
    name: 'protocols',
    initialState,
    reducers: {
        resetProtocols: (state) => {
            state.protocols = initialState.protocols;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProtocols.fulfilled, (state, { payload }) => {
            state.protocols.data = [...payload.data];
            state.protocols.total = payload.meta.total;
        });
    },
});

export const { resetProtocols } = protocolsSlice.actions;
export default protocolsSlice.reducer;
