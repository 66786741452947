import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { SiteAssignmentsStateType, SiteAssignmentsUpdateModel } from '../../interfaces';
import { buildQueryParams } from '../../../../helpers';
import { Interceptor } from '../../../common';
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { CustomDataState } from '../../../common/interfaces';

const initialState: SiteAssignmentsStateType = {
    siteAssignments: INITIAL_DATA_STATE,
    siteAssignedTemplates: [],
};

//create a thunk for getting site assignments
export const getSiteAssignments = createAsyncThunk(
    'receiptSiteAssignments/list',
    async (data_state: CustomDataState | undefined, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.SiteAssignmentsTemplates}`, {
                params: { ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create a thunk for getting assigned templates to a site
export const getAssignmentsForSite = createAsyncThunk(
    'receiptSiteAssignments/templates',
    async (site_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.SiteAssignmentsTemplates}/${site_id}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create a thunk for updating templates for a site
export const updateSiteTemplates = createAsyncThunk(
    'receiptSiteAssignments/templates',
    async ({ site_id, templates }: SiteAssignmentsUpdateModel, { rejectWithValue }) => {
        try {
            const response = await Interceptor().patch(
                `${URLS.SiteAssignmentsTemplates}/${site_id}`,
                templates
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//reducers
const receiptSiteAssignmentsSlice = createSlice({
    name: 'receiptSiteAssignments',
    initialState,
    reducers: {
        resetSiteAssignedTemplates: (state) => {
            state.siteAssignedTemplates = initialState.siteAssignedTemplates;
        },
        resetSiteAssignments: (state) => {
            state.siteAssignments = initialState.siteAssignments;
        },
        updateItems: (state, action) => {
            state.siteAssignments.data = [...action.payload];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSiteAssignments.fulfilled, (state, { payload }) => {
            state.siteAssignments.data = [...payload.data];
            state.siteAssignments.total = payload.meta.total;
        });
        builder.addCase(getAssignmentsForSite.fulfilled, (state, { payload }) => {
            state.siteAssignedTemplates = payload.data;
        });
    },
});

export const { resetSiteAssignedTemplates, resetSiteAssignments, updateItems } =
    receiptSiteAssignmentsSlice.actions;
export default receiptSiteAssignmentsSlice.reducer;
