import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

export const getMandatorsColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'name',
            title: 'general.labels.name',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'street',
            title: 'general.geolocation.street',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'city',
            title: 'general.geolocation.city',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'code',
            title: 'general.geolocation.zipCode',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'country',
            title: 'general.geolocation.country',
            filter: 'text',
            cell: customCells.get('country'),
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'email',
            title: 'general.labels.email',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'sales_partner',
            title: 'mandators.salesPartner',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: !COLUMN_VISIBLE,
        },
        {
            field: 'mandator_state',
            title: 'general.labels.status',
            filter: 'boolean',
            cell: customCells.get('status'),
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
    ];
};
