import { loadMessages, load } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

//import kendo languages
import messages from './kendoLocalizationMessages';
import kendoNumberAndDatesSettings from './kendoNumberAndDatesSettings';

//load messages
const loadKendoMessages = (language: string) => {
    const message = messages.get(language);
    loadMessages(message, language);
};

// load settings regarding dates and numbers
const loadKendoSettings = (language: string) => {
    const languageSettings: unknown[] | undefined = kendoNumberAndDatesSettings.get(language);

    load(likelySubtags, currencyData, weekData, ...(languageSettings as unknown[]));
};

export const loadKendoMessagesAndSettings = (language: string) => {
    loadKendoMessages(language);
    loadKendoSettings(language);
};
