import { Dispatch, SetStateAction } from 'react';
import { getModuleByUrl, getModuleStatus } from '../app/pages/marketplace/marketplaceDuck';
import { Module } from '../app/pages/interfaces';
import { ITEMS } from '../app/pages/marketplace/modulesData';
import { servicesModulesMapping } from '../constants';

export const getServiceStatus = async (id: string, url: string, dispatch: (action: any) => any) => {
    const response = await dispatch(
        getModuleStatus({
            moduleUrl: url,
            mandatorId: id,
        })
    );
    return response.payload;
};

export const setExtendedModuleInfo = async (
    item_url: string,
    mandatorId: string,
    dispatch: (action: any) => any,
    setModule: Dispatch<SetStateAction<Module | undefined>>,
    setModuleStatus: Dispatch<SetStateAction<boolean>>
) => {
    // get module info
    const response = await dispatch(getModuleByUrl(item_url + '/module'));
    const moduleData = (await response).payload;
    setModule(moduleData);
    // get module status
    const status = await getServiceStatus(
        mandatorId,
        `${item_url}/config/mandators/activations/`,
        dispatch
    );
    setModuleStatus(status?.status);
};

export const getAllServicesStatuses = (id: string, dispatch: (action: any) => any) => {
    ITEMS.forEach(async (item) => {
        await dispatch(
            getModuleStatus({
                moduleUrl: item.url + '/config/mandators/activations/',
                mandatorId: id,
            })
        );
    });
};

export const hasMarketplaceDependency = (pageTitle: string) => {
    return Object.keys(servicesModulesMapping).includes(pageTitle);
};
