import { Typography, MenuItem, ListItemIcon } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// redux
import { useAppDispatch } from '../hooks';
import { logout } from './authDuck';

/**
 * Renders a logout button component that dispatches a logout action and navigates to the root route.
 *
 * @return {JSX.Element} The logout button component.
 */
const Logout = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleLogoutClick = async () => {
        await dispatch(logout());
        navigate('/');
    };
    return (
        <MenuItem onClick={handleLogoutClick}>
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
            <Typography variant="h5">{t('authentication.logout')}</Typography>
        </MenuItem>
    );
};
export default Logout;
