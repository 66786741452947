import { ColorsInterface, ThemeInterface, StaticColorsInterface } from './interfaces';

export const staticColors: StaticColorsInterface = {
    white: '#fff',
    darkWhite: '#f6f6f6',
    black: '#000',
    success: '#62B01E',
    lightGray: '#E8E8E8',
    gray: '#CDCDCD',
    darkGray: '#878787',
    error: '#DB062B',
    lightError: '#FEBEC9',
    errorText: '#DB062B',
    warningText: '#EF7D00',
    green: '#8BB31D',
    lightBackground: '#545C63',
    darkBackground: '#474F56',
    darkSelectedBackground: '#343A40',
    blueText: '#0093D3',
};

export const paletteModes = {
    light: 'light',
    dark: 'dark',
};

export const defaultColors: ColorsInterface = {
    primary: '#DB062B',
    backgroundHeader: '#343A40',
    secondary: '#343A40',
    primaryButtonActive: '#8D021A',
    primaryButtonHover: '#A5011D',
    primaryButtonDisabled: '#EBA0AD',
    popoverBackground: '#f6f6f6',
    outlineButtonText: '#878787',
    grayColor: '#CDCDCD',
};

export const themes: ThemeInterface[] = [
    { name: 'default', colours: defaultColors },
    {
        name: 'green',
        colours: {
            primary: '#8BB31D',
            backgroundHeader: '#343A40',
            secondary: '#343A40',
            primaryButtonActive: '#608205',
            primaryButtonHover: '#6F9506',
            primaryButtonDisabled: '#D1E1A5',
            popoverBackground: '#f6f6f6',
            outlineButtonText: '#878787',
            grayColor: '#CDCDCD',
        },
    },
    {
        name: 'orange',
        colours: {
            primary: '#F79646',
            backgroundHeader: '#343A40',
            secondary: '#343A40',
            primaryButtonActive: '#D26810',
            primaryButtonHover: '#DC7725',
            primaryButtonDisabled: '#FCD5B5',
            popoverBackground: '#f6f6f6',
            outlineButtonText: '#878787',
            grayColor: '#CDCDCD',
        },
    },
    {
        name: 'blue',
        colours: {
            primary: '#4F81BD',
            backgroundHeader: '#343A40',
            secondary: '#343A40',
            primaryButtonActive: '#194C89',
            primaryButtonHover: '#275995',
            primaryButtonDisabled: '#B9CDE5',
            popoverBackground: '#f6f6f6',
            outlineButtonText: '#878787',
            grayColor: '#CDCDCD',
        },
    },
    { name: 'custom', colours: defaultColors },
];
