import { createSlice } from '@reduxjs/toolkit';

interface PayedServicesTypes {
    payedModules: Map<string, boolean | undefined>;
    payedComponents: Map<string, boolean | undefined>;
}

const initialState: PayedServicesTypes = {
    payedModules: new Map(),
    payedComponents: new Map(),
};

// reducers
const payedServicesSlice = createSlice({
    name: 'payedServices',
    initialState,
    reducers: {
        updatePayedModules: (state, { payload }) => {
            state.payedModules = new Map(payload.entries());
        },
        updatePayedComponents: (state, { payload }) => {
            state.payedComponents = new Map(payload.entries());
        },
    },
});

// Action creators are generated for each case reducer function
export const { updatePayedModules, updatePayedComponents } = payedServicesSlice.actions;
export default payedServicesSlice.reducer;
