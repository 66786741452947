import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

export { useRights } from './useRights';
export { useWindowSize } from './useWindowSize';
export { useDownloadFile } from './useDownloadFile';
export { usePayedServices } from './usePayedServices';
export { useRemoteControlActions } from './useRemoteControlActions';
export { useTranslateFormErrors } from './useTranslateFormErrors';
export { useExitEdit } from './useExitEdit';
export { useActiveModules } from './useActiveModules';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
