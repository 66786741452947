import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// common components, interfaces, constants and helpers
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { Interceptor } from '../../../common';
import { fleetnetBuildQueryParams } from '../../helpers/fleetnetQueryBuilders';
import { CardProfileReqProps, CardProfileStateTypes } from '../../interfaces/cardProfile';

const initialState: CardProfileStateTypes = {
    cardProfiles: INITIAL_DATA_STATE,
    cardProfileObj: null,
};

/**
 *  create a thunk for getting cardProfile
 */
export const getCardProfileList = createAsyncThunk(
    'cardProfiles/list',
    async ({ dataState }: CardProfileReqProps, { rejectWithValue }) => {
        try {
            const queryParams = dataState ? fleetnetBuildQueryParams(dataState) : null;
            const response = await Interceptor().get(`${URLS.CardProfiles}`, {
                params: { ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const deleteCardProfile = createAsyncThunk(
    'cardProfiles/delete',
    async (cardProfileId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.CardProfiles}/${cardProfileId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

export const addcardProfile = createAsyncThunk(
    'cardProfiles/add',
    async (cardProfile: any, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.CardProfiles}`, { ...cardProfile });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 * create thunk for track validation
 */
export const trackDataValidation = createAsyncThunk(
    'cardProfiles/validate',
    async (validateTrackPostData: any, { rejectWithValue }) => {
        try {
            const res = await Interceptor().post(`${URLS.CardProfilesValidate}`, {
                ...validateTrackPostData,
            });
            return res.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 * create thunk for getting the cardProfile
 */
export const getCardProfileById = createAsyncThunk(
    'cardProfiles/cardProfile',
    async (cardProfileId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.CardProfiles}/${cardProfileId}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

/**
 *  create thunk for updating an cardProfile
 */
export const updateCardProfile = createAsyncThunk(
    'cardProfiles/update',
    async ({ cardProfile, cardProfileId }: any, { rejectWithValue }) => {
        try {
            return await Interceptor().patch(`${URLS.CardProfiles}/${cardProfileId}`, {
                ...cardProfile,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error?.response?.data);
        }
    }
);

/**
 *  create a thunk for getting track data fields
 */
export const getTrackDataFieldList = createAsyncThunk('trackDataFields/list', async () => {
    try {
        const response = await Interceptor().get(`${URLS.TrackDataField}`);
        return response.data;
    } catch (err) {
        const error = err as AxiosError;
        return error?.response;
    }
});

/**
 *  create a thunk for getting card type
 */
export const getCardTypeList = createAsyncThunk('CardTypes/list', async () => {
    try {
        const response = await Interceptor().get(`${URLS.CardType}`);
        return response.data;
    } catch (err) {
        const error = err as AxiosError;
        return error?.response;
    }
});

/**
 *  create a thunk for getting scheme
 */
export const getSchemeList = createAsyncThunk('Schemes/list', async () => {
    try {
        const response = await Interceptor().get(`${URLS.Scheme}`);
        return response.data;
    } catch (err) {
        const error = err as AxiosError;
        return error?.response;
    }
});

const cardProfileSlice = createSlice({
    name: 'cardProfiles',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.cardProfiles.data = [...action.payload];
        },
        resetCardProfile: (state) => {
            state.cardProfileObj = initialState.cardProfileObj;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCardProfileList.fulfilled, (state, { payload }) => {
            state.cardProfiles.data = [...payload.data];
            state.cardProfiles.total = payload.meta.totalItems;
        });

        builder.addCase(getCardProfileById.fulfilled, (state, { payload }) => {
            state.cardProfileObj = payload;
        });
    },
});

// Action creators are generated for each case reducer function
export const { updateItems, resetCardProfile } = cardProfileSlice.actions;
export default cardProfileSlice.reducer;
