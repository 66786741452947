import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

//third party
import { Box, Button, Divider, IconButton, InputAdornment, MenuItem, Popover } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
    MultiViewCalendar,
    MultiViewCalendarChangeEvent,
    SelectionRange,
} from '@progress/kendo-react-dateinputs';

//hooks
import { useAppSelector, useWindowSize } from '../../hooks';
// components, constants
import { FormInput } from '..';
import { convertDateToFormat } from '../../../helpers';
import { DATE_RANGE_INITIAL_FILTER } from '../../../constants';
//styles
import './customDatePicker.scss';

interface CustomDatePickerProps {
    data: Record<string, any>[];
    onSubmit: (param: SelectionRange) => void;
    valueEntry: string;
    titleEntry: string;
    onCancel?: () => void;
    initialValue?: SelectionRange;
    inputClassName?: string;
    currentDateFilters?: SelectionRange;
    maxDateValue?: Date;
    minDateValue?: Date;
}

/**
 * A custom date picker component that allows users to select date ranges.
 * It can display a list of predefined periods and a calendar view.
 *
 * @param {CustomDatePickerProps} props - The props for the component:
 * @param {CustomDatePickerProps} props.data - The data for the date picker.
 * @param {CustomDatePickerProps} props.onSubmit - The function to call when submitting the selected date range.
 * @param {CustomDatePickerProps} props.valueEntry - The key to access the value in the data.
 * @param {CustomDatePickerProps} props.titleEntry - The key to access the title in the data.
 * @param {CustomDatePickerProps} props.numberOfCalendars - The number of calendars to display.
 * @param {CustomDatePickerProps} props.onCancel - The function to call when canceling the selection.
 * @param {CustomDatePickerProps} props.initialValue - The initial value for the date range.
 * @param {CustomDatePickerProps} props.inputClassName - The class name for the input.
 * @param {CustomDatePickerProps} props.currentDateFilters - The current date filters.
 * @param {CustomDatePickerProps} props.maxDateValue - The maximum date value allowed.
 * @param {CustomDatePickerProps} props.minDateValue - The minimum date value allowed.
 * @return {JSX.Element} The custom date picker component.
 */
const CustomDatePicker = ({
    data,
    onSubmit,
    valueEntry,
    titleEntry,
    onCancel,
    initialValue,
    inputClassName,
    currentDateFilters,
    maxDateValue,
    minDateValue,
}: CustomDatePickerProps): JSX.Element => {
    const { t } = useTranslation();
    const [dateInterval, setDateInterval] = useState<SelectionRange>(
        initialValue ?? DATE_RANGE_INITIAL_FILTER
    );
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { formats } = useAppSelector((state) => state.commonData);
    const windowDimensions = useWindowSize();
    const isCompactSize = windowDimensions.width <= 600;

    const handleDatesChange = (event: MultiViewCalendarChangeEvent) => {
        if (event.value && 'start' in event.value) {
            setDateInterval(event.value);
        }
    };

    const resetCalendar = () => {
        setDateInterval(initialValue ?? DATE_RANGE_INITIAL_FILTER);
    };

    const closePopup = () => {
        setAnchorEl(null);
    };

    const isMenuItemSelected = (item: Record<string, SelectionRange>): boolean => {
        if (
            item[valueEntry].start === dateInterval.start &&
            item[valueEntry].end === dateInterval.end
        ) {
            return true;
        }
        return false;
    };

    const handleMenuClick = (item: Record<string, SelectionRange>, index: number) => {
        if (
            item[valueEntry].start === dateInterval.start &&
            item[valueEntry].end === dateInterval.end
        ) {
            resetCalendar();
        } else {
            setDateInterval(data[index][valueEntry]);
        }
    };

    const handleClose = () => {
        closePopup();
        currentDateFilters && setDateInterval(currentDateFilters);
    };

    const handleApplyClick = () => {
        closePopup();
        let newDateInterval = dateInterval;
        if (!dateInterval.end) {
            newDateInterval = { ...dateInterval, end: dateInterval.start };
        }
        setDateInterval(newDateInterval);
        onSubmit(newDateInterval);
    };

    const handleCancelClick = () => {
        closePopup();
        resetCalendar();
        onCancel?.();
    };

    const inputDateValue = useMemo(() => {
        const startDate = dateInterval.start
            ? convertDateToFormat(dateInterval.start, formats.momentDateFormat)
            : formats.dateFormat;

        const endDate = dateInterval.end
            ? convertDateToFormat(dateInterval.end, formats.momentDateFormat)
            : startDate;
        return `${startDate} - ${endDate}`;
    }, [dateInterval, formats]);

    const handleClickCalendar = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget.parentElement?.parentElement || event.currentTarget);
    };

    const endAdornment = () => {
        return (
            <InputAdornment position="end">
                <IconButton aria-label="show calendar" onClick={handleClickCalendar} edge="end">
                    <DateRangeIcon />
                </IconButton>
            </InputAdornment>
        );
    };

    return (
        <>
            <FormInput
                fieldName="dateRange"
                value={inputDateValue}
                type="text"
                className={inputClassName}
                label={t('general.labels.selectTimeFrame')}
                handleChange={() => null}
                readOnly
                endAdornment={endAdornment()}
            />
            <Popover
                className="date-picker"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: `${isCompactSize ? 'column' : 'row'}`,
                    }}>
                    <Box sx={{ minWidth: `${isCompactSize ? '100%' : 'auto'}` }}>
                        {data.map((item, index) => {
                            return (
                                <MenuItem
                                    className="date-picker-menu-item"
                                    key={item[titleEntry]}
                                    value={item[valueEntry]}
                                    selected={isMenuItemSelected(item)}
                                    onClick={() => {
                                        handleMenuClick(item, index);
                                    }}>
                                    {t(item[titleEntry])}
                                </MenuItem>
                            );
                        })}
                    </Box>

                    <Box>
                        <MultiViewCalendar
                            onChange={handleDatesChange}
                            mode={'range'}
                            views={1}
                            value={dateInterval}
                            focusedDate={dateInterval.start ?? undefined}
                            defaultValue={initialValue}
                            max={maxDateValue}
                            min={minDateValue}
                        />
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'center', m: 1, gap: 3 }}>
                    <Button variant="outlined" onClick={handleCancelClick}>
                        {t('general.labels.reset')}
                    </Button>
                    <Button variant="contained" onClick={handleApplyClick}>
                        {t('general.labels.apply')}
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default memo(CustomDatePicker);
