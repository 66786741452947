import { useEffect } from 'react';
import { PAYED_MODULES, PAYED_SERVICES_KEY } from '../../constants';

// redux
import { useAppDispatch, useAppSelector } from '.';
import { updatePayedComponents, updatePayedModules } from '../common/layout/payedServicesDuck';

// determine if mandator has access to component or not
export const usePayedServices = (key?: string) => {
    const dispatch = useAppDispatch();
    const { services } = useAppSelector((state) => state.services);
    const { selectedMandator } = useAppSelector((state) => state.mandators);

    useEffect(() => {
        const result: Map<string, boolean | undefined> = new Map();

        services.forEach((service) => {
            const moduleChildren: Map<string, string[]> = new Map([[service.text, []]]);

            service.items?.forEach((payedService) => {
                payedService.items?.forEach((item) => {
                    if (key === PAYED_SERVICES_KEY) {
                        if (PAYED_MODULES.includes(service.text)) {
                            const oldValue = moduleChildren.get(service.text) ?? [];
                            oldValue.push(item.id as string);
                            const isFounded = oldValue.some((value) =>
                                selectedMandator?.services?.includes(value as never)
                            );
                            result.set(service.text, isFounded);
                        }
                    } else {
                        result.set(
                            item.text,
                            selectedMandator?.services?.includes(item.id as never)
                        );
                    }
                });
            });

            if (key === PAYED_SERVICES_KEY) {
                dispatch(updatePayedModules(result));
            } else {
                dispatch(updatePayedComponents(result));
            }
        });
    }, [selectedMandator, services, dispatch, key]);
};
