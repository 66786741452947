import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, SelectChangeEvent } from '@mui/material';
import i18n from '../../i18n';

import { SelectComponent } from '../common';
import { LANGUAGES } from '../../constants';
import { sortByField } from '../../helpers';
import { updateLanguage } from '../common/commonDataDuck';
import { useAppDispatch } from '../hooks';

/**
 * Renders the authentication header component with language selection functionality.
 *
 * @return {JSX.Element} The authentication header component with language selection.
 */
const AuthHeader = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
        dispatch(updateLanguage(lang));
    }, []);

    const countryCode = LANGUAGES.find((language) => language.code === lang)?.countryCode;
    const handleLangChange = (e: SelectChangeEvent<unknown>) => {
        const value = e.target.value as string;
        i18n.changeLanguage(value);
        setLang(value);
    };

    const sortedLanguages = useMemo(() => sortByField(LANGUAGES, 'name'), []);
    sortedLanguages.map((lng) => (lng.name = t(`general.languages.${lng.code}`)));

    return (
        <Grid container className="public-header">
            <SelectComponent
                data={sortedLanguages}
                onChange={handleLangChange}
                valueEntry="code"
                titleEntry="name"
                defaultValue={lang}
                countryValue={countryCode}
                className="public-header-selection"
            />
        </Grid>
    );
};
export default AuthHeader;
