import { GridCellProps } from '@progress/kendo-react-grid';
import { ComponentType } from 'react';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';
import { IGridColumn } from '../../common/interfaces';

export const getFleetnetSiteColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => [
    {
        field: 'name',
        title: 'general.labels.name',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        cell: customCells.get('name'),
    },
];
