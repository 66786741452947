export function convertToFormattedValue(input: string) {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function capitalizeFirstLetter(input: string) {
    return input.substring(0, 1).toUpperCase() + input.slice(1);
}

export function convertToCamelCase(input: string) {
    const words = input.split(' ');
    const capitalizedWords = words.map((word, index) =>
        index === 0
            ? word.toLowerCase()
            : word.substring(0, 1).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedWords.join('');
}
export function convertIntoTitleCase(input: { name: string }) {
    return input?.name.substring(0, 1).toUpperCase() + input?.name.slice(1);
}

// Helper function to format the reconcileDate
//Reciving data as yymmdd form api and here i'm changing it as per dd/mm/yy
export const formatReconcileDate = (date: string): string => {
    if (date && date.length === 6) {
        const day = date.slice(4, 6);
        const month = date.slice(2, 4);
        const year = date.slice(0, 2);
        return `${day}/${month}/${year}`;
    }
    return date; // Returning the date as is if it's not in the expected format
};

//Remove white space of fileld value in track fields
export const trimFieldValues = (data: any) => {
    return {
        ...data,
        tracks: data?.tracks?.map((track: { fields: { fieldValue: string }[] }) => ({
            ...track,
            fields: track?.fields?.map((field: { fieldValue: string }) => ({
                ...field,
                fieldValue: field?.fieldValue?.replace(/\s+/g, ''),
            })),
        })),
    };
};
