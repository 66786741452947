// general messages that are used to inform the user regarding a successful action
export const SUCCESSFUL_ACTION = 'general.messages.successfully';
export const SUCCESSFUL_SELECTED_ACTION = 'general.messages.successfulActionForSelectedItems';
export enum Operations {
    Updated = 'Updated',
    Created = 'Created',
    Deleted = 'Deleted',
    CopiedToClipboard = 'Copied',
}
// application notification messages types
export enum ToasterType {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
}
