import { useEffect, useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../hooks';
import { logout, updateEulaAgreement, updateLoggedUser } from './authDuck';
import { getUserById } from '../pages/users/usersDuck';
import { getAllRoles } from '../pages/userPermissions/rolesDuck';

import { URLS } from '../../constants';
import AuthHeader from './AuthHeader';

/**
 * Renders the EULA (End User License Agreement) page.
 *
 * @return {JSX.Element} The rendered EULA page.
 */
const Eula = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.users);
    const { accessToken, loggedUser } = useAppSelector((state) => state.auth);
    const [readEula, setReadEula] = useState(false);

    const getUser = useCallback(async () => {
        const response = await dispatch(getUserById(localStorage.userId));
        const userData = response.payload;
        if (userData) {
            dispatch(updateLoggedUser(userData));
        }
    }, [dispatch]);

    useEffect(() => {
        if (accessToken && localStorage.userId && !loggedUser) {
            getUser();
        }
    }, [accessToken, dispatch, getUser, loggedUser]);

    const handleEulaLinkClick = () => {
        setReadEula(true);
    };
    const handleEulaAgreement = useCallback(async () => {
        await dispatch(updateEulaAgreement(localStorage.userId ?? ''));
        getUser();
        dispatch(getAllRoles());
    }, [dispatch, getUser]);

    const handleEulaDecline = useCallback(async () => {
        await dispatch(logout());
        navigate('/');
    }, [dispatch, navigate]);

    return (
        <>
            {user?.accepted_eula === true ? (
                <Navigate to="/dashboard" />
            ) : (
                <>
                    <AuthHeader />
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-evenly"
                        className="login-page">
                        <Grid item xs={8} sm={7} md={5} lg={5} xl={5}>
                            <Box className="eula-content" sx={{ p: 5 }} role="eula-form">
                                <Typography variant="h1" className="white-text" sx={{ mb: 8 }}>
                                    {t('authentication.eula.title')}
                                </Typography>

                                <Typography variant="h2" className="white-text" sx={{ mb: 4 }}>
                                    {t('authentication.eula.body')}
                                </Typography>

                                <Button
                                    onClick={handleEulaLinkClick}
                                    href={`${URLS.EulaFile}/EULA-DE.pdf`}
                                    target="_blank"
                                    className="eula-text white-text">
                                    {t('authentication.eula.title')}
                                </Button>

                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}>
                                    <Button
                                        type="submit"
                                        onClick={handleEulaDecline}
                                        variant="contained"
                                        className="white-text white-button">
                                        {t('general.labels.decline')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={!readEula}
                                        onClick={handleEulaAgreement}
                                        sx={{ mt: 3, mb: 2, width: '10rem' }}
                                        className="form-submit-button">
                                        {t('general.labels.accept')}
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default Eula;
