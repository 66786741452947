import { SetStateAction } from 'react';
import i18n from '../i18n';

import { displayModal } from '../app/common/modal/modalDuck';
import { store } from '../app/store';

import { MODAL_TYPES, Operations, SUCCESSFUL_SELECTED_ACTION, ToasterType } from '../constants';
import { ControlRadioButton } from '../app/common/interfaces';
import { closeModal } from './handleModalVisibility';
import { showToaster } from './formActions';

interface customBulkDelete {
    component: string;
    dispatch: (action: any) => any;
    onDeleteItems: any;
    message?: string;
    customCheckboxLabel?: string;
}

export const updateSelectedEntities = (
    entities: string,
    fieldName: string,
    onSubmit: (fields: any) => Promise<void>,
    labelName = 'general.labels.status',
    customStatusControls?: ControlRadioButton[],
    customInitialValue?: string | boolean
) => {
    const statusControls: ControlRadioButton[] = [
        {
            label: i18n.t('general.labels.activeAll', {
                val: entities,
            }),
            value: true,
        },
        {
            label: i18n.t('general.labels.inactiveAll', {
                val: entities,
            }),
            value: false,
        },
    ];
    const formInitialValues = { [fieldName]: customInitialValue ?? true };
    store.dispatch(
        displayModal({
            showModal: true,
            title: i18n.t('general.labels.updateSelected', {
                entity: entities,
            }),
            type: MODAL_TYPES.UpdateSelectedItems,
            labelName,
            fieldName,
            formInitialValues,
            statusControls: customStatusControls ?? statusControls,
            onSubmit,
        })
    );
};

export const handleCustomBulkDelete = ({
    component,
    dispatch,
    customCheckboxLabel,
    onDeleteItems,
    message = 'general.messages.deleteConfirmation',
}: customBulkDelete) => {
    const modalTitle = i18n.t('general.labels.delete', { entity: component });
    dispatch(
        displayModal({
            showModal: true,
            title: modalTitle,
            type: MODAL_TYPES.CheckboxConfirmationModal,
            message,
            component,
            customCheckboxLabel,
            onLeave: onDeleteItems,
        })
    );
};

/**
 * This method is called after a successful bulk operation, such as deleting multiple items
 * It calls the closeModal() function to close the modal
 * It displays a succcess toaster message
 * It resets the selected entities IDs variable to an empty array
 * It calls the getData() function to fetch and update the data displayed in the app
 */
export const onSuccessfulBulkAction = (
    getData: () => void,
    setSelectedIDs: (value: SetStateAction<string[]>) => void
) => {
    closeModal();
    showToaster(ToasterType.Success, SUCCESSFUL_SELECTED_ACTION, Operations.Deleted);
    setSelectedIDs([]);
    getData();
};
