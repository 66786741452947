import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorTools } from '@progress/kendo-react-editor';
import { Button } from '@progress/kendo-react-buttons';
import { TextSelection } from 'prosemirror-state';

interface LinkButtonProps extends EditorTools.LinkProps {
    linkUrl: string;
}

/**
 * Renders the Link button used on kendo editor
 * @param view the editor's view
 * @param linkUrl the url that will be appendded to the selected text
 * @returns {JSX.Element} The rendered link button component.
 */
const LinkButton = ({ view, linkUrl }: LinkButtonProps) => {
    const { t } = useTranslation();
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        const updateState = () => {
            if (view) {
                const { from, to } = view.state.selection;
                setIsDisabled(from === to);
            }
        };

        updateState();
        view?.dom.addEventListener('mouseup', updateState);
        view?.dom.addEventListener('keyup', updateState);
        view?.dom.addEventListener('focus', updateState);
        view?.dom.addEventListener('mouseleave', updateState);
        return () => {
            view?.dom.removeEventListener('mouseup', updateState);
            view?.dom.removeEventListener('keyup', updateState);
            view?.dom.removeEventListener('focus', updateState);
            view?.dom.removeEventListener('mouseleave', updateState);
        };
    }, [view]);

    const insertLink = () => {
        if (view) {
            const { state, dispatch } = view;
            const { from, to } = state.selection;
            const markType = state.schema.marks.link;

            if (from !== to) {
                // Apply the mark to the selected texts
                let transaction = state.tr.addMark(from, to, markType.create({ href: linkUrl }));
                transaction = transaction.setSelection(
                    TextSelection.near(transaction.doc.resolve(to))
                );
                dispatch(transaction);

                // set focus on editor
                setTimeout(() => {
                    view.focus();
                }, 0);
            }
        }
    };

    return (
        <Button
            title={t('general.labels.insertLink')}
            icon="link-horizontal"
            type="button"
            onClick={insertLink}
            disabled={isDisabled}
        />
    );
};
export default LinkButton;
