//redux
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getFleetnetDeviceConfig } from './fleetnetDeviceDuck';

// common components, interfaces, constants and helpers
import { DefaultFilters } from '../../../common/interfaces';
import { HostProvider } from '../../interfaces/providers';
import { FleetnetDeviceDataItem } from '../../interfaces/fleetnetDevices';

export const useFleetnetDeviceService = () => {
    const dispatch = useAppDispatch();
    const { selectedMandator } = useAppSelector((state) => state.mandators);

    const findProvider = (providerName: string, providerDropDownList: HostProvider[]) => {
        return providerDropDownList?.find(
            (hostProvider: { name: string }) => hostProvider?.name === providerName
        );
    };

    const insertItem = async (
        item: {
            inEdit: boolean;
            hostProviderName: string;
            enableTerminalId: boolean;
            status: string | boolean;
            terminalId: string;
        },
        providerListArray: HostProvider[],
        dataProps: {
            dataItem: FleetnetDeviceDataItem;
        }
    ) => {
        item.inEdit = false;
        const fetchedProvider = await findProvider(item?.hostProviderName, providerListArray);
        return {
            deviceId: dataProps?.dataItem?.id,
            name: dataProps?.dataItem?.name,
            code: dataProps?.dataItem?.code,
            enableTerminalId: item?.enableTerminalId,
            deviceModelName: dataProps?.dataItem?.device_model_name,
            status: item?.status === true || item?.status === 'active' ? 'active' : 'inactive',
            mandatorId: selectedMandator?.id,
            terminalId: item?.terminalId ? item?.terminalId : '',
            hostProvider: {
                id: fetchedProvider?.id,
            },
        };
    };

    //set default filter for filter by device-id
    const defaultFilter = (deviceId: string): DefaultFilters => {
        return {
            filter: {
                logic: 'and',
                filters: [{ field: 'filter.deviceId', operator: '$eq:', value: deviceId }],
            },
        };
    };

    const getItems = async (dataProps?: any, dataState?: any) => {
        const dfilters = defaultFilter(dataProps?.dataItem?.id);
        if (dataState) {
            dataState.filter = dfilters?.filter;
        }
        const requestObj = {
            dataState,
        };

        const response = await dispatch(getFleetnetDeviceConfig(requestObj));
        //clearing filter
        if (dataState) {
            dataState.filter = undefined;
        }
        response?.payload?.data?.forEach(
            (deviceConfig: { hostProviderName: string; hostProvider: { name: string } }) => {
                deviceConfig.hostProviderName = deviceConfig?.hostProvider?.name;
            }
        );
        return response?.payload?.data;
    };

    const updateItem = async (
        item: {
            hostProviderName: string;
            terminalId: string;
            enableTerminalId: boolean;
            status: string | boolean;
        },
        providerListArray: HostProvider[]
    ) => {
        const fetchedProvider = await findProvider(item?.hostProviderName, providerListArray);
        return {
            terminalId: item?.terminalId ? item?.terminalId : '',
            enableTerminalId: item?.enableTerminalId,
            status: item?.status === true || item?.status === 'active' ? 'active' : 'inactive',
            hostProvider: {
                id: fetchedProvider?.id,
            },
        };
    };

    return { insertItem, getItems, updateItem };
};
