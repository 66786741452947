import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, SxProps, Typography } from '@mui/material';
import { DeleteForever, Settings } from '@mui/icons-material';

import { TooltipButton, SearchInput } from '..';
import { CustomDataState, IGridColumn } from '../interfaces';

interface GridActionsAndFiltersProps {
    viewRight?: boolean;
    entityName: string;
    handleUpdateEntities?: () => void;
    isDisabledUpdateButton?: boolean;
    handleDeleteEntities?: () => void;
    isDisabledDeleteButton?: boolean;
    containerSx?: SxProps;
    containerItemSx?: SxProps;
    columns?: IGridColumn[];
    componentServerDataState?: CustomDataState;
    setComponentServerDataState?: (value: SetStateAction<CustomDataState>) => void;
    defaultSearchValue?: string;
    isBulkActionsVisible?: boolean;
}

/**
 * Component used to add bulk actions and filters above each grid
 *
 * @param {GridActionsAndFiltersProps} props - The props object containing the following properties:
 *   - containerSx: The styling for the container.
 *   - containerItemSx: The styling for the container item.
 *   - viewRight: A boolean indicating whether view right is enabled.
 *   - entityName: The name of the entity.
 *   - handleUpdateEntities: A function to handle updating entities.
 *   - isDisabledUpdateButton: A boolean indicating whether the update button is disabled.
 *   - handleDeleteEntities: A function to handle deleting entities.
 *   - isDisabledDeleteButton: A boolean indicating whether the delete button is disabled.
 *   - columns: An array of columns.
 *   - componentServerDataState: The server data state of the component.
 *   - setComponentServerDataState: A function to set the server data state.
 *   - defaultSearchValue: The default search value.
 *   - isBulkActionsVisible: A boolean indicating whether the bulk actions section is visible or not.
 * @return {JSX.Element} The rendered component.
 */
const GridActionsAndFilters = ({
    containerSx,
    containerItemSx,
    viewRight,
    entityName,
    handleUpdateEntities,
    isDisabledUpdateButton,
    handleDeleteEntities,
    isDisabledDeleteButton,
    columns,
    componentServerDataState,
    setComponentServerDataState,
    defaultSearchValue,
    isBulkActionsVisible,
}: GridActionsAndFiltersProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Grid container mb={2} justifyContent="space-between" sx={containerSx}>
            {viewRight && (
                <Grid item display="flex" alignItems="center" gap={1} mb={{ xs: 1, sm: 0 }}>
                    {isBulkActionsVisible && (
                        <>
                            <Typography variant="h3" color="secondary">
                                {t('general.labels.bulkActions')}
                            </Typography>
                            {handleUpdateEntities && (
                                <TooltipButton
                                    title="general.labels.updateSelected"
                                    entity={entityName}
                                    buttonVariant="contained"
                                    role="update-button"
                                    icon={<Settings />}
                                    onClick={handleUpdateEntities}
                                    disabled={isDisabledUpdateButton}
                                />
                            )}
                            {handleDeleteEntities && (
                                <TooltipButton
                                    buttonVariant="contained"
                                    title="general.labels.deleteSelected"
                                    entity={entityName}
                                    role="delete-selected-button"
                                    icon={<DeleteForever />}
                                    onClick={handleDeleteEntities}
                                    disabled={isDisabledDeleteButton}
                                />
                            )}
                        </>
                    )}
                </Grid>
            )}
            {/* if componentServerDataState exists it means that the search input exists as well */}
            {componentServerDataState !== undefined && (
                <Grid item display="flex" ml="auto" sx={containerItemSx}>
                    <SearchInput
                        columns={columns}
                        componentServerDataState={componentServerDataState}
                        setComponentServerDataState={setComponentServerDataState}
                        entityName={entityName}
                        defaultValue={defaultSearchValue}
                    />
                </Grid>
            )}
        </Grid>
    );
};
export default GridActionsAndFilters;
