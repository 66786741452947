import { useTranslation } from 'react-i18next';
import { FilterList } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

interface FilterButtonProps {
    onClick: () => void;
}

/**
 * Component used to render filter button that opens filters drawer
 *
 * @param {FilterButtonProps} props.onClick - Function to handle click event
 * @return {JSX.Element} The JSX element representing the filter button
 */
const FilterButton = ({ onClick }: FilterButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="h3">{t('general.labels.filter')}</Typography>
            <IconButton className="filter-button" onClick={onClick}>
                <FilterList />
            </IconButton>
        </Box>
    );
};

export default FilterButton;
