import enNumbers from 'cldr-numbers-full/main/en/numbers.json';
import enLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import enCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import enDateFields from 'cldr-dates-full/main/en/dateFields.json';

import deNumbers from 'cldr-numbers-full/main/de/numbers.json';
import deLocalCurrency from 'cldr-numbers-full/main/de/currencies.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from 'cldr-dates-full/main/de/dateFields.json';

import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frLocalCurrency from 'cldr-numbers-full/main/fr/currencies.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';

import itNumbers from 'cldr-numbers-full/main/it/numbers.json';
import itLocalCurrency from 'cldr-numbers-full/main/it/currencies.json';
import itCaGregorian from 'cldr-dates-full/main/it/ca-gregorian.json';
import itDateFields from 'cldr-dates-full/main/it/dateFields.json';

import nlNumbers from 'cldr-numbers-full/main/nl/numbers.json';
import nlLocalCurrency from 'cldr-numbers-full/main/nl/currencies.json';
import nlCaGregorian from 'cldr-dates-full/main/nl/ca-gregorian.json';
import nlDateFields from 'cldr-dates-full/main/nl/dateFields.json';

import hrNumbers from 'cldr-numbers-full/main/hr/numbers.json';
import hrLocalCurrency from 'cldr-numbers-full/main/hr/currencies.json';
import hrCaGregorian from 'cldr-dates-full/main/hr/ca-gregorian.json';
import hrDateFields from 'cldr-dates-full/main/hr/dateFields.json';

import huNumbers from 'cldr-numbers-full/main/hu/numbers.json';
import huLocalCurrency from 'cldr-numbers-full/main/hu/currencies.json';
import huCaGregorian from 'cldr-dates-full/main/hu/ca-gregorian.json';
import huDateFields from 'cldr-dates-full/main/hu/dateFields.json';

import plNumbers from 'cldr-numbers-full/main/pl/numbers.json';
import plLocalCurrency from 'cldr-numbers-full/main/pl/currencies.json';
import plCaGregorian from 'cldr-dates-full/main/pl/ca-gregorian.json';
import plDateFields from 'cldr-dates-full/main/pl/dateFields.json';

import ptNumbers from 'cldr-numbers-full/main/pt/numbers.json';
import ptLocalCurrency from 'cldr-numbers-full/main/pt/currencies.json';
import ptCaGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json';
import ptDateFields from 'cldr-dates-full/main/pt/dateFields.json';

import zhNumbers from 'cldr-numbers-full/main/zh/numbers.json';
import zhLocalCurrency from 'cldr-numbers-full/main/zh/currencies.json';
import zhCaGregorian from 'cldr-dates-full/main/zh/ca-gregorian.json';
import zhDateFields from 'cldr-dates-full/main/zh/dateFields.json';

import esNumbers from 'cldr-numbers-full/main/es/numbers.json';
import esLocalCurrency from 'cldr-numbers-full/main/es/currencies.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';

import roNumbers from 'cldr-numbers-full/main/ro/numbers.json';
import roLocalCurrency from 'cldr-numbers-full/main/ro/currencies.json';
import roCaGregorian from 'cldr-dates-full/main/ro/ca-gregorian.json';
import roDateFields from 'cldr-dates-full/main/ro/dateFields.json';

import hiNumbers from 'cldr-numbers-full/main/hi/numbers.json';
import hiLocalCurrency from 'cldr-numbers-full/main/hi/currencies.json';
import hiCaGregorian from 'cldr-dates-full/main/hi/ca-gregorian.json';
import hiDateFields from 'cldr-dates-full/main/hi/dateFields.json';

import slNumbers from 'cldr-numbers-full/main/sl/numbers.json';
import slLocalCurrency from 'cldr-numbers-full/main/sl/currencies.json';
import slCaGregorian from 'cldr-dates-full/main/sl/ca-gregorian.json';
import slDateFields from 'cldr-dates-full/main/sl/dateFields.json';

import daNumbers from 'cldr-numbers-full/main/da/numbers.json';
import daLocalCurrency from 'cldr-numbers-full/main/da/currencies.json';
import daCaGregorian from 'cldr-dates-full/main/da/ca-gregorian.json';
import daDateFields from 'cldr-dates-full/main/da/dateFields.json';

const kendoNumberAndDatesSettings: Map<string, unknown[]> = new Map([
    ['en', [enNumbers, enLocalCurrency, enCaGregorian, enDateFields]],
    ['fr', [frNumbers, frLocalCurrency, frCaGregorian, frDateFields]],
    ['de', [deNumbers, deLocalCurrency, deCaGregorian, deDateFields]],
    ['it', [itNumbers, itLocalCurrency, itCaGregorian, itDateFields]],
    ['nl', [nlNumbers, nlLocalCurrency, nlCaGregorian, nlDateFields]],
    ['hr', [hrNumbers, hrLocalCurrency, hrCaGregorian, hrDateFields]],
    ['hu', [huNumbers, huLocalCurrency, huCaGregorian, huDateFields]],
    ['pl', [plNumbers, plLocalCurrency, plCaGregorian, plDateFields]],
    ['pt', [ptNumbers, ptLocalCurrency, ptCaGregorian, ptDateFields]],
    ['zh', [zhNumbers, zhLocalCurrency, zhCaGregorian, zhDateFields]],
    ['es', [esNumbers, esLocalCurrency, esCaGregorian, esDateFields]],
    ['ro', [roNumbers, roLocalCurrency, roCaGregorian, roDateFields]],
    ['hi', [hiNumbers, hiLocalCurrency, hiCaGregorian, hiDateFields]],
    ['sl', [slNumbers, slLocalCurrency, slCaGregorian, slDateFields]],
    ['da', [daNumbers, daLocalCurrency, daCaGregorian, daDateFields]],
]);
export default kendoNumberAndDatesSettings;
