import { PropsWithChildren } from 'react';
import { Navigate, matchPath, useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '../hooks';

import { AppPrivateRoutes } from './AppPrivateRoutes';
import { ActionTypes } from '../../constants';

type PathParams = {
    id: string;
    action?: ActionTypes;
};
const PrivateRoute = ({ children }: PropsWithChildren) => {
    const location = useLocation();
    const { isAuthenticated, loading, loggedUser } = useAppSelector((state) => state.auth);
    const { action } = useParams<PathParams>();

    if (loggedUser?.accepted_eula === false) {
        return <Navigate to="/eula" />;
    }
    if (isAuthenticated === false && !loading) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    const root =
        location.pathname.split('/').length > 2
            ? `/${location.pathname.split('/')[1]}`
            : location.pathname;
    const rootAttributes = AppPrivateRoutes.find((route) => route.path === root);
    const childrenWithActions = rootAttributes?.children?.filter(
        (childElement) => childElement.allowedActions
    );

    const child = childrenWithActions?.find((item) =>
        // check pattern against url
        matchPath(`${rootAttributes?.path}/${item.path}`, location.pathname)
    );

    if (action) {
        if (child?.allowedActions && child.allowedActions.indexOf(action) > -1) {
            return <>{children}</>;
        } else {
            return <Navigate to="*" />;
        }
    }

    return <>{children}</>;
};
export default PrivateRoute;
