import { useEffect } from 'react';

// redux
import { useAppDispatch, useAppSelector } from '.';
import { updateActiveModules } from '../pages/marketplace/marketplaceDuck';

// determine if mandator has access to component or not
export const useActiveModules = (key?: string) => {
    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const { modules } = useAppSelector((state) => state.marketplace);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const activeModulesForMandator = modules
            .filter((module) => module.status)
            .map((item) => {
                return item.name;
            });
        dispatch(updateActiveModules(activeModulesForMandator));
    }, [dispatch, modules, selectedMandator]);
};
