import Color from 'color';
import { Mandator } from '../app/pages/interfaces';
import { DEFAULT_THEME } from '../constants';
import { updateTheme } from '../themes/themeDuck';
import { staticColors } from '../themes/themes';

export const updateSelectedTheme = (mandator: Mandator | null, dispatch: (action: any) => any) => {
    const currentTheme = mandator?.theme ? JSON.parse(mandator.theme) : DEFAULT_THEME;
    dispatch(updateTheme(currentTheme));
};

/**
 *
 * @param rgbaColor color rgba
 * @returns hexa code of the provided color
 */
export const rgbaToHexa = (rgbaColor: string) => {
    const color = new Color(rgbaColor);
    return color.hexa();
};

export const getOppositeColor = (hex: string) => {
    if (hex?.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? staticColors.black : staticColors.white;
};

/**
 *
 * @param color color in hexa
 * @param alpha add an opacity to the color
 * @returns hexa color with the provided opacity
 */
export const addAlphaToHex = (hexaColor: string, opacity: number) => {
    const color = new Color(hexaColor);
    const currentOpacity = color.alpha();
    return color.alpha(currentOpacity - opacity).hexa();
};

/**
 * darken the current color
 */
export const darkenColor = (hexaColor: string, magnitude: number) => {
    const color = new Color(hexaColor);
    return color.darken(magnitude).hexa();
};
