import { store } from '../app/store';
import { MODAL_TYPES } from '../constants/general';
import { displayModal } from '../app/common/modal/modalDuck';
import { clearLSOnComponentLeave } from './clearStorage';
import i18n from '../i18n';

export const openLeavePageModal = (navigateCallBack: () => void) => {
    store.dispatch(
        displayModal({
            showModal: true,
            title: i18n.t('general.messages.notSaved'),
            type: MODAL_TYPES.ConfirmationMessage,
            message: 'general.messages.leavePage',
            onLeave: () => handleLeavePage(navigateCallBack),
        })
    );
};
const handleLeavePage = (navigateCallBack: () => void) => {
    closeModal();
    clearLSOnComponentLeave();
    navigateCallBack();
};

export const closeModal = () => {
    store.dispatch(displayModal({ showModal: false }));
};
