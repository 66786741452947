import { GridCellProps } from '@progress/kendo-react-grid';
import { InfoOutlined, WarningAmberOutlined, CancelOutlined } from '@mui/icons-material';
import { staticColors } from '../../../../themes/themes';

const logLevelsMap = new Map([
    ['error', <CancelOutlined sx={{ color: staticColors.errorText }} />],
    ['warning', <WarningAmberOutlined sx={{ color: staticColors.warningText }} />],
    ['info', <InfoOutlined sx={{ color: staticColors.blueText }} />],
]);

/**
 * Renders a custom cell for displaying a log level in a Kendo React Grid.
 *
 * @param {GridCellProps} props - The props object containing the dataItem, field.
 * @param {Record<string, any>} props.dataItem - The data item of the row.
 * @param {string} props.field - The field name to which the cell is bound.
 * @return {JSX.Element} The JSX element representing the custom cell.
 */
const LogLevelCell = ({ field, dataItem }: GridCellProps): JSX.Element => {
    const logLevel = dataItem[field || ''];

    return <td>{logLevelsMap.get(logLevel)}</td>;
};
export default LogLevelCell;
