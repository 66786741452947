import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

//redux
import { useAppDispatch } from '../../../hooks';
import { updateCardIssuer } from '../../pages/cardIssuer/cardIssuerDuck';
import { getHostProvider } from '../../pages/hostProvider/hostProviderDuck';
import { getActiveCardProfile } from './cardProfilesModalDuck';

//plugins
import { Box, Button, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { t } from 'i18next';

// common components, interfaces, constants and helpers
import { CUSTOM_INITIAL_GRID_STATE } from '../../../../constants/kendoConstants';
import { clearLSOnComponentLeave } from '../../../../helpers';
import { CustomDataState } from '../../../common/interfaces';
import { convertIntoTitleCase } from '../../helpers/fleetnetConverters';
import { CardProfileReqProps } from '../../interfaces/cardProfile';

interface MenuItemOptions {
    id?: string;
    name?: string;
}
const CardProfilesModal = ({ onClose }: any) => {
    const dispatch = useAppDispatch();
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedItemId(event.target.value);
    };

    const handleApply = useCallback(async () => {
        if (selectedItemId) {
            const patchData = {
                cardProfiles: [
                    {
                        id: `${selectedItemId}`,
                    },
                ],
            };
            const response = await dispatch(
                updateCardIssuer({
                    cardIssuer: patchData,
                    cardIssuerId: localStorage.getItem('idParent'),
                })
            );
            if (updateCardIssuer.fulfilled.match(response)) {
                const message = t('general.messages.successfulAction', {
                    action: t('general.labels.updated'),
                });
                toast.success(message, { theme: 'colored' });
                clearLSOnComponentLeave();
            }
            getHostProviderData(CUSTOM_INITIAL_GRID_STATE);
            onClose();
        }
    }, [selectedItemId, dispatch, onClose]);

    const getHostProviderData = useCallback(
        (dataState: CustomDataState) => {
            const requestObj = {
                dataState,
            };

            dispatch(getHostProvider(requestObj));
        },
        [dispatch]
    );

    const getCardProfilesData = useCallback(
        async (dataState: CustomDataState) => {
            const requestObj: CardProfileReqProps = {
                dataState,
            };

            const response = await dispatch(getActiveCardProfile(requestObj));
            //Making name in title case so that the first letter should look capital in drop down for good look and feel
            const titledPayload = response?.payload.map((res: { id: number; name: string }) => ({
                ...res,
                name: convertIntoTitleCase(res),
            }));
            setFilteredData(titledPayload);
        },
        [dispatch]
    );

    useEffect(() => {
        getCardProfilesData(CUSTOM_INITIAL_GRID_STATE);
    }, [CUSTOM_INITIAL_GRID_STATE, getCardProfilesData]);

    //I18n
    const cardProfileLabel = useMemo(() => t('cardProfile.entity'), [t]);
    const applyLabel = useMemo(() => t('general.labels.apply'), [t]);
    const cancelLabel = useMemo(() => t('general.labels.cancel'), [t]);

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2, pt: 2 }}
            className="cardProfilesModel">
            <Typography variant="h3" align="center">
                {cardProfileLabel}
            </Typography>
            <Select
                sx={{ width: '300px', height: '35px' }}
                value={selectedItemId}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'Without label' }}
                displayEmpty>
                {filteredData?.map((item: MenuItemOptions) => (
                    <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                    </MenuItem>
                ))}
            </Select>

            <Box sx={{ display: 'flex', gap: 1, mt: 4 }}>
                <Button variant="outlined" sx={{ px: 6 }} onClick={onClose}>
                    {cancelLabel}
                </Button>
                <Button variant="contained" sx={{ px: 6 }} onClick={handleApply}>
                    {applyLabel}
                </Button>
            </Box>
        </Box>
    );
};

export default CardProfilesModal;
