import { Typography } from '@mui/material';

interface NoDataMessage {
    message: JSX.Element[] | string;
}
/**
 * Renders a Typography component with a centered message.
 *
 * @param {Object} props - The props object:
 * @param {JSX.Element[] | string} props.message - The message to be displayed.
 * @return {JSX.Element} The Typography component.
 */
const KendoNoDataMessage = ({ message }: NoDataMessage): JSX.Element => {
    return (
        <Typography
            variant="subtitle1"
            align="center"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
            {message}
        </Typography>
    );
};

export default KendoNoDataMessage;
