import { AddBox } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import {
    GridColumn as Column,
    Grid,
    GridCellProps,
    GridColumnMenuCheckboxFilter,
    GridColumnMenuProps,
    GridItemChangeEvent,
    GridPageChangeEvent,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
//redux
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getProviderList } from '../hostProvider/hostProviderDuck';
import {
    addFleetnetSiteConfig,
    deleteFleetnetSiteConfig,
    getFleetnetSiteConfig,
    updateFleetnetSiteConfig,
} from './fleetnetSiteDuck';

//plugins
import { t } from 'i18next';

// common components, interfaces, constants and helpers
import { CUSTOM_INITIAL_GRID_STATE } from '../../../../constants/kendoConstants';
import { clearLSOnComponentLeave } from '../../../../helpers';
import { DropDownCell } from '../../../common';
import { CustomDataState, DefaultFilters } from '../../../common/interfaces';
import { FLEETNET_SITE_DEVICE } from '../../constants/fleetNetGeneral';
import {
    FleetnetActionButtonCell,
    FleetnetCheckboxCell,
    FleetnetSearchComponent,
    FleetnetTooltip,
    SwitchButtonCell,
    generateFilterQuery,
} from '../../fleetnetCommon';
import { useFleetnetService } from './FleetnetSiteService';
import './fleetnetGridToolBar.scss';

const editField = 'inEdit';
const ExpandFleetnetSitesComponent = (dataProps: any) => {
    const { insertItem, getItems, updateItem } = useFleetnetService();
    const [data, setData] = useState<any[]>([]);
    const [filterDropDownData, setFilterDropDownData] = useState([]);
    const [providerForSite, setProviderForSite] = useState<any>([]);
    const [page, setPage] = useState<CustomDataState>(CUSTOM_INITIAL_GRID_STATE);
    const [fleetnetSiteDataState, setFleetnetSiteDataState] =
        useState<CustomDataState>(CUSTOM_INITIAL_GRID_STATE);
    const dispatch = useAppDispatch();
    const { fleetnetSites } = useAppSelector((state) => state.fleetnetSites || {});
    const [searchInput, setSearchInput] = useState('');
    const [previousStationIdValue, setPreviousStationIdValue] = useState('');
    const [isEnabled, setIsEnabled] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({});

    const fetchData = async (filterSiteDateState: CustomDataState) => {
        const siteConfigurationList = await getItems(dataProps, filterSiteDateState);
        setData(siteConfigurationList);
    };

    const pageChange = (event: GridPageChangeEvent) => {
        const take = event.page.take;

        setPage({
            ...event.page,
            take,
        });
        setFleetnetSiteDataState({
            ...event.page,
            take,
        });
    };

    useEffect(() => {
        fetchData(fleetnetSiteDataState);
    }, [fleetnetSiteDataState]);

    const getHostProviderListForSite = useCallback(async () => {
        const providerResForSite = await dispatch(getProviderList());
        setProviderForSite(providerResForSite?.payload?.data?.data);

        //Displaying list of provider in filter dropdown list
        const resData = providerResForSite?.payload?.data?.data?.map(
            (siteConfig: { hostProviderName: string; name: string }) => {
                return {
                    ...siteConfig,
                    hostProviderName: siteConfig?.name,
                };
            }
        );
        setFilterDropDownData(resData);
    }, [dispatch]);

    useEffect(() => {
        getHostProviderListForSite();
    }, [dispatch]);

    const providerDropDownList = providerForSite?.map((item: { name: string; id: string }) => ({
        name: item?.name,
        value: item?.name,
        id: item?.id,
    }));

    const ProviderSelectorForSite = useCallback(
        (props: GridCellProps) => <DropDownCell {...props} data={providerDropDownList} />,
        [providerForSite]
    );

    const remove = async (dataItem: { id: string }) => {
        const res = await dispatch(deleteFleetnetSiteConfig(dataItem?.id));
        if (deleteFleetnetSiteConfig.fulfilled.match(res)) {
            const message = t('general.messages.successfulAction', {
                action: t('general.labels.deleted'),
            });
            toast.success(message, { theme: 'colored' });
        }
        clearLSOnComponentLeave();
        fetchData(fleetnetSiteDataState);
        setPreviousStationIdValue('');
    };

    const add = async (dataItem: any) => {
        dataItem.inEdit = true;
        const postData = await insertItem(dataItem, providerDropDownList, dataProps);
        if (!postData?.hostProvider?.id) {
            dataItem.inEdit = true;
            const message = t('hostProvider.pleaseSelectProvider');
            toast.error(message, {
                theme: 'colored',
            });
        } else if (postData?.enableStationId === true && postData?.stationId === '') {
            dataItem.inEdit = true;
            const message = t('fleetnetSite.enterSiteId');
            toast.error(message, {
                theme: 'colored',
            });
        } else {
            const response = await dispatch(addFleetnetSiteConfig(postData));
            if (addFleetnetSiteConfig.fulfilled.match(response)) {
                clearLSOnComponentLeave();
                getHostProviderListForSite();
                fetchData(fleetnetSiteDataState);
                setPreviousStationIdValue('');
            } else {
                dataItem.inEdit = true;
            }
        }
    };

    const update = async (dataItem: any) => {
        dataItem.inEdit = false;
        const fleetnetSiteId = dataItem?.id;
        const patchData = await updateItem(dataItem, providerDropDownList, dataProps);
        if (patchData?.enableStationId === true && patchData?.stationId === '') {
            dataItem.inEdit = true;
            const message = t('fleetnetSite.enterSiteId');
            toast.error(message, {
                theme: 'colored',
            });
        } else {
            const response = await dispatch(
                updateFleetnetSiteConfig({
                    siteConfiguration: patchData,
                    siteConfigurationId: fleetnetSiteId,
                })
            );
            if (updateFleetnetSiteConfig.fulfilled.match(response)) {
                const message = t('general.messages.successfulAction', {
                    action: t('general.labels.updated'),
                });
                toast.success(message, { theme: 'colored' });
                clearLSOnComponentLeave();
                getHostProviderListForSite();
                fetchData(fleetnetSiteDataState);
                setPreviousStationIdValue('');
            }
        }
    };

    // Local state operations
    const discard = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
        setPreviousStationIdValue('');
    };
    const cancel = async (dataItem: { id: string }) => {
        const originalItem = (await getItems())?.find(
            (p: { id: string }) => p?.id === dataItem?.id
        );
        const newData = data.map((item) => (item?.id === originalItem?.id ? originalItem : item));
        setData(newData);
        setPreviousStationIdValue('');
    };

    const enterEdit = (dataItem: { id: string }) => {
        const isAnyInEdit = data?.some((item) => item?.inEdit === true);

        if (isAnyInEdit) {
            const cannotEditSiteMsg = t('services.manageFleetnets.cannotEditRow');
            toast.error(cannotEditSiteMsg, {
                theme: 'colored',
            });
            return;
        }

        setData(
            data.map((item) =>
                item.id === dataItem.id
                    ? {
                          ...item,
                          inEdit: true,
                      }
                    : item
            )
        );
    };

    const itemChange = (event: GridItemChangeEvent) => {
        //If enableStationId is clicked then only enter this condition
        if (event?.field === 'enableStationId') {
            if (isEnabled) {
                setPreviousStationIdValue(event.dataItem.stationId);
                event.dataItem.stationId = '';
            } else {
                event.dataItem.stationId = previousStationIdValue;
            }
        }

        const newData = data.map((item) =>
            item.id === event.dataItem.id
                ? {
                      ...item,
                      [event.field || '']: event.value,
                  }
                : item
        );
        setData(newData);
    };

    const addNewFleetnetSiteConfig = () => {
        const isAnyInEdit = data?.some((item) => item?.inEdit === true);

        if (isAnyInEdit) {
            const msg = t('services.manageFleetnets.cannotAddNewRow');
            toast.error(msg, {
                theme: 'colored',
            });
            return;
        }
        setIsEnabled(false);

        const newDataItem = {
            inEdit: true,
            status: 'active',
        };
        setData([newDataItem, ...data]);
    };

    const commandCellProps = {
        edit: enterEdit,
        remove: remove,
        add: add,
        discard: discard,
        update: update,
        cancel: cancel,
        editField: editField,
    };

    const CheckboxCustomCell = (props: JSX.IntrinsicAttributes & GridCellProps) => {
        //Editable true false based on  enableStationId and inEdit true
        if (props?.dataItem?.inEdit) {
            if (props?.dataItem?.inEdit === true && props?.dataItem?.enableStationId) {
                setIsEnabled(true);
            } else {
                setIsEnabled(false);
            }
        }
        return (
            <>
                <FleetnetCheckboxCell {...props} />
            </>
        );
    };

    const FleetnetSiteDropDownCell = useCallback(
        (props: any) => <SwitchButtonCell {...props} />,
        []
    );

    const PreparedSiteData = {
        data: data,
        total: fleetnetSites?.total,
    };

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    }, []);

    //set default filter for filter by site-id and search
    const defaultFilter = (siteId: string, searchValue: string): DefaultFilters => {
        return {
            filter: {
                logic: 'and',
                filters: [
                    { field: 'filter.siteId', operator: '$eq:', value: siteId },
                    { operator: 'contains', value: searchValue },
                ],
            },
        };
    };
    let dataState: CustomDataState = CUSTOM_INITIAL_GRID_STATE;

    const handleKeyPress = useCallback(
        async (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                const dfilters = defaultFilter(dataProps?.dataItem?.id, searchInput);

                dataState.filter = dfilters?.filter;
                const requestObj = {
                    dataState,
                };
                const response = await dispatch(getFleetnetSiteConfig(requestObj));
                response?.payload?.data?.forEach(
                    (siteConfig: { hostProviderName: string; hostProvider: { name: string } }) => {
                        siteConfig.hostProviderName = siteConfig?.hostProvider?.name;
                    }
                );
                //clearing filter
                dataState.filter = undefined;
                setData(response?.payload?.data);
            }
        },
        [searchInput]
    );

    const clearInput = (event: React.MouseEvent<HTMLDivElement>) => {
        const inputElement = event.target as HTMLInputElement;
        inputElement.value = '';
        if (fleetnetSiteDataState && setFleetnetSiteDataState) {
            const { filter, ...newState } = fleetnetSiteDataState;
            setFleetnetSiteDataState({
                ...newState,
                skip: 0,
            });
        }
        handleSearch(event as unknown as ChangeEvent<HTMLInputElement>);
    };

    // Site id tooltip
    const siteIdtooltipCustomCell = useCallback(() => {
        return <FleetnetTooltip tooltipMsg={siteIdTooltipMsg} tooltipFieldName={siteIdLabel} />;
    }, []);

    // Site id required tooltip
    const siteIdRequiredtooltipCustomCell = useCallback(() => {
        return (
            <FleetnetTooltip
                tooltipMsg={siteIdRequiredTooltipMsg}
                tooltipFieldName={siteIdRequiredLabel}
            />
        );
    }, []);

    //Column Menu Filter
    const filterChanged = async (filterEvent: any) => {
        if (filterEvent) {
            //This will show slected providers in filter multip select drop down
            const filterDataState: CustomDataState = CUSTOM_INITIAL_GRID_STATE;
            filterDataState.filter = filterEvent;
            setSelectedFilters(filterDataState);

            //Transforming event to apply filter as per comma separated
            dataState = generateFilterQuery(filterEvent);
            const requestObj = {
                dataState,
            };
            requestObj?.dataState?.filter?.filters?.push({
                field: 'filter.siteId',
                operator: '$eq:',
                value: dataProps?.dataItem?.id,
            });

            const response = await dispatch(getFleetnetSiteConfig(requestObj));
            response?.payload?.data?.forEach(
                (siteConfig: { hostProviderName: string; hostProvider: { name: string } }) => {
                    siteConfig.hostProviderName = siteConfig?.hostProvider?.name;
                }
            );
            setData(response?.payload?.data);
        }
    };

    const ColumnMenuCheckboxFilter = (props: GridColumnMenuProps) => {
        return (
            <div style={{ height: '300px', overflow: 'auto' }}>
                <GridColumnMenuCheckboxFilter
                    {...props}
                    data={filterDropDownData}
                    expanded={true}
                    onFilterChange={(e) => {
                        filterChanged(e);
                    }}
                />
            </div>
        );
    };

    //I18n
    const searchMsg = useMemo(
        () =>
            t('general.messages.searchAction', { entities: t('fleetnetSite.fleetnetSitesHeader') }),
        [t]
    );

    const AddLabel = useMemo(() => t('general.labels.add'), [t]);
    const siteIdLabel = useMemo(() => t('fleetnetSite.siteID'), [t]);
    const siteIdTooltipMsg = useMemo(() => t('fleetnetSite.siteIDTooltip'), [t]);
    const siteIdRequiredLabel = useMemo(() => t('fleetnetSite.enabledSiteID'), [t]);
    const siteIdRequiredTooltipMsg = useMemo(() => t('fleetnetSite.siteIDRequiredTooltip'), [t]);

    return (
        <>
            <Grid
                style={{
                    height: '420px',
                }}
                {...selectedFilters}
                className="fleet-tr"
                data={PreparedSiteData}
                onItemChange={itemChange}
                editField={editField}
                skip={page.skip}
                take={page.take}
                sortable
                pageable={{
                    buttonCount: 5,
                    pageSizes: [20, 50, 100, 150],
                }}
                onPageChange={pageChange}>
                <GridToolbar className="fleetToolBar">
                    {/* Will only display in detail page not while expand */}
                    {!dataProps?.dataItem?.expanded && (
                        <div style={{ flex: 1 }}>
                            <FleetnetSearchComponent
                                searchMsg={searchMsg}
                                handleSearch={handleSearch}
                                handleKeyPress={handleKeyPress}
                                searchInput={searchInput}
                                clearInput={clearInput}
                            />
                        </div>
                    )}

                    <div style={{ marginLeft: '12px' }}>
                        <IconButton onClick={addNewFleetnetSiteConfig}>
                            <Tooltip title={AddLabel}>
                                <AddBox style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </IconButton>
                    </div>
                </GridToolbar>

                <Column
                    field="hostProviderName"
                    title={t('hostProvider.provider')}
                    cell={ProviderSelectorForSite}
                    columnMenu={ColumnMenuCheckboxFilter}
                />
                <Column
                    field="enableStationId"
                    title={t('fleetnetSite.enabledSiteID')}
                    cell={CheckboxCustomCell}
                    headerCell={siteIdRequiredtooltipCustomCell}
                />
                <Column
                    field="stationId"
                    title={t('fleetnetSite.siteID')}
                    editable={isEnabled}
                    headerCell={siteIdtooltipCustomCell}
                />
                <Column
                    field="status"
                    title={t('general.labels.status')}
                    cell={FleetnetSiteDropDownCell}
                />

                <Column
                    cell={(props) => (
                        <FleetnetActionButtonCell
                            componentName={FLEETNET_SITE_DEVICE.SiteComponent}
                            {...props}
                            {...commandCellProps}
                        />
                    )}
                />
            </Grid>
        </>
    );
};

export default ExpandFleetnetSitesComponent;
