import { Close, Search } from '@mui/icons-material';
import { InputAdornment, OutlinedInput, Tooltip } from '@mui/material';
import React, { ChangeEvent } from 'react';

interface SearchProps {
    searchMsg: string;
    handleSearch: (event: ChangeEvent<HTMLInputElement>) => void;
    handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => Promise<void>;
    searchInput: string;
    clearInput: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const FleetnetSearchComponent: React.FC<SearchProps> = ({
    searchMsg,
    handleSearch,
    handleKeyPress,
    searchInput,
    clearInput,
}) => {
    return (
        <Tooltip title={searchMsg}>
            <OutlinedInput
                placeholder="Search"
                value={searchInput}
                onChange={handleSearch}
                onKeyDown={handleKeyPress}
                startAdornment={
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                }
                endAdornment={
                    searchInput && (
                        <InputAdornment
                            position="end"
                            onClick={clearInput}
                            style={{ cursor: 'pointer' }}>
                            <Close />
                        </InputAdornment>
                    )
                }
            />
        </Tooltip>
    );
};

export default FleetnetSearchComponent;
