import { useState, useCallback, useMemo, useEffect, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, Typography } from '@mui/material';
import { Formik, FormikValues, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

// redux
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    getTariffAssignments,
    addTariffAssignment,
    updateTariffAssignment,
} from './siteAssignmentsDuck';

// common components, interfaces, constants and helpers
import { AutoCompleteSelect, CustomDateTimePicker, SelectComponent } from '../../../common';
import { AssignmentPickerData, TariffAssignmentFormValues } from '../../interfaces';
import { ToasterType, SUCCESSFUL_ACTION, Operations } from '../../../../constants';
import {
    formatDateToISOString,
    generateMinEndTime,
    showToaster,
    sortByField,
} from '../../../../helpers';

interface AssignmentPickerProps {
    initialValues: FormikValues;
    handleModalClose(): void;
    assignmentPickerData?: AssignmentPickerData;
}

const AssignmentConfiguration = ({
    initialValues,
    handleModalClose,
    assignmentPickerData,
}: AssignmentPickerProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formRef = createRef<FormikProps<FormikValues>>();
    const { formats } = useAppSelector((state) => state.commonData);
    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const [versionList, setVersionList] = useState<Record<string, string>[]>([]);

    const isCurrentAssignment = useMemo(() => {
        return new Date(initialValues.activationStartTime).getTime() < new Date().getTime();
    }, [initialValues]);

    const handleSubmit = useCallback(
        async (fields: FormikValues) => {
            function getEndTime(date: Date | null, initialDate: Date | null) {
                if (!date && !initialDate) {
                    return undefined;
                } else if (date || initialDate) return formatDateToISOString(date ?? initialDate);
            }

            handleModalClose();
            const newAssignment: TariffAssignmentFormValues = {
                activation_period: {
                    start_time: formatDateToISOString(
                        fields.activationStartTime ?? initialValues.activationStartTime
                    ),
                    end_time: getEndTime(fields.activationEndTime, initialValues.activationEndTime),
                },
                tariff_id: fields.tariffId,
                tariff_version: parseInt(fields.version),
                site_id: assignmentPickerData?.siteId ?? '',
            };

            let res;
            if (!assignmentPickerData?.newAssignment && assignmentPickerData?.assignmentId) {
                res = await dispatch(
                    updateTariffAssignment({
                        assignment: newAssignment,
                        assignment_id: assignmentPickerData.assignmentId,
                    })
                );
            } else {
                res = await dispatch(addTariffAssignment(newAssignment));
            }
            if (addTariffAssignment.fulfilled.match(res)) {
                showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.Created);
            } else if (updateTariffAssignment.fulfilled.match(res)) {
                showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.Updated);
            }

            await dispatch(getTariffAssignments({ mandator_id: selectedMandator?.id ?? '' }));
        },
        [assignmentPickerData, handleModalClose, initialValues]
    );

    const tariffsList = useMemo(() => {
        const tariffsNameIdList: Record<string, string>[] = [];
        if (assignmentPickerData) {
            assignmentPickerData.tariffsMap.forEach((value, key) => {
                tariffsNameIdList.push({ tariffName: value.name, tariffId: key });
            });
        }
        return sortByField(tariffsNameIdList, 'tariffName');
    }, [assignmentPickerData]);

    const updateVersionList = useCallback(
        (tariffId?: string | undefined, resetVersion?: boolean) => {
            const newVersionList: Record<string, string>[] = [];

            if (tariffId) {
                const maxVersion = assignmentPickerData?.tariffsMap.get(tariffId)?.currentVersion;
                if (maxVersion) {
                    for (let i = parseInt(maxVersion); i >= 0; i--) {
                        newVersionList.push({ version: i.toString() });
                    }
                }
            }
            resetVersion && formRef.current?.setFieldValue('version', '');
            setVersionList(newVersionList);
        },
        [dispatch, formRef]
    );

    useEffect(() => {
        function getInitialVersion() {
            if (initialValues.tariffId) {
                updateVersionList(initialValues.tariffId);
            }
        }
        getInitialVersion();
    }, []);

    const getAutoCompleteValue = (id: string) => {
        return (
            tariffsList.find((tariff) => tariff.tariffId === id) ||
            tariffsList.find((tariff) => tariff.tariffId === initialValues.tariffId)
        );
    };

    const validationSchema = useCallback(
        () =>
            Yup.object().shape({
                tariffId: Yup.string().required(
                    t('general.validations.requiredField', {
                        val: t('parking.tariffs.entityName'),
                    })
                ),
                version: Yup.string().required(
                    t('general.validations.requiredField', {
                        val: t('parking.assignments.tariffVersion'),
                    })
                ),
                activationStartTime: Yup.string()
                    .trim()
                    .required(
                        t('general.validations.requiredField', {
                            val: t('parking.tariffs.activationStart'),
                        })
                    ),
            }),
        [t]
    );

    const getMinAssignmentEndTime = (date: string | undefined | null) => {
        if (date) {
            return generateMinEndTime(isCurrentAssignment ? new Date() : new Date(date));
        }
        return undefined;
    };

    const getDefaultVersion = useCallback(
        (formVersion: string) => {
            if (versionList.length > 0) {
                return formVersion ?? initialValues.version?.toString();
            }
            return undefined;
        },
        [initialValues.version, versionList]
    );

    const handleClear = (fieldName: string) => {
        formRef.current?.setFieldTouched(fieldName, true, true);
        formRef.current?.setFieldValue(fieldName, '', true);
    };

    return (
        <Box>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                innerRef={formRef}>
                {({ setFieldValue, handleBlur, handleChange, values, errors, touched }) => (
                    <Form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <AutoCompleteSelect
                            infoLabel={
                                isCurrentAssignment
                                    ? t('parking.messages.currentAssignmentEditInfo')
                                    : undefined
                            }
                            label={t('parking.assignments.tariffSelection')}
                            data={tariffsList}
                            value={getAutoCompleteValue(values?.tariffId)}
                            onChange={(_, value: Record<string, string>) => {
                                updateVersionList(value?.tariffId, true);
                                value?.tariffId && setFieldValue('tariffId', value.tariffId);
                            }}
                            name="tariffId"
                            onBlur={handleBlur}
                            errors={errors}
                            titleEntry="tariffName"
                            className="assignments-selector autocomplete"
                            placeHolder={`${t('parking.tariffs.entityName')} ${t(
                                'general.labels.name'
                            )}`}
                            touched={touched}
                            disabled={isCurrentAssignment}
                        />

                        <SelectComponent
                            formChild
                            label={t('parking.assignments.tariffVersion')}
                            defaultValue={getDefaultVersion(values.version)}
                            errors={errors}
                            data={versionList}
                            touched={touched}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            valueEntry="version"
                            titleEntry="version"
                            name="version"
                            placeholder={`${t('parking.tariffs.entityName')} ${t(
                                'general.labels.version'
                            )}`}
                            className="assignments-selector"
                            disabled={isCurrentAssignment}
                        />

                        <FormControl sx={{ mb: 4, width: '100%' }}>
                            <Typography variant="h3" title="s">
                                {t('parking.tariffs.activeOnSelectedPeriod')}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: 2,
                                }}>
                                <CustomDateTimePicker
                                    name="activationStartTime"
                                    defaultValue={
                                        initialValues.activationStartTime
                                            ? new Date(initialValues.activationStartTime)
                                            : null
                                    }
                                    onClear={() => {
                                        handleClear('activationStartTime');
                                    }}
                                    onChange={(e) => {
                                        handleChange(e);
                                        e.value &&
                                            e.value > new Date(values.activationEndTime) &&
                                            setFieldValue(
                                                `activationEndTime`,
                                                generateMinEndTime(new Date(e.value))
                                            );
                                    }}
                                    onBlur={handleBlur}
                                    value={
                                        values.activationStartTime
                                            ? new Date(values.activationStartTime)
                                            : null
                                    }
                                    min={
                                        !isCurrentAssignment
                                            ? generateMinEndTime(new Date())
                                            : undefined
                                    }
                                    format={formats.dateTimeFormat}
                                    disabled={isCurrentAssignment}
                                    required={true}
                                    touched={touched}
                                    errors={errors}
                                />
                                <CustomDateTimePicker
                                    name="activationEndTime"
                                    defaultValue={
                                        initialValues.activationEndTime
                                            ? new Date(initialValues.activationEndTime)
                                            : null
                                    }
                                    onClear={() => {
                                        handleClear('activationEndTime');
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={
                                        values.activationEndTime
                                            ? new Date(values.activationEndTime)
                                            : null
                                    }
                                    min={getMinAssignmentEndTime(values.activationStartTime)}
                                    format={formats.dateTimeFormat}
                                />
                            </Box>
                        </FormControl>

                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="outlined"
                                onClick={handleModalClose}
                                sx={{
                                    mr: 1,
                                }}>
                                {t('general.labels.cancel')}
                            </Button>
                            <Button type="submit" variant="contained">
                                {t('general.labels.apply')}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default AssignmentConfiguration;
