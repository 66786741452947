import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// common components, interfaces, constants and helpers
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { Interceptor } from '../../../common';
import { fleetnetBuildQueryParams } from '../../helpers/fleetnetQueryBuilders';
import {
    FleetnetSiteStateTypes,
    FleetnetSitesReqProps,
    PostDataFleetnetSite,
    UpdateFleetnetSiteModel,
} from '../../interfaces/fleetnetSites';

const initialState: FleetnetSiteStateTypes = {
    fleetnetSites: INITIAL_DATA_STATE,
    fleetnetSite: null,
};

/**
 *  create a thunk for getting fleetnet site
 */
export const getFleetnetSiteConfig = createAsyncThunk(
    'fleetnetSite/list',
    async ({ dataState }: FleetnetSitesReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = dataState ? fleetnetBuildQueryParams(dataState) : null;
            const response = await Interceptor().get(`${URLS.SiteConfiguration}`, {
                params: { ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 *  create thunk for updating an site configuration
 */
export const updateFleetnetSiteConfig = createAsyncThunk(
    'fleetnetSite/update',
    async (
        { siteConfiguration, siteConfigurationId }: UpdateFleetnetSiteModel,
        { rejectWithValue }
    ) => {
        try {
            return await Interceptor().patch(`${URLS.SiteConfiguration}/${siteConfigurationId}`, {
                ...siteConfiguration,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 *  create a thunk for adding a fleetnet site configuration
 */
export const addFleetnetSiteConfig = createAsyncThunk(
    'fleetnetSite/add',
    async (siteConfiguration: PostDataFleetnetSite, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.SiteConfiguration}`, {
                ...siteConfiguration,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

export const deleteFleetnetSiteConfig = createAsyncThunk(
    'fleetnetSite/delete',
    async (siteConfigurationId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.SiteConfiguration}/${siteConfigurationId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

const fleetnetSiteSlice = createSlice({
    name: 'fleetnetSite',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.fleetnetSites.data = [...action.payload];
        },
        resetFleetnetSite: (state) => {
            state.fleetnetSite = initialState.fleetnetSite;
        },
    },
    extraReducers: (builder) => {
        builder?.addCase(getFleetnetSiteConfig?.fulfilled, (state, { payload }) => {
            state.fleetnetSites.total = payload?.meta?.totalItems;
        });
    },
});

// Action creators are generated for each case reducer function
export const { updateItems, resetFleetnetSite } = fleetnetSiteSlice.actions;
export default fleetnetSiteSlice.reducer;
