import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import {
    DateInput,
    DateTimePicker,
    DateTimePickerChangeEvent,
} from '@progress/kendo-react-dateinputs';

//styles
import './customDateTimePicker.scss';

interface CustomDateTimePickerProps {
    name: string;
    defaultValue: Date | null | undefined;
    onClear: () => void;
    onChange: (event: DateTimePickerChangeEvent) => void;
    onBlur?: (event: any) => void;
    value?: Date | null;
    min?: Date;
    max?: Date;
    format?: string;
    disabled?: boolean;
    required?: boolean;
    touched?: FormikTouched<FormikValues>;
    errors?: FormikErrors<FormikValues>;
}
/**
 * Renders a custom date input component.
 *
 * @param {any} props - The properties passed to the component.
 * @return {JSX.Element} The custom date input component.
 */
const CustomDateInput = (props: any): JSX.Element => {
    const { onClear, onChange, customBlur, value, format, disabled, name } = props;
    return (
        <>
            <DateInput
                value={value}
                format={format}
                name={name}
                onChange={(e) => {
                    onChange(e);
                    customBlur();
                }}
                id={name}
            />
            {!disabled && (
                <span className="k-link k-link-clear">
                    <span
                        unselectable="on"
                        className="k-icon k-i-close"
                        aria-controls="dtp_timeview"
                        style={{ marginTop: '11px' }}
                        onClick={onClear}
                    />
                </span>
            )}
        </>
    );
};

const CustomDateTimePicker = ({
    min,
    max,
    format,
    onChange,
    onBlur,
    defaultValue,
    disabled,
    required,
    name,
    onClear,
    touched,
    errors,
    value,
}: CustomDateTimePickerProps) => {
    const applyError = touched?.[name] && errors?.[name] ? true : false;
    const errorText = applyError ? errors?.[name] : '';

    const customBlur = () => {
        onBlur?.(name);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <DateTimePicker
                id={name}
                name={name}
                defaultValue={defaultValue}
                value={value}
                min={min}
                max={max}
                format={format}
                onChange={(e) => {
                    if (e.syntheticEvent.type === 'input') {
                        return;
                    }
                    onChange(e);
                }}
                onBlur={customBlur}
                disabled={disabled}
                required={applyError && required}
                dateInput={(props) =>
                    CustomDateInput({
                        ...props,
                        onClear,
                        onChange,
                        customBlur,
                        format,
                        disabled,
                        name,
                        value,
                    })
                }
            />
            <Typography
                color="#d63232"
                variant="caption"
                className="errorHeight"
                role={`error-${name}`}>
                <>{errorText}</>
            </Typography>
        </Box>
    );
};

export default memo(CustomDateTimePicker);
