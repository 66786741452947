import { nanoid } from '@reduxjs/toolkit';
import { UploadFileStatus } from '@progress/kendo-react-upload';
import { convertDateToFormat } from './dateHandlers';

export const getFileName = (format: string, ext = 'pdf', mandatorName?: string) => {
    const fileName = mandatorName ? `report, ${mandatorName}.${ext}` : `report.${ext}`;
    const formattedDate = convertDateToFormat(new Date(), format).replace(':', '.');

    return `${formattedDate}, ${fileName}`;
};

export const getDefaultFilesInfo = (value: string, status?: number) => {
    return [
        {
            name: value.split('/').pop() ?? '',
            extension: '.' + value.split('.').pop(),
            uid: nanoid(),
            status: status || UploadFileStatus.Initial,
            progress: 0,
        },
    ];
};
