import { Dispatch, SetStateAction } from 'react';
import i18n from '../i18n';
import { NavigateFunction } from 'react-router-dom';

import { displayModal } from '../app/common/modal/modalDuck';
import { logout } from '../app/auth/authDuck';

import { MODAL_TYPES, Operations, SUCCESSFUL_ACTION, ToasterType } from '../constants';
import { clearLSOnComponentLeave, closeModal, navigateAfterFulfilledAction, showToaster } from '.';
import { SelectedEntities } from '../app/pages/interfaces';

interface DeleteEntity {
    component: string;
    deleteEntity: any;
    dispatch: (action: any) => any;
    navigate?: NavigateFunction;
    getData?: () => void | Promise<void>;
    entity?: string;
    parent?: string;
    loggedUserId?: string;
    selectedMandatorId?: string;
    message?: string;
    isDeleteAllowed?: boolean;
    entityId: string;
}

export const onDeleteEntity = async (
    deleteEntity: any,
    entityId: string | string[],
    dispatch: (action: any) => any,
    navigate?: NavigateFunction,
    getData?: () => void,
    loggedUserId?: string,
    selectedMandatorId?: string,
    resetSelectedIdsVariable?: Dispatch<SetStateAction<SelectedEntities[]>> //sa schimb
) => {
    closeModal();
    const res = await dispatch(deleteEntity(entityId));
    if (deleteEntity.fulfilled.match(res)) {
        if (loggedUserId === entityId || selectedMandatorId === entityId) {
            await dispatch(logout());
            return;
        } else {
            if (getData) {
                showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.Deleted);
                // get data
                getData();
                resetSelectedIdsVariable?.([]);
            } else navigateAfterFulfilledAction(navigate as NavigateFunction, Operations.Deleted);
        }
    }
    clearLSOnComponentLeave();
};

export const handleDeleteEntity = ({
    component,
    deleteEntity,
    navigate,
    dispatch,
    getData,
    entity,
    parent,
    loggedUserId,
    selectedMandatorId,
    entityId,
    message = 'general.messages.deleteConfirmation',
    isDeleteAllowed = true,
}: DeleteEntity) => {
    const modalTitle = i18n.t('general.labels.delete', { entity: entity ?? component });
    dispatch(
        displayModal({
            showModal: true,
            title: modalTitle,
            type: MODAL_TYPES.ConfirmationMessage,
            message,
            component,
            parent,
            onLeave: () => {
                isDeleteAllowed
                    ? onDeleteEntity(
                          deleteEntity,
                          entityId,
                          dispatch,
                          navigate,
                          getData,
                          loggedUserId,
                          selectedMandatorId
                      )
                    : closeModal();
            },
            ...(!isDeleteAllowed
                ? {
                      confirmCustomLabel: i18n.t('general.labels.ok'),
                      isCancelButtonHidden: true,
                  }
                : {}),
        })
    );
};
