import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// common components, interfaces, constants and helpers
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { Interceptor } from '../../../common';
import { fleetnetBuildQueryParams } from '../../helpers/fleetnetQueryBuilders';
import { CardProfileReqProps } from '../../interfaces/cardProfile';

const initialState = {
    cardProfiles: INITIAL_DATA_STATE,
    cardProfileObj: null,
};

export const getCardProfileList = createAsyncThunk(
    'cardProfile/list',
    async ({ dataState }: CardProfileReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = dataState ? fleetnetBuildQueryParams(dataState) : null;
            const response = await Interceptor().get(`${URLS.CardProfiles}`, {
                params: { ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

const cardProfileSlice = createSlice({
    name: 'cardProfiles',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.cardProfiles.data = [...action.payload];
        },
        resetCardProfile: (state) => {
            state.cardProfileObj = initialState.cardProfileObj;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCardProfileList.fulfilled, (state, { payload }) => {
            state.cardProfiles.data = [...payload.data];
            state.cardProfiles.total = payload.meta.totalItems;
        });
    },
});

export const getActiveCardProfile = createAsyncThunk(
    'activecardProfile/list',
    async ({ dataState }: CardProfileReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = dataState ? fleetnetBuildQueryParams(dataState) : null;
            const response = await Interceptor().get(`${URLS.ActiveCardProfile}`, {
                params: { ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

// Action creators are generated for each case reducer function
export const { updateItems, resetCardProfile } = cardProfileSlice.actions;
export default cardProfileSlice.reducer;
