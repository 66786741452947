import { useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
    COMPLETED_ACTION_STATES,
    PointStatuses,
    RemoteActions,
    TIME_INTERVAL,
} from '../../constants';
import { RemoteAction } from '../pages/interfaces';
import { getOngoingActions, clearSpinner } from '../pages/devices/remoteControl/remoteControlDuck';
import { useRights, useAppDispatch, useAppSelector } from '.';

const remoteControlRights = new Map([['subComponent', 'services.manageSites.remoteControl']]);

// handles the notifications that are triggered by remote actions
export const useRemoteControlActions = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionsTimer = useRef<number | null>(null);
    const { remoteActions } = useAppSelector((state) => state.remoteControl);
    const { selectedMandator } = useAppSelector((state) => state.mandators);

    // get the rights for remote control
    const rights = useRights(remoteControlRights);

    const createTimer = useCallback(() => {
        actionsTimer.current = setInterval(async () => {
            const response = await dispatch(getOngoingActions());
            if (getOngoingActions.rejected.match(response)) {
                clearTimer();
            }
        }, TIME_INTERVAL) as unknown as number;
    }, [dispatch]);

    const clearTimer = () => {
        if (actionsTimer.current) {
            clearInterval(actionsTimer.current);
            actionsTimer.current = null;
        }
    };

    useEffect(() => {
        if (rights?.subComponent?.view) {
            dispatch(getOngoingActions());
            return () => clearTimer();
        }
    }, [rights, dispatch]);

    const compareStatuses = useCallback(
        (storageActionsValues: [] | RemoteAction[], newActions: [] | RemoteAction[]) => {
            storageActionsValues.forEach((oldAction) => {
                const newAction = newActions.find((el) => el.action_id === oldAction.action_id);

                newAction && newAction.point
                    ? managePointsStatuses(oldAction, newAction)
                    : manageDeviceStatuses(oldAction, newAction);
            });
        },
        []
    );
    useEffect(() => {
        if (remoteActions) {
            const inProgressActions = remoteActions.find(
                (action) => !COMPLETED_ACTION_STATES.includes(action.action_state)
            );
            if (actionsTimer.current === null && inProgressActions) {
                createTimer();
            } else if (!inProgressActions) {
                // clear the interval if there are no actions in progress
                clearTimer();
            }
        }
    }, [createTimer, remoteActions]);

    useEffect(() => {
        if (remoteActions && selectedMandator?.id && rights?.subComponent?.view) {
            try {
                const storageActions = localStorage.remoteActions;
                let storageActionsMap;
                if (storageActions) {
                    storageActionsMap = new Map<string, RemoteAction[] | []>(
                        JSON.parse(localStorage.remoteActions)
                    );

                    const storageActionsValues = storageActionsMap.get(selectedMandator.id);
                    if (storageActionsValues) {
                        // must compare old and new actions
                        compareStatuses(storageActionsValues, remoteActions);
                    }
                } else {
                    storageActionsMap = new Map<string, RemoteAction[] | []>();
                }
                storageActionsMap.set(selectedMandator.id, remoteActions);

                localStorage.setItem(
                    'remoteActions',
                    JSON.stringify(Array.from(storageActionsMap))
                );
            } catch (e) {
                console.log(e);
            }
        }
    }, [remoteActions, selectedMandator, compareStatuses]);

    const manageStartAction = (oldAction: RemoteAction, newAction: RemoteAction) => {
        if (
            (oldAction.point?.status === PointStatuses.Available &&
                newAction.point?.status === PointStatuses.InUse) ||
            (oldAction.point?.status === PointStatuses.Available &&
                newAction.point?.status === PointStatuses.Occupied) ||
            (oldAction.point?.status === PointStatuses.Available &&
                newAction.point?.status === PointStatuses.Reserved)
        ) {
            const transactionStarted = t('devices.remote_control.messages.transactionStarted', {
                entity: oldAction.device.name,
                entity2: oldAction.point?.name,
            });
            toast.info(transactionStarted, { theme: 'colored' });
        }
    };
    const manageStopAction = (oldAction: RemoteAction, newAction: RemoteAction) => {
        if (
            (oldAction.point?.status === PointStatuses.InUse ||
                oldAction.point?.status === PointStatuses.Reserved) &&
            newAction.point?.status === PointStatuses.Available
        ) {
            const transactionCompleted = t('devices.remote_control.messages.transactionCompleted', {
                entity: oldAction.device.name,
                entity2: oldAction.point?.name,
            });
            toast.info(transactionCompleted, { theme: 'colored' });
        }
    };
    const managePointsStatuses = (oldAction: RemoteAction, newAction?: RemoteAction) => {
        if (oldAction.action_name === RemoteActions.Start && newAction) {
            manageStartAction(oldAction, newAction);
        }

        if (oldAction.action_name === RemoteActions.Stop && newAction) {
            manageStopAction(oldAction, newAction);
        }
        if (
            oldAction.point?.status !== PointStatuses.OutOfOrder &&
            newAction?.point?.status === PointStatuses.OutOfOrder
        ) {
            const pointNotReachable = t('devices.remote_control.messages.pointNotReachable', {
                entity: newAction.device.name,
            });
            toast.info(pointNotReachable, { theme: 'colored' });
        }
        if (
            oldAction.point?.status !== PointStatuses.Unknown &&
            newAction?.point?.status === PointStatuses.Unknown
        ) {
            const possibleErrors = t('devices.remote_control.messages.possibleErrors', {
                entity: newAction.device.name,
                entity2: newAction.point?.name,
            });
            toast.info(possibleErrors, { theme: 'colored' });
        }
    };

    const manageDeviceStatuses = (oldAction: RemoteAction, newAction?: RemoteAction) => {
        if (oldAction.action_name === RemoteActions.Restart && newAction) {
            if (oldAction.device.device_state === true && newAction.device.device_state === false) {
                const restartDevice = t('devices.remote_control.messages.restartDevice', {
                    entity: newAction.device.name,
                });
                toast.info(restartDevice, {
                    theme: 'colored',
                });
            }

            if (oldAction.device.device_state === false && newAction.device.device_state === true) {
                const deviceRestarted = t('devices.remote_control.messages.deviceRestarted', {
                    entity: newAction.device.name,
                });
                toast.success(deviceRestarted, {
                    theme: 'colored',
                });
                dispatch(clearSpinner(oldAction.device.id));
            }
        }
    };
};
