import { Checkbox } from '@mui/material';
import { GridCellProps } from '@progress/kendo-react-grid';

interface CheckboxCellProps extends GridCellProps {
    disabled?: boolean;
}

/**
 * Renders a custom checkbox cell for a Kendo React Grid.
 *
 * @param {CheckboxCellProps} props - The props for the CheckboxCell component.
 * @param {string} props.field - The name of the field.
 * @param {number} props.dataIndex - The index of the data item.
 * @param {object} props.dataItem - The data item.
 * @param {function} props.onChange - The function to call when the checkbox value changes.
 * @param {boolean} [props.disabled] - Whether the checkbox is disabled.
 * @param {string} [props.className] - Additional class name for the cell.
 * @param {object} [props.style] - Additional inline styles for the cell.
 * @return {JSX.Element} The rendered checkbox cell.
 */
const CheckboxCell = ({
    field,
    dataIndex,
    dataItem,
    onChange,
    disabled,
    className,
    style,
}: CheckboxCellProps): JSX.Element => {
    const fieldName = field || '';
    const dataValue = dataItem[fieldName];

    const handleChange = (e: any) => {
        if (onChange) {
            onChange({
                dataIndex: dataIndex,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.checked,
            });
        }
    };

    return (
        <td className={className} style={style}>
            <Checkbox
                checked={!!dataValue}
                value={dataValue}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                color="secondary"
                name={fieldName}
                role={fieldName}
                disabled={disabled}
            />
        </td>
    );
};
export default CheckboxCell;
