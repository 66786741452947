import { useState, useCallback, createRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// redux
import { useAppDispatch, useAppSelector, useTranslateFormErrors } from '../hooks';
import { completeNewPassword } from './authDuck';

// common components, interfaces, constants and helpers
import {
    getCognitoPasswordValidations,
    PasswordValidationError,
    sanitizeFields,
    validatePassword,
    validatePasswordOnChangedLanguage,
} from '../../helpers';

import { AuthHeader, PasswordValidations, ErrorComponent } from '.';

interface ResetPasswordValues {
    password: string;
    confirmPassword: string;
    showPassword: boolean;
    showConfirmPassword: boolean;
}

/**
 * ResetPassword component for handling the password reset functionality.
 *
 * @return {JSX.Element} The JSX element representing the ResetPassword component.
 */
const ResetPassword = (): JSX.Element => {
    const { t } = useTranslation();
    const { error, isAuthenticated } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const initialValues: ResetPasswordValues = {
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
    };
    const formRef = createRef<FormikProps<ResetPasswordValues>>();
    useTranslateFormErrors(formRef);

    const validationSchema = useCallback(
        () =>
            Yup.object().shape({
                password: getCognitoPasswordValidations(8, 20, t('password.customLabel'), true),
                confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password'), null], t('password.match'))
                    .required(
                        t('general.validations.requiredField', {
                            val: t('password.customLabel', { val: t('password.new') }),
                        })
                    ),
            }),
        [t]
    );
    const [passwordValidations, setPasswordValidations] = useState<PasswordValidationError[]>([]);
    async function validate(values: ResetPasswordValues) {
        const password = values.password;

        let passValidations: PasswordValidationError[] = passwordValidations;

        if (localStorage.languageChanged) {
            passValidations = await validatePasswordOnChangedLanguage(validationSchema);
        }

        validatePassword({
            validationSchema,
            password,
            passwordValidations: passValidations,
            setPasswordValidations,
        });
    }
    const handleResetPassword = async (fields: ResetPasswordValues) => {
        fields = sanitizeFields(fields);
        try {
            await dispatch(completeNewPassword(fields.password));
        } catch (exception) {
            console.log(exception);
        }
    };
    return (
        <>
            {isAuthenticated ? (
                <Navigate to="/eula" />
            ) : (
                <>
                    <AuthHeader />
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-evenly"
                        className="reset-password-page">
                        <Grid item xs={8} sm={7} md={5} lg={4} xl={3}>
                            <Box className="reset-password-content" sx={{ p: 5 }}>
                                <Formik
                                    innerRef={formRef}
                                    initialValues={initialValues}
                                    onSubmit={(fields) => {
                                        handleResetPassword(fields);
                                    }}
                                    validationSchema={validationSchema}
                                    validate={(values) => validate(values)}
                                    validateOnMount>
                                    {({
                                        values,
                                        touched,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        setValues,
                                    }) => (
                                        <Form className="reset-password-form">
                                            <Typography variant="h1" className="white-text">
                                                {t('authentication.resetPassword.title')}
                                            </Typography>
                                            {error && <ErrorComponent error={error} />}
                                            <FormControl variant="standard" sx={{ my: 1 }}>
                                                <InputLabel
                                                    htmlFor="password"
                                                    className="form-input">
                                                    {t('password.customLabel', {
                                                        val: t('password.new'),
                                                    })}
                                                </InputLabel>
                                                <Input
                                                    id="password"
                                                    name="password"
                                                    type={values.showPassword ? 'text' : 'password'}
                                                    onChange={handleChange}
                                                    className="form-input"
                                                    inputProps={{ role: 'password' }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                className="form-input"
                                                                sx={{ padding: 0 }}
                                                                onClick={() =>
                                                                    setValues({
                                                                        ...values,
                                                                        showPassword:
                                                                            !values.showPassword,
                                                                    })
                                                                }>
                                                                {values.showPassword ? (
                                                                    <Visibility className="form-input" />
                                                                ) : (
                                                                    <VisibilityOff className="form-input" />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                            <FormControl variant="standard" sx={{ my: 1 }}>
                                                <InputLabel
                                                    htmlFor="confirmPassword"
                                                    className="form-input">
                                                    {t('password.confirmNewPassword')}
                                                </InputLabel>
                                                <Input
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    type={
                                                        values.showConfirmPassword
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-input"
                                                    inputProps={{ role: 'confirmPassword' }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                className="form-input"
                                                                sx={{ padding: 0 }}
                                                                onClick={() =>
                                                                    setValues({
                                                                        ...values,
                                                                        showConfirmPassword:
                                                                            !values.showConfirmPassword,
                                                                    })
                                                                }>
                                                                {values.showConfirmPassword ? (
                                                                    <Visibility className="form-input" />
                                                                ) : (
                                                                    <VisibilityOff className="form-input" />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormLabel
                                                    className="form-error"
                                                    role="error-confirm-password">
                                                    {errors.confirmPassword &&
                                                    touched.confirmPassword
                                                        ? errors.confirmPassword
                                                        : ''}
                                                </FormLabel>
                                            </FormControl>
                                            {passwordValidations && (
                                                <PasswordValidations errors={passwordValidations} />
                                            )}
                                            <Button
                                                role="submit-button"
                                                type="submit"
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                className="form-submit-button">
                                                {t('authentication.resetPassword.setNewPassword')}
                                            </Button>
                                            <Button
                                                href="/"
                                                className="mb-3 mx-auto forgot-pass-text white-text">
                                                {t('authentication.backToLogin')}
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};
export default ResetPassword;
