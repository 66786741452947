import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

export const getManufacturersColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'name',
            title: 'chargingStations.entityName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'created_at',
            title: 'general.labels.createdAt',
            filter: 'date',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'number_of_devices',
            title: 'devices.deviceCount',
            filter: 'numeric',
            cell: customCells.get('deviceCount'),
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
    ];
};

export const getModelsColumns = (
    customCells?: Map<string, ComponentType<GridCellProps>>,
    sortable?: boolean,
    isHiddenColumnMenu?: boolean
): IGridColumn[] => {
    return [
        {
            field: 'system_name',
            title: 'chargingStations.models.deviceModel',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('system_name'),
            sortable,
            isHiddenColumnMenu,
        },
        {
            field: 'heartbeat_retries',
            title: 'chargingStations.models.heartbeatRetries',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('heartbeat_retries'),
            sortable,
            isHiddenColumnMenu,
        },
        {
            field: 'heartbeat_interval',
            title: 'chargingStations.models.heartbeatInterval',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('heartbeat_interval'),
            sortable,
            isHiddenColumnMenu,
        },
        {
            field: 'remote_call_retries',
            title: 'chargingStations.models.remoteCallRetries',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('remote_call_retries'),
            sortable,
            isHiddenColumnMenu,
        },
        {
            field: 'timeout',
            title: 'chargingStations.models.timeout',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('timeout'),
            sortable,
            isHiddenColumnMenu,
        },
        {
            field: 'meter_value_interval',
            title: 'chargingStations.models.meterValueInterval',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('meter_value_interval'),
            sortable,
            isHiddenColumnMenu,
        },
        {
            field: 'test',
            title: 'chargingStations.models.test',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('test'),
            sortable,
            isHiddenColumnMenu,
        },
        {
            field: 'version',
            title: 'chargingStations.models.version',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('version'),
            sortable,
            isHiddenColumnMenu,
        },
    ];
};
