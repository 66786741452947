import './polyfills';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
import App from './App';

const container: Element = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Suspense fallback="">
            <App />
        </Suspense>
    </React.StrictMode>
);
