export const CURRENCIES = [
    { name: 'EUR', code: 'EUR' },
    { name: 'USD', code: 'USD' },
    { name: 'JPY', code: 'JPY' },
    { name: 'AUD', code: 'AUD' },
    { name: 'CAD', code: 'CAD' },
    { name: 'CHF', code: 'CHF' },
    { name: 'CNY', code: 'CNY' },
    { name: 'HKD', code: 'HKD' },
    { name: 'NZD', code: 'NZD' },
    { name: 'SEK', code: 'SEK' },
    { name: 'KRW', code: 'KRW' },
    { name: 'SGD', code: 'SGD' },
    { name: 'NOK', code: 'NOK' },
    { name: 'MXN', code: 'MXN' },
    { name: 'INR', code: 'INR' },
    { name: 'RUB', code: 'RUB' },
    { name: 'ZAR', code: 'ZAR' },
    { name: 'TRY', code: 'TRY' },
    { name: 'BRL', code: 'BRL' },
    { name: 'TWD', code: 'TWD' },
    { name: 'DKK', code: 'DKK' },
    { name: 'PLN', code: 'PLN' },
    { name: 'THB', code: 'THB' },
    { name: 'IDR', code: 'IDR' },
    { name: 'HUF', code: 'HUF' },
    { name: 'ILS', code: 'ILS' },
    { name: 'CLP', code: 'CLP' },
    { name: 'PHP', code: 'PHP' },
    { name: 'AED', code: 'AED' },
    { name: 'COP', code: 'COP' },
    { name: 'SAR', code: 'SAR' },
    { name: 'MYR', code: 'MYR' },
    { name: 'RON', code: 'RON' },
];

export const DEFAULT_CURRENCY = CURRENCIES[0].code;
