import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';

import { useAppSelector } from '../../hooks';

import { EMAIL_HECTRONIC_LOGO, IMAGE_EXTENSIONS, URLS } from '../../../constants';
import { closeModal, createDeleteUrl, getDefaultFilesInfo } from '../../../helpers';
import { CustomUploadImageProps, FormikSetFieldValue } from '../interfaces';

import { UploadFiles, FormInput } from '../';

interface UploadImageFormProps {
    altText: string;
    srcImage: string | null;
}

const UplaodImage = ({
    saveUrl,
    fileMetadata,
    uploadImageLabel,
    editorView,
    imagePath,
}: CustomUploadImageProps) => {
    const { loading } = useAppSelector((state) => state.loading);
    const { t } = useTranslation();

    const formInitialValues: UploadImageFormProps = useMemo(() => {
        return {
            altText: '',
            srcImage: imagePath ?? '',
        };
    }, [imagePath]);

    const onImageInsert = async (fields: UploadImageFormProps) => {
        closeModal();
        if (editorView) {
            editorView.focus();
            const state = editorView.state;
            let transaction = state.tr;

            // remove the image from the editor if it exists
            state.doc.descendants((node, pos) => {
                if (node.type.name === 'image') {
                    transaction = transaction.delete(pos, pos + node.nodeSize);
                }
            });
            // Insert new image at the beginning of the document only if it exists
            if (fields.srcImage) {
                const newImage = state.schema.nodes.image.create({
                    src: fields.srcImage,
                    alt: fields.altText,
                });

                transaction = transaction.insert(0, newImage);
            }

            if (transaction.docChanged) {
                editorView.dispatch(transaction);
            }
        }
    };

    const onFileChange = useCallback(
        (event: UploadOnStatusChangeEvent, setFieldValue: FormikSetFieldValue) => {
            if (event.newState.length === 0) {
                setFieldValue('srcImage', null);
            }
        },
        []
    );
    const defaultFiles = useMemo(() => {
        return imagePath ? getDefaultFilesInfo(imagePath) : [];
    }, [imagePath]);

    const getRemoveUrl = useCallback((srcImage: string) => {
        return srcImage !== EMAIL_HECTRONIC_LOGO
            ? createDeleteUrl(`${URLS.ManageFiles}`, srcImage)
            : null;
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', px: 2, pt: 2 }}>
            <Formik
                initialValues={formInitialValues}
                onSubmit={(fields) => {
                    onImageInsert(fields);
                }}>
                {({ values, handleChange, setFieldValue }) => (
                    <Form>
                        <Typography variant="h3" mt={2} mb={0.5}>
                            {uploadImageLabel}
                        </Typography>
                        <UploadFiles
                            saveUrl={saveUrl}
                            removeUrl={getRemoveUrl(values.srcImage ?? '')}
                            fileMetaData={fileMetadata}
                            allowedExtensions={IMAGE_EXTENSIONS}
                            withCredentials={false}
                            onChange={(event: UploadOnStatusChangeEvent) => {
                                onFileChange(event, setFieldValue);
                            }}
                            onRemove={(event) => {
                                onFileChange(event, setFieldValue);
                            }}
                            setFieldValue={setFieldValue}
                            fieldName="srcImage"
                            defaultFiles={defaultFiles}
                        />
                        <FormInput
                            label={t('general.labels.altText')}
                            fieldName="altText"
                            value={values.altText}
                            type="text"
                            handleChange={handleChange}
                            sx={{ width: '100%', maxWidth: '100%', ml: '0 !important' }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="outlined"
                                onClick={() => closeModal()}
                                sx={{
                                    mr: 1,
                                }}
                                disabled={loading}>
                                {t('general.labels.cancel')}
                            </Button>

                            <Button type="submit" variant="contained" disabled={loading}>
                                {t('general.labels.insert')}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
export default UplaodImage;
