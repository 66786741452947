import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { Interceptor } from '../../../common';
import { Tariff, TariffHistoriesRequestObject, TariffStateTypes } from '../../interfaces';

const initialState: TariffStateTypes = {
    tariffs: INITIAL_DATA_STATE,
    tariffHistories: INITIAL_DATA_STATE,
    tariff: null,
};

// create a thunk for tariffs list
export const getTariffs = createAsyncThunk(
    'parkingTariffs/list',
    async (mandator_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Tariffs}`, {
                params: { mandator_id },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for tariff details
export const getTariffById = createAsyncThunk(
    'parkingTariffs/tariff',
    async (tariff_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Tariffs}/${tariff_id}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for adding a tariff
export const addTariff = createAsyncThunk(
    'parkingTariffs/add',
    async (tariff: Tariff, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Tariffs}`, { ...tariff });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const updateTariff = createAsyncThunk(
    'parkingTariffs/update',
    async (tariff: Tariff, { rejectWithValue }) => {
        try {
            return await Interceptor().patch(`${URLS.Tariffs}/${tariff.id}`, { ...tariff });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getTariffHistory = createAsyncThunk(
    'parkingTariffs/history',
    async (tariff_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Tariffs}/${tariff_id}/history`);
            return response.data.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//get the histories of all tariffs
export const getTariffHistories = createAsyncThunk(
    'parkingTariffs/histories',
    async ({ mandator_id, only_latest }: TariffHistoriesRequestObject, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Tariffs}/histories`, {
                params: {
                    mandator_id,
                    only_latest,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

// reducers
const tariffsSlice = createSlice({
    name: 'tariffs',
    initialState,
    reducers: {
        resetTariff: (state) => {
            state.tariff = initialState.tariff;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTariffs.fulfilled, (state, { payload }) => {
            state.tariffs.data = [...payload.data];
            state.tariffs.total = payload.meta.total;
        });
        builder.addCase(getTariffById.fulfilled, (state, { payload }) => {
            state.tariff = payload.data[0];
        });
        builder.addCase(getTariffHistories.fulfilled, (state, { payload }) => {
            state.tariffHistories.data = [...payload.data];
            state.tariffHistories.total = payload.meta.total;
        });
    },
});

export const { resetTariff } = tariffsSlice.actions;
export default tariffsSlice.reducer;
