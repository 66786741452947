import { ImageMetaData } from '../app/common/interfaces';

export const createUploadUrl = (
    baseUrl: string,
    imageMetaData: ImageMetaData,
    component: string,
    mandator: string | null
) => {
    return `${baseUrl}?mandator_id=${mandator}&component_type=${component}&label=${imageMetaData.label}&width=${imageMetaData.width}&height=${imageMetaData.height}`;
};

export const createDeleteUrl = (baseUrl: string, accessFilePath: string) => {
    return `${baseUrl}?access_file_path=${accessFilePath}`;
};
