import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { URLS, INITIAL_DATA_STATE } from '../../../constants';
import { buildQueryParams } from '../../../helpers';
import { Interceptor } from '../../common';
// interfaces
import {
    Product,
    ProductStateTypes,
    ProductUpdateReqProps,
    ExtendedGetReqProps,
} from '../interfaces';

const initialState: ProductStateTypes = {
    products: INITIAL_DATA_STATE,
    product: null,
};

// create a thunk for get products
export const getProducts = createAsyncThunk(
    'products/list',
    async ({ mandator_id, data_state }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Products}`, {
                params: { ...queryParams, mandator_id },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
// create a thunk for adding a product
export const addProduct = createAsyncThunk(
    'products/add',
    async (product: Product, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Products}`, { ...product });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
// create thunk for getting the product by id
export const getProductById = createAsyncThunk(
    'products/product',
    async (product_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Products}/${product_id}`);
            return response.data.data[0];
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
// create thunk for updating an product
export const updateProduct = createAsyncThunk(
    'products/update',
    async ({ product, product_id }: ProductUpdateReqProps, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.Products}/${product_id}`, { ...product });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const deleteProduct = createAsyncThunk(
    'products/delete',
    async (productId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Products}/${productId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//reducers
const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        resetProduct: (state) => {
            state.product = initialState.product;
        },
        resetProducts: (state) => {
            state.products = initialState.products;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProducts.fulfilled, (state, { payload }) => {
            state.products.data = [...payload.data];
            state.products.total = payload.meta.total;
        });
        builder.addCase(getProductById.fulfilled, (state, { payload }) => {
            state.product = payload;
        });
    },
});

export const { resetProduct, resetProducts } = productsSlice.actions;
export default productsSlice.reducer;
