import InfoRounded from '@mui/icons-material/InfoRounded';
import { Tooltip } from '@mui/material';

const FleetnetTooltip = (props: any) => {
    //converting camelcase to title case
    const words = [];
    let start = 0;
    let columnName;
    if (props?.titleCaseEnabled) {
        for (let end = 1; end < props?.tooltipFieldName.length; end++) {
            if (props?.tooltipFieldName[end].toUpperCase() === props?.tooltipFieldName[end]) {
                words.push(props?.tooltipFieldName.substring(start, end));
                start = end;
            }
        }

        words.push(props?.tooltipFieldName.substring(start));

        columnName = words
            .map((word) => word.substring(0, 1).toUpperCase() + word.slice(1))
            .join(' ');
    } else if (!props?.titleCaseEnabled) {
        columnName = props?.tooltipFieldName;
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
                style={{
                    minWidth: '0',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    flexShrink: '1',
                    overflow: 'hidden',
                }}>
                {columnName}
            </span>
            <Tooltip
                title={props?.tooltipMsg}
                arrow={true}
                placement="top"
                style={{ whiteSpace: 'pre-line', marginLeft: '10px', cursor: 'pointer' }}>
                <InfoRounded />
            </Tooltip>
        </div>
    );
};

export default FleetnetTooltip;
