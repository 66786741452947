import { Typography } from '@mui/material';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { PanMaskValidations } from '../../constants/fleetNetGeneral';

// Check if panMaskPattern contains characters other than 'x'(case-insensitive) and '0'(Zero)
const isPanMaskPatternValid = (panMaskPattern?: string | null): boolean => {
    if (!panMaskPattern) return true;
    const patternRegex = /^[xX0]+$/i;
    return patternRegex.test(panMaskPattern);
};

// Validate the length of PAN field
const validatePanLength = (track: any): string | null => {
    const { panMaskPattern, fields, trackNo } = track;

    if (!fields) return null;

    for (const field of fields) {
        if (field.key === PanMaskValidations.Pan && field.fieldLength !== panMaskPattern?.length) {
            const message = t('cardProfile.panLengthNotMatched');
            toast.error(`${message}${trackNo}`, {
                theme: 'colored',
            });
            return PanMaskValidations.PanLengthNotMatched;
        }
    }
    return null;
};

export const validatePan = (tracks: any) => {
    for (const track of tracks) {
        if (!track) continue;

        const panMaskPattern = track.panMaskPattern;
        const trackNo = track.trackNo;

        if (!panMaskPattern && trackNo) {
            const message = t('cardProfile.panMaskNotPresent');
            toast.error(`${message} ${trackNo}`, {
                theme: 'colored',
            });
            return PanMaskValidations.PanMaskPatternNotPresent;
        }

        if (!isPanMaskPatternValid(panMaskPattern)) {
            const msg = t('cardProfile.invalidPanmaskpattern');
            toast.error(`${msg} no: ${track.trackNo}`, {
                theme: 'colored',
            });
            return PanMaskValidations.PanMaskingFormatInvalid;
        }

        const panLengthError = validatePanLength(track);
        if (panLengthError) {
            return panLengthError;
        }
    }
    return null;
};

//Only applying panMaskPattern data is valid or not
export const validatePanMask = (panMaskPattern: string, trackNo: number) => {
    if (!isPanMaskPatternValid(panMaskPattern)) {
        const msg = t('cardProfile.invalidPanmaskpattern');
        toast.error(`${msg} no: ${trackNo}`, {
            theme: 'colored',
        });
        return PanMaskValidations.PanMaskingFormatInvalid;
    }
};
// Function to check if a pattern is valid
const isPatternValid = (pattern: string) => {
    return pattern && /^(x|0)+$/i.test(pattern);
};

// Function to check if any pattern field is invalid
export const isAnyPatternInvalid = (
    formPropsCP: {
        values: {
            tracks: { trackNo: any }[];
            trackOnePanMaskPattern: string;
            trackTwoPanMaskPattern: string;
            trackThreePanMaskPattern: string;
        };
    },
    selectedTrackInput?: string | number | undefined
) => {
    const trackNo = selectedTrackInput || formPropsCP?.values?.tracks[0]?.trackNo;
    //selectedTrackInput type is no so need to parse into string
    const tNoParsed = trackNo?.toString();

    switch (tNoParsed) {
        case '1':
            return formPropsCP?.values?.trackOnePanMaskPattern
                ? !isPatternValid(formPropsCP?.values?.trackOnePanMaskPattern)
                : false;
        case '2':
            return formPropsCP?.values?.trackTwoPanMaskPattern
                ? !isPatternValid(formPropsCP?.values?.trackTwoPanMaskPattern)
                : false;
        case '3':
            return formPropsCP?.values?.trackThreePanMaskPattern
                ? !isPatternValid(formPropsCP?.values?.trackThreePanMaskPattern)
                : false;
        default:
            return false;
    }
};

//Pan lenght and track fields lenght validation
export const PanLengthErrorValidate = (
    trackTwoFields: any[],
    panMaskPatternLength: any,
    mismatchLength: any
) => {
    {
        return trackTwoFields?.map((tkTwoField: any) => {
            if (
                tkTwoField?.key === PanMaskValidations.Pan &&
                tkTwoField?.fieldLength !== panMaskPatternLength
            ) {
                return (
                    <Typography color="#d63232" variant="caption" ml={1}>
                        <> {mismatchLength}</>
                    </Typography>
                );
            } else {
                return;
            }
        });
    }
};
