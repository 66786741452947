export const LANGUAGES = [
    { code: 'en', name: 'English', countryCode: 'GB' },
    { code: 'fr', name: 'French', countryCode: 'FR' },
    { code: 'it', name: 'Italian', countryCode: 'IT' },
    { code: 'de', name: 'German', countryCode: 'DE' },
    { code: 'ro', name: 'Romanian', countryCode: 'RO' },
    { code: 'pt', name: 'Portuguese', countryCode: 'PT' },
    { code: 'es', name: 'Spanish', countryCode: 'ES' },
    { code: 'nl', name: 'Dutch', countryCode: 'NL' },
    { code: 'hr', name: 'Croatian', countryCode: 'HR' },
    { code: 'hu', name: 'Hungarian', countryCode: 'HU' },
    { code: 'hi', name: 'Hindi', countryCode: 'IN' },
    { code: 'zh', name: 'Chinese', countryCode: 'CN' },
    { code: 'pl', name: 'Polish', countryCode: 'PL' },
    { code: 'sl', name: 'Slovenian', countryCode: 'SI' },
    { code: 'da', name: 'Danish', countryCode: 'DK' },
];

export type LANGUAGE_CODES_TYPE =
    | 'en'
    | 'fr'
    | 'ro'
    | 'de'
    | 'pl'
    | 'sl'
    | 'hu'
    | 'it'
    | 'pt'
    | 'es'
    | 'nl'
    | 'hr'
    | 'da';

export const LANGUAGE_CODES = [
    'en',
    'fr',
    'it',
    'de',
    'ro',
    'pt',
    'es',
    'nl',
    'hr',
    'hu',
    'sl',
    'da',
    'pl',
];

export const FREQUENT_LANGUAGES = ['en', 'de', 'it', 'fr', 'pl'];

// constant used for sites languages
// it contains all languages supported in DR PDF
export const SITE_DEFAULT_LANGUAGES = LANGUAGE_CODES.map((languageCode) => {
    return { code: languageCode, name: `general.languages.${languageCode}` };
});
