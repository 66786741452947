import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { Interceptor } from '../../../common';
import {
    SiteAssignmentsStateTypes,
    TariffAssignmentFormValues,
    TariffAssignmentUpdateModel,
    TariffAssignmentsGetProps,
} from '../../interfaces';

const initialState: SiteAssignmentsStateTypes = {
    assignments: INITIAL_DATA_STATE,
};

export const getTariffAssignments = createAsyncThunk(
    'tariffAssignments/list',
    async ({ mandator_id, active_on_date }: TariffAssignmentsGetProps, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(URLS.SiteTariffAssignments, {
                params: { mandator_id, active_on_date },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const addTariffAssignment = createAsyncThunk(
    'tariffAssignments/add',
    async (assignment: TariffAssignmentFormValues, { rejectWithValue }) => {
        try {
            const response = await Interceptor().post(URLS.SiteTariffAssignments, {
                ...assignment,
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const updateTariffAssignment = createAsyncThunk(
    'tariffAssignments/update',
    async ({ assignment, assignment_id }: TariffAssignmentUpdateModel, { rejectWithValue }) => {
        try {
            const response = await Interceptor().patch(
                `${URLS.SiteTariffAssignments}/${assignment_id}`,
                { ...assignment }
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const deleteTariffAssignment = createAsyncThunk(
    'tariffAssignments/delete',
    async (assignment_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().delete(
                `${URLS.SiteTariffAssignments}/${assignment_id}`
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// reducers
const sitesAssignmentsSlice = createSlice({
    name: 'tariffsAssignments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTariffAssignments.fulfilled, (state, { payload }) => {
            state.assignments.data = payload.data;
        });
    },
});

export default sitesAssignmentsSlice.reducer;
