import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { INITIAL_DATA_STATE, URLS } from '../../../constants';
import { Interceptor } from '../../common';

// interfaces
import {
    ExtendedGetReqProps,
    ProductAssignmentStateTypes,
    UpdateMandatorProducts,
} from '../interfaces';
import { buildQueryParams } from '../../../helpers';

const initialState: ProductAssignmentStateTypes = {
    mandatorProducts: INITIAL_DATA_STATE,
};

// create a thunk for get mandator products
export const getMandatorProducts = createAsyncThunk(
    'productsAssignment/mandatorProducts',
    async ({ mandator_id, data_state }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Products}/mandators/${mandator_id}`, {
                params: { ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
// create a thunk for update products for a mandator
export const updateMandatorProducts = createAsyncThunk(
    'productsAssignment/mandatorProducts/update',
    async ({ mandator_id, assignments }: UpdateMandatorProducts, { rejectWithValue }) => {
        try {
            const response = await Interceptor().post(`${URLS.Products}/mandators/${mandator_id}`, {
                ...assignments,
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
//reducers
const productsAssignmentSlice = createSlice({
    name: 'productsAssignment',
    initialState,
    reducers: {
        resetMandatorProducts: (state) => {
            state.mandatorProducts = initialState.mandatorProducts;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMandatorProducts.fulfilled, (state, { payload }) => {
            state.mandatorProducts.data = [...payload.data];
            state.mandatorProducts.total = payload.meta.total;
        });
    },
});

export const { resetMandatorProducts } = productsAssignmentSlice.actions;
export default productsAssignmentSlice.reducer;
