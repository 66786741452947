import { useMemo, useEffect, useCallback, useState, ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, InputBase, Menu, MenuItem, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp, LocationCity, Search } from '@mui/icons-material';

//redux
import { useAppDispatch, useAppSelector, useRights } from '../../../hooks';
import {
    getMandatorById,
    updateItems,
    updateSelectedMandator,
} from '../../../pages/mandators/mandatorDuck';
import { logout, updateCurrentMandator } from '../../../auth/authDuck';
import { displayModal } from '../../modal/modalDuck';
import { resetUserRights } from '../userRightsDuck';
import { getOngoingActions } from '../../../pages/devices/remoteControl/remoteControlDuck';

// common components, interfaces, constants and helpers
import {
    closeModal,
    sortByField,
    updateSelectedTheme,
    getAllServicesStatuses,
} from '../../../../helpers';
import { ComponentWidthProps } from '../../interfaces';
import { Mandator, RemoteAction } from '../../../pages/interfaces';
import { BREAKPOINTS, MODAL_TYPES, COMPLETED_ACTION_STATES } from '../../../../constants';

import '../layout.scss';
const remoteControlRightsValue = new Map([['subComponent', 'services.manageSites.remoteControl']]);

/**
 * Renders a menu for selecting a mandator.
 * @param {ComponentWidthProps} props - The props object containing the following properties:
 * @param {number} props.width - The width of the component.
 * @param {number} props.maxWidth - The maximum width of the component.
 * @return {JSX.Element} The rendered menu component.
 */
const MandatorMenu = ({ width, maxWidth }: ComponentWidthProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const pathParams = useParams();
    const { t } = useTranslation();
    const { userMandatorsList, selectedMandator } = useAppSelector((state) => state.mandators);
    const { loggedUser } = useAppSelector((state) => state.auth);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchText, setSearchText] = useState('');

    const remoteControlRights = useRights(remoteControlRightsValue);

    const mandatorList: Record<string, string>[] = useMemo(() => {
        const rawMandatorsList = userMandatorsList
            .filter((mandator) => mandator.mandator_state === true)
            .map((item) => {
                return { id: item.id ?? '', name: item.name };
            });
        return sortByField(rawMandatorsList, 'name');
    }, [userMandatorsList]);

    const setSelectedMandator = useCallback(async () => {
        if (!mandatorList.length) {
            dispatch(updateItems([]));
            dispatch(logout());
        }
        if (!localStorage.selectedMandator && loggedUser?.preferred_mandator) {
            const newMandator = await findAndUpdateMandator(loggedUser.preferred_mandator);
            if (!newMandator) {
                await findAndUpdateMandator(mandatorList[0]?.id);
            }
            return;
        }
        if (
            !localStorage.selectedMandator &&
            (!selectedMandator ||
                mandatorList.findIndex((mandator) => mandator.id === selectedMandator?.id) === -1)
        ) {
            await findAndUpdateMandator(mandatorList[0]?.id);
            return;
        }
        if (localStorage.selectedMandator && !selectedMandator) {
            const newMandator = await getMandatorDetailsById(localStorage.selectedMandator);

            dispatch(updateSelectedMandator(newMandator));
            getAllServicesStatuses(newMandator?.id ?? '', dispatch);
        }
    }, [dispatch, mandatorList, loggedUser, selectedMandator]);

    useEffect(() => {
        setSelectedMandator();
    }, [setSelectedMandator]);

    const getMandatorDetailsById = async (id: string) => {
        const res = await dispatch(getMandatorById(id));
        const newMandator: Mandator = res.payload;

        return newMandator?.mandator_state ? newMandator : null;
    };

    const closeMenu = () => {
        setAnchorEl(null);
        setSearchText('');
    };

    const findAndUpdateMandator = async (id: string) => {
        const newMandator = await getMandatorDetailsById(id);

        if (newMandator?.mandator_state) {
            await dispatch(updateCurrentMandator(newMandator?.id ?? ''));
            dispatch(updateSelectedMandator(newMandator));
            localStorage.setItem('selectedMandator', newMandator.id ?? '');
            updateSelectedTheme(newMandator, dispatch);
            getAllServicesStatuses(newMandator?.id ?? '', dispatch);
            return newMandator;
        }
        return null;
    };

    const changeMandator = async (selection: string) => {
        closeModal();
        localStorage.removeItem('idParent');
        await findAndUpdateMandator(selection);
    };

    const conditionalMandatorChange = async (selection: string) => {
        // reset user rights when the mandator is changed
        dispatch(resetUserRights());
        if (pathParams.id || localStorage.addOrEdit) {
            localStorage.removeItem('addOrEdit');
            await changeMandator(selection);
            navigate(-1);
        } else {
            changeMandator(selection);
        }
    };

    const handleChangeMandator = async (event: any) => {
        closeMenu();
        const selection = event.target.attributes.value?.value;
        if (selection === selectedMandator?.id) {
            return;
        }
        if (remoteControlRights?.subComponent?.view) {
            const response = await dispatch(getOngoingActions());
            if (getOngoingActions.fulfilled.match(response)) {
                const currentRemoteActions: RemoteAction[] = response.payload.data;
                if (
                    currentRemoteActions.find(
                        (action) => !COMPLETED_ACTION_STATES.includes(action.action_state)
                    )
                ) {
                    dispatch(
                        displayModal({
                            showModal: true,
                            title: t('general.labels.change', {
                                entity: t('general.labels.mandator_one'),
                            }),
                            type: MODAL_TYPES.ConfirmationMessage,
                            message: 'general.messages.changeMandatorConfirmation',
                            onLeave: () => conditionalMandatorChange(selection),
                        })
                    );
                    return;
                }
            }
        }

        conditionalMandatorChange(selection);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchText(event.target.value);
    };

    function renderMandatorMenu() {
        const displayOptions = mandatorList
            .map((item) => {
                if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
                    return item;
                }
                return undefined;
            })
            .filter((item) => item !== undefined);

        return (
            <Menu
                anchorEl={anchorEl}
                keepMounted={true}
                open={!!anchorEl}
                onClose={closeMenu}
                className="tenant-selection">
                {mandatorList.length > 3 && (
                    <MenuItem disableTouchRipple={true} role="menu-item" className="tenant-search">
                        <Search sx={{ width: 24, height: 24, color: '#CDCDCD' }} />

                        <InputBase
                            sx={{ pl: 1 }}
                            placeholder={t('general.labels.search')}
                            onChange={handleSearchChange}
                            value={searchText}
                        />
                    </MenuItem>
                )}
                <div className="scrollable-list">
                    {displayOptions.map((item, index) => {
                        return (
                            <div key={index}>
                                <MenuItem
                                    onClick={handleChangeMandator}
                                    selected={item?.id === selectedMandator?.id}
                                    role="menu-item"
                                    value={item?.id}>
                                    {item?.name}
                                </MenuItem>
                            </div>
                        );
                    })}
                </div>
            </Menu>
        );
    }
    return (
        <div>
            <IconButton type="button" onClick={handleMenuOpen} sx={{ color: '#fff' }}>
                <LocationCity sx={{ mr: 1 }} />
                {width > BREAKPOINTS.md && (
                    <Typography variant="subtitle1" noWrap sx={{ maxWidth: maxWidth }}>
                        {selectedMandator?.name}
                    </Typography>
                )}
                {!anchorEl ? <ArrowDropDown /> : <ArrowDropUp />}
            </IconButton>
            {renderMandatorMenu()}
        </div>
    );
};
export default MandatorMenu;
