import { Backdrop, CircularProgress } from '@mui/material';
import { useAppSelector } from '../../hooks';

/**
 * Renders a loading backdrop with circular progress based on the loading state.
 *
 * @return {JSX.Element} Loading backdrop with circular progress
 */
const Loading = (): JSX.Element => {
    const { loading } = useAppSelector((state) => state.loading);
    return (
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
export default Loading;
