import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { URLS } from '../../../constants';
import { Interceptor } from '../../common';
import { ProvidersStateTypes } from '../interfaces';

const initialState: ProvidersStateTypes = {
    providers: [],
};

// create thunk for getting the providers
export const getProviders = createAsyncThunk('providers/list', async (_, { rejectWithValue }) => {
    try {
        const response = await Interceptor().get(`${URLS.Providers}`);
        return response.data;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error.response);
    }
});

//reducers
const providersSlice = createSlice({
    name: 'providers',
    initialState,
    reducers: {
        resetProviders: (state) => {
            state.providers = initialState.providers;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProviders.fulfilled, (state, { payload }) => {
            state.providers = [...payload.data];
        });
    },
});

export const { resetProviders } = providersSlice.actions;
export default providersSlice.reducer;
