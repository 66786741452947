import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { t } from 'i18next';

import { IGridColumn } from '../../common/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

export const getProviderFieldsColumns = (
    CustomCell: ComponentType<GridCellProps>
): IGridColumn[] => {
    return [
        {
            field: 'key',
            title: 'providerFields.key',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'name',
            title: 'general.labels.name',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'created_at',
            title: 'general.labels.createdAt',
            filter: 'date',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'description',
            title: 'general.labels.description',
            filter: 'text',
            sortable: false,
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'type',
            title: 'general.labels.type',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'template_count',
            title: t('general.labels.entityCount', { val: t('general.labels.template') }),
            filter: 'numeric',
            cell: CustomCell,
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
    ];
};
