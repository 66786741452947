export enum ProviderFieldTypes {
    String = 'text',
    Number = 'number',
    Boolean = 'boolean',
}
export const PROVIDER_FIELD_TYPES = [
    { name: 'providerFields.types.text', value: ProviderFieldTypes.String },
    { name: 'providerFields.types.number', value: ProviderFieldTypes.Number },
    { name: 'providerFields.types.boolean', value: ProviderFieldTypes.Boolean },
];
