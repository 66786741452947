import { Typography, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmationMessageProps {
    onClose(): void;
    onConfirm?: () => void;
    message: string;
    component?: string;
    parent?: string;
    confirmCustomLabel?: string;
    cancelCustomLabel?: string;
    isCancelButtonHidden?: boolean;
    customMessage?: JSX.Element;
}

/**
 * Renders a confirmation message modal.
 *
 * @param {ConfirmationMessageProps} props - The properties for the confirmation message.
 * @param {Function} props.onClose - The function to close the modal.
 * @param {Function} [props.onConfirm] - The function to confirm the action.
 * @param {string} props.message - The message to display.
 * @param {string} [props.component] - The component name.
 * @param {string} [props.parent] - The parent name.
 * @param {string} [props.confirmCustomLabel] - The custom label for the confirm button.
 * @param {string} [props.cancelCustomLabel] - The custom label for the cancel button.
 * @param {boolean} [props.isCancelButtonHidden] - Indicates if the cancel button is hidden.
 * @param {JSX.Element} [props.customMessage] - The custom message to display.
 * @return {JSX.Element} The rendered confirmation message modal.
 */
const ConfirmationMessage = ({
    onClose,
    onConfirm,
    message,
    component,
    parent,
    confirmCustomLabel,
    cancelCustomLabel,
    isCancelButtonHidden,
    customMessage,
}: ConfirmationMessageProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2, pt: 2 }}>
            <Typography variant="h3" align="center">
                {customMessage ??
                    t(message, { val: component, parent: parent })
                        .split('\n')
                        .map((i, key) => {
                            return (
                                <div key={key} className={`test ${key}`}>
                                    {i}
                                </div>
                            );
                        })}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, mt: 4 }}>
                {!isCancelButtonHidden && (
                    <Button variant="outlined" onClick={onClose} sx={{ mr: 1, px: 6 }}>
                        {cancelCustomLabel ?? t('general.labels.no')}
                    </Button>
                )}
                <Button variant="contained" sx={{ px: 6 }} onClick={onConfirm}>
                    {confirmCustomLabel ?? t('general.labels.yes')}
                </Button>
            </Box>
        </Box>
    );
};

export default ConfirmationMessage;
