import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    GridColumnMenuItem,
    GridColumnMenuItemContent,
    GridColumnMenuItemGroup,
    GridColumnMenuProps,
    GridColumnMenuSort,
} from '@progress/kendo-react-grid';
import { IGridColumn } from './interfaces';
import { EXPANDED_FIELD } from '../../../constants';
interface CustomColumnMenuProps extends GridColumnMenuProps {
    columns: IGridColumn[];
    onColumnsSubmit: (columns: IGridColumn[]) => void;
    onColumnsReset: () => void;
}
/**
 * Renders a custom column menu component for a Kendo React Grid.
 *
 * @param {CustomColumnMenuProps} props - The props for the component.
 * @param {Column[]} props.columns - The array of columns to display in the menu.
 * @param {Function} props.onColumnsReset - The function to call when the reset button is clicked.
 * @param {Function} props.onColumnsSubmit - The function to call when the apply button is clicked.
 * @param {SortableProps} [props.sortable] - The optional sortable props for the component.
 * @param {Function} [props.onCloseMenu] - The optional function to call when the menu is closed.
 * @return {JSX.Element} The rendered custom column menu component.
 */
const ColumnMenu = (props: CustomColumnMenuProps): JSX.Element => {
    const { t } = useTranslation();
    const [columnsExpanded, setColumnsExpanded] = useState(false);
    const [columns, setColumns] = useState(props.columns);
    const oneVisibleColumn = columns.filter((c) => c.show && c.title).length === 1;
    const onToggleColumn = (id: number) => {
        const newColumns = columns.map((column, idx) => {
            return idx === id ? { ...column, show: !column.show } : column;
        });
        setColumns(newColumns);
    };
    const onMenuItemClick = () => {
        setColumnsExpanded(!columnsExpanded);
    };
    const handleResetColumns = () => {
        closeColumnMenu();
        props.onColumnsReset();
    };
    const handleChangeColumns = () => {
        closeColumnMenu();
        props.onColumnsSubmit(columns);
    };
    const closeColumnMenu = () => {
        if (props.onCloseMenu) {
            props.onCloseMenu();
        }
    };

    return (
        <div>
            {props.sortable && <GridColumnMenuSort {...props} />}
            {columns.length > 1 && (
                <GridColumnMenuItemGroup>
                    <GridColumnMenuItem
                        title={t('general.labels.columns')}
                        iconClass="k-i-columns"
                        onClick={onMenuItemClick}
                    />
                    <GridColumnMenuItemContent show={columnsExpanded}>
                        <div className="k-column-list-wrapper">
                            <div className="k-column-list">
                                {columns.map((column, idx) => {
                                    const showColumn =
                                        column.title &&
                                        idx < columns.length - 1 &&
                                        column.field !== EXPANDED_FIELD;
                                    return (
                                        showColumn && (
                                            <div key={idx} className="k-column-list-item">
                                                <span>
                                                    <input
                                                        id={`column-visiblity-show-${idx}`}
                                                        className="k-checkbox k-checkbox-md k-rounded-md"
                                                        type="checkbox"
                                                        readOnly={true}
                                                        disabled={column.show && oneVisibleColumn}
                                                        checked={column.show}
                                                        onClick={() => {
                                                            onToggleColumn(idx);
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor={`column-visiblity-show-${idx}`}
                                                        className="k-checkbox-label">
                                                        {t(column.title || '')}
                                                    </label>
                                                </span>
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                            <div className="k-actions k-hstack k-justify-content-stretch">
                                <button className="k-button" onClick={handleResetColumns}>
                                    {t('general.labels.reset')}
                                </button>
                                <button
                                    className="k-button k-button-primary"
                                    onClick={handleChangeColumns}>
                                    {t('general.labels.apply')}
                                </button>
                            </div>
                        </div>
                    </GridColumnMenuItemContent>
                </GridColumnMenuItemGroup>
            )}
        </div>
    );
};
export default ColumnMenu;
