import { NavigateFunction } from 'react-router';
import { Operations, SUCCESSFUL_ACTION, ToasterType } from '../constants';
import { clearLSOnComponentLeave } from './clearStorage';
import { showToaster } from './formActions';

export const navigateBack = () => window.history.back();
export const navigateToParent = (navigate: NavigateFunction) => {
    navigate('..');
};
export const navigateToPath = (navigate: NavigateFunction, customPath: string) => {
    navigate(customPath ?? '');
};

export const navigateAfterFulfilledAction = (
    navigate: NavigateFunction,
    operation?: `${Operations}`
) => {
    if (operation) {
        showToaster(ToasterType.Success, SUCCESSFUL_ACTION, operation);
    }
    clearLSOnComponentLeave();
    navigateToParent(navigate);
};

export const navigateToChildAfterFulfilledAction = (
    navigate: NavigateFunction,
    customPath: string,
    operation?: `${Operations}`
) => {
    if (operation) {
        showToaster(ToasterType.Success, SUCCESSFUL_ACTION, operation);
    }
    localStorage.removeItem('addOrEdit');
    navigateToPath(navigate, customPath);
};
