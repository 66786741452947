import { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartArea,
    ChartValueAxisItem,
    ChartValueAxis,
    SeriesClickEvent,
    ChartSeriesLabels,
} from '@progress/kendo-react-charts';

import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { ChartPoint, GraphProps } from '../interfaces';
import { EllipsisText } from '..';

const BACKGROUND_COLOR = 'var(--secondary-background-color)';
const TEXT_COLOR = 'var(--secondary-color)';
/**
 * A custom component that renders a custom Kendo React Chart based on the provided data and settings.
 * It can display different kinds of charts with custom settings for each.
 * @param {GraphProps} props - The props for the component containing:
 * @param {GraphProps} props.role - The role of the chart.
 * @param {string} props.type - The type of the chart.
 * @param {any[]} props.data - The data to be displayed on the chart.
 * @param {number} props.width - The width of the chart.
 * @param {number} props.height - The height of the chart.
 * @param {number} props.graphHeight - The height of the graph area.
 * @param {boolean} props.legendVisibility - Whether the legend is visible.
 * @param {any} props.categoryAxis - The category axis data.
 * @param {string} props.categoryAxisTitle - The title of the category axis.
 * @param {string} props.legendPosition - The position of the legend.
 * @param {number} props.min - The minimum value for the chart.
 * @param {number} props.max - The maximum value for the chart.
 * @param {string} props.title - The title of the chart.
 * @param {string} props.formatting - The formatting of the chart data.
 * @param {any} props.menuData - The menu data for the chart.
 * @param {string} props.seriesColor - The color of the series.
 * @return {JSX.Element} The rendered custom Kendo React Chart component.
 */
const GraphicBlock = ({
    role,
    type,
    data,
    width,
    height,
    graphHeight,
    legendVisibility,
    categoryAxis,
    categoryAxisTitle,
    legendPosition,
    min,
    max,
    title,
    formatting,
    menuData,
    seriesColor,
}: GraphProps): JSX.Element => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [barChartIndex, setBarChartIndex] = useState<number | null>(null);
    const navigate = useNavigate();

    const generateCustomChartData = useCallback(() => {
        return data.map((item) => {
            const value = item.value as number[];
            return { name: t(item.name ?? ''), value: value[0] };
        });
    }, [data, t]);

    const generateChart = (chartType: string) => {
        switch (chartType) {
            case 'bar':
            case 'column':
                return data?.map((item: any, index: number) => {
                    return (
                        <ChartSeriesItem
                            key={index}
                            type={chartType}
                            data={item.value}
                            tooltip={{
                                visible: true,
                                format: formatting,
                            }}
                            name={t(item.name)}
                            color={item.color}
                        />
                    );
                });
            case 'area':
                return data?.map((item: any, index: number) => {
                    return (
                        <ChartSeriesItem
                            key={index}
                            type={chartType}
                            data={t(item.data)}
                            overlay={{
                                gradient: 'sharpBevel',
                            }}
                            tooltip={{
                                visible: true,
                                format: formatting,
                            }}
                            categoryField="category"
                            field="value"
                            name={title}
                        />
                    );
                });
            case 'line':
                return (
                    <ChartSeriesItem
                        type="line"
                        data={data}
                        tooltip={{
                            visible: true,
                            format: formatting,
                        }}
                    />
                );
            case 'pie':
                return (
                    <ChartSeriesItem
                        type="pie"
                        overlay={{
                            gradient: 'sharpBevel',
                        }}
                        tooltip={{
                            visible: true,
                        }}
                        autoFit={true}
                        data={generateCustomChartData()}
                        categoryField="name"
                        field="value">
                        <ChartSeriesLabels position="outsideEnd" content={generateLabelContent} />
                    </ChartSeriesItem>
                );

            case 'donut':
                return (
                    <ChartSeriesItem
                        type="donut"
                        data={generateCustomChartData()}
                        categoryField="name"
                        field="value"
                        tooltip={{
                            visible: true,
                        }}
                        autoFit={true}>
                        <ChartSeriesLabels position="outsideEnd" content={generateLabelContent} />
                    </ChartSeriesItem>
                );

            default:
                return null;
        }
    };

    const handleSeriesClick = (event: SeriesClickEvent) => {
        setBarChartIndex(event.series.index);
        setAnchorEl(event.nativeEvent.target);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const generateMenuItems = useCallback(() => {
        let menuItemsData: ChartPoint[] = [];
        let status = '';
        switch (barChartIndex) {
            case 0:
                menuItemsData = menuData?.totalAvailablePoints ?? [];
                status = 'points.statuses.available';
                break;
            case 1:
                menuItemsData = menuData?.totalOutOfOrderPoints ?? [];
                status = 'points.statuses.outOfOrder';
                break;
            case 2:
                menuItemsData = menuData?.totalOccupiedPoints ?? [];
                status = 'points.statuses.occupied';
                break;
            case 3:
                menuItemsData = menuData?.totalInUsePoints ?? [];
                status = 'points.statuses.inUse';
                break;
            default:
                menuItemsData = menuData?.totalUnknownPoints ?? [];
                status = 'points.statuses.unknown';
        }

        return (
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px 3px 10px 15px',
                        alignItems: 'center',
                    }}>
                    <Typography variant="subtitle2">{`${t('charts.points', {
                        status: t(status),
                    })} (${menuItemsData.length})`}</Typography>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Box className="menu-item">
                    {menuItemsData.map((menuItem, key) => {
                        return (
                            <MenuItem
                                sx={{
                                    width: '50%',
                                    padding: '5px 0px 5px 0px',
                                }}
                                key={key}
                                onClick={() => navigate(`/points/${menuItem.id}/edit`)}>
                                <EllipsisText variant="body2" maxWidth={200}>
                                    <> {menuItem.name}</>
                                </EllipsisText>
                            </MenuItem>
                        );
                    })}
                </Box>
            </Box>
        );
    }, [barChartIndex, menuData, navigate, t]);

    const anchorElPosition = useMemo(() => anchorEl?.getBoundingClientRect(), [anchorEl]);
    const generateEntitiesMenu = (
        <Menu
            className="entities-menu"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorPosition={{ left: anchorElPosition?.left ?? 0, top: anchorElPosition?.top ?? 0 }}
            anchorReference="anchorPosition">
            {generateMenuItems()}
        </Menu>
    );

    const generateLabelContent = (e: any) => {
        return e.value > 0 ? `${(e.percentage * 100).toFixed(2)}%` : '';
    };

    const memoChart = useMemo(() => {
        return (
            <Chart
                style={{
                    width: width,
                    height: height,
                }}
                seriesColors={seriesColor ?? undefined}
                {...(menuData ? { onSeriesClick: handleSeriesClick } : {})}>
                <ChartArea height={graphHeight} background={BACKGROUND_COLOR} />
                {/* y axis */}
                <ChartValueAxis>
                    <ChartValueAxisItem
                        min={min}
                        max={max}
                        labels={{
                            color: TEXT_COLOR,
                            ...(formatting && {
                                format: `{0:${formatting}}`,
                            }),
                        }}
                    />
                </ChartValueAxis>

                {/* x axis */}
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        categories={categoryAxis}
                        title={{ text: categoryAxisTitle, color: TEXT_COLOR }}
                        labels={{ color: TEXT_COLOR }}
                    />
                </ChartCategoryAxis>

                {/* data */}
                <ChartSeries>{generateChart(type)}</ChartSeries>

                {/* legend */}
                <ChartLegend
                    margin={0}
                    padding={0}
                    visible={legendVisibility}
                    position={legendPosition}
                    labels={{ color: TEXT_COLOR }}
                />
            </Chart>
        );
    }, [
        data,
        categoryAxis,
        categoryAxisTitle,
        formatting,
        graphHeight,
        height,
        legendPosition,
        legendVisibility,
        max,
        min,
        type,
        width,
        seriesColor,
    ]);

    return (
        <div role={role}>
            {memoChart}
            {menuData && generateEntitiesMenu}
        </div>
    );
};

export default memo(GraphicBlock);
