import { createSlice } from '@reduxjs/toolkit';
import { SIDEBAR_WIDTH_MAX } from '../../../constants';

interface LayoutState {
    sideWidth: number;
}
const initialState: LayoutState = {
    sideWidth: SIDEBAR_WIDTH_MAX,
};
// reduces
const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        updateSideWidth: (state, { payload }) => {
            state.sideWidth = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { updateSideWidth } = layoutSlice.actions;
export default layoutSlice.reducer;
