import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
    AssignAllSitesToTemplate,
    AssignSitesToTemplate,
    ExtendedGetReqProps,
    ReceiptStateTypes,
    TemplateFormValues,
    UpdateTemplateModel,
    UpdateTranslation,
} from '../../interfaces';
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { buildQueryParams } from '../../../../helpers';
import { Interceptor } from '../../../common';

const initialState: ReceiptStateTypes = {
    templates: INITIAL_DATA_STATE,
    template: null,
    fields: [],
};

// create a thunk for getting all mandator's templates (paginated)
export const getReceiptTemplates = createAsyncThunk(
    'digitalReceipts/templates',
    async ({ mandator_id, data_state }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(
                `${URLS.DigitalReceiptTemplates}/${mandator_id}/templates`,
                { params: { ...queryParams } }
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting template by id
export const getReceiptTemplateById = createAsyncThunk(
    'digitalReceipts/template',
    async (templateId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(
                `${URLS.DigitalReceiptsTemplates}/${templateId}`
            );
            return response.data.data[0];
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for delete one template
export const deleteReceiptTemplate = createAsyncThunk(
    'digitalReceipts/deleteTemplate',
    async (templateId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.DigitalReceiptsTemplates}/${templateId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for delete a list of templates
export const deleteReceiptTemplates = createAsyncThunk(
    'digitalReceipts/deleteTemplates',
    async (templates: string[], { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.DigitalReceiptsTemplates}`, {
                data: templates,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for adding a template
export const addTemplate = createAsyncThunk(
    'digitalReceipt/template/add',
    async (template: TemplateFormValues, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.DigitalReceiptsTemplates}`, {
                ...template,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for updating a template
export const updateTemplate = createAsyncThunk(
    'digitalReceipt/template/update',
    async ({ template, templateId }: UpdateTemplateModel, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.DigitalReceiptsTemplates}/${templateId}`, {
                ...template,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for assign sites to a template
export const assignSitesToTemplate = createAsyncThunk(
    'digitalReceipt/template/assign',
    async (
        assignationData: AssignAllSitesToTemplate | AssignSitesToTemplate[],
        { rejectWithValue }
    ) => {
        try {
            const templateAssignations = Array.isArray(assignationData)
                ? assignationData
                : { ...assignationData };
            return await Interceptor().patch(
                `${URLS.SiteAssignmentsTemplates}`,
                templateAssignations
            );
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create thunk for getting receipt fields
export const getReceiptFields = createAsyncThunk(
    'digitalReceipts/fields',
    async (_, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.ReceiptFields}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const updateTranslation = createAsyncThunk(
    'digitalReceipts/translation/update',
    async (translations: UpdateTranslation[], { rejectWithValue }) => {
        try {
            return await Interceptor().patch(`${URLS.ReceiptFields}`, translations);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

const digitalReceiptsSlice = createSlice({
    name: 'digitalReceipts',
    initialState,
    reducers: {
        updateTemplates: (state, action) => {
            state.templates.data = [...action.payload];
        },
        resetTemplates: (state) => {
            state.templates = initialState.templates;
        },
        resetReceiptTemplate: (state) => {
            state.template = initialState.template;
        },
        resetReceiptFields: (state) => {
            state.fields = initialState.fields;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getReceiptTemplates.fulfilled, (state, { payload }) => {
            state.templates.data = [...payload.data];
            state.templates.total = payload.meta.total;
        });

        builder.addCase(getReceiptTemplateById.fulfilled, (state, { payload }) => {
            state.template = payload;
        });
        builder.addCase(getReceiptFields.fulfilled, (state, { payload }) => {
            state.fields = [...payload.data];
        });
    },
});

export const { resetReceiptTemplate, resetReceiptFields, resetTemplates, updateTemplates } =
    digitalReceiptsSlice.actions;
export default digitalReceiptsSlice.reducer;
