import { createSlice } from '@reduxjs/toolkit';

export interface FormTypes {
    validationSchema: any;
}

const initialState = {
    validationSchema: null,
};

// reducers
const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        updateValidationSchema: (state, action) => {
            state.validationSchema = action.payload;
        },
        resetValidationSchema: (state) => {
            state.validationSchema = initialState.validationSchema;
        },
    },
});

export const { updateValidationSchema, resetValidationSchema } = formSlice.actions;
export default formSlice.reducer;
