import { t } from 'i18next';

// site opening hours selection values
export const OPENING_HOURS = [
    { name: 'Mo-Sun', value: 'alldays' },
    { name: 'Mo-Fri', value: 'weekdays' },
    { name: 'Sat', value: 'saturday' },
    { name: 'Sun', value: 'sunday' },
];
// site status selection values
export const SITE_STATUSES = [
    { name: t('general.labels.active'), value: 'active' },
    { name: t('general.labels.inactive'), value: 'inactive' },
    { name: t('sites.construction'), value: 'construction' },
    { name: t('sites.dismantled'), value: 'dismantled' },
];
