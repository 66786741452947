import { Typography, TypographyProps } from '@mui/material';
interface EllipsisTextProps {
    variant: TypographyProps['variant'];
    maxWidth: number;
    children?: JSX.Element | string;
}

/**
 * Renders a trimmed text according to the maximum width.
 *
 * @param {EllipsisTextProps} props - The properties of the component.
 * @param {TypographyProps['variant']} props.variant - The variant of the Typography component.
 * @param {number} props.maxWidth - The maximum width of the text.
 * @param {JSX.Element | string} [props.children] - The content of the Typography component.
 * @return {JSX.Element} The rendered Typography component.
 */
const EllipsisText = ({ variant, maxWidth, children }: EllipsisTextProps): JSX.Element => {
    return (
        <Typography
            variant={variant}
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: maxWidth,
            }}>
            <>{children}</>
        </Typography>
    );
};

export default EllipsisText;
