import { AnyAction, createSlice } from '@reduxjs/toolkit';

interface LoadingState {
    loading: boolean;
    actionsCounter: number;
}

const initialState: LoadingState = {
    loading: false,
    actionsCounter: 0,
};

const actions = [
    'remoteControl/actions/add/',
    'remoteControl/actions/list/',
    'remoteControl/patchTransaction/',
    'remoteControl/addPaymentToTransaction/',
    'dashboard/transactions/', //avoid loading for Dashboard page
];
const actionStates = ['pending', 'fulfilled', 'rejected'];

const excludedActions = actions
    .map((action) => {
        const excludedAction: string[] = [];
        actionStates.forEach((actionState) => {
            excludedAction.push(`${action}${actionState}`);
        });
        return excludedAction;
    })
    .flat();

function isPendingAction(action: AnyAction) {
    return action.type.endsWith('/pending') && excludedActions.indexOf(action.type) === -1;
}
function isDoneAction(action: AnyAction) {
    return (
        (action.type.endsWith('/rejected') || action.type.endsWith('/fulfilled')) &&
        excludedActions.indexOf(action.type) === -1
    );
}

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isPendingAction, (state) => {
            state.actionsCounter = state.actionsCounter + 1;
            if (state.actionsCounter > 0) {
                state.loading = true;
            }
        });
        builder.addMatcher(isDoneAction, (state) => {
            state.actionsCounter = state.actionsCounter - 1;
            if (state.actionsCounter <= 0) {
                state.loading = false;
                state.actionsCounter = 0;
            }
        });
    },
});
export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
