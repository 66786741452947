import { lazy } from 'react';

export const HostProvidersList = lazy(() => import('../pages/hostProvider/HostProvidersList'));
export const AddHostProvider = lazy(() => import('./hostProvider/AddHostProvider'));
export const HostProviderDetails = lazy(() => import('../pages/hostProvider/HostProviderDetails'));
export const ProductMapping = lazy(() => import('../pages/productMapping/ProductMapping'));
export const ConnectionConfiguration = lazy(
    () => import('../pages/connectionConfiguration/ConnectionConfiguration')
);
export const CardProfileList = lazy(() => import('../pages/CardProfile/CardProfileList'));
export const AddCardProfile = lazy(() => import('../pages/CardProfile/AddCardProfile'));
export const CardProfileDetails = lazy(() => import('../pages/CardProfile/CardProfileDetails'));
