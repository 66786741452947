import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

export const getProductAssignmentColumns = (
    customCells?: Map<string, ComponentType<GridCellProps>>,
    sortable?: boolean,
    isHiddenColumnMenu?: boolean
): IGridColumn[] => {
    return [
        {
            field: 'product_name',
            title: 'general.labels.name',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable,
            isHiddenColumnMenu,
            editable: false,
        },
        {
            field: 'product_code',
            title: 'products.code',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable,
            isHiddenColumnMenu,
            editable: false,
        },
        {
            field: 'mandator_product_code',
            title: 'products.mandatorCode',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('mandator_product_code'),
            sortable,
            isHiddenColumnMenu,
        },
        {
            field: 'mandator_product_name',
            title: 'products.mandatorName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('mandator_product_name'),
            sortable,
            isHiddenColumnMenu,
        },
    ];
};
