import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorTools, EditorToolsSettings, EditorUtils } from '@progress/kendo-react-editor';
import { Button } from '@progress/kendo-react-buttons';

import { useAppDispatch } from '../../hooks';
import { displayModal } from '../modal/modalDuck';

import { MODAL_TYPES } from '../../../constants';
import { CustomUploadImageProps } from '../interfaces';

const imageSettings = EditorToolsSettings.image;

interface InsertImageProps extends EditorTools.InsertImageProps, CustomUploadImageProps {}

const InsertImage = ({
    view,
    saveUrl,
    fileMetadata,
    uploadImageLabel,
    imagePath,
}: InsertImageProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const state = view && view.state;
    const nodeType = state ? state.schema.nodes[imageSettings.node] : undefined;

    const openDialoag = useCallback(() => {
        dispatch(
            displayModal({
                showModal: true,
                title: t('general.labels.insertImage'),
                type: MODAL_TYPES.UplaodImage,
                saveUrl,
                fileMetadata,
                uploadImageLabel,
                editorView: view,
                imagePath,
            })
        );
    }, [dispatch, fileMetadata, imagePath, saveUrl, t, uploadImageLabel, view]);

    return (
        <Button
            onClick={openDialoag}
            disabled={!nodeType || !state || !EditorUtils.canInsert(state, nodeType)}
            onMouseDown={(e) => e.preventDefault()}
            onPointerDown={(e) => e.preventDefault()}
            title={t('general.labels.insertImage')}
            {...imageSettings.props}
        />
    );
};
export default InsertImage;
