import { CompositeFilterDescriptor, FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import {
    DropDownList,
    DropDownListChangeEvent,
    DropDownListFilterChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

//redux
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getActiveCardProfile } from '../../fleetnetCommon/fleetNetModal/cardProfilesModalDuck';
import { getCardIssuerById, updateCardIssuer } from './cardIssuerDuck';

//plugins
import { Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

// common components, interfaces, constants and helpers
import { Actions } from '../../../../constants';
import { CUSTOM_INITIAL_GRID_STATE } from '../../../../constants/kendoConstants';
import {
    clearLSOnComponentLeave,
    generateFormTitle,
    getStringValidations,
    preventSubmit,
    sanitizeFields,
    validateForm,
} from '../../../../helpers';
import {
    FormComponentFooter,
    FormComponentHeader,
    FormInput,
    SelectComponent,
} from '../../../common';
import { CustomDataState } from '../../../common/interfaces';
import { CARD_ISSUER_STATUS } from '../../constants/fleetNetGeneral';
import { convertIntoTitleCase } from '../../helpers/fleetnetConverters';
import { CardIssuerFormValues } from '../../interfaces/cardIssuer';
import { CardProfileReqProps } from '../../interfaces/cardProfile';

const CardIssuerEditView = () => {
    const { t } = useTranslation();
    const [tabState, setTabState] = useState(1);
    const [isDisabled, setIsDisabled] = useState(true);
    const dispatch = useAppDispatch();
    const { cardIssuer } = useAppSelector<any>((state) => state.cardIssuers);
    const [cardIssuerId, setCardIssuerId] = useState<string | null>(null);
    const navigate = useNavigate();
    const paramData: { id?: string; action?: string } = useParams();
    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState<any>([]);

    const handleTabs = (value: number) => {
        setTabState(value);
    };

    //Preparing patch data as per  drop down selected item
    const patchData = async (
        fieldProps: CardIssuerFormValues,
        selectedItemProps: { id: undefined }
    ) => {
        if (selectedItemProps?.id !== undefined) {
            return {
                name: fieldProps?.name,
                code: fieldProps?.code,
                status: fieldProps?.status,
                cardProfiles: [
                    {
                        id: `${selectedItemProps?.id}`,
                    },
                ],
            };
        } else {
            return {
                name: fieldProps?.name,
                code: fieldProps?.code,
                status: fieldProps?.status,
            };
        }
    };

    const handleSubmit = async (fields: CardIssuerFormValues) => {
        fields = sanitizeFields(fields);
        const patchRes = await patchData(fields, selectedItem);

        if (cardIssuer) {
            const response = await dispatch(
                updateCardIssuer({ cardIssuer: patchRes, cardIssuerId: cardIssuerId })
            );
            if (updateCardIssuer.fulfilled.match(response)) {
                const message = t('general.messages.successfulAction', {
                    action: t('general.labels.updated'),
                });
                toast.success(message, { theme: 'colored' });
                clearLSOnComponentLeave();
                navigate('/fleetnet');
            }
        }
    };

    const createInitialValues = useCallback(() => {
        let initialValues = {
            code: '',
            name: '',
            status: '',
            cardProfiles: [
                {
                    name: '',
                },
            ],
        };
        if (cardIssuerId && cardIssuer) {
            initialValues = {
                name: cardIssuer.name !== undefined ? cardIssuer.name : '',
                code: cardIssuer.code !== undefined ? cardIssuer.code : '',
                status: cardIssuer?.status,
                cardProfiles: [
                    {
                        name: cardIssuer?.cardProfiles[0]?.name,
                    },
                ],
            };
        }
        return initialValues;
    }, [cardIssuer, cardIssuerId]);

    /**
     *  get all cardIssuer data for edit page
     */
    const getcardIssuer = useCallback(() => {
        if (paramData?.id !== undefined) {
            dispatch(getCardIssuerById(paramData?.id));
        }
    }, [dispatch]);

    /**
     *  get logged in cardIssuer data
     */
    useEffect(() => {
        if (paramData?.id !== undefined) {
            setCardIssuerId(paramData?.id);
        }
        paramData.action === Actions.View ? setIsDisabled(true) : setIsDisabled(false);
        if (paramData?.id) {
            getcardIssuer();
        }
    }, [dispatch, getcardIssuer, t]);

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            name: getStringValidations(2, 30, t('general.labels.name'), true),
            code: getStringValidations(2, 30, t('general.labels.code'), true),
        });
    }, [t]);

    const filterData = useCallback(
        (filter: FilterDescriptor | CompositeFilterDescriptor) => {
            const filtered = filterBy(data, filter);
            setFilteredData(filtered);
        },
        [data]
    );

    const filterChange = useCallback(
        (event: DropDownListFilterChangeEvent) => {
            filterData(event.filter);
        },
        [filterData]
    );

    const getCardProfilesData = useCallback(
        async (dataState: CustomDataState) => {
            const requestObj: CardProfileReqProps = {
                dataState,
            };

            const response = await dispatch(getActiveCardProfile(requestObj));
            //Making name in title case so that the first letter should look capital in drop down for good look and feel
            const titledPayload = response?.payload?.map((res: { id: number; name: string }) => ({
                ...res,
                name: convertIntoTitleCase(res),
            }));
            setData(titledPayload);
            setFilteredData(titledPayload);
        },
        [dispatch]
    );

    useEffect(() => {
        getCardProfilesData(CUSTOM_INITIAL_GRID_STATE);
    }, [CUSTOM_INITIAL_GRID_STATE, getCardProfilesData]);

    const handleChange = useCallback((event: DropDownListChangeEvent) => {
        setSelectedItem(event.target.value);
    }, []);

    useEffect(() => {
        setSelectedItem({
            name:
                cardIssuer?.cardProfiles[0]?.name.substring(0, 1).toUpperCase() +
                    cardIssuer?.cardProfiles[0]?.name.slice(1) || '',
        });
    }, [cardIssuer]);

    return (
        <>
            <FormComponentHeader
                title={generateFormTitle(paramData, cardIssuer?.name ?? '', 'cardIssuer.entity')}
            />

            <Paper elevation={0} sx={{ mt: 2, backgroundColor: '#fff', width: '100%' }}>
                <Formik
                    initialValues={createInitialValues()}
                    enableReinitialize
                    onSubmit={(fields: CardIssuerFormValues) => {
                        handleSubmit(fields);
                    }}
                    validate={validateForm}
                    validationSchema={validationSchema}>
                    {(props) => (
                        <Form onKeyDown={preventSubmit}>
                            <Tabs
                                value={tabState}
                                onChange={(_, value: number) => {
                                    handleTabs(value);
                                }}
                                scrollButtons="auto">
                                <Tab value={1} label={t('general.labels.generalTab')} wrapped />
                            </Tabs>

                            <Grid
                                container
                                columnSpacing={{ xs: 2, sm: 2, md: 4, lg: 2 }}
                                spacing={2}>
                                {tabState === 1 && (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            mt={3}
                                            sx={{ flexDirection: 'column', display: 'flex' }}>
                                            <FormInput
                                                label={t('cardIssuer.issuer')}
                                                fieldName="name"
                                                value={props.values.name || ''}
                                                type="text"
                                                handleChange={props.handleChange}
                                                handleBlur={props.handleBlur}
                                                errors={props.errors}
                                                touched={props.touched}
                                                disabled={isDisabled}
                                            />
                                            <FormInput
                                                label={t('general.labels.code')}
                                                fieldName="code"
                                                value={props.values.code || ''}
                                                type="text"
                                                handleChange={props.handleChange}
                                                handleBlur={props.handleBlur}
                                                errors={props.errors}
                                                touched={props.touched}
                                                disabled={isDisabled}
                                            />
                                            <SelectComponent
                                                data={CARD_ISSUER_STATUS}
                                                defaultValue={props.values.status}
                                                titleEntry="name"
                                                onChange={props.handleChange}
                                                className="formControl"
                                                valueEntry="value"
                                                name={'status'}
                                                formChild
                                                label={t('general.labels.status')}
                                                touched={props.touched}
                                                errors={props.errors}
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={8} mt={3}>
                                            <Typography
                                                style={{
                                                    margin: '0',
                                                    fontSize: '16px',
                                                    fontWeight: '700',
                                                    fontFamily: 'Open Sans-regular',
                                                    lineHeight: '1.167',
                                                    marginBottom: '4px',
                                                    marginLeft: '5px',
                                                }}>
                                                {t(`cardIssuer.linkedProfile`)}
                                            </Typography>
                                            <DropDownList
                                                data={filteredData}
                                                value={selectedItem}
                                                textField="name"
                                                filterable={true}
                                                onFilterChange={filterChange}
                                                onChange={handleChange}
                                                disabled={isDisabled}
                                                style={{
                                                    height: '38px',
                                                    minHeight: '1.4375em',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    width: '320px',
                                                    backgroundColor: 'white',
                                                    border: '0.1px solid gray',
                                                    borderRadius: '3px',
                                                    marginLeft: '7px',
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            {tabState === 1 && <FormComponentFooter isDisabled={isDisabled} />}
                        </Form>
                    )}
                </Formik>
            </Paper>
        </>
    );
};

export default CardIssuerEditView;
