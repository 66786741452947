import { DataResult } from '@progress/kendo-data-query';
import { FormikProps } from 'formik';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { IGridColumn, ModulePermissions } from '../app/common/interfaces';
import { ModuleType } from '../app/common/layout/userRightsDuck';
import { LayoutProps } from '../app/pages/interfaces';
import { MARKETPLACE_MENU_ITEMS, servicesModulesMapping } from '../constants';

export const removeEmptyAttributes = (obj: object): any => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, v]) => v != null && v !== '')
            .map(([k, v]) => [k, v === Object(v) ? removeEmptyAttributes(v) : v])
    );
};

export const removeRedundantAttributes = (obj: object, fields: string[]): any => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([key]) => !fields.includes(key))
            .map(([k, v]) => [k, v === Object(v) ? removeRedundantAttributes(v, fields) : v])
    );
};

export const sanitizeFields = (obj: object): any => {
    if (typeof obj === 'string') return sanitizeStringField(obj);
    if (!obj || obj instanceof Date) return obj;
    if (Array.isArray(obj))
        return obj.map((value) => (value !== null ? sanitizeFields(value) : value));
    if (Object.entries(obj).length > 1) {
        return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, sanitizeFields(v)]));
    }
    return obj;
};

export const sanitizeStringField = (value: string) => {
    if (typeof value === 'string') {
        return value === '' ? null : value.trim();
    }
    return value;
};

export const normalizeStringField = (value: string) => {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const resetFormFields = <T>(
    formRef: RefObject<FormikProps<T>>,
    fields: string[],
    customValue?: any
) => {
    if (formRef.current?.values) {
        fields.forEach((field) => {
            formRef.current?.setFieldValue(field, customValue ?? null);
        });
    }
};

export const deepCloneArray = <T>(value: T[] | DataResult | LayoutProps) => {
    return JSON.parse(JSON.stringify(value));
};

export const checkModuleRights = (
    module: ModuleType | null,
    userRights: ModulePermissions[],
    payedModules?: Map<string, boolean | undefined>,
    payedComponents?: Map<string, boolean | undefined>,
    activeModules?: string[]
) => {
    const currentModuleRights = userRights.filter((el) => el.module === module?.name);
    const moduleMarketplaceDependency = MARKETPLACE_MENU_ITEMS.includes(module?.name ?? '');
    let selectedModuleRight = true;

    const isCurrentModuleHidden = currentModuleRights.every(
        (currentModulePermissions: ModulePermissions) => {
            return (
                currentModulePermissions.view === false ||
                payedComponents?.get(currentModulePermissions.sub_component ?? '') === false
            );
        }
    );
    if (isCurrentModuleHidden || payedModules?.get(module?.name ?? '') === false) {
        selectedModuleRight = false;
    }
    if (moduleMarketplaceDependency) {
        const marketplaceMenuEntriesForCurrentModule = currentModuleRights.filter((moduleItem) =>
            Object.keys(servicesModulesMapping).includes(moduleItem.sub_component ?? '')
        );
        const availableMenuEntries = marketplaceMenuEntriesForCurrentModule.some((menuEntry) => {
            return (
                activeModules?.includes(servicesModulesMapping[menuEntry.sub_component ?? '']) &&
                menuEntry.view === true
            );
        });

        const menuEntriesForModuleWithoutDependency = currentModuleRights
            .filter((items) => !marketplaceMenuEntriesForCurrentModule.includes(items))
            .some((moduleItem) => {
                return (
                    moduleItem.view === true &&
                    payedComponents?.get(moduleItem.sub_component ?? '') !== false
                );
            });

        if (availableMenuEntries) {
            selectedModuleRight = true;
        }
        if (!availableMenuEntries && !menuEntriesForModuleWithoutDependency) {
            selectedModuleRight = false;
        }
    }
    return selectedModuleRight;
};

export const changeArrayElements = <T>(currentIndex: number, changedIndex: number, array: T[]) => {
    if (array[currentIndex] && array[changedIndex])
        [array[currentIndex], array[changedIndex]] = [array[changedIndex], array[currentIndex]];
    return array;
};

export const sortArrayDescending = <T extends Record<string, any>>(arr: T[], fieldName: string) => {
    return arr.sort((firstItem, secondItem) => secondItem[fieldName][0] - firstItem[fieldName][0]);
};

export const sortByField = <T extends Record<string, any>>(
    arr: T[],
    fieldName: string,
    direction?: string
) => {
    if (direction === 'desc') {
        const sortDescending = (firstItem: T, secondItem: T) => {
            return typeof firstItem[fieldName] === 'string'
                ? firstItem[fieldName].toUpperCase() < secondItem[fieldName].toUpperCase()
                : firstItem[fieldName] < secondItem[fieldName];
        };
        return arr.sort((firstItem: T, secondItem: T) =>
            sortDescending(firstItem, secondItem) ? 1 : -1
        );
    }
    const sortAscending = (firstItem: T, secondItem: T) => {
        return typeof firstItem[fieldName] === 'string'
            ? firstItem[fieldName].toUpperCase() > secondItem[fieldName].toUpperCase()
            : firstItem[fieldName] > secondItem[fieldName];
    };
    return arr.sort((firstItem: T, secondItem: T) =>
        sortAscending(firstItem, secondItem) ? 1 : -1
    );
};

export const flattenArray = <T extends Record<string, any>>(arr: T[], nestedField: string) => {
    return arr.reduce((prev: T[], curr: T) => {
        prev = [...prev, curr];
        if (curr[nestedField]) {
            prev = [...prev, ...curr[nestedField]];
        }
        return prev;
    }, []);
};

//compares 2 objects of the same type and returns an array of the fields that are different, processed by the function sent as parameter
//the 'initialItem' param is optional; in case it is missing, the 'initialItemDifferences' array will be empty
export const generateComparisonDataItem = <T extends Record<string, any>>(
    dataProcessor: (key: string | number | symbol, value: any) => string,
    keysArray: Array<string | number | symbol>,
    currentItem: T,
    initialItem?: T
) => {
    const comparisonData = {
        currentItemDifferences: [] as string[],
        initialItemDifferences: [] as string[],
    };

    Object.keys(currentItem).forEach((objectKey) => {
        const key = objectKey as keyof T;
        if (keysArray.includes(key)) {
            if ((initialItem && currentItem[key] !== initialItem[key]) || !initialItem) {
                const currentItemData = dataProcessor(key, currentItem[key]);
                const initialItemData = initialItem ? dataProcessor(key, initialItem[key]) : '';
                currentItemData !== '' &&
                    comparisonData.currentItemDifferences.push(currentItemData);
                initialItemData !== '' &&
                    comparisonData.initialItemDifferences.push(initialItemData);
            }
        }
    });
    return comparisonData;
};

// check to see if any item is assigned to parent
export const isBulkDeleteAllowed = <T extends Record<string, any>>(
    haystack: any,
    conditionField?: string
) => {
    return conditionField
        ? haystack.every((straw: T) => straw[conditionField].toString() === '0')
        : haystack.every((straw: T) => straw.toString() === '0');
};

// check to see if item is assigned to parent
export const isDeleteAllowed = <T>(
    entities: T[],
    itemAttributeValue: string,
    conditionField: string
) => {
    const existingEntity = entities.find(
        (entity) => entity['id' as keyof T] === itemAttributeValue
    );
    return (existingEntity?.[conditionField as keyof T] as number) === 0;
};
export const getKeyByValue = (map: Map<string, string>, value: string) => {
    return Array.from(map.keys()).find((key) => map.get(key) === value);
};

export const updateGridColumns = (
    columns: IGridColumn[],
    initialColumns: IGridColumn[],
    setColumns: Dispatch<SetStateAction<IGridColumn[]>>
) => {
    // update only the last element
    const newColumns = columns.map((column, index) =>
        index === columns.length - 1 ? initialColumns[initialColumns.length - 1] : column
    );
    setColumns(newColumns);
};

// get difference between 2 arrays of objects => array of objects
export const getDifference = <T>(array1: T[], array2: T[], attribute: string) => {
    return array1.filter((object1) => {
        return !array2.some((object2) => {
            return object1[attribute as keyof T] === object2[attribute as keyof T];
        });
    });
};

// get difference between 2 objects
export const getObjectsDifferences = (initialObject: any, newObject: any) => {
    const changedObject: any = {};
    for (const key in initialObject) {
        if (
            Object.prototype.hasOwnProperty.call(newObject, key) &&
            initialObject[key] !== newObject[key]
        ) {
            changedObject[key] = newObject[key];
        }
    }
    return changedObject;
};

export const isNullishArray = <T>(arr: T[]): boolean => {
    const allNull = arr.every((element) => element === null);
    const allUndefined = arr.every((element) => element === undefined);

    if (allNull || allUndefined) return true;
    return false;
};
