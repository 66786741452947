import { useEffect } from 'react';
import { FormikProps } from 'formik';

// When change language is triggered, re-validate the form as to get all errors translated
export const useTranslateFormErrors = <T>(formRef: React.RefObject<FormikProps<T>>) => {
    useEffect(() => {
        if (localStorage.languageChanged === '1') {
            formRef.current?.validateForm();
            localStorage.removeItem('languageChanged');
        }
    });
};
