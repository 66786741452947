import { Check } from '@mui/icons-material';
import { Box, FormLabel } from '@mui/material';
import { PasswordValidationError } from '../../helpers/handleValidations';
interface PasswordValidationsProps {
    errors: PasswordValidationError[];
}
/**
 * Renders validation errors for each error in the errors array.
 *
 * @param {PasswordValidationsProps} props - The props for the component.
 * @param {PasswordValidationError[]} props.errors - Array of password validation errors
 * @return {JSX.Element} JSX element representing the validation errors
 */
const PasswordValidations = ({ errors }: PasswordValidationsProps): JSX.Element => {
    return (
        <Box display="flex" flexDirection="column" sx={{ py: 2 }}>
            {errors.map((validationError) => (
                <FormLabel
                    role="validation-label"
                    key={validationError.value}
                    className={`validation-error ${
                        !validationError.exists ? 'checked-validation-error' : ''
                    }`}>
                    {!validationError.exists && <Check fontSize="inherit" />}
                    {validationError.value}
                </FormLabel>
            ))}
        </Box>
    );
};
export default PasswordValidations;
