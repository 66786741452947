import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

//redux
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getSiteById } from '../../../pages/sites/siteDuck';

// common components, interfaces, constants and helpers
import { t } from 'i18next';
import { FormComponentHeader } from '../../../common';
import ExpandFleetnetSitesComponent from './ExpandFleetnetSitesComponent';

const FleetnetSiteDetails = () => {
    const dispatch = useAppDispatch();
    const { site } = useAppSelector((state) => state.sites);
    const paramData: { id?: string; action?: string } = useParams();

    // get site data for edit page
    const getSite = useCallback(
        async (siteID: string) => {
            dispatch(getSiteById(siteID));
        },
        [dispatch]
    );

    useEffect(() => {
        if (paramData.id) {
            getSite(paramData.id);
        }
    }, [dispatch, getSite, paramData.id]);

    const configureLabel = useMemo(() => t('services.manageFleetnets.configure'), [t]);

    return (
        <>
            <FormComponentHeader title={`${configureLabel} ${site?.name ? site.name : ''}`} />
            {site && site.id === paramData?.id && (
                <ExpandFleetnetSitesComponent
                    dataItem={{
                        id: site.id,
                        name: site.name,
                        currency: site.currency,
                        mandator_id: site.mandator_id,
                        code: site.code,
                        timezone: site.timezone,
                    }}
                />
            )}
        </>
    );
};

export default FleetnetSiteDetails;
