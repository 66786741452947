import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// common components, interfaces, constants and helpers
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { Interceptor } from '../../../common';
import { fleetnetBuildQueryParams } from '../../helpers/fleetnetQueryBuilders';
import {
    FleetnetDeviceReqProps,
    FleetnetDeviceStateTypes,
    PostDataFleetnetDevice,
    UpdateFleetnetDeviceModel,
} from '../../interfaces/fleetnetDevices';

const initialState: FleetnetDeviceStateTypes = {
    fleetnetDevices: INITIAL_DATA_STATE,
    fleetnetDevice: null,
};

/**
 *  create a thunk for getting fleetnet device
 */
export const getFleetnetDeviceConfig = createAsyncThunk(
    'fleetnetDevice/list',
    async ({ dataState }: FleetnetDeviceReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = dataState ? fleetnetBuildQueryParams(dataState) : null;
            const response = await Interceptor().get(`${URLS.DeviceConfiguration}`, {
                params: { ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 *  create thunk for updating an device configuration
 */
export const updateFleetnetDeviceConfig = createAsyncThunk(
    'fleetnetDevice/update',
    async (
        { deviceConfiguration, deviceConfigurationId }: UpdateFleetnetDeviceModel,
        { rejectWithValue }
    ) => {
        try {
            return await Interceptor().patch(
                `${URLS.DeviceConfiguration}/${deviceConfigurationId}`,
                {
                    ...deviceConfiguration,
                }
            );
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 *  create a thunk for adding a fleetnet device configuration
 */
export const addFleetnetDeviceConfig = createAsyncThunk(
    'fleetnetDevice/add',
    async (deviceConfiguration: PostDataFleetnetDevice, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.DeviceConfiguration}`, {
                ...deviceConfiguration,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

export const deleteFleetnetDeviceConfig = createAsyncThunk(
    'fleetnetDevice/delete',
    async (deviceConfigurationId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(
                `${URLS.DeviceConfiguration}/${deviceConfigurationId}`
            );
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data);
        }
    }
);

const fleetnetDeviceSlice = createSlice({
    name: 'fleetnetDevice',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.fleetnetDevices.data = [...action.payload];
        },
        resetFleetnetDevice: (state) => {
            state.fleetnetDevice = initialState.fleetnetDevice;
        },
    },
    extraReducers: (builder) => {
        builder?.addCase(getFleetnetDeviceConfig?.fulfilled, (state, { payload }) => {
            state.fleetnetDevices.total = payload?.meta?.totalItems;
        });
    },
});

// Action creators are generated for each case reducer function
export const { updateItems, resetFleetnetDevice } = fleetnetDeviceSlice.actions;
export default fleetnetDeviceSlice.reducer;
