import { ChangeEvent, FocusEvent } from 'react';
import { FormikErrors, FormikValues, FormikTouched } from 'formik';
import { usePlacesWidget } from 'react-google-autocomplete';
import { FormControl, Typography, TextField, InputAdornment } from '@mui/material';
import { GOOGLE_API_KEY, REQUIRED_FIELD_CLASS_NAME } from '../../../constants';
import ReactCountryFlag from 'react-country-flag';

import { useAppSelector } from '../../hooks';

import {
    checkRequiredField,
    convertCountryNameToCode,
    normalizeStringField,
} from '../../../helpers';

interface AutocompleteInterface {
    type: string;
    defaultValue: string;
    label?: string;
    fieldName: string;
    country?: string;
    icon?: boolean;
    dirty?: boolean;
    errors?: FormikErrors<FormikValues>;
    touched?: FormikTouched<FormikValues>;
    placeholder?: string;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur: (e: FocusEvent<HTMLInputElement>) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    disabled: boolean;
}

interface Address {
    long_name: string;
    short_name: string;
    types: string[];
}

/**
 * Renders a Google Autocomplete component for address input.
 *
 * @param {AutocompleteInterface} props - The props for the component.
 * @param {string} props.type - The type of autocomplete (e.g. '(regions)', '(cities)', 'address').
 * @param {string} props.defaultValue - The default value for the input.
 * @param {string} [props.label] - The label for the input.
 * @param {string} props.fieldName - The name of the input field.
 * @param {string} [props.country] - The country restriction for the autocomplete.
 * @param {boolean} [props.icon] - Whether to display a country flag icon.
 * @param {boolean} [props.dirty] - Whether the input is dirty.
 * @param {FormikErrors<FormikValues>} [props.errors] - The errors for the input.
 * @param {FormikTouched<FormikValues>} [props.touched] - The touched state for the input.
 * @param {string} [props.placeholder] - The placeholder for the input.
 * @param {(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void} props.onChange - The change event handler for the input.
 * @param {(e: FocusEvent<HTMLInputElement>) => void} props.onBlur - The blur event handler for the input.
 * @param {(field: string, value: any, shouldValidate?: boolean) => void} props.setFieldValue - The function to set the value of the input field.
 * @param {boolean} props.disabled - Whether the input is disabled.
 * @return {JSX.Element} The rendered Google Autocomplete component.
 */
const GoogleAutoComplete = ({
    type,
    defaultValue,
    label,
    fieldName,
    country,
    icon,
    dirty,
    errors,
    touched,
    placeholder,
    onChange,
    setFieldValue,
    onBlur,
    disabled,
}: AutocompleteInterface): JSX.Element => {
    const { validationSchema } = useAppSelector((state) => state.formData);

    const handleInputChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: string
    ) => {
        if (event.target.value.length < 2 && field === 'street') {
            setFieldValue('city', '', false);
            setFieldValue('country', '', false);
        }
        onChange(event);
    };
    const inputFieldName = fieldName || '';
    const applyError = touched?.[inputFieldName] && errors?.[inputFieldName] ? true : false;
    const errorText = applyError ? errors?.[inputFieldName] : '';

    let countryCode: string = convertCountryNameToCode(defaultValue);
    const countryRestriction = () => {
        return country ? { country: convertCountryNameToCode(country) } : null;
    };
    const setAddress = (place: any, address: any) => {
        let streetNumber = '';
        let zipCode = '';
        let streetName = '';

        for (const item of address) {
            switch (item.types[0]) {
                case 'street_number':
                    streetNumber = item.long_name;
                    break;
                case 'route':
                    streetName = item.long_name;
                    break;
                case 'postal_code':
                    zipCode = item.long_name;
                    break;
            }
        }
        const completeAddress = streetName + ' ' + streetNumber;
        // replace accents and diacritics
        const val = normalizeStringField(completeAddress);

        setFieldValue(fieldName, val, true);
        setFieldValue('zip_code', zipCode);

        const placeholderCountry = address.filter((el: Address) => el.types[0] === 'country')[0]
            .long_name;

        const placeholderCity = place?.formatted_address.split(',');
        setLocation(place.geometry.location);
        setFieldValue('country', placeholderCountry.trim(), true);
        setTimeout(() => setFieldValue('city', placeholderCity[1].trim(), true), 100);

        countryCode = convertCountryNameToCode(placeholderCountry);
    };

    const setLocation = (location: any) => {
        setFieldValue('gps.latitude', Number.parseFloat(location.lat().toFixed(6)));
        setFieldValue('gps.longitude', Number.parseFloat(location.lng().toFixed(6)));
    };
    const { ref } = usePlacesWidget({
        apiKey: GOOGLE_API_KEY,
        language: 'en',
        onPlaceSelected: (place) => {
            const address = place.address_components;

            switch (type) {
                case '(regions)': //country
                    setFieldValue(fieldName, place.formatted_address.trim(), true);
                    countryCode = convertCountryNameToCode(place.formatted_address);
                    break;
                case '(cities)':
                    setFieldValue(fieldName, address[0].long_name.trim(), true);
                    break;
                case 'address':
                    setAddress(place, address);
                    break;
                default:
                    setFieldValue(fieldName, address[0].long_name.trim(), true);
            }
        },

        options: {
            types: [type],
            // get only used fields
            fields: ['address_components', 'formatted_address', 'geometry.location'],
            componentRestrictions: countryRestriction(),
        },
    });

    const iconAdornment = icon
        ? {
              startAdornment: (
                  <InputAdornment position="start">
                      <ReactCountryFlag
                          countryCode={countryCode}
                          svg
                          style={{
                              width: '2em',
                              height: '2em',
                          }}
                          alt={countryCode}
                      />
                  </InputAdornment>
              ),
          }
        : {};

    const fieldClassName =
        validationSchema && checkRequiredField(validationSchema, fieldName)
            ? REQUIRED_FIELD_CLASS_NAME
            : '';

    return (
        <FormControl className="formControl" variant="outlined">
            <Typography variant="h3" className={fieldClassName}>
                {label}
            </Typography>

            <TextField
                fullWidth
                color="secondary"
                variant="outlined"
                size="small"
                inputRef={ref}
                id={fieldName}
                name={fieldName}
                role={fieldName}
                onChange={(event) => {
                    handleInputChange(event, fieldName);
                }}
                onBlur={onBlur}
                value={defaultValue}
                placeholder={placeholder || ''}
                InputProps={iconAdornment}
                disabled={disabled}
                error={applyError}
            />

            <Typography
                color="#d63232"
                variant="caption"
                className="errorHeight"
                role={`error-${fieldName}`}>
                <>{errorText}</>
            </Typography>
        </FormControl>
    );
};

export default GoogleAutoComplete;
